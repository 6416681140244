import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./configurations/authConfig";
import { Provider } from 'react-redux';
import store from './store';
import ApiAzureService from "./services/ApiAzureService";
import  secureLocalStorage  from  "react-secure-storage";
//const root = ReactDOM.createRoot(document.getElementById('root'));

new ApiAzureService().getClientId().then((result) =>{
    //console.log(result);
    msalConfig.auth.clientId = result.clientId
    msalConfig.auth.authority = "https://login.microsoftonline.com/" + result.tenantId
    secureLocalStorage.setItem('clientId',result.clientId)
    //console.log(msalConfig);
    const rootElement = document.getElementById('root');
    const msalInstance = new PublicClientApplication(msalConfig);
    ReactDOM.render(
        <React.StrictMode>
            <MsalProvider instance={msalInstance}>
                <Provider store={store}>
                    <App />
                </Provider>
            </MsalProvider>
        </React.StrictMode>, rootElement
    );
}
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
