import React, { useState, useEffect } from 'react';
import { overallC0Image, overallC1Image ,overallC3Image,overallC4Image,overallC5Image,newRegisterIcon} from '../images'
import { AiOutlineInfoCircle } from "react-icons/ai";
import ReactTooltip from "react-tooltip";
import './../assets/css/OverallTable.css'
import { config } from '../config'
import { spiImage, bpiImage } from '../images';

const OverallTablePdf = () => {
  //console.log(props)
    useEffect(()=>{
      

    },[])
    
    
    return (
        <>
       
            <div id="col-overall-table" className="row row-cols-6 mx-auto overalltable" style={{width:'200mm',  fontSize: '11.5px', fontWeight: 'bold', textAlign: 'center' ,marginBottom:'0'}}>

                <div className="col bg-white border-start border-end shadow1 col-color-0" style={{  zIndex:"7" }} >
                    <div style={{paddingBottom: '25px'}} >
                        <div className="col-circle-out col-circle-color-out0">
                        <div className="col-circle-in">
                            <img src={overallC0Image} className="hvr-icon col-circle-image" />
                        </div>
                        </div>
                    </div>

                    <div className="header-block-down">Project Category
                    </div>
                    {/*
                    <div className="block-down"><div>&nbsp;</div></div>
                    */}
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />

                    <div className="block-col1 hvr-sweep-to-right">
                        <a href={config.BASE_URL + "/dashboard?projectMode=1"} style={{ fontWeight: 'bold'}}>
                            <span id="project-mode-1"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col1 hvr-sweep-to-right">
                        <a href={config.BASE_URL + "/dashboard?projectMode=2"} style={{ fontWeight: 'bold'}}>
                            <span id="project-mode-2"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col1 hvr-sweep-to-right">
                        <a className="small-font" href={config.BASE_URL + "/dashboard?projectMode=3"} style={{ fontWeight: 'bold', overflowWrap:'anywhere'}}>
                            <span id="project-mode-3"></span>
                        </a>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    
                </div>


                        <ReactTooltip id='project-no-info' data-tooltip-html={true} backgroundColor='white' borderColor='grey' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                            <div style={{minWidth: '200px'}}>Number of total active project at the beginning of the year (1 January)</div>
                        </ReactTooltip>
                <div className="col bg-white border-start border-end shadow1" style={{ zIndex:"6" }} >
                    <div className="col-circle-out col-circle-color-out1">
                        <div className="col-circle-in">
                            <img src={overallC1Image} className="hvr-icon col-circle-image" />
                        </div>
                    </div>
                    <div className="header-block-down2">
                        <div>
                        <a data-tip data-for='project-no-info' style={{ cursor: 'pointer', marginTop: "-17px" }}>
                            <AiOutlineInfoCircle color='#777777' size={16} />
                            </a>
                        </div>
                        <div className="main-label" >Total Project<br /></div>
                        {/* <span className="minor-label" >{"@JAN-" + props.date.getFullYear()}</span> */}
                        <span className="minor-label" >@JAN-<span id="full-year"></span></span>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />

                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=1&projectStatus=0&activeDate=2"}>
                            {/* { props.projectSummaries[0].totalProject } */}
                            <span id="total-project-1"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=2&projectStatus=0&activeDate=2"}>
                            {/* { props.projectSummaries[1].totalProject } */}
                            <span id="total-project-2"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=3&projectStatus=0&activeDate=2"}>
                            {/* { props.projectSummaries[2].totalProject } */}
                            <span id="total-project-3"></span>
                        </a>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                </div>


                        <ReactTooltip id='new-register-info' data-tooltip-html={true} backgroundColor='white' borderColor='grey' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                            <div style={{minWidth: '200px'}}>Number of new project created during the year (up to current date)</div>
                        </ReactTooltip>
                <div className="col bg-white border-start border-end shadow1" style={{ zIndex:"5" }}>
                    <div className="col-circle-out col-circle-color-out2">
                        <div className="col-circle-in">
                            <img src={newRegisterIcon} className="hvr-icon col-circle-image" />
                        </div>
                    </div>
                    <div className="header-block-down2">
                        <div>
                        <a data-tip data-for='new-register-info' style={{ cursor: 'pointer', marginTop: "-17px" }}>
                            <AiOutlineInfoCircle color='#777777' size={16} />
                            </a>
                        </div>
                        <div className="main-label" >New Register<br /></div>
                        <span className="minor-label" >YTD</span>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />

                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=1&registerDate=1&projectStatus=0"}>
                            {/* { props.projectSummaries[0].totalNewRegister } */}
                            <span id="new-register-1"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=2&registerDate=1&projectStatus=0"}>
                            {/* { props.projectSummaries[1].totalNewRegister } */}
                            <span id="new-register-2"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=3&registerDate=1&projectStatus=0"}>
                            {/* { props.projectSummaries[2].totalNewRegister } */}
                            <span id="new-register-3"></span>
                        </a>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                </div>

                        <ReactTooltip id='complete-info' data-tooltip-html={true} backgroundColor='white' borderColor='grey' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                            <div style={{minWidth: '200px'}}>Number of projects completed during the year (up to current date)</div>
                        </ReactTooltip>
                <div className="col bg-white border-start border-end shadow1" style={{ zIndex:"4" }}>
                    <div className="col-circle-out col-circle-color-out3">
                        <div className="col-circle-in">
                            <img src={overallC3Image} className="hvr-icon col-circle-image" />
                        </div>
                    </div>
                    <div className="header-block-down2">
                        <div>
                        <a data-tip data-for='complete-info' style={{ cursor: 'pointer' }}>
                            <AiOutlineInfoCircle color='#777777' size={16} />
                            </a>
                        </div>
                        <div className="main-label" >Completed<br /></div>
                        <span className="minor-label" >YTD</span>

                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />

                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=1&projectStatus=2&activeDate=1"}>
                            {/* { props.projectSummaries[0].totalCompleted } */}
                            <span id="total-complete-1"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=2&projectStatus=2&activeDate=1"}>
                        {/* { props.projectSummaries[1].totalCompleted } */}
                            <span id="total-complete-2"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=3&projectStatus=2&activeDate=1"}>
                        {/* { props.projectSummaries[2].totalCompleted } */}
                            <span id="total-complete-3"></span>
                        </a>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                </div>

                <ReactTooltip id='total-active-info' data-tooltip-html={true} backgroundColor='white' borderColor='grey' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                <div style={{minWidth: '200px'}}>Total project + New Register - Completed (up to current date)</div>
                        </ReactTooltip>
                <div className="col bg-white border-start border-end shadow1" style={{ zIndex:"3" }}>
                    <div className="col-circle-out col-circle-color-out4">
                        <div className="col-circle-in">
                            <img src={overallC4Image} className="hvr-icon col-circle-image" />
                        </div>
                    </div>
                    <div className="header-block-down2">
                        <div>
                        <a data-tip data-for='total-active-info' style={{ cursor: 'pointer' }}>
                            <AiOutlineInfoCircle color='#777777' size={16} />
                            </a>
                        </div>
                        <div className="main-label" >Total Active<br /></div>
                        <span className="minor-label" >YTD</span>

                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />

                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=1&projectStatus=0&activeDate=1"}>
                        {/* { props.projectSummaries[0].totalActive } */}
                            <span id="total-active-1"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=2&projectStatus=0&activeDate=1"}>
                            {/* { props.projectSummaries[1].totalActive } */}
                            <span id="total-active-2"></span>
                        </a>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col hvr-sweep-to-right2">
                        <a href={config.BASE_URL + "/dashboard?projectMode=3&projectStatus=0&activeDate=1"}>
                            {/* { props.projectSummaries[2].totalActive } */}
                            <span id="total-active-3"></span>
                        </a>
                    </div>
                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                </div>

                        <ReactTooltip id='spi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black'  border={true} offset={{ top: 8 }}>
                            <div style={{minWidth: '200px'}}>{"Number of project that has schedule concern (SPI<1)"}</div>
                            <img src={spiImage} width={200} />
                        </ReactTooltip>
                        
                        <ReactTooltip id='bpi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' border={true} offset={{ top: 8 }}>
                            <div style={{minWidth: '200px'}}>{"Number of project that has budget concern (BPI>1)"}</div>
                            <img src={bpiImage} width={200} />
                        </ReactTooltip>
                <div className="col bg-white border-start border-end shadow1" style={{ zIndex:"2" }}>
                    <div className="col-circle-out col-circle-color-out5">
                        <div className="col-circle-in">
                            <img src={overallC5Image} className="hvr-icon col-circle-image" />
                        </div>
                    </div>
                    <div className="header-block-down2">
                        <div>
                        <a data-tip data-for='spi-info' style={{ cursor: 'pointer', marginTop: "-17px", zIndex: "999" }}>
                            <AiOutlineInfoCircle color='#777777' size={16} style={{}} />
                        </a>

                        <a data-tip data-for='bpi-info' style={{ cursor: 'pointer', marginTop: "-17px", marginLeft: "25px" }}>
                            <AiOutlineInfoCircle color='#777777' size={16} style={{}} />
                            </a>
                        </div>
                        <div className="main-label" >Focus Area<br /></div>
                        <span className="main-label" >SPI | BPI</span>


                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />

                    <div className="block-col-last">
                        <div className="row" style={{ textAlign: "center", margin: "0px", height: "100%", width: "100%" }}>
                            <div className='hvr-sweep-to-right3 hvr-grow-shadow' style={{ width:'50%', textAlign: "center", paddingTop: "13px", paddingBottom: "13px", borderRight:"#bbbbbb solid 1px" }} >
                                <a href={config.BASE_URL + "/dashboard?projectMode=1&spi=-1&projectStatus=0&activeDate=1"}>
                                    {/* { props.projectSummaries[0].totalSpi } */}
                                    <span id="total-spi-1"></span>
                                </a>
                            </div>
                            <div className='hvr-sweep-to-right3 hvr-grow-shadow' style={{ width:'50%', textAlign: "center", paddingTop: "13px", paddingBottom: "13px" }} >
                            <a href={config.BASE_URL + "/dashboard?projectMode=1&bpi=1&projectStatus=0&activeDate=1"}>
                                {/* { props.projectSummaries[0].totalBpi } */}
                                <span id="total-bpi-1"></span>
                            </a>
                            </div>
                        </div>
                    </div>

                    <hr className="border-blue" style={{
                        marginTop: '0px', marginBottom: '0px'
                    }} />
                    <div className="block-col-last">
                        <div className="row" style={{ textAlign: "center", margin: "0px", height: "100%", width: "100%" }}>
                            <div className='hvr-sweep-to-right3 hvr-grow-shadow' style={{ width:'50%', textAlign: "center", paddingTop: "13px", paddingBottom: "13px", borderRight: "#bbbbbb solid 1px"}} >
                                <a href={config.BASE_URL + "/dashboard?projectMode=2&spi=-1&projectStatus=0&activeDate=1"}>
                                <span id="total-spi-2"></span>
                                </a>
                            </div>
                            <div className='hvr-sweep-to-right3 hvr-grow-shadow' style={{ width:'50%', textAlign: "center", paddingTop: "13px", paddingBottom: "13px" }} >
                                <a href={config.BASE_URL + "/dashboard?projectMode=2&bpi=1&projectStatus=0&activeDate=1"}>
                                <span id="total-bpi-2"></span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <hr className="border-blue" style={{ marginTop: '0px', marginBottom: '0px'}} />
                    <div className="block-col-last">
                        <div className="row" style={{ textAlign: "center", margin: "0px", height: "100%", width:"100%" }}>
                            <div className='hvr-sweep-to-right3 hvr-grow-shadow' style={{ width:'50%', textAlign: "center", paddingTop: "13px", paddingBottom: "11px", borderRight: "#bbbbbb solid 1px"}} >
                                 <a href={config.BASE_URL + "/dashboard?projectMode=3&spi=-1&projectStatus=0&activeDate=1"}>
                                <span id="total-spi-3"></span>
                                </a>
                            </div>
                            <div className='hvr-sweep-to-right3 hvr-grow-shadow' style={{ width:'50%', textAlign: "center", paddingTop: "13px", paddingBottom: "11px" }} >
                             <a href={config.BASE_URL + "/dashboard?projectMode=3&bpi=1&projectStatus=0&activeDate=1"}>
                                <span id="total-bpi-3"></span>
                            </a>
                            </div>
                        </div>
                    </div>
                    <hr className="border-blue" style={{ marginTop: '0px', marginBottom: '0px'}} />

                </div>
            </div>
            

        </>
  );
}

export default OverallTablePdf