import React, { useEffect, useState } from 'react';
import { Link  } from 'react-router-dom';
import Information from './Information';
import { PropertyService,ProjectService,ProjectDeliverableService,
    ProjectPhaseService,ProjectPhaseRoleService,
    DeliverableTemplateService,
    ProjectProgressService
} from '../../services'
import { useApiAuth } from '../../hooks/useApiAuth';
import StatusUpdate from './StatusUpdate';
import StatusView from './StatusView';
import InformationUpdateForm from './informationUpdateForm';
import Loading from '../Loading'
import { Property, Project,Phase,AuthenUser,RoleProjectLevel,
    ProjectDeliverable,ProjectPhase,ProjectPhaseRole,DeliverableTemplate,
    ProjectProgress} from '../../models'
import PrepStatus from './PrepStatus'
import { VscFilePdf } from "react-icons/vsc";
import { FiEdit } from "react-icons/fi";
import { AiOutlineEye } from "react-icons/ai";
import uniqid from 'uniqid';
import '../../assets/css/tab.css'
import { ProjectLevel,Color,SystemLevel } from '../../enum';
import  secureLocalStorage  from  "react-secure-storage";
import Modal from 'react-bootstrap/Modal'
import { RiErrorWarningLine } from "react-icons/ri";

interface AppState {
    property:Property| null
    project:Project| null
    phases:Phase[]
    projectRole:RoleProjectLevel | null
    projectdeliverables:{ [key:string]:ProjectDeliverable[] }
    projectPhases:ProjectPhase[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectProgresses:ProjectProgress[]
    deliverableTemplates:DeliverableTemplate[]
    currentPhaseId:number
    currentdate: Date
    isLoading:boolean
    isCanUpdate:boolean
    isCanUpdateStatus:boolean
    isUpdate:boolean
}
const propertyFlag =  ['projectMode','phase','projectType','developmentScenario','resourceType',
'asset','assetType','categorySize','country','productType','projectStatus','stage','roleProjectLevel','date'
]

const ProjectView = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('pid')
    const projectId:number =  (id) ? parseInt(id) : 0
    const { accessToken } = useApiAuth();
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        property: null ,
        project: null ,
        phases:[],
        projectRole: null,
        projectdeliverables: {},
        projectPhases:[],
        projectPhaseRoles:[],
        projectProgresses:[],
        currentPhaseId: 0,
        deliverableTemplates:[],
        currentdate: new Date(),
        isCanUpdate: false,
        isCanUpdateStatus:false,
        isLoading:true,
        isUpdate: false 
    })

    useEffect(()=> {
        window.scrollTo(0, 0);
        if(accessToken){
            Promise.all([
                new PropertyService(accessToken).getProperties(propertyFlag), 
                new ProjectService(accessToken).getById(projectId),
                new ProjectDeliverableService(accessToken).getByProjectId(projectId),
                new ProjectPhaseService(accessToken).getByProjectId(projectId),
                new ProjectPhaseRoleService(accessToken).getByProjectId(projectId),
                new ProjectProgressService(accessToken).getByProjectId(projectId),
            ]).then( async (result) => {
                    console.log(result)
                    let [propertyResult,projectResult,projectDeliverableResult,projectPhaseResult,projectPhaseRoleResult,projectProgressResult] = result
                    let state = Object.assign({},appState)
                
                    if(propertyResult && propertyResult.success){
                        state.property = propertyResult.data
                    }

                    if(projectResult && projectResult.success){
                        let project = projectResult.data
                        project.registerDateTime = new Date(project.registerDateTime)
                        state.project = project
                        state.currentPhaseId = project.currentPhaseId
                        let deliverableTemplateResult = await new DeliverableTemplateService(accessToken).getByProjectMode(project.projectModeId)
                        if(deliverableTemplateResult && deliverableTemplateResult.success){
                            state.deliverableTemplates = deliverableTemplateResult.data
                        }
                        
                        if(state.property){
                            let projectRole = getProjectRole(project,state.property.roleProjectLevels)
                            state.projectRole = (projectRole) ? projectRole : null
                        }   
                   
                    }
                    if(projectDeliverableResult && projectDeliverableResult.success){
                        state.projectdeliverables = projectDeliverableResult.data
                    }

                    if(projectPhaseResult && projectPhaseResult.success){
                        state.projectPhases = projectPhaseResult.data
                    }

                    if(projectPhaseRoleResult && projectPhaseRoleResult.success){
                        state.projectPhaseRoles = projectPhaseRoleResult.data
                    }

                    if(projectProgressResult && projectProgressResult.success){
                        state.projectProgresses = projectProgressResult.data
                    }

                    if(state.property && state.project){
                        state.phases = state.property.phases.filter( x => x.projectModeId == state.project?.projectModeId)
                    }

                    if(user.informationUpdate  || user.statusUpdate || user.deliverableUpdate || user.phaseChange || user.assuranceActivityUpdate || user.deliverableApprove)
                    {
                        state.isCanUpdate = true
                    }

                    if(state.projectRole){
                        if(state.projectRole.informationUpdate || state.projectRole.waiveUpdate || state.projectRole.statusUpdate || state.projectRole.deliverableUpdate || state.projectRole.deliverableApprove)
                        {
                            state.isCanUpdate = true
                        }
                    }

                    if(user.statusUpdate || user.phaseChange )
                    {
                        state.isCanUpdateStatus = true
                    }

                    if(state.projectRole){
                        if(state.projectRole.waiveUpdate || state.projectRole.statusUpdate)
                        {
                            state.isCanUpdateStatus = true
                        }
                    }
                    
                    state.isLoading = false
                    setAppState(state)
                })
    
        }
        
       
        window.addEventListener("scroll", updatePosition);
        updatePosition()
        
        return () => window.removeEventListener("scroll", updatePosition);
    },[accessToken])

    const getProjectRole = (project:Project,roleProjectLevels:RoleProjectLevel[]) => {
        if(project.focalPoints.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.FocalPoint)
        }
        if(project.projectManagers.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.ProjectManager)
        }
        if(project.projectOwners.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.ProjectOwner)
        }
        if(project.gateKeepers.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.GateKeeper)
        }
        if(project.teamMembers.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.TeamMember)
        }
        if(project.secretaries.findIndex(p => p.username == user.username) > -1){
            return roleProjectLevels.find(x => x.roleProjectLevelId === ProjectLevel.Secretary)
        }
        return null;
    }

    const onInformationUpdate = async (project:Project,isGatekeeperUpdate:boolean) => {
        if(!isGatekeeperUpdate){
            let state2 = Object.assign({},appState)
            state2.isLoading = true
            setAppState(state2)

            let state = Object.assign({},appState)
            let projectResult = await new ProjectService(accessToken).getById(projectId)
            if(projectResult && projectResult.success){
                let project = projectResult.data
                project.registerDateTime = new Date(project.registerDateTime)
                state.project = project
                state.currentPhaseId = project.currentPhaseId
                let deliverableTemplateResult = await new DeliverableTemplateService(accessToken).getByProjectMode(project.projectModeId)
                if(deliverableTemplateResult && deliverableTemplateResult.success){
                    state.deliverableTemplates = deliverableTemplateResult.data
                }
            
                if(state.property){
                    let projectRole = getProjectRole(project,state.property.roleProjectLevels)
                    state.projectRole = (projectRole) ? projectRole : null
                    
                }
               
           }
           state.isLoading = false
           setAppState(state)

            
        }
        else{
            window.location.reload()
        }
    }

    const onStatusUpdate = async (projectPhase:ProjectPhase, projectPhases:ProjectPhase[],projectPhaseRoles:ProjectPhaseRole[],projectProgresses:ProjectProgress[]) => {
        const state = Object.assign({},appState)
        let result = await new ProjectDeliverableService(accessToken).getByProjectId(projectId)
        if(result && result.success){
            state.projectdeliverables = result.data
        }
        
        state.projectPhases = projectPhases
        state.projectPhaseRoles = projectPhaseRoles
        state.currentPhaseId =  projectPhase.phaseId
        state.projectProgresses = projectProgresses
        //console.log(projectPhase)
        //console.log(state.projectPhases)
        setAppState(state)
    }

    const onUpdateChange = () => {
        let state = Object.assign({},appState)
        state.isUpdate = true
        setAppState(state)
    }

    const updatePosition = () => {
        let pos = window.pageYOffset;
        
        if(pos > 20){
        
            document.getElementById('project-menu')?.classList.add('active')
        }
        else{
           
            document.getElementById('project-menu')?.classList.remove('active')
        }
    }

    const getParameterByName = (name, url = window.location.href) => {
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    const print = () => {
      
        window.open('/printpdf?pid=' + projectId ,'print','directories=no,titlebar=no,toolbar=no,location=no,status=yes,menubar=no,scrollbars=no,resizable=yes')
    }

    const onViewModeChange = async () => {
        let element = document.getElementById('save-project-btn') as HTMLButtonElement
        if(element && element.disabled === false){
            let toPage = document.getElementById('to-page') as HTMLInputElement
            toPage.value = 'project-view'
            confirmMessageShow()
        }
        else{
            window.location.reload()
        }
        
    }
    
    const routeChange = () =>{
        let element = document.getElementById('save-project-btn') as HTMLButtonElement
        if(element && element.disabled === false){
            let toPage = document.getElementById('to-page') as HTMLInputElement
            toPage.value = 'dashboard'
            confirmMessageShow()
        }
        else{
            let element = document.getElementById('link-dashboard') as HTMLLinkElement
            element.click()
        }
      }
    
      const routeChangeForce = () =>{
        confirmMessageHide()
        let toPage = document.getElementById('to-page') as HTMLInputElement
        console.log(toPage.value)
        if(toPage.value === 'project-view'){
            window.location.reload()
        }
        if(toPage.value === 'dashboard'){
            let element = document.getElementById('link-dashboard') as HTMLLinkElement
            element.click()
        }
       
      }
      
      const confirmMessageShow = () => {
        let element = document.getElementById('confirm-message') as HTMLDivElement
        element.style.display = 'inline'
        element.scrollIntoView({block: "center"})
        let backdrop = document.getElementById('modal-backdrop') as HTMLDivElement
        backdrop.style.display = 'inline'
    }

    const confirmMessageHide = () => {
        let element = document.getElementById('confirm-message') as HTMLElement
        element.style.display = 'none'
        let backdrop = document.getElementById('modal-backdrop') as HTMLDivElement
        backdrop.style.display = 'none'
    }

    return (
        <div className="container-fluid">
           <nav className="nav-menu" aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-bottom">
                    <li className="breadcrumb-item active" aria-current="page">
                    <a style={{ cursor: 'pointer'}} onClick={() => routeChange()}>   
                        <span>Portfolio Dashboard</span>
                    </a>
                        <Link id="link-dashboard" to="/dashboard"></Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                       Project Information
                    </li>
                </ol>
               
            </nav>
            <div id="project-menu" className='row project-view-menu'>
                            <div className='col-md-6 project-view-menu-left'>
                                { appState.isCanUpdate ?
                                <div className='project-view-edit'>
                                    {!appState.isUpdate ?
                                        <button type="button" className="btn btn-sm bg-dark-blue"  onClick={() => onUpdateChange()} style={{ float: 'right'}}>
                                            <FiEdit size={20}/>
                                        </button>
                                        :
                                        <button type="button" className="btn btn-sm bg-dark-blue" onClick={() => onViewModeChange()}  style={{ float: 'right'}}>
                                            <AiOutlineEye size={20}/>
                                        </button>
                                    }
                                </div>:null
                                }

                                <span>{ appState.project?.currentProjectName }</span>
                                
                            </div>
                            
                            <div className='col-md-6 project-view-menu-right'>
                                { appState.isCanUpdate ?
                                <>
                                    {!appState.isUpdate ?
                                        <button type="button" className="btn btn-sm bg-dark-blue"  onClick={() => onUpdateChange()}>
                                            <FiEdit size={20}/>
                                            <span style={{ marginLeft: '5px'}}>Edit/Update Mode</span>
                                        </button>
                                        :
                                        <button type="button" className="btn btn-sm bg-dark-blue" onClick={() => onViewModeChange()}>
                                            <AiOutlineEye size={20}/>
                                            <span style={{ marginLeft: '5px'}}>View Mode</span>
                                        </button>
                                    }
                                </>:null
                                }

                                    
                                <span style={{ marginRight: '10px'}}></span>
                            
                                { !appState.isUpdate ? 
                                    <button type="button" className="btn btn-sm bg-dark-blue btn-project-view-text" onClick={print}>
                                        <VscFilePdf size={20}/>
                                        <span style={{ marginLeft: '5px'}} className='project-view-text'>Export PDF</span>
                                    </button>: null
                                }
                            </div>
            </div>

            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                <div className="tabs">
                        <input type="radio" id="tab1" name="tab-control" defaultChecked />
                        <ul></ul>
  
                        <div className="content" style={{ minHeight: '400px',marginBottom: '30px',marginTop: '40px' }}>
                            <section id="view-data">
                                {appState && appState.property && appState.project && !appState.isLoading ? 
                                    <>
                                        <div id="information">
                                            { user.informationView  && appState.isUpdate === false? 
                                                <>
                                                <Information 
                                                    key={uniqid()} 
                                                    user={user} 
                                                    projectData={appState.project} 
                                                    projectRole={appState.projectRole}
                                                    minimize={true}
                                                    hiddenText={false}
                                                />
                                                </>:null
                                            }
                                            { (user.informationUpdate || appState.projectRole?.informationUpdate) && appState.isUpdate ? 
                                                <>
                                                {  <InformationUpdateForm 
                                                    key={uniqid()}  
                                                    property={appState.property} 
                                                    initialData={appState.project} 
                                                    user={user}
                                                    projectRole={appState.projectRole }
                                                    onChange={onInformationUpdate}
                                                /> }
                                                </>:null
                                            }
                                        </div>
                                        
                                        <div id="prep-status" style={{ marginTop: '20px' }}>
                                            <PrepStatus  
                                                    key={uniqid()} 
                                                    isUpdate={appState.isUpdate} 
                                                    projectId={appState.project ? appState.project.projectId : 0} 
                                                    projectName={appState.project.currentProjectName}
                                                    projectModeId={ appState.project ? appState.project?.projectModeId : 0} 
                                                    phaseId={appState.project ? appState.project?.currentPhaseId : 0} 
                                                    phases={appState.phases} 
                                                    projectPhases={appState.projectPhases}
                                                    user={user} 
                                                    projectOwners={appState.project.projectOwners}
                                                    gatekeepers={appState.project.gateKeepers}
                                                    projectPhaseRoles={appState.projectPhaseRoles}
                                                    projectRole={appState.projectRole ? appState.projectRole : null}  
                                                    projectDeliverables={appState.projectdeliverables}
                                              />
                                        </div>
                                        
                                        <div id="view-status" style={{ marginTop: '20px'}}>
                                            { appState.isUpdate === false ? 
                                                <StatusView 
                                                    key={uniqid()} 
                                                    projectId={appState.project?.projectId} 
                                                    projectModeId={ appState.project ? appState.project?.projectModeId : 0} 
                                                    phaseId={appState.currentPhaseId} 
                                                    phases={appState.phases} 
                                                    projectPhases={appState.projectPhases}
                                                    projectPhaseRoles={appState.projectPhaseRoles}
                                                    projectProgresses={appState.projectProgresses.sort((a, b) => new Date(a.progressDate).getTime() - new Date(b.progressDate).getTime())}
                                                    user={user}
                                                    projectRole={appState.projectRole}
                                                /> 
                                                : null
                                            }
                                            { appState.isUpdate && appState.isCanUpdateStatus ?
                                                <StatusUpdate 
                                                    key={uniqid()} 
                                                    projectId={appState.project.projectId} 
                                                    projectName={appState.project.currentProjectName}
                                                    projectModeId={ appState.project ? appState.project?.projectModeId : 0} 
                                                    phaseId={appState.currentPhaseId} 
                                                    phases={appState.phases} 
                                                    user={user} 
                                                    projectOwners={appState.project.projectOwners}
                                                    projectRole={appState.projectRole ? appState.projectRole : null} 
                                                    projectPhases={appState.projectPhases}
                                                    projectPhaseRoles={appState.projectPhaseRoles}
                                                    currentdate={appState.currentdate}
                                                    deliverableTemplates={appState.deliverableTemplates}
                                                    projectProgresses={appState.projectProgresses.sort((a, b) => new Date(a.progressDate).getTime() - new Date(b.progressDate).getTime())}
                                                    onChange={onStatusUpdate}
                                                />
                                                 :null
                                            }
                                        </div>
                                        <input id="to-page" type="hidden" value=""/>
                                    </>: <Loading text="Loading..."/>
                                } 
                            </section>
                            
                           
                        </div>
                    </div>

                </div>
            </div>
            <div id="modal-backdrop" className="fade modal-backdrop" style={{ display: 'none',opacity: 0.4}}></div>
            <div id="confirm-message" className="modal"  style={{ display: 'none', position: 'absolute' }}>
            <Modal.Dialog size="lg" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => confirmMessageHide()}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                     <RiErrorWarningLine size={35} color={Color.DarkBlue} />
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}>Project information has been changed without saving, would you like to exit from this page?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => routeChangeForce() } >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => confirmMessageHide()} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                   
                </Modal.Body>
            </Modal.Dialog>
            
            </div>
        </div>
    );
  
}

export default ProjectView
