import React, { useState,useEffect } from 'react';
import { BsSlashCircleFill} from "react-icons/bs";
import ReactTooltip from "react-tooltip";
import '../../assets/css/deliverable.css' 
import '../../assets/css/infographic-table.css'
import Modal from 'react-bootstrap/Modal'
import { AiOutlineInfoCircle, AiOutlineFolder, AiOutlineEdit,AiOutlineSearch,AiOutlineFile, AiOutlineFilePdf,AiOutlineFileExcel,AiOutlineFileImage,AiOutlineFileWord,} from "react-icons/ai";
import { VscCircleSlash,VscIssueReopened,VscCheck,VscChromeClose,VscCloudDownload,VscCircleLargeOutline,
    VscClose,VscChevronRight,VscChevronDown ,VscLink} from "react-icons/vsc";
import { ProjectDeliverableDocumentService ,PropertyService,FileManageService,HistoryLogService,EmailService} from '../../services'
import {BsFilter} from "react-icons/bs"
import { Color, StatusDeliverableDocument} from '../../enum'
import {GatekeeperWorkList,SelectItem,DeliverableDocumentStatus,PageEvent,ProjectDeliverableItemFile,AuthenUser} from '../../models'
import uniqid from 'uniqid';
import { useApiAuth } from '../../hooks/useApiAuth';
import { DateHelper } from "../../helpers";
import Loading from "../Loading";
import ReactPaginate from 'react-paginate';
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState{
    page: number,
    limit: number,
    total: number,
    totalPgae: number,
    limitPage: number,
    key:string,
    arrowDown:boolean[]
    gatekeeperWorkList:GatekeeperWorkList | null
    gatekeeperWorkLists:GatekeeperWorkList[]
    deliverableStatusId:number
    action:string
    remark:string
    isFeedbackShow:boolean
    isDownloading:boolean[]
    isStatusShow:boolean
    isAssuranceShow:boolean
    isLoading:boolean
}

const GateKeeperWorkList = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const username = account ? account.username : ""
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        page: 1,
        limit: 25,
        total: 0,
        totalPgae: 0,
        limitPage: 0,
        key:"",
        arrowDown: [],
        gatekeeperWorkList:null,
        gatekeeperWorkLists: [],
        deliverableStatusId:0,
        action:"",
        remark:"",
        isFeedbackShow:false,
        isDownloading:[],
        isStatusShow:false,
        isAssuranceShow:false,
        isLoading: true
    })
    const [project,setProject] = useState({projectId: 0, projectName: ""})
    const [deliverableDocumentStatuses,setDeliverableDocumentStatuses] = useState<DeliverableDocumentStatus[]>([])
  
 
    useEffect(() => {
        if(accessToken){
            new PropertyService(accessToken).getProperties(['deliverableDocumentStatus']).then((result) => {
                //console.log(result)
                if(result && result.success) {
                    setDeliverableDocumentStatuses(result.data.deliverableDocumentStatuses)
                }
                getProjectGatekeeperWorklist(1,appState.limit)
            })
            
        }
    },[accessToken])
    
    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getProjectGatekeeperWorklist(page,appState.limit);
        
    }
    const getProjectGatekeeperWorklist = async (page,limitPage) => {
       
        let projectId = project.projectId >= 0 ? project.projectId : 0
        let token = await getToken()
        let result = await new ProjectDeliverableDocumentService(token).getGateKeeperWorklist(page,limitPage, username,projectId,appState.deliverableStatusId ,appState.key)
        //console.log(result)
        if(result && result.success){
            let data = result.data
            let state = Object.assign({},appState)
            state.page = page
            state.limit = data.limit
            state.total = data.total
            state.totalPgae = Math.ceil(data.total / data.limit)
            state.gatekeeperWorkLists = data.items
           
            let length = data.items.length
            state.arrowDown = state.arrowDown.fill(false,0,length)
            state.isDownloading = state.isDownloading.fill(false,0,length)
            state.isLoading = false
            setAppState(state)
      
        }

    }
    
    const setArrowDown = (index,status) => {
        let state = Object.assign({},appState)
        state.arrowDown[index] = status
        setAppState(state)
    }

    const feedbackShow = (item:GatekeeperWorkList) => {
        let state = Object.assign({},appState)
        state.gatekeeperWorkList = item
        state.isFeedbackShow = true
        setAppState(state)
    }

    const feedbackClose = () => {
        let state = Object.assign({},appState)
        state.isFeedbackShow = false
        setAppState(state)
    }

    const statusActionShow = (item:GatekeeperWorkList,action) => {
        let state = Object.assign({},appState)
        state.gatekeeperWorkList = item
        state.action = action
        state.isStatusShow = true
        setAppState(state)
    }

    const statusActionClose = () => {
        let state = Object.assign({},appState)
        state.isStatusShow = false
        setAppState(state)
    }


    const updateFeedback = async() => {
        let gatekeeperWorkList = Object.assign({},appState.gatekeeperWorkList)
        let inputFeedback = document.getElementById('gatekeeper-feedback') as HTMLTextAreaElement
        let feedback = ""
        if(inputFeedback){
            feedback = inputFeedback.value
        }

        let token = await getToken()
        let result = await new ProjectDeliverableDocumentService(token).updateFeedback(gatekeeperWorkList.projectDeliverableDocumentId,feedback,name)
        if(result && result.success){
            let state = Object.assign({},appState)
            state.isFeedbackShow = false
            let index = state.gatekeeperWorkLists.findIndex(x => x.projectDeliverableDocumentId === gatekeeperWorkList.projectDeliverableDocumentId)
            state.gatekeeperWorkLists[index].gatekeeperFeedback = feedback
            setAppState(state)
        }
    }

    const updateStatus = async() => {
        if(appState.gatekeeperWorkList){
            let {projectDeliverableDocumentId,deliverableDocumentStatusId} =  appState.gatekeeperWorkList
            let oldStatusId = deliverableDocumentStatusId
            let oldStatus = "Waiting for Verification"
            let action = ""
            if(appState.action === 'accept'){
                action = 'Accept'
                deliverableDocumentStatusId = StatusDeliverableDocument.Accept
            }
            if(appState.action === 'reject'){
                action = 'Reject'
                deliverableDocumentStatusId = StatusDeliverableDocument.Reject
            }
            if(appState.action === 'waive'){
                action = 'Waive'
                deliverableDocumentStatusId = StatusDeliverableDocument.Waive
            }

            if(oldStatusId === StatusDeliverableDocument.Accept){
                oldStatus = 'Accept'
            }
            if(oldStatusId === StatusDeliverableDocument.Reject){
                oldStatus = 'Reject'
            }
            if(oldStatusId === StatusDeliverableDocument.Waive){
                oldStatus = 'Waive'
            }

            let token = await getToken()
            let result = await new ProjectDeliverableDocumentService(token).updateStatus(projectDeliverableDocumentId,deliverableDocumentStatusId,name)
            if(result && result.success)
            {
                let historyAction = "Update Deliverable Document "+ appState.gatekeeperWorkList.stageName + "/" + appState.gatekeeperWorkList.assuranceActivityName + "/" + appState.gatekeeperWorkList.deliverableItemName + ', ' + action 
                new HistoryLogService(token).create(user.userId, appState.gatekeeperWorkList.projectId,"Gatekeeper Worklist",historyAction)
               // new EmailService(accessToken).sendIconStatusChange2(appState.gatekeeperWorkList.projectId,appState.gatekeeperWorkList.projectPhaseId,appState.gatekeeperWorkList.deliverableItemName,oldStatus,action)

                let state = Object.assign({},appState)
                state.isStatusShow = false
                let index = state.gatekeeperWorkLists.findIndex(x => x.projectDeliverableDocumentId === projectDeliverableDocumentId)
                state.gatekeeperWorkLists[index].deliverableDocumentStatusId = deliverableDocumentStatusId
                state.gatekeeperWorkLists[index].verifyDateTime = result.date
                state.gatekeeperWorkLists[index].verifyBy = name
                setAppState(state)
            }
    
        }
    }


    const searchProject = async (key:string) => {
        if(!account) return

        let token = await getToken()
        let result =  await new ProjectDeliverableDocumentService(token).searchProjectName(username,key)
        //console.log(result)
        return result.data
    }

    const selectProject = (item:SelectItem) => {
        setProject({ projectId: item.id,projectName: item.value ? item.value : ""})
    }

    const onPerpageChange = (limit) => {
        let state = Object.assign({},appState)
        state.limit = limit
        setAppState(state)
        getProjectGatekeeperWorklist(1,limit)
    }

    const onStatusChange = (value) => {
        let state = Object.assign({},appState)
        state.deliverableStatusId = value
        setAppState(state)
    }

    const download = async (item:ProjectDeliverableItemFile) => {
        let token = await getToken()
        new FileManageService(token).downloadFile(item.path,item.name);
    }

    const downloadZip = async (item:GatekeeperWorkList,index:number) => {
        if(item.projectDeliverableItemFiles.length == 0){
            return
        }
        //console.log('downloadZip')
        let state = Object.assign({},appState)
        state.isDownloading[index] = true
        setAppState(state)
     
        let path = "p" + item.projectId.toString() + "/ph" +  item.projectPhaseId.toString() + "/s" + item.stageId.toString() + "/a" + item.assuranceActivityId +  "/d" + item.projectDeliverableDocumentId.toString()
        //console.log(path)
        let token = await getToken()
        await new FileManageService(token).downloadZipFile(path);
        
        let state2 = Object.assign({},appState)
        state2.isDownloading[index] = false
        setAppState(state2)
    }

    const redirectUrl = (url:string) => {
        window.open(url, '_blank');
    }

    return (
    <div className="container-fluid">
         
        <nav className="nav-menu" aria-label="breadcrumb">
                
                <ol className="breadcrumb breadcrumb-bottom">
                    
                    <li className="breadcrumb-item" aria-current="page">
                        Gatekeeper WorkList
                    </li>
                </ol>
                
            </nav>
        <div style={{ padding: '5px 20px'}}>
            <div className="body">
                <div className="tabs" style={{ minHeight: '400px'}}>
            <div className='row'>
                <div className='col-md-2'>
                    <select className="form-select form-select-sm" value={appState.deliverableStatusId} onChange={ev => onStatusChange(parseInt(ev.target.value))} style={{fontSize:"1rem"}} >
                        <option value={0}>All Status</option>
                        { deliverableDocumentStatuses.map(m => <option key={uniqid()} value={m.verifyStatusId}>{ m.verifyStatusName}</option>) }   
                    </select>
                        
                </div>
                <div className='col-md-3'>
                    
                    <div className="input-group" style={{ display: 'none'}}>
                            <input className="form-control border-end-0 border" type="search" placeholder="Search project name" id="example-search-input"/>
                                <span className="input-group-append">
                                    <button className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ left:'-2px'}}>
                                        <AiOutlineSearch size={20} />
                                    </button>
                                </span>
                        </div>
                        <TextboxAutocomplete key={uniqid()} idKey="projectId" valueKey="projectName"  onClick={searchProject} onChange={searchProject} onSelect={selectProject}  elementId={'project-input'} initialId={project.projectId} initialValue={project.projectName}/>
                </div>
                <div className='col-md-2'>
                    <button type="button" className="btn btn-sm bg-dark-blue" style={{ width: "100%" }} onClick={() => getProjectGatekeeperWorklist(1,appState.limit)}>
                        <BsFilter size="20" color="white" />
                        <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Filter</span>
                    </button>
                  
                </div>
               
                <div className='col-md-5' style={{ textAlign: 'right'}}>
                    <span>
                        <VscCheck size={20} color='green' style={{ marginRight: '5px'}}/>
                        <span>Accept</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscClose size={20} color="red" style={{ marginRight: '5px'}}/>
                        <span>Reject</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/>
                        <span>Waiting for Verification</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>
                        <span>Wait for Deliverable</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleSlash size={18} color='black' style={{ marginRight: '5px'}}/>
                        <span>Waive</span>
                    </span>
                </div>
            </div>

            <div className='row' style={{ marginTop: '20px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            
                            <select className=""  value={appState.limit} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}} onChange={ev => onPerpageChange(parseInt(ev.target.value))}>
                                <option value={10}>10</option>
                                <option value={25}>25</option> 
                                <option value={50}>50</option>
                                <option value={100}>100</option>        
                            </select>
                        </div>
            </div>

            <div className="table-responsive table-responsive-sm" >
            <table className="table deliverable-table">
                <thead>
                    <tr style={{ borderTop: '1px solid'}}>
                        <th>Verification Status</th>
                        <th style={{ width: '150px'}}>Project Name</th>
                        <th style={{ width: '100px'}}>Phase</th>
                        <th >Stage/Assurance Activity</th>
                        
                        <th style={{ textAlign: 'center',width: '600px'}}>Deliverable Name</th>
                      
                        
                        <th style={{ width: '150px'}}>Recommendation</th>
                        <th style={{ width: '150px' }}>Focal Point Comment</th>
                        <th style={{ width: '150px' }}>Gatekeeper Feedback</th>
                        <th style={{ width: '100px'}}>
                            <span>Type</span>
                            <a data-tip data-for='deliverable-type-info' style={{cursor: 'pointer'}}>
                                <AiOutlineInfoCircle color='#00338D' size={20} style={{ verticalAlign: 'middle',marginLeft: '2px'}}/>
                            </a>
                            <ReactTooltip id='deliverable-type-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                <div>M = Mandatory</div>
                                <div>C = Conditional</div>
                            </ReactTooltip>
                        </th>
                        
                        <th style={{ textAlign: 'center'}}>Verified by</th>
                    </tr>
                </thead>
               
                { appState && !appState.isLoading ?
                     <tbody>
                    { appState.gatekeeperWorkLists.map((item,index) =>(
                        <>
                            <tr key={uniqid()} className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td  style={{ paddingTop: '34px' }}>
                            <span className="deliverable-user">
                            { !appState?.arrowDown[index] ?
                                    <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setArrowDown(index,true)}/>
                                    :
                                    <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setArrowDown(index,false)}/>
                                }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Accept ? <VscCheck size={18}color='green' style={{ marginRight: '5px'}}/> : null }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Reject ?   <VscClose size={20} color="red" style={{ marginRight: '5px'}}/> : null}
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.WaitingForVerification ?   <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/> : null }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.WaitingForAttach ?    <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>: null}
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Waive ?    <VscCircleSlash size={20} color='grey' style={{ marginRight: '5px'}}/>: null}
                            </span>
                        </td>
                        <td>
                            <a href={'/project-view?pid=' + item.projectId} style={{cursor: 'pointer'}}>
                                <span className="deliverable-desc" style={{ paddingTop: '32px' }}>{ item.projectName }</span>
                            </a>
                        </td>
                        <td>
                        <span className="deliverable-desc" style={{ paddingTop: '32px' }}>{item.phaseName }</span>
                        </td>
                        <td >
                            <span className="deliverable-desc" style={{ paddingTop: '32px' }}>{item.stageName}/{item.assuranceActivityName}</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { item.projectDeliverableItemFiles.length > 0 && item.deliverableDocumentStatusId !== StatusDeliverableDocument.Accept ?
                                    <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'accept')}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>:null
                                }
                                { item.projectDeliverableItemFiles.length > 0 && item.deliverableDocumentStatusId !== StatusDeliverableDocument.Reject ?
                                <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'reject')}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>:null
                                }
                                { item.deliverableDocumentStatusId !== StatusDeliverableDocument.Waive ?
                                    <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'waive')}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>:null
                                }
                            
                                
                                    { item.projectDeliverableItemFiles.length > 0 ?
                                        <span style={{ cursor: 'pointer'}}  onClick={() => downloadZip(item,index)}>
                                            <VscCloudDownload size={18} color={Color.Blue} cursor="pointer"/>
                                            <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download All</span>
                                        </span>:null
                                    }
                                 
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>{item.deliverableItemName} ({item.projectDeliverableItemFiles.length})</span>
                            { appState?.arrowDown[index] ?
                                    <>
                                   
                                        <table key={uniqid()} className="file-table" style={{ width: '95%'}}>
                                            <tr>
                                                <td colSpan={3} style={{textAlign: 'left',paddingLeft: '40px'}}>
                                                    <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                                </td>
                                            </tr>
                                            
                                            {item.projectDeliverableItemFiles && item.projectDeliverableItemFiles.map((file) => (
                                                <tr key={uniqid()}>
                                                    <td style={{ paddingLeft: '55px',width: '50px'}}>
                                                        { file.itemType === 'file' ? 
                                                            <span>
                                                                { file.name.indexOf('.pdf') > -1 ? 
                                                                    <AiOutlineFilePdf size={18} color={'red'}/> : 
                                                                        file.name.indexOf('.xls') > -1 ||  file.name.indexOf('.xlsx') > -1 ? 
                                                                            <AiOutlineFileExcel size={18} color={'green'}/> :
                                                                                file.name.indexOf('.jpg') > -1 ||  file.name.indexOf('.jpeg') > -1 ||  file.name.indexOf('.png') > -1 ? 
                                                                                    <AiOutlineFileImage size={18} color={Color.DarkBlue}/> :
                                                                                        file.name.indexOf('.doc') > -1 ||  file.name.indexOf('.docx') > -1 ? 
                                                                                            <AiOutlineFileWord size={18} color={Color.DarkBlue} /> :
                                                                                                <AiOutlineFile size={18}/> 
                                                                }
                                                            </span> 
                                                            : 
                                                             <span style={{ cursor:'pointer'}}  onClick={() => redirectUrl(file.path)}><VscLink color={Color.Blue} size={18}/></span> 
                                                        }
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        { file.itemType === 'file' ? 
                                                            <span style={{marginRight: '10px'}}>{file.name}</span>
                                                            :
                                                            <span className="font-blue" style={{ cursor:'pointer'}}  onClick={() => redirectUrl(file.path)}>{file.name}</span>
                                                        }
                                                    </td>
                                                    <td style={{ minWidth:'80px',textAlign:'right'}}>
                                                        { file.itemType === 'file' ?
                                                        
                                                            <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" onClick={() => download(file)}/>:null
                                                           
                                                        
                                                        
                                                        }
                                                       
                                                    </td>
                                                </tr>
                                                 ))
                                            }
                                            
                                            
                                        </table>
                                       
                                    
                                    </>:null
                            }
                        </td>
                        <td style={{textAlign: 'left'}}>
                            <span className="deliverable-desc" style={{ paddingTop: '32px' }}>{item.recomendation}</span>
                        </td>
                        <td className="deliverable-doc">
                                    <div className="deliverable-action" style={{ float: 'right' }}>
                                       
                                    </div>
                                    <span className='deliverable-desc' style={{ paddingTop: '32px' }}>{item.focalPointComment}</span>
                        </td>
                        <td className="deliverable-doc">
                                    <div className="deliverable-action" style={{ float: 'right' }}>
                                       
                                            <span style={{ cursor: 'pointer' }} onClick={() => feedbackShow(item)}>
                                                <AiOutlineEdit size={18} color={'#009FDA'} cursor="pointer" onClick={() => feedbackShow(item)} />
                                                <span style={{ fontSize: '12px', marginLeft: '2px' }}>Feedback</span>
                                            </span>
                                        
                                    </div>
                                    <span className='deliverable-desc' style={{ paddingTop: '0' }}>{item.gatekeeperFeedback}</span>

                        </td>
                        <td style={{ paddingTop: '34px' }}>
                            <span className="deliverable-desc" style={{ fontSize:'13px',fontWeight: 'bold'}}>{ item.deliverableTypeName}</span>
                        </td>
                     
                        <td>
                            <div className='deliverable-user'>
                            <div >{item.verifyBy }</div>
                                  <div>{ DateHelper.getDate(item.verifyDateTime)}</div>
                          </div>
                        </td>
                            </tr>
                          
                        </>
                    ))
                    }
                    </tbody>:<Loading text="Loading..."/>
                }
                
            </table>
            </div>

            <div className='row'>
                    <div className='col-md-4' style={{ display: 'flex'}}>
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={appState.totalPgae}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                onPageChange={handlePageClick}
                                forcePage={appState.page - 1}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active-gradient"
                            />
                        </div>
                    </div>
               </div>
        </div>
        </div>
        </div>
       
        <Modal show={appState.isStatusShow} onHide={() => statusActionClose()} size="sm" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => statusActionClose()}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                        { appState.action === 'accept' ? <VscCheck size={20} color={'green'}/> : null }
                        { appState.action === 'reject' ? <VscClose size={25} color={'red'} />: null }
                        { appState.action === 'waive' ? <VscCircleSlash size={22} color={'black'} />: null }
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}> Would you like to to {appState.action}?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateStatus()} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => statusActionClose()} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>
        <Modal show={appState.isFeedbackShow} onHide={() => feedbackClose()} size="lg"  style={{top:'20%'}}>
         <Modal.Header className="bg-dark-blue">
            <Modal.Title style={{ fontSize: '15px'}}>Gatekeeper Feedback</Modal.Title>
            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => feedbackClose() }>X</span>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <textarea id="gatekeeper-feedback" className="form-control"></textarea>
              </div>
              <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateFeedback()} >
                  <span style={{ marginLeft: '5px'}} >Save</span>
               </button>
               <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => feedbackClose()} >
                    <span style={{ marginLeft: '5px'}} >Cancel</span>
                </button>
          </Modal.Body>
        </Modal>

    </div>   
    );
  
}

export default GateKeeperWorkList