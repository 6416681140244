import React, { useEffect } from 'react';
import Pdf from '../../assets/pdf/DMND0001248-PTTEP EDE Project Portfolio_Admin_Manual_v2_20230308.pdf';
import { AiOutlineFilePdf } from "react-icons/ai";

const AdminManual = () => {
    
    useEffect(() => {
        
      
    },[])


    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                    <div className='project-block-none' style={{ marginTop: '15px'}}>
                        <div><span style={{ fontWeight:'bold'}}>Admin Manual</span></div>
                        <div style={{ marginTop: '5px'}}>
                            <a href = {Pdf} target = "_blank" style={{textDecoration:'underline'}}>
                                <AiOutlineFilePdf size={25} color='red' style={{ marginRight: '4px',verticalAlign:'bottom'}}/>
                                DMND0001248-PTTEP EDE Project Portfolio_Admin_Manual_v2_20230308.pdf
                            </a>
                        </div>
                    </div>
         
                </div>
            </div>
     
        </div>
    );
  
}

export default AdminManual