import React, { useState, useEffect, useRef } from 'react';
import DatePicker from "react-datepicker";
import uniqid from 'uniqid';
import ReactTooltip from "react-tooltip";
import { AiOutlineCloseSquare, AiOutlineInfoCircle ,AiOutlineCheckCircle,AiOutlineSave,AiOutlineSearch} from "react-icons/ai";
import { VscAdd } from "react-icons/vsc";
import { RiAddCircleLine,RiDeleteBin6Line,RiErrorWarningLine } from "react-icons/ri";
import { Project,Property,Partner,ProjectModeFlag,Phase,Location,SelectItem,Item,
    UserItem, Coordinate,GatekeeperUpdate,AuthenUser,RoleProjectLevel,ProjectBlock,ProjectPartner} from '../../models'
import { ProjectService,PartnerService,UserService,LocationService,GraphApiService,HistoryLogService} from '../../services'
import { useApiAuth } from '../../hooks/useApiAuth';
import { Color,SystemLevel,ProjectLevel } from '../../enum'
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import '../../assets/css/information.css'
import '../../assets/css/autocomplete.css'
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-bootstrap/Modal'
import { Picker } from "../picker";
import { NumHelper} from '../../helpers'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

const initialProjectModeFlag:ProjectModeFlag = {
    projectTypeFlag: 1,
    assetTypeFlag: 1,
    resourceTypeFlag: 1,
    categorySizeFlag: 1,
    changeTermConditionFlag: 1,
    changeDevelopmentConceptFlag: 1,
    developmentScenarioFlag: 1
}

interface Props{
    initialData:Project
    property:Property
    user:AuthenUser
    projectRole:RoleProjectLevel | null
    onChange:any
}

const InformationUpdateForm = (props:Props) => {
    const { instance, accounts } = useMsal();
    const { account,accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const property:Property = props.property
    const [registerDateTime, setRegisterDateTime] = useState(props.initialData.registerDateTime)
    const [projectModeId,setProjectModeId] = useState(props.initialData.projectModeId)
    const [assetId,setAssetId]= useState(props.initialData.assetId)
    const [projectModeFlag,setProjectModeFlag] = useState<ProjectModeFlag>(initialProjectModeFlag);
    const [projectTypeId,setProjectTypeId] = useState(props.initialData.projectTypeId)
    const [assetTypeId,setAssetTypeId] = useState(props.initialData.assetTypeId)
    const [resourceTypeId,setResourceTypeId] = useState(props.initialData.resourceTypeId)
    const [productTypeId,setProductTypeId] = useState(props.initialData.productTypeId)
    const [projectStatusId,setProjectStatusId] = useState(props.initialData.projectStatusId)
    const [categorySizeId,setCategorySizeId] = useState(props.initialData.categorySizeId)
    const [developmentScenarioId,setDevelopmentScenarioId] = useState(props.initialData.developmentScenarioId)
    const [phaseId,setPhaseId] = useState(props.initialData.startPhaseId)
    const [changeInTermAndCondition,setChangeInTermAndCondition] = useState(props.initialData.changeInTermAndCondition)
    const [changeInDevelopmentConcept,setChangeInDevelopmentConcept] = useState(props.initialData.changeInDevelopmentConcept)
    const [phases,setPhases] = useState<Phase[]>([])
    const [blocks,setBlocks] = useState<ProjectBlock[]>(props.initialData.projectBlocks)
    const [blockPartnerIndex,setBlockPartnerIndex] = useState({blockIndex: 0,partnerIndex: 0})
    const [country,setCountry] = useState(props.initialData.countryName);
    const [location, setLocation] = useState<Location>({ locationId: props.initialData.locationId, locationName: props.initialData.locationName })
    const [isSuccess, setIsSuccess] = useState(false)
    const [isUpdate,setIsUpdate] = useState(false)
    const [errorMessage,setErrorMessage] = useState("")
    const [isConfirmDeleteBlock,setIsConfirmDeleteBlock] = useState(false)
    const [isConfirmDeletePartner,setIsConfirmDeletePartner] = useState(false)

    const [projectOwners, setProjectOwners] = useState<Item[]>(props.initialData.projectOwners.map(p => {  let item:Item = { key: p.username, name: p.name } ; return item}))
    const [projectOwnersBackgroundColor, setProjectOwnersBackgroundColor] = useState("")
    
    const [projectManagers, setProjectManagers] = useState<Item[]>(props.initialData.projectManagers.map(p => {  let item:Item = { key: p.username, name: p.name } ; return item}))
    const [projectManagersBackgroundColor, setProjectManagersBackgroundColor] = useState("")

    const [gatekeepers, setGatekeeperss] = useState<Item[]>(props.initialData.gateKeepers.map(p => {  let item:Item = { key: p.username, name: p.name } ; return item}))
    const [gatekeepersBackgroundColor, setGatekeepersBackgroundColor] = useState("")

    const [focalPoints, setFocalPoints] = useState<Item[]>(props.initialData.focalPoints.map(p => {  let item:Item = { key: p.username, name: p.name } ; return item}))
    const [focalPointsBackgroundColor, setFocalPointsBackgroundColor] = useState("")

    const [teamMembers, setTeamMembers] = useState<Item[]>(props.initialData.teamMembers.map(p => {  let item:Item = { key: p.username, name: p.name } ; return item}))
    const [teamMembersBackgroundColor, setTeamMembersBackgroundColor] = useState("")

    const [locationBackgroundColor, setLocationBackgroundColor] = useState("")

    const [errors,setErrors] = useState<string[]>([])
    const inputProjectMode = useRef<HTMLSelectElement>(null)
    const inputProjectModeMessage = useRef<HTMLSpanElement>(null)
    const inputInitialProjectName = useRef<HTMLInputElement>(null)
    const inputInitialProjectNameMessage = useRef<HTMLSpanElement>(null)
    const inputCurrentProjectName = useRef<HTMLInputElement>(null)
    const inputCurrentProjectNameMessage = useRef<HTMLSpanElement>(null)
    const inputRegisterDate = document.getElementById('register-date') as HTMLInputElement
    const inputRegisterDateMessage = useRef<HTMLSpanElement>(null)
    const inputStartingPhase = useRef<HTMLSelectElement>(null)
    const inputStartingPhaseMessage = useRef<HTMLSpanElement>(null)
    const inputProjectType = useRef<HTMLSelectElement>(null)
    const inputProjectTypeMessage = useRef<HTMLSpanElement>(null)
    const inputAssetName = useRef<HTMLSelectElement>(null)
    const inputAssetNameMessage = useRef<HTMLSpanElement>(null)
    const inputAssetType = useRef<HTMLSelectElement>(null)
    const inputAssetTypeMessage = useRef<HTMLSpanElement>(null)
    const inputResourceType = useRef<HTMLSelectElement>(null)
    const inputResourceTypeMessage = useRef<HTMLSpanElement>(null)
    const inputChangeInDevelopment = useRef<HTMLSelectElement>(null)
    const inputChangeInDevelopmentMessage = useRef<HTMLSpanElement>(null)
    const inputChangeInTerm = useRef<HTMLSelectElement>(null)
    const inputChangeInTermMessage = useRef<HTMLSpanElement>(null)
    const inputProductType = useRef<HTMLSelectElement>(null)
    const inputProductTypeMessage = useRef<HTMLSpanElement>(null)
    const inputProjectValue = useRef<HTMLInputElement>(null)
    const inputProjectValueMessage = useRef<HTMLSpanElement>(null)
    const inputDevelopmentScenario = useRef<HTMLSelectElement>(null)
    const inputDevelopmentScenarioMessage = useRef<HTMLSpanElement>(null)


    const inputProjectOwnerMessage = useRef<HTMLInputElement>(null)
    const inputGateKeeperMessage = useRef<HTMLInputElement>(null)
    const inputProjectManagerMessage = useRef<HTMLInputElement>(null)
    const inputFolcalPointMessage = useRef<HTMLInputElement>(null)
    const inputLocation = document.getElementById('location') as HTMLInputElement
    const inputLocationMessage = useRef<HTMLInputElement>(null)
    const inputMapLocation = document.getElementById('map-location') as HTMLInputElement
    const inputMapLocationMessage = useRef<HTMLInputElement>(null)
   
    const inputAssetCode = useRef<HTMLInputElement>(null)
    const inputDescription =  useRef<HTMLTextAreaElement>(null)
    const inputRemark =  useRef<HTMLTextAreaElement>(null)
    const inputNote =  useRef<HTMLTextAreaElement>(null)
    const inputConcessions = useRef<HTMLInputElement>(null)

    const isGatekeeper =  props.user.roleSystemLevelId == SystemLevel.GateKeeper || props.projectRole?.roleProjectLevelId == ProjectLevel.GateKeeper ? true : false
    const isSuperAdmin = props.user.roleSystemLevelId == SystemLevel.SuperAdmin ? true : false
    const isCanUpdateInformation = props.user.informationUpdate || props.projectRole?.informationUpdate ? true : false
    const disabled = isGatekeeper  ? true : false
    
    var map:google.maps.Map;
    var marker = new google.maps.Marker
    var mapInput:any = null
    var mapSearchBox:any = null
    const [center,setCenter ]= useState<Coordinate>({ lat:  13.7414844, lng: 100.4559493 })
    const [isMapLoad,setMapLoad] = useState(false)
    const [isMapOpen,setMapOpen] = useState(false)
    
    useEffect( () => {
        if(accessToken){
            if(props.initialData.projectId > 0) {
                onProjectmodeChangeHandler(projectModeId,phaseId)
                //onAssetChangeHandler(assetId)
                setCenterMap()
               
            }
        }
    },[accessToken])
    
    const onProjectmodeChangeHandler = (_projectModeId,_phaseId = 0) => {
       
        let _projectModeFlag = Object.assign({},initialProjectModeFlag)
        
        if(_projectModeId == 2){
            _projectModeFlag.developmentScenarioFlag = 0
        }

        if(_projectModeId == 3){
            _projectModeFlag.projectTypeFlag = 0
            _projectModeFlag.assetTypeFlag = 0
            _projectModeFlag.resourceTypeFlag = 0
            _projectModeFlag.changeTermConditionFlag = 0
            _projectModeFlag.changeDevelopmentConceptFlag = 0
            _projectModeFlag.developmentScenarioFlag = 0
        }
        
        setProjectModeId(_projectModeId)
        setProjectModeFlag(_projectModeFlag)
        setProjectPhases(_projectModeId)
        setPhaseId(_phaseId)
    }

    const onAssetChangeHandler = (id: number) => {
        let asset = property.assets.find(a => a.assetId == id)
        if(asset){
            let countries = property.countries.find(c => c.countryId == asset?.countryId)
            if(countries && countries.countryName){
                setCountry(countries.countryName)
            }
        }
        setAssetId(id)
    }

    const setCenterMap = () => {
        let coordinate:Coordinate = { lat : props.initialData.lat, lng : props.initialData.lng}
        if(coordinate.lat > 0 && coordinate.lng > 0){
            setCenter(coordinate)
        }
    }

    const setProjectPhases = (_projectModeId: number) => {
        if(property){
            let _phases = property.phases.filter(x => x.projectModeId == _projectModeId)
            if(_phases){
                setPhases(_phases)
            }
        }
        
    }

    const setBackgroundColor = (refs:React.RefObject<any>[],value:string) => {
        refs.forEach((ref) => {
            if(ref && ref.current){
                ref.current.style.backgroundColor = value;
            }
        })
    }

    const setInnerText = (refs:React.RefObject<any>[],value:string) => {
        refs.forEach((ref) => {
            if(ref && ref.current){
                ref.current.innerText = value;
            }
        })
    }

    const setErrorShow = (ref:React.RefObject<any>,ref2:React.RefObject<any>,msg:string = "") => {
        let requiredFieldMessage = ' *Required Field.'
        let error_color = "#FFDDDD"
        if(ref && ref.current){
            ref.current.style.backgroundColor = error_color;
            ref.current.scrollIntoView({block: "center"})
        }
        if(ref2 && ref2.current){
            ref2.current.innerText = (msg) ? msg : requiredFieldMessage;
        }
       
    }

    const setElementErrorShow = (element:HTMLInputElement,ref2:React.RefObject<any>,msg:string = "") => {
        let requiredFieldMessage = ' *Required Field.'
        let error_color = "#FFDDDD"
        if(element){
            element.style.backgroundColor = error_color;
            element.scrollIntoView({block: "center"})
        }
        if(ref2 && ref2.current){
            ref2.current.innerText = (msg) ? msg : requiredFieldMessage;
        }
       
    }
    
    const validateProjectData = (projectData:Project):boolean =>{
        let message = ""
        let requiredFieldMessage = ' *Required Field.'
        let errorFlag = false
        let error_color = "#FFDDDD"

        // reset required
        setBackgroundColor([inputProjectMode,inputInitialProjectName,inputCurrentProjectName,inputStartingPhase,inputAssetName,
            inputDevelopmentScenario,inputResourceType,inputAssetType,inputProjectType,inputChangeInDevelopment,
            inputChangeInTerm,inputProductType,inputProjectValue
        ],"")
        setInnerText([inputProjectModeMessage,inputInitialProjectNameMessage,inputCurrentProjectNameMessage,inputStartingPhaseMessage,inputAssetNameMessage,
            inputDevelopmentScenarioMessage,inputResourceTypeMessage,inputAssetTypeMessage,inputProjectTypeMessage,
            inputChangeInDevelopmentMessage,inputChangeInTermMessage,inputFolcalPointMessage,inputGateKeeperMessage,
            inputProjectOwnerMessage,inputProductTypeMessage,inputMapLocationMessage,
            inputLocationMessage,inputProjectValueMessage
        
        ],"*")
        
        setFocalPointsBackgroundColor("")
        setGatekeepersBackgroundColor("")
        setProjectManagersBackgroundColor("")
        setProjectOwnersBackgroundColor("")
        setLocationBackgroundColor("")
        inputMapLocation.style.backgroundColor = "";
        
        if (!projectData.projectModeId) {
            setErrorShow(inputProjectMode,inputProjectModeMessage)
            errorFlag=true
        } 

        if(!projectData.registerDateTime){
            setElementErrorShow(inputRegisterDate,inputRegisterDateMessage)
            errorFlag=true
        }

        if (!projectData.initialProjectName) {
            setErrorShow(inputInitialProjectName,inputInitialProjectNameMessage)
            errorFlag=true
        }

        if (!projectData.currentProjectName) {
            setErrorShow(inputCurrentProjectName,inputCurrentProjectNameMessage)
            errorFlag=true
        } 

        if (!projectData.startPhaseId) {
            setErrorShow(inputStartingPhase,inputStartingPhaseMessage)
            errorFlag = true
        }

        if (!projectData.assetId) {
            setErrorShow(inputAssetName,inputAssetNameMessage)
            errorFlag = true
        }

        if (!projectData.projectValue) {
            setErrorShow(inputProjectValue,inputProjectValueMessage)
            errorFlag = true
        }

        if (projectData.projectValue.toString().length > 10) {
            setErrorShow(inputProjectValue,inputProjectValueMessage," *Max length 10")
            errorFlag = true
        }

        if (projectData.locationId == -1) {
            if(inputLocation){
                setLocationBackgroundColor(error_color)
                inputMapLocation.scrollIntoView({block: "center"})
            }
            
            setInnerText([inputLocationMessage],requiredFieldMessage)
            errorFlag = true
          
        }

        if (inputLocation.value == "") {
            if(inputLocation){
                setLocationBackgroundColor(error_color)
                inputMapLocation.scrollIntoView({block: "center"})
            }
            
            setInnerText([inputLocationMessage],requiredFieldMessage)
            errorFlag = true
          
        }

        let mapLocation = inputMapLocation.value.split(',')
        let mapLocationError = false
        if (inputMapLocation.value === "" || mapLocation.length <= 1 || !NumHelper.IsNumber(mapLocation[0]) || !NumHelper.IsNumber(mapLocation[1])) {
            if(inputMapLocation){
                inputMapLocation.style.backgroundColor = error_color;
                inputMapLocation.scrollIntoView({block: "center"})
            }
            setInnerText([inputMapLocationMessage],requiredFieldMessage)
            mapLocationError = true
            errorFlag = true
        }

        if (mapLocationError === false) {
            if(parseFloat(mapLocation[0]) > 90 || parseFloat(mapLocation[0]) < -90){
                mapLocationError = true
            }
            if(parseFloat(mapLocation[1]) > 180 || parseFloat(mapLocation[1]) < -180){
                mapLocationError = true
            }
            if(mapLocationError){
                if(inputMapLocation){
                    inputMapLocation.style.backgroundColor = error_color;
                    inputMapLocation.scrollIntoView({block: "center"})
                }
                setInnerText([inputMapLocationMessage],"Latitude: min/max -90 to 90, Longitude: min/max -180 to 180")
                mapLocationError = true
                errorFlag = true
            }
        }

        if (!projectData.productTypeId) {
            setErrorShow(inputProductType,inputProductTypeMessage)
            errorFlag = true
        }

        if (projectData.projectOwners.length == 0) {

            setProjectOwnersBackgroundColor(error_color)
            if(inputProjectOwnerMessage.current){
                inputProjectOwnerMessage.current.innerText = requiredFieldMessage;
                inputProjectOwnerMessage.current.scrollIntoView({block: "center"})
            }
            errorFlag = true
        }

        if(blocks.length > 0){
            blocks.forEach((block,blockIndex) => {
                let blockInputMessage = document.getElementById('input-block-' + blockIndex) as HTMLInputElement
                if(blockInputMessage){
                    blockInputMessage.style.backgroundColor = ""
                }

                let blockMessage = document.getElementById('block-message-' + blockIndex) as HTMLSpanElement
                if(blockMessage){
                    blockMessage.innerHTML = "*"
                }

                if(blockInputMessage.value == ""){
                    if(blockInputMessage){
                        blockInputMessage.style.backgroundColor = error_color
                    }
                    if(blockMessage){
                        blockMessage.innerHTML = "*Required Field."
                    }
                }

                let partnerMessage = document.getElementById('partner-message-' + blockIndex) as HTMLSpanElement
                if(partnerMessage){
                    partnerMessage.innerHTML = ""
                }

                let partnerMiniMessage = document.getElementById('partner-mini-message-' + blockIndex) as HTMLDivElement
                if(partnerMiniMessage){
                    partnerMiniMessage.innerHTML = ""
                }

                let partnerShareMessage = document.getElementById('partner-share-message-' + blockIndex) as HTMLSpanElement
                if(partnerShareMessage){
                    partnerShareMessage.innerHTML = ""
                }

                if(block.partners.length > 0){
                    let sumPartner = 0
                    let isHasPartner = false

                    block.partners.forEach( (p,index) => {
                        let partnerElement = document.getElementById('partner-' + blockIndex + '-' + index) as HTMLInputElement
                        if(partnerElement){
                            partnerElement.style.backgroundColor = ""
                        }

                        let element = document.getElementById('partner-share-' + blockIndex + '-' + index) as HTMLInputElement
                        if(element){
                            element.style.backgroundColor = ""
                        }
                        
                        if(partnerElement && !partnerElement.value){
                            if(partnerMessage){
                                partnerMessage.innerHTML = "*Required Field."
                            }
                            if(partnerMiniMessage && partnerMessage.style.display !== 'none'){
                                partnerMiniMessage.innerHTML =  "*Required Field."
                                partnerMiniMessage.scrollIntoView({block: "center"})
                            }
                            if(partnerElement){
                                partnerElement.style.backgroundColor = error_color
                            }
                            errorFlag = true
                        }

                        if(p.partnerName){
                            isHasPartner = true
                        }
                        

                        if(NumHelper.IsNumber(p.sharePercentage)){
                            sumPartner = sumPartner + parseFloat(p.sharePercentage.toString())
                        }
                        else{
                            element.style.backgroundColor = error_color
                            if(partnerShareMessage){
                                partnerShareMessage.innerHTML = "*Required Field."
                            }
                           
                            let elementFirst = document.getElementById('partner-share-' + blockIndex +'-0') as HTMLInputElement
                            if(elementFirst){
                                elementFirst.scrollIntoView({block: "center"})
                            }
                            if(partnerMiniMessage && partnerMessage.style.display !== 'none'){
                                partnerMiniMessage.innerHTML =  "*Required Field."
                                partnerMiniMessage.scrollIntoView({block: "center"})
                            }
                            errorFlag = true
                        }
                    })
           
                    if(isHasPartner && sumPartner != 100)
                    {
                        block.partners.forEach( (p,index) => {
                            let element = document.getElementById('partner-share-' + blockIndex + '-' + index) as HTMLInputElement
                            if(element){
                                element.style.backgroundColor = error_color
                            }             
                        })

                        if(partnerShareMessage){
                            partnerShareMessage.innerHTML = "Total share should be 100%."
                        }
                        
                        let elementFirst = document.getElementById('partner-share-' + blockIndex +'-0') as HTMLInputElement
                        if(elementFirst){
                            elementFirst.scrollIntoView({block: "center"})
                        }
                        if(partnerMiniMessage && partnerMessage.style.display !== 'none'){
                            partnerMiniMessage.innerHTML =  "Total share should be 100%."
                            partnerMiniMessage.scrollIntoView({block: "center"})
                        }
                        errorFlag = true
                    }
          
                 }
                 else{
                    if(partnerMessage){
                        partnerMessage.innerHTML = "*Required Field."
                    }
                    if(partnerMiniMessage && partnerMessage.style.display !== 'none'){
                        partnerMiniMessage.innerHTML =  "*Required Field."
                        partnerMiniMessage.scrollIntoView({block: "center"})
                    }
                    if(partnerShareMessage){
                        partnerShareMessage.innerHTML = "*Required Field."
                    }
                    errorFlag = true
                 }
            })
        }

        if (projectData.projectId == 0) {
            if (!projectData.focalPoints || projectData.focalPoints.length == 0) {
                setFocalPointsBackgroundColor(error_color)
                if(inputFolcalPointMessage.current){
                    inputFolcalPointMessage.current.innerText = requiredFieldMessage;
                    inputFolcalPointMessage.current.scrollIntoView({block: "center"})
                }
                errorFlag = true
            }
        }
        
        if (projectModeId == 0 || projectModeId == 1) {
            if (!projectData.developmentScenarioId) {
                setErrorShow(inputDevelopmentScenario,inputDevelopmentScenarioMessage)
                errorFlag = true
            }
            if (!projectData.resourceTypeId) {
                setErrorShow(inputResourceType,inputResourceTypeMessage)
                errorFlag = true
            }
            if (projectData.changeInDevelopmentConcept === -1) {
                setErrorShow(inputChangeInDevelopment,inputChangeInDevelopmentMessage)
                errorFlag = true
            }

            if (projectData.changeInTermAndCondition === -1) {
                setErrorShow(inputChangeInTerm,inputChangeInTermMessage)
                errorFlag = true
            }
        }

        if (projectModeId == 1 || projectModeId == 2) {

            if (!projectData.assetTypeId) {
                setErrorShow(inputAssetType,inputAssetTypeMessage)
                errorFlag = true
            }
            if (!projectData.projectTypeId) {
                setErrorShow(inputProjectType,inputProjectTypeMessage)
            }
        }
        return !errorFlag
    }


    const saveProject = async () => {
        if(isSuperAdmin){
            saveAll()
        }
        else if(isGatekeeper){
            saveGatekeeper()
        }
        else{
            save()
        }
    }

    const saveGatekeeper =async () => {
        let saveBtn = document.getElementById('save-project-btn') as HTMLButtonElement
        if(saveBtn){
            saveBtn.disabled = true
        }
        let message = ""
        let requiredFieldMessage = ' *Required Field.'
        let error_color = "#FFDDDD"

        if (!gatekeepers || gatekeepers.length == 0) {
            setGatekeepersBackgroundColor(error_color)
            if(inputGateKeeperMessage.current){
                inputGateKeeperMessage.current.innerText = requiredFieldMessage;
                inputGateKeeperMessage.current.scrollIntoView({block: "center"})
            }
            if(saveBtn){
                saveBtn.disabled = false
            }
             return false
        }
            
        if(projectStatusId == 0){
            message = 'Error!! ,Project Status is required..'
            let element = document.getElementById('project-status') as HTMLInputElement
            let messageElement = document.getElementById('project-status-message') as HTMLSpanElement
            if(element){
                element.style.backgroundColor = error_color;
                element.scrollIntoView({block: "center"})
            }
            if(messageElement){
                messageElement.innerHTML = message
            }
            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }
        
        const projectId = props.initialData.projectId;
        const projectData:GatekeeperUpdate= {
            projectId: projectId,
            projectStatusId: projectStatusId,
            remark: inputRemark.current ? inputRemark.current.value: "",
            gateKeepers: gatekeepers.map(p => {
                let userItem:UserItem = { username: p.key,name: p.name }
                return userItem
            }),
            updateBy:  account && account.name ? account.name : "",
        }
        //console.log(projectData)
        let token = await getToken()
        let result = await new ProjectService(token).updateGatekeeper(projectData)
        //console.log(result)
        if(result && result.success){
           
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    window.location.reload()
                }, 500);
            
        }
        else{
            if(result)
                alert(`fail ,${result.message}`)

            return false
        }
    }

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const save = async () => {
        //if(!property){
           // return false
        //}
        let saveBtn = document.getElementById('save-project-btn') as HTMLButtonElement
        if(saveBtn){
            saveBtn.disabled = true
        }
        const projectId = props.initialData.projectId;
        let _projectTypeId = projectTypeId
        let _assetTypeId = assetTypeId
        let _resourceTypeId = resourceTypeId
        let _changeInTermAndCondition = changeInTermAndCondition
        let _changeInDevelopmentConcept = changeInDevelopmentConcept
        let _categorySizeId = categorySizeId
        let _developmentScenarioId = developmentScenarioId
        let lat = 0
        let lng = 0
       
        if(projectModeId == 2){
            _developmentScenarioId = 0
            _changeInTermAndCondition = 0
            _changeInDevelopmentConcept = 0
            _resourceTypeId = 0
        }

        if(projectModeId == 3){
            _projectTypeId = 0
            _assetTypeId = 0
            _resourceTypeId = 0
            _categorySizeId = 0
            _changeInTermAndCondition = 0
            _changeInDevelopmentConcept = 0
            _developmentScenarioId = 0
        }
        
        const projectData: Project ={
            projectId: projectId,
            projectModeId: projectModeId,
            projectModeName: null,
            startPhaseId: phaseId,
            startPhaseName: null,
            registerDateTime: registerDateTime,
            projectNo: props.initialData.projectNo,
            assetId: assetId,
            assetName: null,
            assetCode: (inputAssetCode.current) ? inputAssetCode.current.value : "",
            lat: lat,
            lng: lng,
            locationId: location.locationId,
            locationName: location.locationName,
            assetTypeId: _assetTypeId,
            assetTypeName: null,
            resourceTypeId: _resourceTypeId,
            resourceTypeName: null,
            categorySizeId: _categorySizeId,
            categorySizeName: null,
            projectTypeId: _projectTypeId,
            projectTypeName: null,
            developmentScenarioId: _developmentScenarioId,
            developmentScenarioName: "",
            countryName: null,
            initialProjectName: (inputInitialProjectName.current) ? inputInitialProjectName.current.value : "",
            currentProjectName: (inputCurrentProjectName.current) ? inputCurrentProjectName.current.value : "",
            projectValue: (inputProjectValue.current) ? parseFloat(inputProjectValue.current.value) : 0,
            changeInDevelopmentConcept: _changeInDevelopmentConcept,
            changeInTermAndCondition: _changeInTermAndCondition,
            concessions: (inputConcessions.current) ? inputConcessions.current.value : "",
            productTypeId: productTypeId,
            productTypeName: "",
            projectStatusId: 0,
            projectStatusName: "",
            remark: "",
            projectDescription: inputDescription.current ? inputDescription.current.value : "",
            note:  inputNote.current ? inputNote.current.value : "",
            currentPhaseId: phaseId,
            currentStageId: 0,
            projectOwners: projectOwners.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            projectManagers: projectManagers.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            focalPoints: focalPoints.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            gateKeepers: gatekeepers.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            teamMembers: teamMembers.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            secretaries:[],
            projectBlocks: getProjectBlock(),
            active: props.initialData.active,
            createBy: "",
            updateBy: account && account.name ? account.name : "",
            categorySizeThreshold: props.initialData.categorySizeThreshold
        }
        console.log(projectData)
        
        if(!validateProjectData(projectData)){
            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }
        
        let [_lat,_lng] = inputMapLocation.value.split(',')
        projectData.lat = parseFloat(_lat.toString())
        projectData.lng = parseFloat(_lng.toString())
        
        let token = await getToken()
        let result = await new ProjectService(token).update(projectData)
    
        //console.log(result)
        if(result && result.success){
            let token = await getToken()
            new HistoryLogService(token).create(user.userId, projectData.projectId,"Project Informations","Update Project")
          
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    window.location.reload()
                }, 500);
        }
        else{
            if(result)
                alert(`fail ,${result.message}`)

            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }
    }

    const saveAll = async () => {
        //if(!property){
           // return false
        //}
        let saveBtn = document.getElementById('save-project-btn') as HTMLButtonElement
        if(saveBtn){
            saveBtn.disabled = true
        }
        const projectId = props.initialData.projectId;
        let _projectTypeId = projectTypeId
        let _assetTypeId = assetTypeId
        let _resourceTypeId = resourceTypeId
        let _changeInTermAndCondition = changeInTermAndCondition
        let _changeInDevelopmentConcept = changeInDevelopmentConcept
        let _categorySizeId = categorySizeId
        let _developmentScenarioId = developmentScenarioId
        let lat = 0
        let lng = 0
       
        if(projectModeId == 2){
            _developmentScenarioId = 0
            _changeInTermAndCondition = 0
            _changeInDevelopmentConcept = 0
            _resourceTypeId = 0
        }

        if(projectModeId == 3){
            _projectTypeId = 0
            _assetTypeId = 0
            _resourceTypeId = 0
            _categorySizeId = 0
            _changeInTermAndCondition = 0
            _changeInDevelopmentConcept = 0
            _developmentScenarioId = 0
        }
        
        const projectData: Project ={
            projectId: projectId,
            projectModeId: projectModeId,
            projectModeName: null,
            startPhaseId: phaseId,
            startPhaseName: null,
            registerDateTime: registerDateTime,
            projectNo: props.initialData.projectNo,
            assetId: assetId,
            assetName: null,
            assetCode: (inputAssetCode.current) ? inputAssetCode.current.value : "",
            lat: lat,
            lng: lng,
            locationId: location.locationId,
            locationName: location.locationName,
            assetTypeId: _assetTypeId,
            assetTypeName: null,
            resourceTypeId: _resourceTypeId,
            resourceTypeName: null,
            categorySizeId: _categorySizeId,
            categorySizeName: null,
            projectTypeId: _projectTypeId,
            projectTypeName: null,
            developmentScenarioId: _developmentScenarioId,
            developmentScenarioName: "",
            countryName: null,
            initialProjectName: (inputInitialProjectName.current) ? inputInitialProjectName.current.value : "",
            currentProjectName: (inputCurrentProjectName.current) ? inputCurrentProjectName.current.value : "",
            projectValue: (inputProjectValue.current) ? parseFloat(inputProjectValue.current.value) : 0,
            changeInDevelopmentConcept: _changeInDevelopmentConcept,
            changeInTermAndCondition: _changeInTermAndCondition,
            concessions: (inputConcessions.current) ? inputConcessions.current.value : "",
            productTypeId: productTypeId,
            productTypeName: "",
            projectStatusId: projectStatusId,
            projectStatusName: "",
            remark: inputRemark.current ? inputRemark.current.value: "",
            projectDescription: inputDescription.current ? inputDescription.current.value : "",
            note:  inputNote.current ? inputNote.current.value : "",
            currentPhaseId: phaseId,
            currentStageId: 0,
            projectOwners: projectOwners.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            projectManagers: projectManagers.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            focalPoints: focalPoints.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            gateKeepers: gatekeepers.map(p => {
                let userItem:UserItem = { username: p.key,name: p.name }
                return userItem
            }),
            teamMembers: teamMembers.map(p => {
                let userItem:UserItem = { username : p.key , name: p.name};
                return userItem
            }),
            secretaries:[],
            projectBlocks: getProjectBlock(),
            active: props.initialData.active,
            createBy: "",
            updateBy: account && account.name ? account.name : "",
            categorySizeThreshold: props.initialData.categorySizeThreshold
        }
        console.log(projectData)
        
        if(!validateProjectData(projectData)){
            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }

        let message = ""
        let requiredFieldMessage = ' *Required Field.'
        let error_color = "#FFDDDD"

        if (!gatekeepers || gatekeepers.length == 0) {
            setGatekeepersBackgroundColor(error_color)
            if(inputGateKeeperMessage.current){
                inputGateKeeperMessage.current.innerText = requiredFieldMessage;
                inputGateKeeperMessage.current.scrollIntoView({block: "center"})
            }
            if(saveBtn){
                saveBtn.disabled = false
            }
             return false
        }
            
        if(projectStatusId == 0){
            message = 'Error!! ,Project Status is required..'
            let element = document.getElementById('project-status') as HTMLInputElement
            let messageElement = document.getElementById('project-status-message') as HTMLSpanElement
            if(element){
                element.style.backgroundColor = error_color;
                element.scrollIntoView({block: "center"})
            }
            if(messageElement){
                messageElement.innerHTML = message
            }
            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }
        
        let [_lat,_lng] = inputMapLocation.value.split(',')
        projectData.lat = parseFloat(_lat.toString())
        projectData.lng = parseFloat(_lng.toString())
        
        let token = await getToken()
        let result = await new ProjectService(token).updateAll(projectData)
    
        //console.log(result)
        if(result && result.success){
            let token = await getToken()
            new HistoryLogService(token).create(user.userId, projectData.projectId,"Project Informations","Update Project")
          
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    window.location.reload()
                }, 500);
        }
        else{
            if(result)
                alert(`fail ,${result.message}`)

            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }
    }

    const onProjectOwnersSelect = (item) => {
        setProjectOwners(item)
    }

    const onProjectManagersSelect = (item) => {
        setProjectManagers(item)
    }

    const onGatekeepersSelect = (item) => {
        //console.log(item)
        let focalpointList:Item[] = item.filter(x => focalPoints.findIndex(f => f.key == x.key) > -1)
        let projectManagerList:Item[] = item.filter(x => projectManagers.findIndex(f => f.key == x.key) > -1)
        if(focalpointList.length > 0 || projectManagerList.length > 0)
        {
            if(focalPoints.length > 0){
                let usernames = focalpointList.map( m => { return m.name })
                setErrors(['Error, Found "' + usernames.join(', ') + '" in Focal Point'])
            }
            
            if(projectManagerList.length > 0){
                let usernames = projectManagerList.map( m => { return m.name })
                setErrors(['Error, Found "' + usernames.join(', ') + '" in Project Manager'])
            }
          
        }
        else{
            setGatekeeperss(item)
        }
        
    }

    const onTeamMembersSelect = (item) => {
        setTeamMembers(item)
    }

    const onFocalPointSelect = (item) => {
        setFocalPoints(item)
    }

    const searchUser = async(key) => {
        let token = await getToken()
        let result = await new GraphApiService(token).SearchUsername(key)
        return result.data
    }

    const searchUserOther = async(key) => {
        let token = await getToken()
        let result = await new GraphApiService(token).SearchOther(key)
        return result.data
    }

    const searchFocalpoint = async(key) => {
        let token = await getToken()
        let result = await new GraphApiService(token).SearchRole(key,SystemLevel.FocalPoint)
        let data = result.data.filter(x => focalPoints.findIndex(f => f.key == x.userPrincipalName) == -1)
        return data
    }

    const searchGatekeeper = async(key) => {
        let token = await getToken()
        let result = await new GraphApiService(token).SearchRole(key,SystemLevel.GateKeeper)
        let data = result.data.filter(x => gatekeepers.findIndex(f => f.key == x.userPrincipalName) == -1)
        return data
    }

    const searchPartner = async (key:string,index:number) => {
        let token = await getToken()
        let result =  await new PartnerService(token).search(key)
        //console.log(result)
        let partners = blocks[index].partners.map(x => { return x.partnerId })
        let data = result.data.filter(x => partners.indexOf(x.partnerId) === -1)
        return data
    }

    const getProjectBlock = () => {
        let projectBlocks:ProjectBlock[] = Object.assign([],blocks)
        //console.log(blocks)
        blocks.forEach((block,blockIndex) => {
           let blockInput = document.getElementById('input-block-' + blockIndex) as HTMLInputElement
           if(blockInput && blockInput.value){
                projectBlocks[blockIndex].projectBlockName = blockInput.value
                let partners:ProjectPartner[] = []
                block.partners.forEach((partner,partnerIndex) => {
                    if(!partner.partnerId){
                        let partnerElement = document.getElementById('partner-' + blockIndex + '-' + partnerIndex) as HTMLInputElement
                        if(partnerElement){
                            partnerElement.value = ""
                        }
                    }
                    //if(partner.partnerId > 0){
                        let sharePercentageInput = document.getElementById('partner-share-' + blockIndex + '-' + partnerIndex) as HTMLInputElement
                        if(sharePercentageInput){
                            partner.sharePercentage = parseFloat(sharePercentageInput.value)
                            partners.push(partner)
                        }
                    //}
                })
                projectBlocks[blockIndex].partners = partners
           }
        })
        return projectBlocks
    }
    
     const onProjectBlockChange = (blockIndex:number) => {
        let blockInput = document.getElementById('input-block-'+ blockIndex) as HTMLInputElement
        let _blocks:ProjectBlock[] = Object.assign([],blocks)
        if(blockInput){
            _blocks[blockIndex].projectBlockName = blockInput.value
        }
        setBlocks(_blocks)
        setIsUpdate(true)
       
    }

     const addBlock = () => {
        let _blocks:ProjectBlock[] = Object.assign([],blocks)
        blocks.forEach((item,blockIndex) => {
            if(!item.projectBlockName){
                let element = document.getElementById('input-block-' + blockIndex) as HTMLInputElement
                if(element)
                    _blocks[blockIndex].projectBlockName = element.value
            }
        })
        let projectBlocks:ProjectBlock = {
            projectBlockId: 0,
            projectBlockName: "",
            projectId: 0,
            partners:[]
        }
        let partner:ProjectPartner ={
            projectPartnerId: 0,
            partnerId: 0,
            partnerName: "",
            sharePercentage: 0
        }
        projectBlocks.partners.push(partner)
        _blocks.push(projectBlocks)
        setBlocks(_blocks)
        setIsUpdate(true)
        let btnSave = document.getElementById('save-project-btn') as HTMLButtonElement
        if(btnSave){
            btnSave.scrollIntoView({block: "center"})
        }
    }

    const addPartner = (blockIndex:number) => {
        let _blocks:ProjectBlock[] = Object.assign([],blocks)
        blocks.forEach((item,blockIndex) => {
            if(!item.projectBlockName){
                let element = document.getElementById('input-block-' + blockIndex) as HTMLInputElement
                if(element)
                    _blocks[blockIndex].projectBlockName = element.value
            }
        })
        
        let partner:ProjectPartner ={
            projectPartnerId: 0,
            partnerId: 0,
            partnerName: "",
            sharePercentage: 0
        }
        _blocks[blockIndex].partners.push(partner)
        setBlocks(_blocks)
        setIsUpdate(true)
    }

    const confirmDeleteBlock = (blockIndex) => {
        setBlockPartnerIndex({blockIndex: blockIndex,partnerIndex: 0})
        setIsConfirmDeleteBlock(true)
    }

    const removeBlock = () => {
        let _block:ProjectBlock[] = Object.assign([],blocks)
        if(_block.length > 0){
            _block.splice(blockPartnerIndex.blockIndex, 1);
            setBlocks(_block)
        }
        setIsConfirmDeleteBlock(false)
        setIsUpdate(true)
    }

    const confirmDeletePartner = (blockIndex:number,partnerIndex:number) => {
        setBlockPartnerIndex({blockIndex: blockIndex,partnerIndex: partnerIndex})
        setIsConfirmDeletePartner(true)
    }

    const removePartner = () => {
        let _block:ProjectBlock[] = Object.assign([],blocks)
        if(_block.length > 0){
            if(_block[blockPartnerIndex.blockIndex].partners.length > 0){
                _block[blockPartnerIndex.blockIndex].partners.splice(blockPartnerIndex.partnerIndex, 1);
                setBlocks(_block)
            }
        }
        setIsConfirmDeletePartner(false)
        setIsUpdate(true)
    }

    const onPartnerSelect = (item:SelectItem) => {
        //console.log(item)
        if(item.index === -1){
            return false
        }
        if(item.index2 != null && item.index2 > -1){
            let _blocks:ProjectBlock[] = Object.assign([],blocks)
            let blockInput = document.getElementById('input-block-'+ item.index) as HTMLInputElement
            let sharePercentageInput = document.getElementById('partner-share-' + item.index + '-' + item.index2) as HTMLInputElement
            if(blockInput){
                _blocks[item.index].projectBlockName = blockInput.value
            }
            if(sharePercentageInput){
                let sharePercentage = sharePercentageInput.value
                if(sharePercentageInput.value.indexOf('.') > -1){
                    let [number,decimal] = sharePercentageInput.value.split(".")
                    sharePercentage = number + '.' + decimal.substring(0,4)
                }
               
                _blocks[item.index].partners[item.index2].sharePercentage = sharePercentage
               
            }
            // if(sharePercentageInput ){
            //     _blocks[item.index].partners[item.index2].sharePercentage = parseFloat(sharePercentageInput.value)
            // }
            _blocks[item.index].partners[item.index2].partnerId = item.id
            _blocks[item.index].partners[item.index2].partnerName = item.value ? item.value : ""
            
            //console.log(_blocks)
            setBlocks(_blocks)
            setIsUpdate(true)
        }

    }

    const onPartnerShareChange = (blockIndex: number,partnerIndex:number) => {
        let _blocks:ProjectBlock[] = Object.assign([],blocks)
        let blockInput = document.getElementById('input-block-'+ blockIndex) as HTMLInputElement
        let sharePercentageInput = document.getElementById('partner-share-' + blockIndex + '-' + partnerIndex) as HTMLInputElement
        if(blockInput){
            _blocks[blockIndex].projectBlockName = blockInput.value
        }
        if(sharePercentageInput){
            let sharePercentage = sharePercentageInput.value
                if(sharePercentageInput.value.indexOf('.') > -1){
                    let [number,decimal] = sharePercentageInput.value.split(".")
                    sharePercentage = number + '.' + decimal.substring(0,4)
                }
           
            _blocks[blockIndex].partners[partnerIndex].sharePercentage = sharePercentage
        }
    
        setBlocks(_blocks)
        setIsUpdate(true)
    }

    const onProjectValueChange = (value:number) => {
        if(projectModeId === 3){
            return
        }
        
        let _categorySizeId = (value > props.initialData.categorySizeThreshold) ? 1 : 2
        setCategorySizeId(_categorySizeId)
    }

    const onInitialProjectNameChange = () => {
        let inputCurrentProjectName = document.getElementById('current-project-name') as HTMLInputElement
        if(inputCurrentProjectName && inputInitialProjectName.current){
            inputCurrentProjectName.value = inputInitialProjectName.current.value
        }
    }

    const searchLocation = async (key) => {
        let token = await getToken()
        let result =  await new LocationService(token).Search(key)
        //console.log(result)
        return result.data
    }

    const onLocationSelect = (item:SelectItem) => {
        let _location = Object.assign({},location)
        _location.locationId = item.id
        _location.locationName = item.value ? item.value : ""
        //console.log(_location)
        setLocation(_location)
        setIsUpdate(true)
    }

    /* Google Map Function */
    const initMap = () => {
        map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
          center: center,
          zoom: 8,
        });

        mapInput = document.getElementById("pac-input") as HTMLInputElement;
        mapSearchBox = new google.maps.places.SearchBox(mapInput);

        map.controls[google.maps.ControlPosition.TOP_CENTER].push(mapInput);
        map.addListener("bounds_changed", () => {
            mapSearchBox.setBounds(map.getBounds() as google.maps.LatLngBounds);
        });

        marker = new google.maps.Marker({
            position: center,
            map: map,
            title: "test",
            draggable:true
        });
        
        google.maps.event.addListener(map, "click",onMarkerMove)
        marker.addListener('dragend', onMarkerDragEnd);
        
        mapSearchBox.addListener("places_changed", () => {
            const places = mapSearchBox.getPlaces();
        
            if (places.length == 0) {
              return;
            }
        
            const bounds = new google.maps.LatLngBounds();
        
            places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                //console.log("Returned place contains no geometry");
                return;
              }
              
              marker.setPosition(place.geometry.location)
              
              if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
              } else {
                bounds.extend(place.geometry.location);
              }
            });
            map.fitBounds(bounds);
        });
        
        setMapLoad(true)
      }

    const onMapLocationClick = () => {
        let mapBox = document.getElementById('map-box') as HTMLDivElement
        let searchBox = document.getElementById('pac-input') as HTMLInputElement
        mapBox.style.height = '500px'
        searchBox.style.height = '35px'
        searchBox.style.border = '1px solid #ddd'
        searchBox.placeholder = "Search Location"
        setMapOpen(true)
        
        if(!isMapLoad){
            setTimeout(() => {
                initMap() 
            }, 1000)
        }
        
    }
    
    const onMarkerMove = (ev) => {
        let _coordinate:Coordinate = { lat: ev.latLng.lat(), lng:  ev.latLng.lng()}
        marker.setPosition(_coordinate)
        inputMapLocation.value = _coordinate.lat + ',' + _coordinate.lng
        setIsUpdate(true)
    }

    const onMarkerDragEnd = (ev) => {
        let _coordinate:Coordinate = { lat: ev.latLng.lat(), lng:  ev.latLng.lng()}
        marker.setPosition(_coordinate)
        inputMapLocation.value = _coordinate.lat + ',' + _coordinate.lng
        setIsUpdate(true)
    }
    
    const onMapCloseHandler = () => {
        let mapBox = document.getElementById('map-box') as HTMLDivElement
        let searchBox = document.getElementById('pac-input') as HTMLInputElement
        mapBox.style.height = '0'
        searchBox.style.height = '0'
        searchBox.style.border = 'none'
        searchBox.placeholder = ""
        setMapOpen(false)
    }
    /* End Google Map Function */

    const getLocation = async () => {
        let token = await getToken()
        let result =  await new LocationService(token).getLimit()
        //console.log(result)
        return result.data
    }

    const getPartner = async (index:number) => {
        let token = await getToken()
        let result =  await new PartnerService(token).getLimit()
        let partners = blocks[index].partners.map(x => { return x.partnerId })
        let data = result.data.filter(x => partners.indexOf(x.partnerId) === -1)

        return data
    }

    const validate4Decimal = (e) => {
        let value = e.target.value
        if (/^\d+\.\d{4,}/.test(value)) {
            let [number,decimal] = value.split('.')
            e.target.value = number + '.' + decimal.substring(0,4)
        }
    }

    return (
        
        <div className='inform-section'>
                
                <div className='row'>
                    <div className="col-sm-6" style={{ textAlign: 'left' ,marginBottom: '10px'}}>
                        <div className='project-label' style={{ padding: '0',marginLeft: '0'}}>
                            <span>Project Information</span>
                        </div>
                    </div>
                    {/* <div className='col-sm-6' style={{textAlign:'right',paddingRight:'0px'}}>
                        <button type="button" className="btn btn-sm bg-dark-blue" style={{width:'150px'}} onClick={saveProject}>
                            <AiOutlineSave size={20} />
                            <span style={{ marginLeft: '5px'}}>Save</span>
                        </button>
                    </div> */}
                </div>
                

                <div className='row'>
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Project Category <span ref={inputProjectModeMessage} className='require'>*</span></div>
                    <select ref={inputProjectMode} id="project-mode" className='form-select form-select-sm '  value={projectModeId} onChange={ ev => onProjectmodeChangeHandler(parseInt(ev.target.value)) } disabled>
                            <option value={0} disabled hidden>Please Select</option>
                            { property?.projectModes.map(m => <option key={uniqid()} value={m.projectModeId}>{ m.projectModeName}</option>) }  
                        </select>
                    </div>
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Initial Project Name <span ref={inputInitialProjectNameMessage} className='require'>*</span></div>
                        <input ref={inputInitialProjectName} id="initial-project-name" onBlur={onInitialProjectNameChange} defaultValue={props.initialData.initialProjectName} type="text" className="form-control form-control-sm inform-input" onChange={() => setIsUpdate(true)} disabled={disabled}/>
                    </div>
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Current Project Name <span ref={inputCurrentProjectNameMessage} className='require'>*</span></div>
                        <input ref={inputCurrentProjectName} id="current-project-name"  defaultValue={props.initialData.currentProjectName} type="text" className="form-control form-control-sm inform-input" onChange={() => setIsUpdate(true)} disabled={disabled}/>
                    </div>
                </div>
                <div className='row'>
                
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Registered Date <span ref={inputRegisterDateMessage} className='require'>*</span></div>
                    <DatePicker 
                        id="register-date" 
                        dateFormat="dd MMM yyyy" 
                        selected={ registerDateTime } 
                        onChange={ (date) => { setRegisterDateTime(date); setIsUpdate(true) } } 
                        minDate={new Date('2000-01-01')} 
                        maxDate={new Date(props.property.date)} 
                        isClearable 
                        placeholderText="Select Date" 
                        className="form-control form-control-sm" 
                        disabled={disabled}
                    />
                    </div>
                
                    <div className='col-md-4 inform'>
                    { projectModeId === 1 || projectModeId === 2 ?
                    <>
                        <div className='topic-head'>Project Type<span ref={inputProjectTypeMessage} className='require'>*</span></div>

                            <select ref={inputProjectType} id="project-type" value={projectTypeId} className='form-select form-select-sm ' onChange={ (ev) => { setProjectTypeId(parseInt(ev.target.value));setIsUpdate(true) }} disabled={disabled}>
                                    <option value={0} disabled hidden>Please Select</option>
                                    { property?.projectTypes.map(t => <option key={uniqid()} value={t.projectTypeId}>{ t.projectTypeName}</option>) }  
                                </select>  
                    </>:null
                    }        
                    </div>
                    <div className='col-md-4 inform'>
                    { projectModeId === 1 || projectModeId === 2 ?
                        <>
                        <div className='topic-head'>Asset Type <span ref={inputAssetTypeMessage} className='require'>*</span></div>
                    
                            <select ref={inputAssetType} id="asset-type" value={assetTypeId}  className='form-select form-select-sm ' onChange={ (ev) => { setAssetTypeId(parseInt(ev.target.value));setIsUpdate(true)  }} disabled={disabled} > 
                                    <option value={0} disabled hidden>Please Select</option>
                                    { property?.assetTypes.map(a => <option key={uniqid()} value={a.assetTypeId}>{ a.assetTypeName}</option>) }  
                                </select>     
                        </>:null
                    }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Asset Name <span ref={inputAssetNameMessage} className='require'>*</span></div>
                    <select ref={inputAssetName} id="asset" className='form-select form-select-sm ' value={assetId} onChange={ (ev) => { onAssetChangeHandler(parseInt(ev.target.value));setIsUpdate(true) }} disabled={disabled}>
                            <option value={0} disabled hidden>Please Select</option>
                            <option value={props.initialData.assetId}>{props.initialData.assetCode} - { props.initialData.assetName }</option>
                            { property?.assets.filter(x => x.assetId != props.initialData.assetId).map(a => <option key={uniqid()} value={a.assetId}>{a.assetCode} - { a.assetName }</option>) }  
                        </select>
                    </div>
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Country</div>
                        <input id="country"  value={country ? country : ""} type="text" className="form-control form-control-sm" disabled/>
                    </div>
                 
                </div>
            { projectModeId == 1 ?
                <div className='row'>
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Hydrocarbon Resource (New or Existing) 
                            <a data-tip data-for='hydrocarbon-resource-info' style={{cursor: 'pointer'}}>
                                <AiOutlineInfoCircle  size={20}/>
                            </a>
                            <span ref={inputResourceTypeMessage} className='require'>*</span>
                            <ReactTooltip id='hydrocarbon-resource-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                            <div className='tooltip-div'>New hydrocarbon resource or Existing hydrocarbon resource</div>
                            </ReactTooltip>   
                        </div> 
                        { projectModeFlag.resourceTypeFlag ?
                        <select ref={inputResourceType} id="resourec-type" value={resourceTypeId} className='form-select form-select-sm ' onChange={ (ev) => { setResourceTypeId(parseInt(ev.target.value));setIsUpdate(true) }}  disabled={disabled}>
                                <option value={0} disabled hidden>Please Select</option>
                                { property?.resourceTypes.map(r => <option key={uniqid()} value={r.resourceTypeId}>{ r.resourceTypeName }</option>) }  
                            </select>
                            :<div><input type="text" className="form-control form-control-sm inform-input" disabled/></div>
                        }

                    </div>
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Change in Development Concept 
                            <a data-tip data-for='change-development-concept-info' style={{cursor: 'pointer'}}>
                                <AiOutlineInfoCircle  size={20}/>
                            </a>
                        <span ref={inputChangeInDevelopmentMessage} className='require'>*</span>
                            <ReactTooltip id='change-development-concept-info'data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                            <div className='tooltip-div'>Major change from existing design.</div>
                            </ReactTooltip>   
                        </div>
                        { projectModeFlag.changeDevelopmentConceptFlag ?
                            <>
                            <select ref={inputChangeInDevelopment} id="change-development-concept" value={changeInDevelopmentConcept} className='form-select form-select-sm ' onChange={ (ev) => { setChangeInDevelopmentConcept(parseInt(ev.target.value));setIsUpdate(true) }} disabled={disabled}>
                                    <option value={-1} disabled hidden>Please Select</option>
                                    <option value={1}>Change in Development Concept</option>
                                    <option value={0}>No Change in Development Concept</option>
                                </select>
                            </>: <div><input type="text" className="form-control form-control-sm inform-input" disabled/></div>
                        }
                    </div>
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Change in Term and Condition 
                                    <a data-tip data-for='change-term-and-condition-info' style={{cursor: 'pointer'}}>
                                        <AiOutlineInfoCircle  size={20}/>
                                    </a>
                        <span ref={inputChangeInTermMessage} className='require'>*</span>
                                    <ReactTooltip id='change-term-and-condition-info' data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                    <div className='tooltip-div'>Change in commercial commitment e.g., GSA,PSC, Concession.</div>
                                    </ReactTooltip>
                        </div>
                        { projectModeFlag.changeTermConditionFlag ?
                            <>
                            <select ref={inputChangeInTerm} id="change-term-and-condition" value={changeInTermAndCondition} className='form-select form-select-sm' onChange={ (ev) => { setChangeInTermAndCondition(parseInt(ev.target.value));setIsUpdate(true) }} disabled={disabled}>
                                    <option value={-1} disabled hidden>Please Select</option>
                                    <option value={1}>Change in Terms and Conditions</option>
                                    <option value={0}>No Change in Terms and Conditions</option>
                                </select>  
                            </>: <div><input type="text" className="form-control form-control-sm inform-input" disabled/></div>
                        }
                    </div>
                </div> : null
            }

                <div className='row'>
                    <div className='col-md-4 inform'>
                    <div className='topic-head'>Product Type <span ref={inputProductTypeMessage} className='require'>*</span></div>        
                    <select ref={inputProductType} id="product-type" value={productTypeId} className='form-select form-select-sm ' onChange={ (ev) => { setProductTypeId(parseInt(ev.target.value));setIsUpdate(true) }}  disabled={disabled}>
                            <option value={0} disabled hidden>Please Select</option>
                            { property?.productTypes.map(p => <option key={uniqid()} value={p.productTypeId}>{ p.productTypeName }</option>) }  
                        </select>
                    </div>
                     <div className='col-md-4 inform'>
                        {  props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1?
                        <>
                        <div className='topic-head'>Project Value/Cost Estimated(MMUSD) 
                            <a data-tip data-for='project-value-info' style={{cursor: 'pointer'}}>
                                <AiOutlineInfoCircle size={20}/>
                            </a>
                        <span ref={inputProjectValueMessage} className='require'>*</span>
                            <ReactTooltip id='project-value-info' data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                            <div className='tooltip-div'>Estimated Cost of a project development / Approved Budget</div>
                            </ReactTooltip>
                        </div>
                        <input ref={inputProjectValue} type="number" defaultValue={ props.initialData.projectValue ?  props.initialData.projectValue : ''} id="project-value" onBlur={(ev) => { onProjectValueChange(parseFloat(ev.target.value));setIsUpdate(true)}} className="form-control form-control-sm inform-input" disabled={disabled}/>
                       </>:<input ref={inputProjectValue} type="hidden" defaultValue={ props.initialData.projectValue ?  props.initialData.projectValue : '0'} />
                        }
                    </div>
                    <div className='col-md-4 inform'>
                    { projectModeId ===1 || projectModeId === 2 ?
                            <>
                                <div className='topic-head'>Project Category Size</div>  
                                <input value={ categorySizeId === 1 ? 'Large' : 'Medium'} id="project-category" className="form-control form-control-sm inform-input" disabled/>
                            </>:null
                        }
                    </div>
                   
                </div>

                <div className='row'>
                { projectModeId == 1 ?
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Project Development Scenario <span ref={inputDevelopmentScenarioMessage} className='require'>*</span></div>
                            <select ref={inputDevelopmentScenario} id="project-development-scenario" value={developmentScenarioId} className='form-select form-select-sm' onChange={ (ev) => { setDevelopmentScenarioId(parseInt(ev.target.value));setIsUpdate(true) }}  disabled={disabled}>
                                        <option value={0} disabled hidden>Please Select</option>
                                            { property?.developmentScenarioes.map(d => <option key={uniqid()} value={d.developmentScenarioId}>{ d.developmentScenarioName}</option>) }  
                            </select>
                    </div>: null
                }
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Concession/PSC/PPA</div>
                        <input ref={inputConcessions} id="concession "type="text" defaultValue={props.initialData.concessions} className="form-control form-control-sm inform-input" onChange={() => setIsUpdate(true)} disabled={disabled}/>
                    </div>
                    { projectModeId != 1 ?
                    <div className='col-md-4 inform'>
                       
                    </div>: null
                }
                   
                </div>

                <div className='row'>
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Project Description
                            <a data-tip data-for='project-description-info' style={{cursor: 'pointer',marginLeft: '2px'}}>
                                <AiOutlineInfoCircle  size={20}/>
                            </a>
                         
                            <ReactTooltip id='project-description-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                            <div className='tooltip-div'>Project Background. Example: To develop greenfield Oil development project to support Algeria business plan</div>
                            </ReactTooltip>
                        </div>
                        <textarea ref={inputDescription} id="description" defaultValue={props.initialData.projectDescription} className="form-control" rows={3} placeholder="" onChange={() => setIsUpdate(true)} disabled={disabled}></textarea>
                    </div>
                    <div className='col-md-4 inform'>
                        <div className='topic-head'>Note</div>
                        <textarea ref={inputNote} id="note" defaultValue={props.initialData.note} className="form-control" rows={3} onChange={() => setIsUpdate(true)} disabled={disabled}></textarea>
                    </div>
                    <div className='col-md-4 inform'></div>
                </div>
                        
                <div className='row'>
                    <div className='col-md-4' style={{ paddingTop: '10px'}}>
                    <div className='topic-head'>Project Owner <span ref={inputProjectOwnerMessage} className='require'>*</span></div>
                        { disabled ?  <input defaultValue={props.initialData.projectOwners.map((p) => { return p.name }).join(',') }  type="text" className="form-control form-control-sm" style={{ height: '40px'}} disabled/> : 
                        
                    <Picker
                            placeholder='Search by ID , Name'
                            selectedItems={projectOwners ? projectOwners : []}
                            onChange={ (item) => { onProjectOwnersSelect(item);setIsUpdate(true)}}
                            service={searchUserOther}
                            inputTextColor={projectOwnersBackgroundColor}
                            minimumTextLength={1}
                    />
                        
                        
                    }
                    </div>
                    { props.initialData.projectId > 0 ?
                       <>
                          <div className='col-md-4' style={{ paddingTop: '10px'}}>
                         
                              <div className='topic-head'>Project Owner Team/Project Team Members</div>
                              { disabled ?  <input defaultValue={props.initialData.teamMembers.map((p) => { return p.name }).join(',')}  type="text" className="form-control form-control-sm" style={{ height: '40px'}} disabled/> :   

                                <Picker
                                    placeholder='Search by ID , Name'
                                    selectedItems={teamMembers ? teamMembers : []}
                                    onChange={(item) => { onTeamMembersSelect(item);setIsUpdate(true) }}
                                    service={searchUserOther}
                                    inputTextColor={teamMembersBackgroundColor}
                                    minimumTextLength={1}
                                />
                             }
                      </div>
                      { (isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                      <div className='col-md-4' style={{ paddingTop: '10px'}}>
                                <div className='topic-head'>Gatekeeper <span ref={inputGateKeeperMessage} className='require'>*</span></div>

                                <Picker
                                    placeholder='Search by ID , Name'
                                    selectedItems={gatekeepers ? gatekeepers : []}
                                    onChange={ (item) => { onGatekeepersSelect(item);setIsUpdate(true) }}
                                    service={searchGatekeeper}
                                    inputTextColor={gatekeepersBackgroundColor}
                                    minimumTextLength={1}
                                />
                        </div>: null
                        }
                        </>:null
                    }
                { props.initialData.projectId == 0 ?
                    <>
                    <div className='col-md-4' style={{ paddingTop: '10px'}}>
                            <div className='topic-head'>Project Manager <span ref={inputProjectManagerMessage} className='require'></span></div>
                        { disabled ?  <input defaultValue={props.initialData.projectManagers.join(',')}  type="text" className="form-control form-control-sm" disabled/> : 

                                <Picker
                                    placeholder='Search by ID , Name'
                                    selectedItems={projectManagers ? projectManagers : []}
                                    onChange={ (item) => { onProjectManagersSelect(item);setIsUpdate(true) }}
                                    service={searchUserOther}
                                    inputTextColor={projectManagersBackgroundColor}
                                    minimumTextLength={1}
                                />
                        }
                    </div>
                
                    <div className='col-md-4' style={{ paddingTop: '10px'}}>
                            <div className='topic-head'>Focal Point <span className='require'>*</span></div>
                            { disabled ?  <input defaultValue={props.initialData.focalPoints.join(',')}  type="text" className="form-control form-control-sm" disabled/> : 

                                <Picker
                                    placeholder='Search by ID , Name'
                                    selectedItems={focalPoints ? focalPoints : []}
                                    onChange={(item) => { onFocalPointSelect(item);setIsUpdate(true) }}
                                    service={searchFocalpoint}
                                    inputTextColor={focalPointsBackgroundColor}
                                    minimumTextLength={1}
                                />
                            }
                            </div>
                    </>:null
                }
                </div>

                { props.initialData.projectId == 0 ?
                <div className='row'>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner Team/Project Team Members</div>
                        { disabled ?  <input defaultValue={props.initialData.teamMembers.join(',')}  type="text" className="form-control form-control-sm" disabled/> : 

                            <Picker
                                placeholder='Search by ID , Name'
                                selectedItems={teamMembers ? teamMembers : []}
                                onChange={(item) => { onTeamMembersSelect(item);setIsUpdate(true) }}
                                service={searchUser}
                                inputTextColor={teamMembersBackgroundColor}
                                minimumTextLength={1}
                            />
                        }
                        
                    </div>
                    <div className='col-md-8 inform-bottom'></div>
                </div>:null
                }
                { (isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                <div className='row'>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Status <span id="project-status-message" className='require'>*</span></div>
                        <select id="project-status" value={projectStatusId}  className='form-select form-select-sm' onChange={ (ev) => { setProjectStatusId(parseInt(ev.target.value));setIsUpdate(true) }} disabled={props.projectRole?.informationUpdate || isSuperAdmin ? false : true}>
                            { property?.projectStatuses.map(p => <option key={uniqid()} value={p.projectStatusId}>{ p.projectStatusName }</option>) }  
                        </select>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Remark</div>
                        <textarea ref={inputRemark} id="remark" defaultValue={props.initialData.remark} className="form-control" rows={3} placeholder="" onChange={() => setIsUpdate(true)} disabled={props.projectRole?.informationUpdate || isSuperAdmin ? false : true}></textarea>
                    </div>
                    <div className='col-md-4 inform-bottom'></div>
                </div> : null
                }
                
           
                <div className='row'>
                    <div className='col-md-4 inform' style={{ paddingTop: '10px'}}>
                        <div className='topic-head'>Location <span ref={inputLocationMessage} className='require'>*</span></div>
                            { disabled ?  <input id='location' defaultValue={location.locationName}  type="text" className="form-control form-control-sm" disabled/> : 
                            <TextboxAutocomplete key={uniqid()} idKey="locationId" valueKey="locationName" onClick={getLocation} onChange={searchLocation} onSelect={onLocationSelect} elementId={'location'} initialId={location.locationId} initialValue={location.locationName} backgroundColor={locationBackgroundColor}/>
                            }
                    </div>
                    <div className='col-md-4 inform'  style={{ paddingTop: '10px'}}>
                        <div style={{marginBottom:'5px'}}>
                            <div className='topic-head'>Map Location
                                <a data-tip data-for='map-location-info' style={{cursor: 'pointer'}}>
                                    <AiOutlineInfoCircle  size={20} color={Color.Blue}/>
                                </a>

                                <span ref={inputMapLocationMessage} className='require'>*</span>
                                <ReactTooltip id='map-location-info' data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                <div className='tooltip-div'>Project location numbering system refer to google map ( example : 8.827419,101.826292)</div>
                                <div className='tooltip-div'>Latitude: min/max -90 to 90, Longitude: min/max -180 to 180</div>
                                </ReactTooltip> 

                                { (!isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                                    <span style={{float:'right',cursor:'pointer'}} onClick={ () => onMapLocationClick()} >
                                        <AiOutlineSearch color={Color.DarkBlue} size={18} cursor="pointer"/>
                                        <span className='font-dark-blue'>Search Location</span>
                                    </span>: null
                                }
                            </div>
                        </div>
                        <input id="map-location" defaultValue={props.initialData.lat + ',' + props.initialData.lng}  type="text" className="form-control form-control-sm inform-input" placeholder='lat,lon' onChange={() => setIsUpdate(true)} disabled={disabled}/>
                       
                    </div>
                   
                </div>
                <div className='row'>
                    <div className='col-md-12 inform' style={{paddingBottom:'20px'}}>
                        <div style={{ float:'right'}}>
                            { isMapOpen ?  <AiOutlineCloseSquare size={30} color="grey" cursor="pointer" onClick={() => onMapCloseHandler()}/> : <></> }
                        </div>
                        <div id="map-box" style={{height: 0 , border: '3px solid white'}}>

                            <input id="pac-input" className='controls' type="text" placeholder="" style={{height:0,border: 'none',width:'200px'}}/>
                            <div id="map" style={{ width: '100%',height: '100%'}}></div>
                        </div>
                    </div>
                   
                </div>
                
           
            
                <div className='row' >
                    <div id="block-section" className='col-md-8' >
                        <span className='topic-head'>Blocks </span>
                        { (!isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                            <>
                                <a data-tip data-for='add-block-info' style={{position:'absolute', cursor: 'pointer',marginTop:'-2px',paddingLeft:'5px'}}  onClick={() => addBlock()}>
                                    <RiAddCircleLine color={Color.DarkBlue} size={22}  cursor="pointer"/>
                                </a>
                                <ReactTooltip id='add-block-info' data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                    <div className='tooltip-div'>Add Blocks</div>
                                </ReactTooltip>
                            </>:null
                        }
                    </div>
                </div>
                {
                    blocks.map((block,blockIndex) => (
                        <div className='row ' key={uniqid()}>
                            <div className="col-sm-12 col-div border-concession" style={{paddingLeft:'25px'}}>
                                <div className='row' key={uniqid()}>
                                    <div className='col-sm-12'>
                                        <div className='topic-head'>
                                            <span>Block Name</span><span id={'block-message-' + blockIndex} className="require" style={{ marginLeft: '5px'}}>*</span>
                                            { (!isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                                                <RiDeleteBin6Line size={20} color="red" cursor="pointer" onClick={() => confirmDeleteBlock(blockIndex)} style={{ float: 'right'}}/>
                                                :null
                                            }
                                        </div>
                                        <div>
                                            <input key={uniqid()} id={'input-block-' + blockIndex} defaultValue={block.projectBlockName}  type="text" className="form-control form-control-sm inform-input input-block" onChange={() => setIsUpdate(true)} disabled={disabled}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='partner-section' key={uniqid()} >
                                        <div>
                                            <div>
                                                <div className='topic-head' style={{ marginTop: '5px',width: '125px'}}>
                                                   <span className='partner-text'> Partner Name</span>
                                                   <span className='partner-text-mini' >Partners<span className="require" style={{ marginLeft: '5px'}}>*</span></span>
                                                   { (!isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                                                    <span  data-tip data-for='add-partner-info' style={{position:'absolute', cursor:"pointer",marginTop:'-7px',padding:'5px'}} onClick={() => addPartner(blockIndex)}>
                                                        <RiAddCircleLine color={Color.DarkBlue} size={22}  cursor="pointer" />
                                                    </span>:null
                                                    }
                                                    <span id={'partner-message-' + blockIndex} className="require partner-message"></span>
                                                </div>
                                                <div id={'partner-mini-message-' + blockIndex} className="require partner-mini-message"></div>
                                                <ReactTooltip id='add-partner-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                                        <div className='tooltip-div'>Add Partner Name</div>
                                                </ReactTooltip>
                                            </div>
                                            <div style={{marginTop:'20px'}}>
                                                <div className='topic-head'><span className="partner-text">Share (%)</span><span id={'partner-share-message-' + blockIndex} className="require partner-share-message" style={{ marginLeft: '5px'}}></span></div>
                                            </div>
                                           
                                   
                                        </div>
                                    { 
                                        block.partners.map((partner,partnerIndex) => (
                                        <div key={uniqid()}>
                                            
                                            <div style={{marginLeft:'10px'}}>
                                            { partnerIndex > 0 ? <div className='partner-name-text partner-text-mini topic-head' >Partner Name</div> : <div className='partner-text-mini topic-head' >Partner Name</div> }
                                            { disabled ?  <input defaultValue={partner.partnerName}  type="text" className="form-control form-control-sm" disabled/> : 
                                                <TextboxAutocomplete key={uniqid()} elementId={'partner-' + blockIndex + '-' + partnerIndex} index={blockIndex} index2={partnerIndex} idKey="partnerId" valueKey="partnerName" onClick={() => getPartner(blockIndex)}  onChange={searchPartner} onSelect={onPartnerSelect} initialId={partner.partnerId} initialValue={partner.partnerName} extendClass="partner-list"/>
                                            }
                                            </div>
                                            
                                            <div style={{marginLeft:'10px',marginTop:'10px'}}>
                                                <div className='partner-text-mini topic-head' >Share (%)</div>
                                                <input key={uniqid()} id={'partner-share-' + blockIndex + '-' + partnerIndex} type="number" defaultValue={parseFloat(partner.sharePercentage.toString()) > 0 ? partner.sharePercentage : ""} className="form-control form-control-sm inform-input" onBlur={() => onPartnerShareChange(blockIndex,partnerIndex)} disabled={disabled}/>
                                                { (!isGatekeeper && isCanUpdateInformation) || isSuperAdmin ?
                                                    <RiDeleteBin6Line size={20} color="red" cursor="pointer" onClick={() => confirmDeletePartner(blockIndex,partnerIndex)} style={{marginTop:'7px',float:'right'}}/>
                                                    :null
                                                }
                                            </div>
                                           
                                        </div>
                                        ))
                                    }
                                </div>
                               
                            </div>      
                        </div>
                    ))
                }
            
            
                <div className='row' style={{ marginTop: '25px', marginBottom: '25px'}}>
                    <div className="col-md-12" style={{ textAlign: 'right',paddingRight: '0'}}>
                        <button id="save-project-btn" type="button" className="btn btn-sm bg-dark-blue" style={{width:'150px'}} disabled={!isUpdate} onClick={saveProject}>
                            <AiOutlineSave size={20} />
                            <span style={{ marginLeft: '5px'}}>Save</span>
                        </button>
                    </div>
                </div>

        <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="sm" style={{ top: '20%'}}>
            <Modal.Header className="bg-dark-blue">
                <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => setErrors([])}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}}>
                <div><RiErrorWarningLine color="red" size={25}/></div>
                <div style={{ fontSize: '14px'}}>
                {   errors.map((msg) =>(
                            <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                        ))
                    }
                    
                </div>
                <div style={{marginTop:'15px'}}>
               
                <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                    <span style={{ marginLeft: '5px'}} >Close</span>
                </button>
               </div>
          </Modal.Body>
        </Modal>
                
                <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}}>
                        <div className="alert alert-success" role="alert" style={{marginBottom: 0}}>
                            <AiOutlineCheckCircle color="green" size={25}/>
                            <span style={{marginLeft:'10px',color:'green'}}>Success</span>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={isConfirmDeleteBlock} onHide={() => setIsConfirmDeleteBlock(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                            Confirm Delete Block
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setIsConfirmDeleteBlock(false)}>X</span>
                        </Modal.Header>
                         <Modal.Body style={{ textAlign: 'center'}}>
                            <div>
                                <RiErrorWarningLine size={25} color={'red'} />
                                <span style={{ marginLeft: '2px', fontWeight: 'bold', color: 'red' }}> Would you like to  delete?</span>
                            </div>
                            <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => removeBlock()} >
                                <span style={{ marginLeft: '5px'}} >Yes</span>
                            </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setIsConfirmDeleteBlock(false)} >
                                <span style={{ marginLeft: '5px' }} >No</span>
                            </button>
                        </Modal.Body>
                </Modal>

                <Modal show={isConfirmDeletePartner} onHide={() => setIsConfirmDeletePartner(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                            Confirm Delete Partner
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setIsConfirmDeletePartner(false)}>X</span>
                        </Modal.Header>
                         <Modal.Body style={{ textAlign: 'center'}}>
                            <div>
                                <RiErrorWarningLine size={25} color={'red'} />
                                <span style={{ marginLeft: '2px', fontWeight: 'bold', color: 'red' }}> Would you like to  delete?</span>
                            </div>
                            <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => removePartner()} >
                                <span style={{ marginLeft: '5px'}} >Yes</span>
                            </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setIsConfirmDeletePartner(false)} >
                                <span style={{ marginLeft: '5px' }} >No</span>
                            </button>
                        </Modal.Body>
                </Modal>         
        </div>
    );
}

export default InformationUpdateForm