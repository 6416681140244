import React,{useState, useEffect } from 'react';
import uniqid from 'uniqid';
import { AiFillCaretUp,AiFillCaretDown } from "react-icons/ai";
import { Project,AuthenUser,RoleProjectLevel } from '../../models'
import { DateHelper } from '../../helpers'
import '../../assets/css/information.css'
import { UserService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { SystemLevel } from '../../enum'

interface Props {
    user:AuthenUser
    projectData:Project
    projectRole:RoleProjectLevel | null,
    minimize:boolean
    hiddenText:boolean
}

const Information = (props:Props) => 
{
    const project:Project = props.projectData
    const [isLoading,setIsLoading] = useState(true)
    const [minimize,setMinimize]= useState(props.minimize)
    const { accessToken } = useApiAuth();

    useEffect(() => {
        if(accessToken){
            setIsLoading(false)
        }
        
    },[accessToken])
    
    return (
        
        <div className='inform-section'>
        { !isLoading ?
            <>
                <div>
                    <span className='project-label'> Project Information</span>
                { !props.hiddenText ?
                    <>
                    { minimize ? 
                        <span  style={{ cursor: 'pointer',color:"#00338D", marginLeft: '5px'}} onClick={ () => setMinimize(false)}>
                            <AiFillCaretDown size={20}/>
                            <span style={{fontStyle: 'italic'}}>(more information)</span>
                        </span>
                        :
                        <span  style={{ cursor: 'pointer',color:"#00338D", marginLeft: '5px'}} onClick={ () => setMinimize(true)}>
                            <AiFillCaretUp size={20}/>
                            <span style={{fontStyle: 'italic'}}>(hide information)</span>
                        </span>
                    } 
                    </>:null
                }
                </div>
            
                { minimize ? 
                    <>
                        <div className='row' >
                            <div className='col-md-4 inform-bottom'>
                                <div className='topic-head'>Project Name</div>
                                <div >{ project.currentProjectName } </div>
                            </div>
                            <div className='col-md-4 inform-bottom'>
                                <div className='topic-head'>Asset Name</div>
                                <div >{project.assetCode} - { project.assetName }</div>
                            </div>
                            <div className='col-md-4 inform-bottom'>
                                <div className='topic-head'>Asset Type</div>
                                <div >{ project.assetTypeName ? project.assetTypeName : 'Operated' }</div>     
                            </div>
                        </div>
                    </>:null
                }               
            
            { !minimize && project.projectModeId === 1 ?
                <>
                <div className='row'>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Name</div>
                        <div className=''>{ project.currentProjectName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Category</div>
                        <div >{ project.projectModeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project No.</div>
                        <div >{ project.projectNo }</div>
                    </div>
                </div>
            
                <div className='row'>
                    
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Type</div>
                        <div >{ project.projectTypeName }</div>
                    </div>
                
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Asset Type</div>
                        <div >{ project.assetTypeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                      
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Asset Name</div>
                        <div >{project.assetCode} - { project.assetName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Country</div>
                        <div >{ project.countryName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                     
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Hydrocarbon Resource (New or Existing)</div>
                        <div>{  project.resourceTypeName}</div>
                    </div>
                    
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Change in Development Concept</div>
                        <div>{ project.changeInDevelopmentConcept ? 'Change in Development Concept' : 'No Change in Development Concept' }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Change in Terms and Conditions</div>
                        <div>{ project.changeInTermAndCondition ? 'Change in Terms and Conditions' : 'No Change in Terms and Conditions'}</div>
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Product Type</div>
                        <div >{ project.productTypeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        { props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1 ?
                            <>
                                <div className='topic-head'>Project Value/Cost Estimated(MMUSD)</div>
                                <div >{ project.projectValue }</div>
                            </>:null
                        }
                    </div>
               
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Category Size</div>
                        <div >{  project.categorySizeName }</div>
                    </div>
                   
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Development Scenario</div>
                        <div >{ project.projectModeId === 1 ? project.developmentScenarioName : "-" }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Concession/PSC/PPA</div>
                        <div >{ project.concessions ? project.concessions : "" }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                       
                    </div>
                </div>

                

                <div className='row' style={{ minHeight: '65px' }}>
                   
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Description</div>
                        <div style={{ wordBreak: 'break-all'}}>{ project.projectDescription }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='font-bold' style={{ color: 'black',textDecoration : 'underline' }}>Note</div>
                        <div style={{ wordBreak: 'break-all'}}>{ project.note }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                       
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner</div>
                        {   
                            project.projectOwners.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                   
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner Team/Project Team Members</div>
                        {   
                            project.teamMembers.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Gatekeeper</div>
                        {   
                            project.gateKeepers.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                </div>

                
                        
                <div className='row' >
                   
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Project Status</div>
                       <div >{ project.projectStatusName }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Remark</div>
                       <div >{ project.remark }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                      
                   </div>
                   
               </div>
                
               <div className='row' >
                   
                    
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Location</div>
                       <div >{ project.locationName }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Map Location</div>
                       <div >{ project.lat + ","  + project.lng }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                      
                   </div>
                </div>
                
               <div className="pagebreak"> </div>
                <div id="information-partner" className='row' style={{ minHeight: '65px' }}>
                    <div className='col-md-12 inform-bottom'>
                        <div className='topic-head'>Partners & Share</div>
                        <div className='' key={uniqid()} style={{ display:'flex',justifyContent:'flex-start',flexWrap:'wrap'}}>
                        { project.projectBlocks.map((block,index) => (
                            <div key={uniqid()} className='pertner-block' style={{ marginLeft: index > 0 ? '10px' : 0 ,marginTop: '5px'}}> 
                                <div className='topic-head'>{block.projectBlockName}</div>
                                { block.partners.map((partner) => (
                                        <div key={uniqid()} style={{ display:'flex',justifyContent:'space-between'}}>
                                            <div>{partner.partnerName}</div>
                                            <div style={{marginLeft:'10px'}}>{partner.sharePercentage}%</div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            ))
                        }
                        </div>
                    </div>
                    
                    
                </div>
                
                </>: null
            }

            { !minimize && project.projectModeId === 2 ?
                <>
                <div className='row'>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Name</div>
                        <div className=''>{ project.currentProjectName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Category</div>
                        <div >{ project.projectModeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project No.</div>
                        <div >{ project.projectNo }</div>
                    </div>
                </div>
            
                <div className='row'>
                    
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Type</div>
                        <div >{ project.projectTypeName}</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Asset Type</div>
                        <div >{ project.assetTypeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                      
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Asset Name</div>
                        <div >{project.assetCode} - { project.assetName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Country</div>
                        <div >{ project.countryName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                       
                    </div>
                </div>

               
                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Product Type</div>
                        <div >{ project.productTypeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        { props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1 ?
                            <>
                                <div className='topic-head'>Project Value/Cost Estimated(MMUSD)</div>
                                <div >{ project.projectValue }</div>
                            </>:null
                        }
                    </div>
               
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Category Size</div>
                        <div >{ project.categorySizeName}</div>
                    </div>
                   
                </div>

                <div className='row' style={{ minHeight: '65px'}}>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Concession/PSC/PPA</div>
                        <div >{ project.concessions ? project.concessions : "" }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                      
                    </div>
                    <div className='col-md-4 inform-bottom'>
                      
                    </div>
                    
                </div>

                <div className='row' style={{ minHeight: '65px' }}>
                   
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Description</div>
                        <div style={{ wordBreak: 'break-all'}}>{ project.projectDescription }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='font-bold' style={{ color: 'black',textDecoration : 'underline' }}>Note</div>
                        <div style={{ wordBreak: 'break-all'}}>{ project.note }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                      
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner</div>
                        {   
                            project.projectOwners.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                    
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner Team/Project Team Members</div>
                        {   
                            project.teamMembers.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Gatekeeper</div>
                        {   
                            project.gateKeepers.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                </div>

                
                        
                <div className='row' >
                   
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Project Status</div>
                       <div >{ project.projectStatusName }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Remark</div>
                       <div >{ project.remark }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                      
                   </div>
                   
               </div>
                
               <div className='row' style={{ minHeight: '65px'}}>
                    
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Location</div>
                        <div >{ project.locationName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Map Location</div>
                        <div >{ project.lat + ","  + project.lng }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                      
                    </div>
                </div>
                
                <div className="pagebreak"> </div>
                <div id="information-partner" className='row' style={{ minHeight: '65px' }}>
                    <div className='col-md-12 inform-bottom'>
                        <div className='topic-head'>Partners & Share</div>
                        <div className='' key={uniqid()} style={{ display:'flex',justifyContent:'flex-start',flexWrap:'wrap'}}>
                        { project.projectBlocks.map((block,index) => (
                            <div key={uniqid()} className='pertner-block' style={{ marginLeft: index > 0 ? '10px' : 0 ,marginTop: '5px'}}> 
                                <div className='topic-head'>{block.projectBlockName}</div>
                                { block.partners.map((partner) => (
                                        <div key={uniqid()} style={{ display:'flex',justifyContent:'space-between'}}>
                                            <div>{partner.partnerName}</div>
                                            <div style={{marginLeft:'10px'}}>{partner.sharePercentage}%</div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            ))
                        }
                        </div>
                    </div>
                    
                    
                </div>
                </>: null
            }

            { !minimize && project.projectModeId === 3 ?
                <>
                <div className='row'>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Name</div>
                        <div className=''>{ project.currentProjectName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Category</div>
                        <div >{ project.projectModeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project No.</div>
                        <div >{ project.projectNo }</div>
                    </div>
                </div>
            
                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Asset Name</div>
                        <div >{project.assetCode} - { project.assetName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Country</div>
                        <div >{ project.countryName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Asset Type</div>
                        <div >Operated</div>
                    </div>
                </div>

                

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Product Type</div>
                        <div >{ project.productTypeName }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        { props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1 ?
                            <>
                                <div className='topic-head'>Project Value/Cost Estimated(MMUSD)</div>
                                <div >{ project.projectValue }</div>
                            </>:null
                        }
                    </div>
               
                    <div className='col-md-4 inform-bottom'>
                        
                    </div>
                    
                </div>

                <div className='row' style={{ minHeight: '65px'}}>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Concession/PSC/PPA</div>
                        <div >{ project.concessions ? project.concessions : "" }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                       
                    </div>
                    <div className='col-md-4 inform-bottom'>
                       
                    </div>
                 
                </div>

                

                <div className='row' style={{ minHeight: '65px' }}>
                  
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Description</div>
                        <div style={{ wordBreak: 'break-all'}}>{ project.projectDescription }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='font-bold' style={{ color: 'black',textDecoration : 'underline' }}>Note</div>
                        <div style={{ wordBreak: 'break-all'}}>{ project.note }</div>
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        
                    </div>
                </div>

                <div className='row' >
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner</div>
                        {   
                            project.projectOwners.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                   
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Project Owner Team/Project Team Members</div>
                        {   
                            project.teamMembers.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                    <div className='col-md-4 inform-bottom'>
                        <div className='topic-head'>Gatekeeper</div>
                        {   
                            project.gateKeepers.map((item) => (
                                <div key={uniqid()}>{item.name}</div>
                            ))
                        }
                    </div>
                </div>
                
                <div className='row' >
                   
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Project Status</div>
                       <div >{ project.projectStatusName }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Remark</div>
                       <div >{ project.remark }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                      
                   </div>
                   
               </div>
                
               <div className='row' >
                   
                    
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Location</div>
                       <div >{ project.locationName }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                       <div className='topic-head'>Map Location</div>
                       <div >{ project.lat + ","  + project.lng }</div>
                   </div>
                   <div className='col-md-4 inform-bottom'>
                       
                   </div>
                </div>
                
                <div className="pagebreak"> </div>
                <div id="information-partner" className='row' style={{ minHeight: '65px' }}>
                    <div className='col-md-12 inform-bottom'>
                        <div className='topic-head'>Partners & Share</div>
                        <div className='' key={uniqid()} style={{ display:'flex',justifyContent:'flex-start',flexWrap:'wrap'}}>
                        { project.projectBlocks.map((block,index) => (
                            <div key={uniqid()} className='pertner-block' style={{ marginLeft: index > 0 ? '10px' : 0 ,marginTop: '5px'}}> 
                                <div className='topic-head'>{block.projectBlockName}</div>
                                { block.partners.map((partner) => (
                                        <div key={uniqid()} style={{ display:'flex',justifyContent:'space-between'}}>
                                            <div>{partner.partnerName}</div>
                                            <div style={{marginLeft:'10px'}}>{partner.sharePercentage}%</div>
                                        </div>
                                    ))
                                }
                            </div>
                            
                            ))
                        }
                        </div>
                    </div>
                    
                    
                </div>
              
                </>: null
            }

                <div className='row' style={{ textAlign: 'right',paddingTop:'10px',color:'grey',fontStyle:'italic'}}>
                    <div className='col-md-12'>
                        <span>Register By:</span><span style={{marginLeft:'5px'}}>{ project.createBy}</span>
                        <span style={{marginLeft:'10px'}}>, Register Date:</span><span style={{marginLeft:'5px'}}>{ DateHelper.getDate(project.registerDateTime) }</span>
                    </div>
                </div>
              
            </>:null
        }
         </div>
    )
  
}

export default Information;