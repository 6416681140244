import React, { useState ,useEffect} from "react";
import { AiOutlineInfoCircle,AiOutlineDelete,AiOutlineFile,AiOutlineChrome,AiOutlineFolder,AiOutlineEdit } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import { BsSlashCircleFill } from "react-icons/bs";
import { VscAdd,VscLink,VscFileZip,VscCircleSlash,VscCheck,VscChromeClose,
    VscCloudDownload,VscClose,VscChevronRight,VscChevronDown,VscIssueReopened,VscCircleLargeOutline } from "react-icons/vsc";

import ReactTooltip from "react-tooltip";
import '../../assets/css/deliverable.css'
import Modal from 'react-bootstrap/Modal'
import { Color,PhaseName } from '../../enum'
import  secureLocalStorage  from  "react-secure-storage";
import { config } from '../../config';

const DeliverableDocument = () => {
    const [phase,setPhase]= useState(0)
    const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [show8, setShow8] = useState(false);
  const [trSHow, setTrShow] = useState(false);
  const [trSHow2, setTrShow2] = useState(false);
  const [trSHow3, setTrShow3] = useState(false);
  const [trSHow4, setTrShow4] = useState(false);
  const [trSHow5, setTrShow5] = useState(false);
  const [trSHow6, setTrShow6] = useState(false);
  const [trSHow7, setTrShow7] = useState(false);
  const [trSHow8, setTrShow8] = useState(false);
  const [trSHow9, setTrShow9] = useState(false);
  const [trSHow10, setTrShow10] = useState(false);
  const [trSHow11, setTrShow11] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [imgShow2, setImgShow2] = useState(false);
  const [imgShow3, setImgShow3] = useState(false);
  const [imgShow4, setImgShow4] = useState(false);
  const [imgShow5, setImgShow5] = useState(false);
  const role = localStorage.getItem('role')

  useEffect(() => {
    setPhase(4)
  },[])
    
  
    
    const inputFile =  document.getElementById("input-file") as HTMLInputElement

    const uploadClick =() => {
        inputFile.click();
    }
    const handleFilesUpload = () => {
        let fileQusery = document.querySelector('input[type="file"]') as HTMLDivElement
        let file = fileQusery[0]
    }

    
    const btnStyle = {
        b0:{
            backgroundColor: '',
            color: '',
            width: '170px'
        },
        b1:{
            backgroundColor: Color.DarkBlue,
            color: 'white',
            width: '170px'
        }
    }
    return (

        <div>   
                    <div style={{ borderStyle: 'solid', borderColor: '#cccccc', padding: '15px', borderRadius: '10px', marginTop:'10px' }}>
            <div className='row' style={{ marginTop: '30px'}}>
                <div className='col-md-2'>
                    <span className='project-label'> Deliverable Document</span>

                </div>
                <div className='col-md-10' style={{ textAlign: 'right'}}>
                    <span>
                        <VscCheck size={18} color='green' style={{ marginRight: '5px'}}/>
                        <span>Accept</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscClose size={20} color="red" style={{ marginRight: '5px'}}/>
                        <span>Reject</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/>
                        <span>Waiting for Verification</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>
                        <span>Wait for Deliverable</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleSlash size={20} color='black' style={{ marginRight: '5px'}}/>
                        <span>Waive</span>
                    </span>
                </div>
            </div>
            

            <div className='row' style={{ marginBottom : '0'}}>
            <div style={{ marginTop : '10px'}}>
                <button className="tab-btn" style={ (phase === 1) ? btnStyle.b1 : btnStyle.b0 } onClick={() => setPhase(1) }>
                    <span>{PhaseName.Phase1}</span>
                    <AiOutlineInfoCircle size={20} cursor="pointer" onClick={() => setImgShow(true)} style={{ marginLeft: '5px' }} />
                </button>
                <button className="tab-btn" style={ (phase === 2) ? btnStyle.b1 : btnStyle.b0 } onClick={() => setPhase(2) }>
                    {PhaseName.Phase2}
                    <AiOutlineInfoCircle size={20} cursor="pointer" onClick={() => setImgShow2(true)} style={{ marginLeft: '5px' }} />
                </button>
                <button className="tab-btn" style={ (phase === 3) ? btnStyle.b1 : btnStyle.b0 } onClick={() => setPhase(3)}>
                    {PhaseName.Phase3}
                    <AiOutlineInfoCircle size={20} cursor="pointer" onClick={() => setImgShow3(true)} style={{ marginLeft: '5px' }} />
                </button>
                <button className="tab-btn" style={ (phase === 4) ? btnStyle.b1 : btnStyle.b0 } onClick={() => setPhase(4)}>
                    {PhaseName.Phase4}
                    <AiOutlineInfoCircle size={20} cursor="pointer" onClick={() => setImgShow4(true)} style={{ marginLeft: '5px' }} />
                </button>
                <button className="tab-btn" style={ (phase === 5) ? btnStyle.b1 : btnStyle.b0 } onClick={() => setPhase(5)}>
                    {PhaseName.Phase5}
                    <AiOutlineInfoCircle size={20} cursor="pointer" onClick={() => setImgShow5(true)} style={{ marginLeft: '5px' }} />
                </button>
            </div>
            <div id={ 'phase-'+ phase}>
               
            </div> 
            </div>
            <div className='row' style={{ marginTop: '20px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found 11 record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            
                            <select className="" defaultValue={20} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                                <option value={10}>10</option>
                                <option value={20}>20</option> 
                                <option value={50}>50</option>
                                <option value={100}>100</option>        
                            </select>
                        </div>
            </div>
        <div className="table-responsive table-responsive-sm" >
            <table className="table deliverable-table">
                <thead>
                    <tr style={{ borderTop: '1px solid'}}>
                        <th>Verification Status</th>
                        <th style={{ width: '205px'}}>Stage</th>
                        <th>Assurance Activity</th>
                        <th style={{ textAlign: 'center',minWidth: '500px'}}>Deliverable Name</th>
                      
                        
                        <th style={{ width: '150px'}}>Recommendation</th>
                        <th style={{ width: '150px'}}>Comment</th>
                        <th style={{ width: '150px'}}>Feedback</th>
                        <th style={{ width: '100px'}}>
                            <span>Type</span>
                            <a data-tip data-for='deliverable-type-info' style={{cursor: 'pointer'}}>
                                <AiOutlineInfoCircle color='#00338D' size={20} style={{ verticalAlign: 'middle',marginLeft: '2px'}}/>
                            </a>
                            <ReactTooltip id='deliverable-type-info' data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                <div>M = Mandatory</div>
                                <div>C = Conditional</div>
                            </ReactTooltip>
                        </th>
                        
                        <th style={{ textAlign: 'center',minWidth: '100px'}}>Verified by</th>
                    </tr>
                </thead>
                <tbody>
                {/*1 */}
                <>
                    <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td  >
                            <div className="deliverable-user">
                            { !trSHow ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow(true)}/>
                            :
                            <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow(false)}/>
                            }
                            <VscCheck size={18}color='green' style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td>
                            <span className="deliverable-desc">Value Assurance Check</span>
                        </td>
                        <td>
                            <span className="deliverable-desc">ORR</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                   
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                                
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Construction / Fabrication / Loadout / Transportation & Installation Procedure(3)</span>
                        </td>
                 
                        
                        <td>
                            <span className="deliverable-desc">{ 'จะต้องได้รับการ verify "Production Data" ก่อนดำเนินการส่วนดังกล่าว'.substring(0,40)}...</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <AiOutlineEdit size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>: null
                            }
                            </div>
                            <span className='deliverable-desc' style={{ paddingTop: role === 'focalpoint' || role === 'admin' ? '0':''}}>{'This file does not have an app associated with it for performing this action.'.substring(0,40)}...</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <AiOutlineEdit size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className='deliverable-desc' style={{ paddingTop: role === 'gatekeeper' || role === 'admin' ? '0':''}}>{'Comment from one whos moved on NYT Crossword Clue Answers are listed below and every time we find a new solution for this clue, we add it on the answers'.substring(0,40)}...</span>
                            
                        </td>
                        <td>
                            <span className="deliverable-desc" style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td>
                            <div className='deliverable-user'>
                                <div >Washin T.</div>
                                <div>29 Sep 2022</div>
                          </div>
                        </td>
                    </tr>
                    { trSHow ?
                    <tr >
                        <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                                    <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                    </tr>
                    : null
                    }
                </>
                    
                {/*2 */}
                <>
                <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            { !trSHow2 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow2(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow2(false)}/>
                            }
                            <VscCheck size={18}color='green' style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Appropriate Committee</span>
                        </td>
                        <td className='text-center-both'>
                             <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
   
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Handover Packages (3)</span>
                        </td>
                 
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>C</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow2 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                        <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"  onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"  onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                        
                    </tr>
                    : null
                    }
                </>

                {/*3 */}
                <>
                <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            { !trSHow3 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow3(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow3(false)}/>
                            }
                            <VscClose size={24} color="red" />
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Value Assurance Check</span>
                        </td>
                        <td className='text-center-both'>
                             <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow(true)}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>
                                    
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                               
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Operation & Maintenance Procedure (3)</span>
                        </td>
                 
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow3 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                         <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                    </tr>
                    : null
                    }
                </>

                {/*4 */}
                <>
                <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            { !trSHow4 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow4(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow4(false)}/>
                            }
                            <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Decision Gate</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">PTR#3</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow(true)}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                               
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Operation & Maintenance Contracts (3)</span>
                        </td>
                 
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow4 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                         <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                    </tr>
                    : null
                    }
                </>

                {/*5 */}
                <>
                    <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            
                            <VscChevronRight size={20}  color={Color.Grey} style={{ float: 'left'}}/>
                            
                            <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Appropriate Committee</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">PCA</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Operation Safty Case (3)</span>
                        </td>
                 
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow5 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                        <td colSpan={3} style={{ paddingTop: 0}}></td>
                                    <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                                      
                                    
                                        
                                    </td>
                                   
                                    <td colSpan={6} style={{ paddingTop: 0}}></td>
                                   
                                   
                         
                        
                    </tr>
                    : null
                    }
                </>

                {/*6 */}
                <>
                    <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            <VscChevronRight size={20}  color={Color.Grey} style={{ float: 'left'}}/>
                            <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Appropriate Committee</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">ORR</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                              
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Non Conformance / Inspection Report (3)</span>
                        </td>
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>C</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow6 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                        <td colSpan={3} style={{ paddingTop: 0}}></td>
                                    <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                                        
                                    
                                        
                                    </td>
                                   
                                    <td colSpan={6} style={{ paddingTop: 0}}></td>
                                   
                                   
                         
                        
                    </tr>
                    : null
                    }
                </>

                {/*7 */}
                <>
                <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            { !trSHow7 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow7(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow7(false)}/>
                            }
                            <VscCheck size={18}color='green' style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Appropriate Committee</span>
                        </td>
                        <td className='text-center-both'>
                             <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                   
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                              
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Service Level Agreement (3)</span>
                        </td>
                 
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow7 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                       <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                    </tr>
                    : null
                    }
                </>

                {/*8 */}
                <>
                <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                        <div className="deliverable-user">
                            { !trSHow8 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow8(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow8(false)}/>
                            }
                             <VscCircleSlash size={20} color='black' style={{ marginRight: '5px'}}/>
                             </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Appropriate Committee</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">RFSU</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow(true)}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                   
                                </>:null
                                }

                                
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Equipment / System Function Test Report (3)</span>
                        </td>
                 
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow8 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                         <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                        
                    </tr>
                    : null
                    }
                </>

                {/*9 */}
                <>
                    <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                        <div className="deliverable-user">
                            { !trSHow9 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow9(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow9(false)}/>
                            }
                            <VscClose size={24} color="red" />
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Value Assurance Check</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">ORR</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow(true)}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>
                                    
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                                
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Pre-commissioning & Commissioning Plan (3)</span>
                        </td>
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>C</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow9 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                       
                       <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                    </tr>
                    : null
                    }
                </>

                {/*10 */}
                <>
                    <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                        <div className="deliverable-user">
                            { !trSHow10 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow10(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow10(false)}/>
                            }
                            <VscIssueReopened size={24} color="#FFC107" style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                                        <span className="deliverable-desc">Value Assurance Check</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">ORR</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow(true)}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                               
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Well Report (3)</span>
                        </td>
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow10 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                       
                       <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                    </tr>
                    : null
                    }
                </>

                {/*11 */}
                <>
                    <tr className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                        <td className='text-center-both'>
                            <div className="deliverable-user">
                            { !trSHow11 ?
                            <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow11(true)}/>
                            :
                             <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setTrShow11(false)}/>
                            }
                            <VscIssueReopened size={24} color="#FFC107" style={{ marginRight: '5px'}}/>
                            </div>
                        </td>
                        
                        <td className='text-center-both'>
                            <span className="deliverable-desc">Decision Gate</span>
                        </td>
                        <td className='text-center-both'>
                             <span className="deliverable-desc">ORR</span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { role === 'focalpoint' || role === 'admin' ?
                                <>
                                <span style={{ cursor: 'pointer'}}   onClick={() => inputFile.click()}>
                                        <VscAdd size={18} color={Color.Blue} />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Files</span>
                                </span>
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow8(true)}>
                                        <VscLink size={18} color={Color.Blue} cursor="pointer" />
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Add Url</span>
                                </span>
                               
                                </>:null
                                }
                                { role === 'gatekeeper' || role === 'admin' ?
                                <>
                                
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow(true)}>
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow2(true)}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>
                                    <span style={{ cursor: 'pointer'}}   onClick={() => setShow4(true)}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>
                                </>:null
                                }

                                
                                <span style={{ cursor: 'pointer'}} >
                                        <VscFileZip size={18} color={Color.Blue} cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download</span>
                                </span>
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>Cost Estimation (3)</span>
                        </td>
                        
                        <td style={{textAlign: 'left'}}>
                            <span></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'focalpoint' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Comment</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                        </td>
                        <td className="deliverable-doc">
                            <div className="deliverable-action"  style={{ float: 'right'}}>
                            { role === 'gatekeeper' || role === 'admin' ?
                                <span style={{ cursor: 'pointer'}} onClick={() => setShow3(true)}>
                                    <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => setShow3(true)}/>
                                    <span style={{ fontSize: '12px',marginLeft:'2px'}}>Feedback</span>
                                </span>:null
                            }
                            </div>
                            <span className=""></span>
                            
                        </td>
                        <td className='text-center-both' style={{ paddingTop: '10px'}}>
                            <span style={{ fontSize:'13px',fontWeight: 'bold'}}>M</span>
                        </td>
                       
                        <td className='text-center-both'>
                          
                          
                        </td>
                    </tr>
                    { trSHow11 ?
                    <tr style={{ borderBottom: '1px solid #ddd'}}>
                         <td colSpan={3} style={{ paddingTop: 0}}></td>
                        <td style={{ textAlign: 'left',verticalAlign: 'top',paddingTop: 0}}> 
                        <div className="deliverable-file-list">
                                        <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                    
                                        <div className="deliverable-file" style={{marginTop: '5px'}}>
                                            <AiOutlineFile size={15}/> 
                                            <span style={{marginRight: '10px'}}>finalzed_ried_development_pian.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" />
                                                { role === 'focalpoint' || role === 'admin' ?
                                                    <span>
                                                        <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                    </span>:null
                                                }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineFile size={15}/>
                                            <span style={{marginRight: '10px'}}>ptt_subsurface_data.pdf</span>
                                            <span style={{ float: 'right'}}>
                                                    <VscCloudDownload cursor="pointer" size={20} className="deliverable-download"/>
                                                    { role === 'focalpoint' || role === 'admin' ?
                                                        <span>
                                                            <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                        </span>:null
                                                    }
                                            </span>
                                        </div>
                                        <div className="deliverable-file">
                                            <AiOutlineChrome size={15}/>
                                            <span style={{marginRight: '10px'}}>
                                            <a href="#" style={{ textDecoration: 'underline'}}>http://share/production.pdf</a>
                                            </span>
                                            <span style={{ float: 'right'}}>
                                                
                                                { role === 'focalpoint' || role === 'admin' ?
                                                <span>
                                                    <AiOutlineDelete cursor="pointer" size={18} className="deliverable-delete"   onClick={() => setShow5(true)}/>
                                                </span>:null
                                                }
                                            </span>
                                        </div>
                                    </div>
                                        
                        </td>
                        <td colSpan={6} style={{ paddingTop: 0}}></td>   
                        
                    </tr>
                    : null
                    }
                </>



          
                </tbody>
            </table>
        </div>

        <div className='row'>
                    <div className='col-md-4' style={{ display: 'flex'}}>
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={10}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                //onPageChange={handlePageClick}
                               // renderOnZeroPageCount={null}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active-gradient"
                            />
                        </div>
                    </div>
                   
                </div>
        </div>
                            
     
            <input id="input-file" type="file" style={{width: '0px'}} />
            <Modal show={show} onHide={() => setShow(false)} size="sm">
         <Modal.Header >
            <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
             <span style={{marginRight:'10px',cursor:'pointer'}} onClick={() => setShow(false)}>X</span>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
                <VscCheck size={20} color={'green'}  onClick={() => setShow(true)}/>
              <span style={{ marginLeft: '2px',fontWeight: 'bold'}}> Would you like to approve?</span>
              </div>
              <button type="button" className="btn btn-primary" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow(false)} >
                  <span style={{ marginLeft: '5px'}} >Yes</span>
               </button>
          </Modal.Body>
                                 
        </Modal>

        <Modal show={show2} onHide={() => setShow2(false)} size="sm">
         <Modal.Header>
            <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
            <span style={{marginRight:'10px',cursor:'pointer'}} onClick={() => setShow2(false)}>X</span>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <VscClose size={25} color={'red'} />
              <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to reject?</span>
              </div>
              <button type="button" className="btn btn-warning" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow2(false)} >
                  <span style={{ marginLeft: '5px'}} >Yes</span>
               </button>
          </Modal.Body>
                                 
        </Modal>

        <Modal show={show3} onHide={() => setShow3(false)} size="lg">
         <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '15px'}}>Message</Modal.Title>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <textarea className="form-control"></textarea>
              </div>
              <button type="button" className="btn btn-primary" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow3(false)} >
                  <span style={{ marginLeft: '5px'}} >Save</span>
               </button>
          </Modal.Body>
        </Modal>

        <Modal show={show4} onHide={() => setShow4(false)} size="sm">
         <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <BsSlashCircleFill size={22} color={'black'} />
              <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to waive?</span>
              </div>
              <button type="button" className="btn btn-warning" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow4(false)} >
                  <span style={{ marginLeft: '5px'}} >Yes</span>
               </button>
          </Modal.Body>
                                 
        </Modal>

        <Modal show={show5} onHide={() => setShow5(false)} size="sm">
         <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <VscClose size={25} color={'red'} />
              <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to delete?</span>
              </div>
              <button type="button" className="btn btn-warning" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow5(false)} >
                  <span style={{ marginLeft: '5px'}} >Yes</span>
               </button>
          </Modal.Body>
                                 
        </Modal>

        <Modal show={show6} onHide={() => setShow6(false)} size="lg">
         <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '15px'}}>Feedback from Gatekeeper</Modal.Title>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <span style={{fontWeight: 'bold'}}>เอกสารไม่สมบูรณ์</span>
              </div>
              <button type="button" className="btn btn-primary" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow6(false)} >
                  <span style={{ marginLeft: '5px'}} >Ok</span>
               </button>
          </Modal.Body>
        </Modal>

        <Modal show={show7} onHide={() => setShow7(false)} size="lg">
         <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '15px'}}>Comment</Modal.Title>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <textarea className="form-control"></textarea>
              </div>
              <button type="button" className="btn btn-primary" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow7(false)} >
                  <span style={{ marginLeft: '5px'}} >Save</span>
               </button>
          </Modal.Body>
        </Modal>    

        <Modal show={show8} onHide={() => setShow8(false)}>
         <Modal.Header >
            <Modal.Title style={{ fontSize: '15px'}}>Add URL</Modal.Title>
            <span style={{marginRight:'10px',cursor:'pointer'}} onClick={() => setShow8(false)}>X</span>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <input id="project-register-number" type="text" className="form-control form-control-sm"/>
              </div>
              <button type="button" className="btn btn-primary" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => setShow8(false)} >
                  <span style={{ marginLeft: '5px'}} >Save</span>
               </button>
          </Modal.Body>
        </Modal>

        <Modal show={imgShow} onHide={() => setImgShow(false)} size="xl">
                                    <Modal.Header closeButton>
                                       
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src={config.API_URL + '/v1/images/phase/1' } style={{ width: '100%'}}/>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={imgShow2} onHide={() => setImgShow2(false)} size="xl">
                                    <Modal.Header closeButton>
                                   
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src={config.API_URL + '/v1/images/phase/2' } style={{ width: '100%'}}/>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={imgShow3} onHide={() => setImgShow3(false)} size="xl">
                                    <Modal.Header closeButton>
                                    
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src={config.API_URL + '/v1/images/phase/3' } style={{ width: '100%'}}/>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={imgShow4} onHide={() => setImgShow4(false)} size="xl">
                                    <Modal.Header closeButton>
                                   
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src={config.API_URL + '/v1/images/phase/4' } style={{ width: '100%'}}/>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>

                                <Modal show={imgShow5} onHide={() => setImgShow5(false)} size="xl">
                                    <Modal.Header closeButton>
                                    
                                    </Modal.Header>
                                    <Modal.Body>
                                        <img src={config.API_URL + '/v1/images/phase/5' } style={{ width: '100%'}}/>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    </Modal.Footer>
                                </Modal>
    </div> 
    );
  
}

export default DeliverableDocument