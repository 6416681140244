import React,{useState ,useEffect} from 'react';
import DatePicker from "react-datepicker";
import ReactPaginate from 'react-paginate';
import { HistoryLogService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { HistoryLog ,PageEvent,Parameter} from '../../models';
import Loading from '../Loading'
import { DateHelper } from '../../helpers';
import { VscRefresh } from "react-icons/vsc";
import {BsFilter} from "react-icons/bs"
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import  secureLocalStorage  from  "react-secure-storage";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    histories:HistoryLog[]
    parameter:Parameter
    isLoading:boolean
}

const AdminHistory = () => {
    const { instance, accounts } = useMsal();
    const { accessToken } = useApiAuth()
    const [startDate,setStartDate] = useState()
    const [endDate,setEndDate] = useState()
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 25,
        histories:[],
        parameter:{
            page: 1,
            limit: 10,
            projectModeId: 0,
            countryId : 0,
            assetId : 0,
            categorySizeId:0,
            bpi : 0,
            spi : 0,
            statusId : 0,
            phaseNumber : 0,
            phaseIds : "",
            key: "",
            locationId : 0,
            startDate: null,
            endDate: null,
            startActiveDate: null,
            endActiveDate: null,
            startRegisterDate: null,
            endRegisterDate: null,
            startDateString: null,
            endDateString: null,
            startActiveDateString: null,
            endActiveDateString: null,
            startRegisterDateString: null,
            endRegisterDateString: null,
            sortType:-1,
            sortBy:"",
            username:"",
            viewSensitiveData:0,
            isSuperAdmin: 0
        },
        isLoading:true
    })

    useEffect(() => {

        if(accessToken){
           getHistory(appState.parameter)
        }
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        let state = Object.assign({},appState)
        state.parameter.page = page
        setAppState(state)
        await getHistory(state.parameter);
        
    }

    const getHistory = async (parameter:Parameter) =>{
        let token = await getToken()
        let result =  await new HistoryLogService(token).getAll(parameter)
            let state = Object.assign({},appState)
            //console.log(result)
            if(result && result.success){
                let {page,limit,total,items} = result.data
                state.limit = limit
                state.total = total
                state.page = page
                state.parameter.page = page
                state.totalPgae = Math.ceil(total / limit)
                state.histories = items
            }
          
            state.isLoading = false
            setAppState(state)
    }

    const onPageLimitChange = (ev) => {
        let limit = parseInt(ev.target.value)
        let state = Object.assign({},appState)
        state.parameter.limit = limit
        setAppState(state)
        getHistory(state.parameter)
    }

    const filterHistory = () => {
        let state = Object.assign({},appState)
        state.parameter.page = 1
        let inputSearch = document.getElementById('search-key') as HTMLInputElement
        if(inputSearch && inputSearch.value != ""){
            state.parameter.key = inputSearch.value
        }
        if(startDate){
            state.parameter.startDate = startDate
        }
        if(endDate){
            state.parameter.endDate = endDate
        }
        setAppState(state)
        getHistory(state.parameter)
    }

    const resetHistory = () => {
        let inputSearch = document.getElementById('search-key') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setStartDate(undefined)
        setEndDate(undefined)
        let state = Object.assign({},appState)
        state.parameter.page = 1
        state.parameter.key = ""
        state.parameter.startDate = null
        state.parameter.endDate = null
        setAppState(state)
        getHistory(state.parameter)
    }
    
    return (
        <div className="container-fluid">
          
            <div style={{ paddingBottom: '50px'}}>
                <div className="body">
              
                    <div className='project-block-none' style={{ paddingTop: '5px' }}>
                        <div className="row" style={{ width: '80%' }}>
                            <div className="col-sm-12">
                            <span className='project-label'>History Logs</span>

                            </div>
                        </div>
                    <div className="row" style={{marginTop:'20px'}}>
                        <div className='col-md-4'>
                            <input id="search-key" className='form-control' style={{ height:'35px'}} placeholder="Search by project name,user"/>
                        {/* <div className="input-group">
                                    <input className="form-control border-end-0 border" type="search" placeholder="search by project name,user" id="example-search-input" style={{ height: '30px', fontSize:"0.875rem" }} />
                                <span className="input-group-append">
                                    <button className="btn btn-outline-secondary bg-white border-start-0 border ms-n5" type="button" style={{ left:'-2px',height: '30px'}}>
                                        <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}}/>
                                    </button>
                                </span>
                        </div> */}
                        </div>
                       
                        <div className="col-sm-2" style={{ display : 'flex'}}>
                            <div style={{ marginTop: '7px',marginRight:'5px'}}>Start</div>
                            <DatePicker
                                dateFormat="dd MMM yyyy"
                                selected={ startDate } 
                                onChange={ (date) => setStartDate(date) } 
                                isClearable 
                                placeholderText="Please select" 
                                className="form-control form-control-sm"
                             />
                        </div>
                        
                        <div className='col-sm-2' style={{ display : 'flex'}}>
                            <div style={{ marginTop: '7px',marginRight:'5px'}}>To</div>
                            <DatePicker
                                dateFormat="dd MMM yyyy"
                                selected={ endDate } 
                                onChange={ (date) => setEndDate(date) } 
                                isClearable 
                                placeholderText="Please select" 
                                className="form-control form-control-sm"
                            />
                        </div>
                        <div className='col-md-2'>
                             <button type="button" className="btn btn-sm bg-dark-blue" style={{ width:'100%'}}  onClick={() => filterHistory()}>
                             <BsFilter size="20" color="white" />
                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}}>Filter</span>
                            </button>
                            
                        </div>
                        <div className='col-md-2'>
                             <button type="button" className="btn btn-sm bg-grey" style={{ width:'100%'}}  onClick={resetHistory}>
                                <VscRefresh size="20" color="white" /> 
                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}}>Reset</span>
                            </button>
                            
                        </div>
            </div>

            <div className='row' style={{ marginTop: '20px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            
                            <select  value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                                <option>10</option>
                                <option>20</option> 
                                <option>50</option>
                                <option>100</option>        
                            </select>
                        </div>
            </div>
        <div className="table-responsive table-responsive-sm">
            <table className="table table-sm table-bordered table-hover">
                <thead>
                    <tr>
                        <th style={{width: '80px'}}>No.</th>
                        <th>Project</th>
                        <th>Function</th>
                        <th>Action</th>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Position</th>
                        <th>Department</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                { !appState.isLoading ?
                <>
                    { appState.histories.map((item,index) => (
                        <tr  style={{height: '45px',textAlign:'left',paddingLeft:'20px', backgroundColor: (index%2) ? '#f3f3f3' : ''}}>
                            <td>{((appState.page -1) * appState.limit) + index + 1}</td>
                       
                                        <td style={{ textAlign: 'left',wordBreak:'break-word'}}>
                                            <span>{item.projectName}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{item.functionName}</span>
                                        </td>
                                        <td style={{ textAlign: 'left',wordBreak:'break-word'}}>
                                            <span>{item.action}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{item.username}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{item.name}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{item.position}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{item.department}</span>
                                        </td>
                                        <td className='text-center-both'>
                                            <span>{DateHelper.getDateTime(item.createDateTime)}</span>
                                        </td>
                                      
                                        
                       
                        </tr>
                    ))
                }
                </>:<Loading text="Loading..."/>
                }
                </tbody>
            </table>
        
         </div>
         <div className='row'>
                     <div className='col-md-4' style={{ display: 'flex'}}>
                  
                     </div>
                     <div className='col-md-8'>
                         <div id='pagination' style={{ float: 'right'}}>
                         <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                             forcePage={appState.parameter.page - 1}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                         </div>
                     </div>     
                 </div>
        
         </div>
                </div>
            </div>
        </div>
    );
  
}

export default AdminHistory
