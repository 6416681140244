import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/information.css'
import "react-datepicker/dist/react-datepicker.css";

const AdminUserUpdate = () => {
 
    return (
        <div className="container-fluid">
           <nav className="nav-menu" aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-bottom">
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/admin">
                            Admin
                        </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                        <Link to="/admin-user">
                            Users
                        </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                       Update
                    </li>
                </ol>
            </nav>
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                <div className='project-block'>
                    <div className="col-sm-12" style={{ marginBottom: '15px'}}>
                        <span style={{ fontWeight: 'bold' }}>User: Chonticha Ladpraw</span>
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            <span>Position:</span>
                        </div>
                        <div className="col-sm-4">
                            <input type="text"  className="form-control form-control-sm " value="Administrative Manager."/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            <span>Email:</span>
                        </div>
                        <div className="col-sm-4">
                            <input type="text"  className="form-control form-control-sm " value="chontica.l@pttep.com"/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            <span>Role:</span>
                        </div>
                        <div className="col-sm-4">
                            <select className="form-select form-select-sm" value="Super Admin">
                                <option value="Super Admin">Super Admin</option>
                                <option>Management</option>
                                <option>Project Owner</option>
                                <option>Project Manager</option>
                                <option>Project Engineer</option>
                                <option>Gate Keeper</option>
                                <option></option>
                            </select>
                        </div>
                    </div>

                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            <span>Status:</span>
                        </div>
                        <div className="col-sm-4">
                            <select className="form-select form-select-sm" value="Active">
                                <option value="Active">Active</option>
                                <option>Inactive</option>
                               
                            </select>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-sm-5 text-center-both'>
                            <button type="button" className="btn btn-warning" style={{"width": "200px",'marginTop': "15px"}}>Update</button>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
  
}

export default AdminUserUpdate