import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { AiOutlineCheckCircle ,AiOutlineCloudUpload} from "react-icons/ai";
import { FileManageService ,HistoryLogService} from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { RiErrorWarningLine } from "react-icons/ri";
import { AuthenUser} from '../../models';

const AdminUpload = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [isSuccess, setIsSuccess] = useState(false)
    const [errors,setErrors] = useState<string[]>([])

    useEffect(() => {
        if(accessToken){
           
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handleFilesUpload = async () => {
        let fileInput= document.getElementById('attach-file') as HTMLInputElement
        const files:FileList|null = fileInput.files
        
        if(files && files.length === 0){
            return
        }
        //console.log(files)
        let formData = new FormData();
        for( var i in files ){
            formData.append('files[' + i + ']', files[i]);
        }
        
        try{
            let token = await getToken()
            let result = await new FileManageService(token).uploadUserManualFile(formData);
            console.log(result)
            if(result && result.success){
                if(files && files[0]){
                    new HistoryLogService(token).create(user.userId, null,"Admin Upload",'Upload user manual "' + files[0].name + '"')
                }
                
                setIsSuccess(true)
                setTimeout(() => { 
                    setIsSuccess(false)
                    fileInput.value = ""
                }, 500);
            
            }
            else{
                if(result && result.message)
                    setErrors([result.message])
            }
        }
        catch(err){
            setErrors([err.message])
    
        }
        
    }
    
    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                { user.administratorUpdate > 0 ?
                    <div className='project-block-none' style={{ paddingTop: '5px'}}>
                        <div className="row" style={{ width: '80%'}}>
                            <div className="col-sm-6">
                                <span className='project-label'>Upload Files</span>
                            </div>
                        </div>
              
                        <div style={{ fontWeight:'bold'}}>User Manual</div>
                        <div className='row' style={{marginTop:'10px'}}>
                                <div className='col-md-12' style={{ textAlign: 'center',display:'flex' }}>
                                <input id="attach-file" type="file" className="form-control form-control-sm " style={{width:'400px'}}/>
                                <button id="save-btn" type="button" className="btn btn-sm bg-dark-blue" style={{marginLeft:'15px', width:'200px'}} onClick={handleFilesUpload}>
                                    <AiOutlineCloudUpload size={20} />
                                    <span style={{ marginLeft: '5px'}}>Upload</span>
                                </button>
                                </div>
                        </div>
                        <div className='row' style={{ marginBottom: '100px'}}>
                            <div className='col-md-12' style={{ textAlign: 'center'}}>
                               
                            </div>
                        </div>
                    </div>:<></>
                }
                </div>
            </div>
            <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}}>
                        <div className="alert alert-success" role="alert" style={{marginBottom: 0}}>
                            <AiOutlineCheckCircle color="green" size={25}/>
                            <span style={{marginLeft:'10px',color:'green'}}>Success</span>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}} >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
        </div>
    );
  
}

export default AdminUpload