import React, { useState, useEffect, useReducer} from 'react';
import { Link } from 'react-router-dom'
import ReactPaginate from 'react-paginate';
import { AiOutlinePlusCircle, AiOutlineInfoCircle, AiFillCheckCircle, 
    AiOutlineTrademarkCircle, AiOutlineClockCircle, AiFillCloseCircle, AiOutlinePauseCircle,
    AiOutlineCloseCircle,AiOutlineSearch
} from "react-icons/ai";
import { PropertyService,ProjectService,HistoryLogService} from '../services'
import { RiFileExcel2Line ,RiDeleteBin6Line,RiFileListLine,RiErrorWarningLine} from "react-icons/ri";
import { FaCircle } from "react-icons/fa";
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import { useApiAuth } from '../hooks/useApiAuth';
import {BsFilter} from "react-icons/bs"
import ReactTooltip from "react-tooltip";
import uniqid from 'uniqid';
import '../assets/css/capital.css'
import { Property,Parameter,AuthenUser,Dashboard } from '../models';
import { spiImage, bpiImage ,rolling} from '../images';
import DatePicker from "react-datepicker";
import { PageEvent } from '../models';
import Loading from './Loading'
import { VscCircleSlash,VscDebugStepOver,VscRefresh } from "react-icons/vsc";
import Modal from 'react-bootstrap/Modal'
import { DateHelper ,StringHelper} from '../helpers';
import  secureLocalStorage  from  "react-secure-storage";
import { config } from '../config'
import { apiRequest } from "../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { Color,SystemLevel,StatusDeliverable } from '../enum'

interface AppState {
    total: number
    totalPgae: number
    projects:Dashboard[]
    deleteId: number
    isLoading:boolean
    isFilter:boolean
    property:Property| null
    parameter:Parameter
}

interface Props{
    projectModeId: number
    categorySizeId: number
    phaseNumber: number
    projectStatusId: number
    registerDate: number
    activeDate: number
    spi:number
    bpi:number
}

const Projects = (props:Props) => {
    const { instance, accounts } = useMsal();
    const { accessToken } = useApiAuth()
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser  = (userStore) ? JSON.parse(userStore.toString()): null
    const isSuperAdmin = user.roleSystemLevelId == SystemLevel.SuperAdmin ? 1 : 0
    const [isConfirm, setIsconfirm] = useState(false);
    const [isExport, setIsExport] = useState(false);
    const [registerDatedateRange, setRegisterDatedateRange] = useState<[Date|null,Date|null]>([null, null]);
    const [startRegisterDate, endRegisterDate] = registerDatedateRange;
    const [activeDatedateRange, setActiveDatedateRange] = useState<[Date|null,Date|null]>([null, null]);
    const [startActiveDate, endActiveDate] = activeDatedateRange;
    const [errors,setErrors] = useState<string[]>([])
    const [appState,setAppState] = useState<AppState>({
            total: 0,
            totalPgae: 0,
            projects:[],
            deleteId: 0,
            isLoading:true,
            isFilter:false,
            property: null,
            parameter:{
                page:1,
                limit:25,
                projectModeId: props.projectModeId,
                countryId:0,
                assetId: 0,
                key:"",
                categorySizeId:props.categorySizeId,
                bpi:props.bpi,
                spi:props.spi,
                statusId: props.projectStatusId,
                startDate:null,
                endDate:null,
                startActiveDate:null,
                endActiveDate:null,
                startRegisterDate:null,
                endRegisterDate:null,
                startDateString: "",
                endDateString: "",
                startActiveDateString: "",
                endActiveDateString: "",
                startRegisterDateString: "",
                endRegisterDateString: "",
                phaseNumber:props.phaseNumber,
                phaseIds:"",
                locationId:0,
                sortType: 1,
                sortBy:"projectName",
                username:user.username,
                viewSensitiveData: user.sensitiveInformationView,
                isSuperAdmin: isSuperAdmin
            }
        }
    )
    
   
    useEffect(()=>{
        if(accessToken){
            process.env.NODE_ENV === 'development' && console.log(user)
            let propertyFlag = ['projectMode','phase','projectType','developmentScenario','resourceType','asset','assetType','categorySize','country','productType','projectStatus','location','date']
            let state = Object.assign({},appState)
            
            new PropertyService(accessToken).getProperties2(propertyFlag).then(async (result) => {
             
                if(result && result.success){
                    state.property = result.data
                    let startDate = DateHelper.getStartDate(state.property.date)
                    let endDate = DateHelper.getEndDate(state.property.date)
                    if(props.registerDate === 1){
                        state.parameter.startRegisterDate = startDate
                        state.parameter.endRegisterDate = endDate
                    }
                    if(props.activeDate === 1){
                        state.parameter.startActiveDate = startDate
                        state.parameter.endActiveDate = endDate
                    }
                    if(props.activeDate === 2){
                        state.parameter.startActiveDate = startDate
                        state.parameter.endActiveDate = startDate
                    }
                    let phaseIds:number[] = []
                    if(props.projectModeId === 0){
                        phaseIds = state.property.phases.filter( x => x.phaseNumber === props.phaseNumber).map( (m) => { return m.phaseId })
                    }
                    else{
                        phaseIds = state.property.phases.filter( x => x.projectModeId === props.projectModeId && x.phaseNumber === props.phaseNumber).map( (m) => { return m.phaseId })
                    }
                    state.parameter.phaseIds = phaseIds.join(',')
                    
                }
                let projectResult = await new ProjectService(accessToken).getAll(state.parameter)
                
                if(projectResult && projectResult.success){
                    let {page,limit,total,items} = projectResult.data
                    state.total = total
                    state.parameter.page = page
                    state.totalPgae = Math.ceil(total / limit)
                    state.projects = items
                }

                if(props.registerDate === 1){
                     setRegisterDatedateRange([state.parameter.startRegisterDate,state.parameter.endRegisterDate])
                }
                if(props.activeDate > 0){
                    setActiveDatedateRange([state.parameter.startActiveDate,state.parameter.endActiveDate])
                }
                
                state.isLoading  = false
                setAppState(state)
            })
            
        }
      
    },[accessToken])

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        let parameter = Object.assign({},appState.parameter)
        parameter.page = page
        await getProjects(parameter);
        
    }

    const filterProject = async() => {
        let parameter = Object.assign({},appState.parameter)
        let element = document.getElementById('search-key') as HTMLInputElement
        if(element && element.value){
            parameter.key = element.value
        }
        else{
            parameter.key = ""
        }
        parameter.startRegisterDate = startRegisterDate
        parameter.endRegisterDate = endRegisterDate
        parameter.startActiveDate = startActiveDate
        parameter.endActiveDate = endActiveDate
        parameter.page = 1
        
        await getProjects(parameter);
    }

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const getProjects = async(parameter:Parameter) =>{
        
        let state = Object.assign({},appState)
        state.isFilter = true
        setAppState(state)

        let state2 = Object.assign({},appState)
        let token = await getToken()
        let result = await new ProjectService(token).getAll(parameter);
        
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state2.projects = items
            
            if(total == 0){
                state2.total = 0
                state2.totalPgae = 0
            }
            else{
                state2.total = total
                state2.totalPgae = Math.ceil(total / limit)
            }
        }
        state2.parameter = parameter
        state2.isFilter = false
        setAppState(state2)
    }

    const searchProject = async (key) => {
        let result =  await new ProjectService(accessToken).search(key)
        return result.data
    }

    const exportExcel = async() =>{
        setIsExport(true)
        let parameter = Object.assign({},appState.parameter)
        if(parameter.startDate){
            parameter.startDateString = DateHelper.getDateFormat(parameter.startDate)
        }
        if(parameter.endDate){
            parameter.endDateString = DateHelper.getDateFormat(parameter.endDate)
        }
        if(parameter.startActiveDate){
            parameter.startActiveDateString = DateHelper.getDateFormat(parameter.startActiveDate)
        }
        if(parameter.endActiveDate){
            parameter.endActiveDateString = DateHelper.getDateFormat(parameter.endActiveDate)
        }
        if(parameter.startRegisterDate){
            parameter.startRegisterDateString = DateHelper.getDateFormat(parameter.startRegisterDate)
        }
        if(parameter.endRegisterDate){
            parameter.endRegisterDateString = DateHelper.getDateFormat(parameter.endRegisterDate)
        }

        parameter.startDate = null
        parameter.endDate = null
        parameter.startActiveDate = null
        parameter.endActiveDate = null
        parameter.startRegisterDate = null
        parameter.endRegisterDate = null
    
        let params:string[] = []
        for (const [key, value] of Object.entries(parameter)) {
            if(value != null){
                params.push(key + "=" + value)
            }
           
        }

        var token = await getToken()
        var url = `${config.API_URL}/v1/exportexcel/dashboard?` + params.join('&')
        var request = new XMLHttpRequest();
        request.onload = function(e) {
            if (this.status === 200) {
                if(this.response.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                    var blob = this.response;
                    
                        var downloadLink = window.document.createElement('a');
                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader? contentTypeHeader : "" }));
                        downloadLink.download = "project_portfolio_" + DateHelper.getDateFormat2() + ".xlsx";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                        setTimeout(() => {
                            setIsExport(false)
                        }, 1000);
                      
                }
                
            }
            else{
                process.env.NODE_ENV === 'development' && console.log("File not found.")
              
            }
           
        };
        request.open('GET', url);
        request.setRequestHeader("Authorization", `Bearer ${token}`);
        request.responseType = 'blob';
        request.send();
       
    }

    const confirmItem = (projectId:number) => {
        let state = Object.assign({},appState)
        state.deleteId  = projectId
        setAppState(state)
        setIsconfirm(true)
    }

    const deleteItem = async() => {
        try{
            let updateBy = (user.username) ? user.username : ""
            let token = await getToken()
            let result = await new ProjectService(token).delete(appState.deleteId,updateBy)
            if(result && result.success){
                new HistoryLogService(token).create(user.userId, appState.deleteId,"Project List","Delete Project")
                setIsconfirm(false)
                filterProject()
            }
            else{
                setIsconfirm(false)
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            setIsconfirm(false)
            setErrors([err.message])
        }
    }

    const onSelectFilterChange = (ev:React.ChangeEvent<HTMLSelectElement>) => {
        let state = Object.assign({},appState)
        let id = ev.target.id
        let value = parseInt(ev.target.value)
        if(id === 'project-mode'){
            state.parameter.projectModeId = value
        }
        if(id === 'country'){
            state.parameter.countryId = value
        }
        if(id === 'asset'){
            state.parameter.assetId = value
        }
        if(id === 'category-size'){
            state.parameter.categorySizeId = value
        }
        if(id === 'spi'){
            state.parameter.spi = value
        }
        if(id === 'bpi'){
            state.parameter.bpi = value
        }
        if(id === 'status'){
            state.parameter.statusId = value
        }
        if(id === 'phase-number'){
            state.parameter.phaseNumber = value
            let phaseIds:number[] = []
            if(appState.property){
                if(props.projectModeId === 0){
                    phaseIds = appState.property.phases.filter( x => x.phaseNumber === value).map( (m) => { return m.phaseId })
                }
                else{
                    phaseIds = appState.property.phases.filter( x => x.projectModeId === props.projectModeId && x.phaseNumber === value).map( (m) => { return m.phaseId })
                }
            }
            state.parameter.phaseIds = phaseIds.join(',')
            
        }
        if(id === 'location'){
            state.parameter.locationId = value
        }

        setAppState(state)
    }

    const ClearFilter = () => {
        let element = document.getElementById('search-key') as HTMLInputElement
        if(element){
            element.value = ""
        }
        
        let projectModeId = 0
        if(props.projectModeId > 0){
            projectModeId = props.projectModeId
        }
        let parameter:Parameter = {
            page:1,
            limit:25,
            projectModeId: projectModeId,
            countryId:0,
            assetId: 0,
            categorySizeId:0,
            bpi:0,
            spi:0,
            statusId:0,
            key:"",
            startDate:null,
            endDate:null,
            startActiveDate:null,
            endActiveDate:null,
            startRegisterDate:null,
            endRegisterDate:null,
            startDateString: "",
            endDateString: "",
            startActiveDateString: "",
            endActiveDateString: "",
            startRegisterDateString: "",
            endRegisterDateString: "",
            phaseNumber:0,
            phaseIds:"",
            locationId:0,
            sortBy:"projectName",
            sortType: 1,
            username:user.username,
            viewSensitiveData:user.sensitiveInformationView,
            isSuperAdmin:isSuperAdmin
        }
        setActiveDatedateRange([null,null])
        setRegisterDatedateRange([null,null])
        getProjects(parameter)
    }

    const sorting = (sortBy:string,sortType:number) =>{
        //setTimeout(() => {
            let state = Object.assign({},appState)
            state.parameter.sortBy = sortBy
            state.parameter.sortType = sortType
            getProjects(state.parameter)
       // }, 500);
       
    }

    const onPageLimitChange = (value:number) =>{
        let state = Object.assign({},appState)
        state.parameter.limit = value
        getProjects(state.parameter)
    }

    return (
    
    <div style={{ padding :"5px"}}>
        {appState && !appState.isLoading ?
        <div style={{ borderTop : "1px solid #ddd"}}>   
            <div id="project-tool">
                <div className='row' style={{ marginTop: '15px'}}>
                    <div className='col-sm-12 project-tool-mini' style={{ textAlign:"left",fontWeight:"bold",color:'#929daf',fontSize:'16px',fontFamily:'tahoma',paddingTop: '5px'}}>
                        <RiFileListLine size={24} style={{ marginTop: '-5px',marginLeft:'0.2em'}}/>
                        <span style={{marginLeft: '5px'}}>Project List</span>
                    </div>

                    <div className='col-sm-12 project-tool-mini' style={{ marginTop:'15px',marginBottom:'10px'}}>
                        { user.projectCreate  ?
                                <Link to="/project-create">
                                    <button type="button" className="btn btn-sm bg-dark-gradient" style={{width : "100%"}}>
                                        <AiOutlinePlusCircle size="20" color="white" />
                                        <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Create Project</span>
                                    </button>
                                </Link>:null
                        }
                    </div>

                    <div className='col-sm-2 project-tool' style={{ textAlign:"left",fontWeight:"bold",color:'#929daf',fontSize:'16px',fontFamily:'tahoma',paddingTop: '5px'}}>
                        <RiFileListLine size={24} style={{ marginTop: '-5px',marginLeft:'0.2em'}}/>
                        <span style={{marginLeft: '5px'}}>Project List</span>
                    </div>
                    <div className='col-sm-8 project-name-search-key'>
                        <div className="input-group">
                            <AiOutlineSearch className='' size={25} color="grey" style={{ position:'absolute',marginTop: '7px',zIndex: '4' }}/>
                            <input id="search-key" placeholder='Project Name' className="form-control form-control-sm inform-input" style={{ paddingLeft: '29px'}}/>
                        </div>
                    </div>
                    <div className='col-sm-2 project-tool' style={{ textAlign:'right',padding:'0'}}>
                        { user.projectCreate  ?
                                <Link to="/project-create">
                                    <button type="button" className="btn btn-sm bg-dark-gradient" style={{width : "100%"}}>
                                        <AiOutlinePlusCircle size="20" color="white" />
                                        <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Create Project</span>
                                    </button>
                                </Link>:null
                        }
                    </div>
                </div>
            </div>

            <div className='row dashboard-table-section'>
                    
                            <div className='col-sm-2 project-search-menu'>
                                <select id="project-mode" className="form-select form-select-sm" value={ appState.parameter.projectModeId } onChange={ev => onSelectFilterChange(ev)}>
                                    <option value={0}>All Project Category</option>
                                    { appState.property?.projectModes.map(m => <option key={uniqid()} value={m.projectModeId}>{ m.projectModeName}</option>) }  
                                </select>
                            </div>
                            <div className='col-sm-2 project-search-menu'>
                                <select id="country" className="form-select form-select-sm" value={ appState.parameter.countryId } onChange={ev => onSelectFilterChange(ev)}>
                                    <option value={0}>All Country</option>
                                    { appState.property?.countries.map(m => <option key={uniqid()} value={m.countryId}>{ m.countryName}</option>) } 
                                </select>
                            </div>
                            <div className='col-sm-2 project-search-menu'>
                                <select id="asset" className="form-select form-select-sm" value={ appState.parameter.assetId } onChange={ev => onSelectFilterChange(ev)}>
                                    <option value={0}>All Asset Name</option>
                                    { appState.property?.assets.map(m => <option key={uniqid()} value={m.assetId}>{m.assetCode} - { m.assetName }</option>) }  
                                </select>
                            </div>
                            
                            <div className='col-sm-2 project-search-menu'>
                                { appState.parameter.projectModeId != 3 ?
                                    <select id="category-size" className="form-select form-select-sm" value={ appState.parameter.categorySizeId } onChange={ev => onSelectFilterChange(ev)}>
                                        <option value={0}>All Category Size</option>
                                        <option value={1}>Large Project</option>
                                        <option value={2}>Medium Project</option>
                                    </select>
                                    :
                                    <select id="category-size" className="form-select form-select-sm" disabled>
                                        <option >All Category Size</option>
                                    </select>
                                }
                            </div>
                            
                            <div className='col-sm-1 project-search-menu spi-bpi-search'>
                                <select id="spi" className="form-select form-select-sm" value={ appState.parameter.spi } onChange={ev => onSelectFilterChange(ev)}>
                                    <option value={0}>All SPI</option>
                                    <option value={1}>SPI {'>= 1'}</option>
                                    <option value={-1}>SPI {'<1'}</option>
                                </select>
                            </div>

                            <div className='col-sm-1 project-search-menu spi-bpi-search'>
                                <select id="bpi" className="form-select form-select-sm" value={ appState.parameter.bpi } onChange={ev => onSelectFilterChange(ev)}>
                                    <option value={0}>All BPI</option>
                                    <option value={1}>BPI {'> 1'}</option>
                                    <option value={-1}>BPI {'<=1'}</option>
                                </select>
                            </div>

                                
                <div className='col-sm-1 project-tool' style={{ padding: '0 2px'}}>
                    <div className="">
                        <button type="button" className="btn btn-sm bg-dark-gradient" style={{ width: "100%" }} onClick={() => filterProject()}>
                            <BsFilter size="20" color="white" />
                            <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Filter</span>
                        </button>
                    </div>

                </div>
                <div className='col-sm-1 project-tool' style={{ padding: '0 2px'}}>
                    <div className="">
                        { !isExport ?
                            <button id="export-btn" type="button" className="btn btn-sm bg-dark-gradient" style={{ width: "100%" }} onClick={() => exportExcel()}>
                                <RiFileExcel2Line id="export-icon" size="20" color="white" />
                                <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Export</span>
                            </button>
                            :
                            <button id="exporting-btn" type="button" className="btn btn-sm bg-dark-gradient" style={{ width: "100%"}}>
                                <img  src={rolling} style={{ width: '20px'}}/>
                                <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: "white"}}>Exporting...</span>
                            </button>
                        }
                    </div>
                </div>

            </div>

                    <div className="row dashboard-table-section">
                        <div className="col-md-2 project-search-menu">
                            <select id="status" className="form-select form-select-sm" value={ appState.parameter.statusId } onChange={ev => onSelectFilterChange(ev)}>
                                <option value={0}>All Status</option>
                                { appState.property?.projectStatuses.map(m => <option key={uniqid()} value={m.projectStatusId}>{ m.projectStatusName}</option>) }  
                            </select>
                        </div>
                        <div className="col-md-2 project-search-menu">
                            <select id="phase-number" className="form-select form-select-sm" value={ appState.parameter.phaseNumber } onChange={ev => onSelectFilterChange(ev)}>
                                <option value={0}>All PREP Phase</option>
                                <option value={1}>Phase 1</option>
                                <option value={2}>Phase 2</option>
                                <option value={3}>Phase 3</option>
                                <option value={4}>Phase 4</option>
                                <option value={5}>Phase 5</option> 
                            </select>
                        </div>
                        <div className='col-md-2 project-search-menu'>
                            <select id="location" className="form-select form-select-sm" value={ appState.parameter.locationId } onChange={ev => onSelectFilterChange(ev)}>
                                <option value={0}>All Location</option>
                                { appState.property?.locations.map(m => <option key={uniqid()} value={m.locationId}> { m.locationName}</option>) }  
                            </select>
                        </div>
                        <div className="col-md-2 project-search-menu ">
                            <DatePicker
                                selectsRange={true}
                                startDate={startActiveDate}
                                endDate={endActiveDate}
                                dateFormat="dd MMM yyyy"
                                onChange={(update) => { setActiveDatedateRange(update) }}
                                isClearable
                                placeholderText="Active Period: Start-End Date"
                                className="form-control form-control-sm"
                            />
                        </div>
                        
                        <div className='col-md-2 project-search-menu '>
                            <DatePicker
                                selectsRange={true}
                                startDate={startRegisterDate}
                                endDate={endRegisterDate}
                                dateFormat="dd MMM yyyy"
                                onChange={(update) => { setRegisterDatedateRange(update) }}
                                isClearable
                                placeholderText="Register Period: Start-End Date"
                                className="form-control form-control-sm"
                            />
                        </div>
                        
                        <div className='col-sm-2 project-tool' style={{ padding: '0 2px' }}>
                            <div className="">
                                <button type="button" className="btn btn-sm bg-grey" style={{ width: "100%" }} onClick={() => ClearFilter()}>
                                    <VscRefresh size="20" color="white" /> 
                                    <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Clear Filter</span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className='row project-tool-mini'>
                        <div className='col-md-12'>
                            <div>
                                <button type="button" className="btn btn-sm bg-dark-gradient" style={{ width: "100%" }} onClick={() => filterProject()}>
                                    <BsFilter size="20" color="white" />
                                    <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Filter</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='row project-tool-mini'>
                        <div className='col-md-12 project-tool-export'>
                            <div>
                                { !isExport ?
                                    <button id="export-btn" type="button" className="btn btn-sm bg-dark-gradient" style={{ width: "100%" }} onClick={() => exportExcel()}>
                                        <RiFileExcel2Line id="export-icon" size="20" color="white" />
                                        <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Export</span>
                                    </button>
                                    :
                                    <button id="exporting-btn" type="button" className="btn btn-sm bg-dark-gradient" style={{ width: "100%"}}>
                                        <img  src={rolling} style={{ width: '20px'}}/>
                                        <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: "white"}}>Exporting...</span>
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='row project-tool-mini'>
                        <div className='col-sm-12'>
                            <div className="">
                                <button type="button" className="btn btn-sm bg-grey" style={{ width: "100%" }} onClick={() => ClearFilter()}>
                                    <VscRefresh size="20" color="white" /> 
                                    <span style={{ "verticalAlign": "middle", marginLeft: '5px', color: 'white' }}>Clear Filter</span>
                                </button>
                            </div>

                        </div>
                    </div>

                    <div className='row' style={{ marginTop: "20px" }}>
                        <div className='col-md-4'>
                            <div className='project-label' style={{ padding: '0', marginLeft: '0' }}>

                            </div>
                        </div>
                        <div className='col-md-8' style={{ textAlign: 'right' }}>
                            <span>
                                <AiFillCheckCircle size={20} color={Color.Green} style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Pass</span>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                <AiOutlineTrademarkCircle size={20} color={Color.Green} style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Recycle</span>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                <AiOutlinePauseCircle size={20} color={Color.Orange} style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Hold</span>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                <AiFillCloseCircle size={20} color={Color.Red} style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Stop</span>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                <AiOutlineClockCircle size={20} color={Color.Yellow} style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>In Progress</span>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                <AiOutlineClockCircle size={20} color={Color.Grey} style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Waiting for Start</span>
                            </span>
                            <span style={{ marginLeft: '10px' }}>
                                <VscCircleSlash size={20} color='black' style={{ marginRight: '5px' }} />
                                <span style={{ fontSize: '13px', fontWeight: 'bold' }}>Waive</span>
                            </span>

                        </div>
                    </div>

            <div className='row' style={{ marginTop: '20px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            
                            <select className="" value={appState.parameter.limit} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}} onChange={(ev) => onPageLimitChange(parseInt(ev.target.value))}>
                                <option>10</option>
                                <option>25</option> 
                                <option>50</option>
                                <option>100</option>        
                            </select>
                        </div>
            </div>
            <div className="table-responsive table-responsive-sm" >
                    
                    <table className="table portfolio-table table-hover">
                            <thead>
                                <tr>
                                    <th  style={{ height: '45px',minWidth:'200px'}}>
                                        Project Name
                                        { appState.parameter.sortBy === 'projectName'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('projectName',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('projectName',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('projectName',1)}/>
                                        }
                                        
                                    </th>
                                    <th style={{ minWidth:'80px'}}>
                                        
                                        <div>Register</div>
                                        <div>Date</div>
                                        { appState.parameter.sortBy === 'registerDateTime'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('registerDateTime',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('registerDateTime',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('registerDateTime',1)}/>
                                        }
                                       
                                    </th>
                                    <th style={{ minWidth:'91px'}}>
                                        
                                        <span>Asset Name</span>
                                        
                                        { appState.parameter.sortBy === 'asset'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('asset',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('asset',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('asset',1)}/>
                                        }
                                       
                                    </th>
                                    
                                    <th style={{ minWidth:'80px'}}>
                                        
                                        <span>Country</span>
                                        { appState.parameter.sortBy === 'country'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('country',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('country',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('country',1)}/>
                                        }
                                    </th>
                                    {/* <th style={{ minWidth:'125px'}}>
                                       
                                        <span>Location</span>
                                      
                                        { appState.parameter.sortBy === 'location'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('location',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('location',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('location',1)}/>
                                        }
                                    </th> */}
                                    <th  style={{ minWidth:'95px'}}>
                                       
                                        <span>Asset Type</span>
                                       
                                        { appState.parameter.sortBy === 'assetType'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('assetType',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('assetType',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('assetType',1)}/>
                                        }
                                    </th>
                                    <th style={{ minWidth:'98px'}}>
                                        <span>Project Type</span>
                                        { appState.parameter.sortBy === 'projectType'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('projectType',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('projectType',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('projectType',1)}/>
                                        }
                                    </th>

                                    <th style={{ minWidth:'90px'}}>
                                        
                                        <span>PREP Phase</span>
                                        
                                        { appState.parameter.sortBy === 'phase'?
                                            <>
                                            { appState.parameter.sortType == -1 ?
                                                <TiArrowSortedUp size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('phase',1)}/>
                                                :
                                                <TiArrowSortedDown size={16} color='rgb(3, 212, 3)'  cursor="pointer"  onClick={() => sorting('phase',-1)}/>
                                            }
                                            </>:
                                            <TiArrowSortedDown size={16} cursor="pointer" onClick={() => sorting('phase',1)}/>
                                        }

                                    </th>
                                  
                                    <th style={{ minWidth:'90px'}}>
                                        <span>PREP Status</span>
                                    </th>
                                

                                    <th style={{ textAlign: 'center',minWidth:'68px' }}>
                                        <span>SPI</span>
                                        <a data-tip data-for='spi-info' style={{ cursor: 'pointer' }}>

                                            <AiOutlineInfoCircle size={13} style={{ verticalAlign: 'top' }} />
                                        </a>

                                        <ReactTooltip id='spi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                        <div style={{minWidth: '200px'}}><img src={spiImage} /></div>
                                        </ReactTooltip>
                                    </th>
                                
                                    <th style={{  textAlign: 'center' ,minWidth:'68px'}}>
                                        <span>BPI</span>
                                        <a data-tip data-for='bpi-info' style={{ cursor: 'pointer' }}>

                                            <AiOutlineInfoCircle size={13} style={{ verticalAlign: 'top' }} />
                                        </a>

                                        <ReactTooltip id='bpi-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                        <div style={{minWidth: '200px'}}><img src={bpiImage} /></div>
                                        </ReactTooltip>
                                    </th>
                                
                                    
                                    <th style={{ width: '150px'}}>
                                        <span>Activity</span>
                                    </th>
                                    <th style={{ width: '150px'}}>
                                        <span>Area of Concern</span>
                                    </th>
                                    {/*
                                    <th>
                                        <span>%Actual Progress</span>
                                    </th>
                                    <th>
                                        <span>%Plan Progress</span>
                                    </th>
                                    */}
                                    { user.projectDelete ?
                                    <th>Delete</th>
                                    : null
                                    }
                                </tr>
                        </thead>
                        <tbody>
                            { !appState.isFilter ?
                                <>
                                { appState.projects.map((item,index) => (
                                        <tr key={uniqid()} >
                                            <td  style={{ textAlign: 'left',wordBreak:'break-word'}}>
                                                <Link to={'/project-view?pid=' + item.projectId}>
                                                    <span className="portfolio-stage-head">{ item.projectName }</span>
                                                </Link>
                                            </td>
                                            <td >
                                                <span>{ DateHelper.getDate2Digit(item.registerDateTime) }</span>
                                            </td>
                                            <td style={{ textAlign: 'center'}}>
                                                <span>{ item.assetName }</span>
                                            </td>
                                            
                                            <td >
                                                <span>{ item.countryName }</span>
                                            </td>
                                            {/* <td >
                                                <span>{ item.locationName }</span>
                                            </td> */}
                                            <td >
                                                <span>{ item.assetTypeName ? item.assetTypeName : 'Operated' }</span>
                                            </td>
                                            <td>
                                               
                                               <span>{ item.projectTypeName && item.projectTypeName !== 'None' ? item.projectTypeName : '-'}</span>
                                           
                                           </td>
                                            <td>
                                                <span>{ item.prepPhase}</span>
                                            </td>
                                           
                                            <td style={{ textAlign: item.prepStatus.length > 0 ? 'left' : 'center', paddingLeft:item.prepStatus.length > 0 ? '20px' : '' }}>
                                                    { item.prepStatus.length > 0 ?
                                                        <>
                                                        {  item.deliverableStatusId == StatusDeliverable.Pass ?
                                                            <AiFillCheckCircle size={18} color={Color.Green} style={{ marginTop: '-5px', marginRight: '2px' }} />
                                                            : item.deliverableStatusId == StatusDeliverable.Recycle ?
                                                            <AiOutlineTrademarkCircle size={18} color={Color.Green} style={{ marginTop: '-5px', marginRight: '2px' }} />
                                                            : item.deliverableStatusId == StatusDeliverable.Hold ?
                                                            <AiOutlinePauseCircle size={18} color='orange' style={{ marginTop: '-5px',marginRight: '2px' }} />
                                                            : item.deliverableStatusId == StatusDeliverable.Stop ?
                                                            <AiFillCloseCircle size={18} color='red' style={{ marginTop: '-5px',marginRight: '2px'}}/>
                                                            : item.deliverableStatusId == StatusDeliverable.InProgress ?
                                                            <AiOutlineClockCircle size={18} color={Color.Yellow} style={{ marginTop: '-5px',marginRight: '2px'}}/>
                                                            : item.deliverableStatusId == StatusDeliverable.WaitingForStart ?
                                                            <AiOutlineClockCircle size={18} color={Color.Grey} style={{ marginTop: '-5px',marginRight: '2px'}}/>
                                                            : item.deliverableStatusId == StatusDeliverable.Waive ?
                                                            <VscCircleSlash size={17} color='black' style={{ marginTop: '-5px',marginRight: '2px'}}/>
                                                            : item.deliverableStatusId == StatusDeliverable.WaivePhase ?
                                                            <VscDebugStepOver size={18} color='Grey' style={{ marginTop: '-5px',marginRight: '2px'}}/>
                                                            : null
                                                        }
                                                        <span>{item.prepStatus}</span>
                                                        </>:
                                                        <span>-</span>
                                                    }
                                                        
                                                       

                                                 </td> 
                                            
              
                                            <td>
                                            { item.spi != null && item.trackProgress === 1 ?
                                                <>
                                                { item.spi == 0 ?
                                                   <span>-</span>
                                                :
                                                <>
                                                    <FaCircle color={item.spi < 1 ? Color.DarkRed : Color.LightGreen} size={18} style={{ marginTop: '-5px', marginRight: '5px' }} />
                                                    <span>{ item.spi.toFixed(2) }</span>
                                                </>
                                                }
                                                
                                                </>: <span>-</span>
                                            }
                                            </td>
                                           
                                            <td>
                                            { item.spi != null && item.trackBudget === 1 ?
                                                <>
                                                { item.bpi == 0 ?
                                                    <span>-</span>
                                                    :
                                                    <>
                                                        <FaCircle color={item.bpi > 1 ? Color.DarkRed : Color.LightGreen} size={18} style={{ marginTop: '-5px', marginRight: '5px' }} />
                                                        <span>{ item.bpi.toFixed(2) }</span>
                                                    </>
                                                }
                                              
                                                </>:<span>-</span>
                                            }
                                            </td>
                                            
                                            <td style={{textAlign: 'center'}}>
                                            { StringHelper.getLength(item.currentStatus) > 10 ?
                                                <>
                                                <a data-tip  data-for={'current-status-info-' + index} style={{ cursor: 'pointer' }}>
                                                   { StringHelper.substr(item.currentStatus,0,10)}
                                                </a>
                                                <ReactTooltip id={'current-status-info-' + index} data-tooltip-html={true} className='message-tooltip' backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                    <div style={{minWidth: '200px'}}>
                                                        {item.currentStatus}
                                                    </div>
                                                </ReactTooltip>
                                                </>
                                                :
                                                <span>{item.currentStatus && item.currentStatus.length > 0 ? item.currentStatus : '-'}</span>

                                            }
                                            </td>
                                            <td style={{textAlign: 'center'}}>
                                            { StringHelper.getLength(item.areaOfConcern) > 10 ?
                                                <>
                                                <a data-tip  data-for={'area-of-concern-info-' + index} style={{ cursor: 'pointer' }}>
                                                   { StringHelper.substr(item.areaOfConcern,0,10)}
                                                </a>
                                                <ReactTooltip id={'area-of-concern-info-' + index} data-tooltip-html={true} className='message-tooltip' backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                    <div style={{minWidth: '200px'}}>
                                                        {item.areaOfConcern}
                                                    </div>
                                                </ReactTooltip>
                                                </>
                                                :
                                                <span>{item.areaOfConcern && item.areaOfConcern.length > 0 ? item.areaOfConcern : '-'}</span>

                                            }
                                               
                                            </td>
                                            {/*
                                            <td>
                                                <span></span>
                                            </td>
                                            <td>
                                                <span></span>
                                            </td>
                                            */}
                                            { user.projectDelete ?
                                            <td>
                                                <RiDeleteBin6Line size={18} color={Color.Red} cursor="pointer" onClick={ () => confirmItem(item.projectId)}/>
                                            </td>
                                            :null
                                            }
                                        </tr>
                                    ))
                                }
                                </>:<Loading text="Loading..."/>
                            }
                        </tbody>
                    </table>
            </div>
            <div className='row' style={{marginTop: '30px'}}>
                    <div className='col-md-4' style={{ display: 'flex'}}>
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={appState.totalPgae}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                onPageChange={handlePageClick}
                                forcePage={appState.parameter.page - 1}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active-gradient"
                            />
                        </div>
                    </div>
                   
            </div>
        </div>:<Loading text="Loading..."/>
        }

            <Modal show={isConfirm} onHide={() => setIsconfirm(false)} size="lg">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setIsconfirm(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}}>
                         <div>
                             <RiDeleteBin6Line size={18} color={'red'} />
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to delete?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => deleteItem()} >
                             <span style={{ marginLeft: '5px'}} >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setIsconfirm(false)} >
                             <span style={{ marginLeft: '5px'}} >No</span>
                         </button>
                     </Modal.Body>
                 </Modal>

                <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}} >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

               
    </div>
  );
}

export default Projects