import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { CategorySizeThreshold,AuthenUser,PageEvent,SelectItem} from '../../models';
import { CategorySizeService ,HistoryLogService } from '../../services';
import { AiOutlineInfoCircle,AiOutlineEdit} from "react-icons/ai";
import { useApiAuth } from '../../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper } from '../../helpers';
import { Color } from '../../enum'
import uniqid from 'uniqid';
import Loading from '../Loading'
import ReactTooltip from "react-tooltip";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { RiErrorWarningLine } from "react-icons/ri";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    categorySizes:CategorySizeThreshold[]
    categoryIndex:number
    categoryActive:number
    isConfirm: boolean
    isLoading:boolean
}

const AdminProjectCategory = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        categorySizes:[],
        categoryIndex:0,
        categoryActive:0,
        isConfirm: false,
        isLoading:true
    })
    const [categorySize, setCategorySize] = useState<CategorySizeThreshold>(
    {
        categorySizeThresholdId: 0,
        projectModeId: 0,
        projectModeName: "",
        categorySizeThresholdValue: 0,
        updateDateTime: new Date(),
        updateBy: name,
        active: 1
    })
    const [modalShow, setModalShow] = useState(false);
    const [errors,setErrors] = useState<string[]>([])

    useEffect(() => {
        if(accessToken){
            getCategorySizes();
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const getCategorySizes =async () => {
        let result = await new CategorySizeService(accessToken).getAll();
        let state = Object.assign({},appState)
        console.log(result)
        if(result && result.success){
            state.categorySizes = result.data
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const editItem = (item:CategorySizeThreshold) => {
        setCategorySize(item)
        setModalShow(true)
    }

    const save = async () => {
        let result:any = null
        let _categorySize = Object.assign({},categorySize)
        let inputItem =document.getElementById('input-item') as HTMLInputElement
        if(!inputItem.value){
            return
        }

        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true
       
        try{
            _categorySize.updateBy = name
            _categorySize.categorySizeThresholdValue =  parseInt(inputItem.value)
            let token = await getToken()
            let result = await new CategorySizeService(token).update(_categorySize)
                new HistoryLogService(token).create(user.userId, null,"Admin Project Category Size",'Update Project Mode ' + _categorySize.projectModeName + ' Set Category Size "' + _categorySize.categorySizeThresholdValue + '"')
        
            if(result && result.success){
                saveBtn.disabled = false
                setModalShow(false)
                getCategorySizes()
            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            saveBtn.disabled = false
            setErrors([err.message])
        }
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                
            <div className='project-block-none' style={{ paddingTop: '5px'}}>
            <div className="row">
                     <div className="col-sm-6">
                         <span className='project-label'>Project Category Size</span>
                     </div>
            </div>
        
        { !appState.isLoading ?
        <>
            <div className="table-responsive table-responsive-sm">
                <table className="table table-sm table-bordered table-hover" >
                    <thead>
                        <th style={{width: '80px'}}>No.</th>
                        <th>Project Mode</th>
                        <th>
                            <div>
                                <span style={{ marginRight: '5px'}}>Threshold in MMUSD</span>
                                <a data-tip data-for={'threshold-info'} style={{cursor: 'pointer',verticalAlign : 'bottom'}}>
                                    <AiOutlineInfoCircle  size={20}/>
                                </a>
                                <ReactTooltip id={'threshold-info'} data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                    { 'EX. if threshold is 200, means <= 200 is medium and > 200 is large' }
                                </ReactTooltip>
                            </div>
                            <div>(for Medium/Large Categorizing)</div>
                        </th>
                        <th>Modified By</th>
                        <th>Modified Date</th>
                        <th>Edit</th>
                    </thead>
                    <tbody>
                    { appState.categorySizes.map((item,index) =>(
                            <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '#f3f3f3' : ''}}>
                                <td>
                                    {((appState.page -1) * appState.limit) + index + 1}
                                </td>
                                <td style={{textAlign:'left',paddingLeft:'20px'}}>
                                    <span>{ item.projectModeName }</span>
                                </td>
                                <td style={{textAlign:'center',paddingLeft:'20px'}}>
                                    <span style={{marginRight:'3px'}}>{ item.categorySizeThresholdValue }</span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <span>{ item.updateBy? item.updateBy: 'Admin' }</span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                </td>
                                <td className='text-center-both'>
                                    { user.administratorUpdate > 0 ?
                                        <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                        :<></>
                                    }
                                </td>
                               
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </>:<Loading text="Loading..."/>
        }
         </div>
         
                </div>
            </div>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                        <Modal.Title style={{ fontSize: '15px'}}>
                           <span>Update Category Size</span>
                        </Modal.Title>
                        <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setModalShow(false)}>X</span>
                         
                     </Modal.Header>
                     <Modal.Body style={{ textAlign: 'center'}}>
                         <div className='row'>
                             <div className="col-sm-1 col-label">
                         
                             </div>
                             <div className="col-sm-10">
                                 <label  className="form-label col-label" style={{ float:'left'}}>Category Size</label>
                                 <input id="input-item" defaultValue={categorySize.categorySizeThresholdValue} type="text"  className="form-control form-control-sm " />
                             </div>
                         </div>
                        
                         <div className='row'>
                             <div className='col-sm-12 text-center-both'>
                                 <button id="save-btn" type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}} onClick={save}>Save</button>
                             </div>
                         </div>
                     </Modal.Body>
                 </Modal>

                 <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}} >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>

        </div>
    );
  
}

export default AdminProjectCategory