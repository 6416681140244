import React, { useState, useEffect } from 'react';
import { useApiAuth } from '../hooks/useApiAuth';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { AiOutlineLogout,AiOutlineHome,AiOutlineUser,AiOutlineMenu,AiOutlineFilePdf } from "react-icons/ai";
import { FileManageService } from '../services';
import { RiDashboardFill} from "react-icons/ri";
import { MdViewList } from "react-icons/md";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import { pttLogo,manualIcon } from '../images'
import { AuthenUser } from '../models'
import { ProjectLevel } from '../enum'
import Modal from 'react-bootstrap/Modal'
import  secureLocalStorage  from  "react-secure-storage";
import { RiErrorWarningLine } from "react-icons/ri";
import { Color } from '../enum'
import { apiRequest } from "../configurations/authConfig";
import './NavMenu.css';
import '../assets/css/side-menu.css';

interface InformSave {
  saveStatus: number
}

export const NavMenu =() => {
  const [collapsed ,setCollapsed] = useState(true)
  const [menu ,setMenu] = useState("main")
  const [manualFile ,setManualFile] = useState("")
  const { instance,accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { account,accessToken } = useApiAuth();
  const userStore = secureLocalStorage.getItem('user')
  const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
  const [isConfirmUpdate,setIsConfirmUpdate] = useState(false)
  const [routeName,setRouteName] = useState("")

  useEffect(() => {
    if(accessToken){
      console.log("version 2.0.1")
      if(window.location.pathname == '/main-dashboard'){
        setMenu('main')
      }
      if(window.location.pathname == '/dashboard' || window.location.pathname == '/project-view'){
        setMenu('dashboard')
      }
      if(window.location.pathname == '/gatekeeper-worklist'){
        setMenu('gatekeeper')
      }
      if(window.location.pathname == '/admin'){
        setMenu('admin')
      }
      
        getManualFile()
    }
      
  },[accessToken])

  const getManualFile = async() => {
    try{
      let token = await getToken()
      let result = await new FileManageService(token).getManualFile();
      console.log(result)
      if(result && result.success){
         setManualFile(result.data)
      }
    }
    catch(err){
      console.log(err.message)
    }
  }

  const download = async () => {
    let token = await getToken()
    new FileManageService(token).downloadManual(manualFile);
  }

  const getToken = async() => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if(acc){
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
  }

  const signOut = () =>{
      localStorage.clear()
     instance.logoutRedirect().catch(e => {
         console.error(e);
     });
  }

  // const clear = () =>{
  //   localStorage.clear()
  //   alert('clear success')
  // }

  const toggleNavbar = () => {
    setCollapsed(!collapsed)
  }

  const linkToMaindashboard = () => {
    window.location.href = "/"
  }

  const routeChange = (_routeName:string) =>{
    let element = document.getElementById('save-project-btn') as HTMLButtonElement
    if(element && element.disabled === false){
      setRouteName(_routeName)
      setIsConfirmUpdate(true)
    }
    else{
      if(_routeName == 'main'){
        toMaindashboard()
      } 
      if(_routeName == 'gatekeeper'){
        toGatekeeperWorkList()
      }
      if(_routeName == 'admin'){
        toAdmin()
      }
    }
  }

  const routeChangeForce = () =>{
      setRouteName("")
      setIsConfirmUpdate(false)
      if(routeName == 'main'){
          toMaindashboard()
      } 
      if(routeName == 'gatekeeper'){
          toGatekeeperWorkList()
      }
      if(routeName == 'admin'){
          toAdmin()
      }
  }

  const toMaindashboard = () => {
    let element = document.getElementById('link-main-dashboard') as HTMLLinkElement
      element.click()
  }

  const toGatekeeperWorkList = () => {
    let element = document.getElementById('link-gatekeeper-worklist') as HTMLLinkElement
      element.click()
  }

  const toAdmin = () => {
      let element = document.getElementById('link-admin') as HTMLLinkElement
      element.click()
  }

  return (
      <header>
          <Navbar id="navbar" className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 header-page" container={'false'} light style={{ height : '120px'}}>
            <NavbarBrand>
            <div style={{ padding: '20px'}}>
              <img src={pttLogo} style={{ width: '80px',height: '80px',cursor: 'pointer' }} onClick={() => routeChange('main')}/>
            </div>
            </NavbarBrand>
            <div className="div-project-name-header">
              <h3 className='font-blue project-name-header' style={{ fontWeight: "bold",cursor:'pointer' }} onClick={() => routeChange('main')}>
                Project Portfolio Dashboard
              </h3>
            </div>
            { user && user.authorization ?
            <>
              <h3 className='side-main-devider ont-dark-blue' style={{  marginLeft:"10px",marginRight:"10px" }}>|</h3>
              <div className='side-menu'>
                <span className='side-menu-text'>
                          <a style={{ cursor: 'pointer'}} onClick={() => routeChange('main')}>
                              <div className="menu-label-1">
                                <span className={menu === 'main' ? 'font-blue font-underline': ''}>
                                  <AiOutlineHome size={20} style={{ verticalAlign: 'top',marginRight: '3px',marginTop:'-2px'}}/>
                                  <span className='side-menu-name'>Main Dashboard</span>
                                  </span>
                              </div>
                          </a>
                          <Link id="link-main-dashboard" to='/main-dashboard' onClick={() => setMenu('main')}></Link>
                </span>
                <span className='side-menu-devider'>|</span>
                <span className='side-menu-text'>
                          <Link to='/dashboard?projectMode=1' onClick={() => setMenu('dashboard')}>
                              <div className="menu-label-2">
                                    <span className={menu === 'dashboard' ? 'font-blue font-underline': ''}>
                                      <RiDashboardFill size={20} style={{ verticalAlign: 'top',marginRight: '3px'}}/>
                                      <span className='side-menu-name'>Portfolio Dashboard</span>
                                  </span>
                              </div>
                          </Link>
                </span>
        
                <span className='side-menu-text'>
                  { user.roleSystemLevelId === ProjectLevel.GateKeeper ?
                    <>
                      <span className='side-menu-devider'>|</span>
                            <a style={{ cursor: 'pointer'}} onClick={() => routeChange('gatekeeper')}>
                                      <div className="menu-label-3">
                                        <span className={menu === 'gatekeeper' ? 'font-blue font-underline': ''}>
                                        <MdViewList size={22} style={{ verticalAlign: 'top',marginRight: '3px'}}/>
                                        <span className='side-menu-name'> Gatekeeper Worklist</span>
                                          </span>
                                      </div>
                            </a>
                            <Link id="link-gatekeeper-worklist" to='/gatekeeper-worklist' onClick={() => setMenu('gatekeeper')}></Link>
                      </>: null
                  }
                </span>

                <span className='side-menu-text admin-menu'>
                  {user.administratorView  || user.administratorUpdate  ?
                    <>
                      <span className='side-menu-devider'>|</span>
                                <a style={{ cursor: 'pointer'}} onClick={() => routeChange('admin')}>
                                      <div className="menu-label-4">
                                        <span className={menu === 'admin' ? 'font-blue font-underline': ''}>
                                        <AiOutlineUser size={21} style={{ verticalAlign: 'top',marginRight: '3px',marginTop:'-1px'}}/>
                                          <span className='side-menu-name'>Administrator</span>

                                        </span>
                                      </div>
                                  </a>
                                  <Link id="link-admin" to='/admin' onClick={() => setMenu('admin')}></Link>
                      </>:null
                  }
                </span>
            
              </div>
              <div className='side-mimi-menu'>
                <span className='side-mini-text'>
                          <a style={{ cursor: 'pointer'}} onClick={() => routeChange('main')}>
                              <div className="menu-label-1">
                                <span className={menu === 'main' ? 'font-blue font-underline': ''}>
                                  <AiOutlineHome size={20} style={{ verticalAlign: 'top',marginRight: '3px',marginTop:'-2px'}}/>
                                  </span>
                              </div>
                          </a>
                          <Link id="link-main-dashboard" to='/main-dashboard' onClick={() => setMenu('main')}></Link>
                </span>
                <span className='side-mini-text'>
                          <Link to='/dashboard?projectMode=1' onClick={() => setMenu('dashboard')}>
                              <div className="menu-label-2">
                                    <span className={menu === 'dashboard' ? 'font-blue font-underline': ''}>
                                      <RiDashboardFill size={20} style={{ verticalAlign: 'top',marginRight: '3px'}}/>
                                     
                                  </span>
                              </div>
                          </Link>
                </span>
                { user.roleSystemLevelId === ProjectLevel.GateKeeper ?
                     <span className='side-mini-text'>
                          <a style={{ cursor: 'pointer'}} onClick={() => routeChange('gatekeeper')}>
                                      <div className="menu-label-3">
                                        <span className={menu === 'gatekeeper' ? 'font-blue font-underline': ''}>
                                        <MdViewList size={22} style={{ verticalAlign: 'top',marginRight: '3px'}}/>
                                       
                                          </span>
                                      </div>
                            </a>
                            <Link id="link-gatekeeper-worklist" to='/gatekeeper-worklist' onClick={() => setMenu('gatekeeper')}></Link>
                      </span>: null
                }
                {user.administratorView  || user.administratorUpdate  ?
                   <span className='side-mini-text admin-menu'>
                      
                                  <a style={{ cursor: 'pointer'}} onClick={() => routeChange('admin')}>
                                      <div className="menu-label-4">
                                        <span className={menu === 'admin' ? 'font-blue font-underline': ''}>
                                        <AiOutlineUser size={21} style={{ verticalAlign: 'top',marginRight: '3px',marginTop:'-1px'}}/>
                                        </span>
                                      </div>
                                  </a>
                                  <Link id="link-admin" to='/admin' onClick={() => setMenu('admin')}></Link>
                    </span>:null
                }
                
            
              </div>
              </>:null
            }
              {/* <NavbarToggler onClick={toggleNavbar} className="mr-2" /> */}
         
              <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={collapsed} navbar style={{ paddingTop: '70px'}}>
                <ul className="navbar-nav flex-grow" style={{ marginRight: '15px'}}>
                  <NavItem>
                  { isAuthenticated ?
                                  <>
                                    { manualFile ?
                                      <div className='user-manual' onClick={download}>
                                        <img src={manualIcon} className='user-manual-icon'/>
                                      </div>:null
                                      }
                                      <div className='account-label login-label'>
                                          <div style={{ fontWeight: 'bold' }}>{account?.name}</div>
                                          <div>
                                            <span style={{ fontStyle:"italic" }} >Role : {user.roleName}</span>
                                            <span className='logout' style={{marginLeft:'10px',color:'red',cursor:'pointer'}}  onClick={signOut}>[Log Out]</span>
                                          </div>
                                      </div>
                                      <div>
                                        <span style={{ color: '#D52B1E', marginLeft: '5px', cursor: 'pointer',display:'none' }} onClick={signOut}>
                                              <AiOutlineLogout size={20} color='#D52B1E' />
                                          </span>
                                      </div>

                   {/*<span onClick={clear}>Clear</span>*/}
                  </>:null
                  
                }
                  </NavItem>
                </ul>
              </Collapse>

          </Navbar>
          <Modal show={isConfirmUpdate} onHide={() => setIsConfirmUpdate(false)} size="lg" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => setIsConfirmUpdate(false)}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                     <RiErrorWarningLine size={35} color={Color.DarkBlue} />
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}>Project information has been changed without saving, would you like to exit from this page?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => routeChangeForce()} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => setIsConfirmUpdate(false)} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>
      </header>
  );
  
}
