import React, { useEffect } from 'react';
import { AiOutlineSearch,AiOutlineClose } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";

const Autocomplete = (props) =>{
    var inputElement: any = null
    var divElement:any = null
    //var clearElement:any = null
    var key:string = ""
    var searchTimeout:NodeJS.Timeout | null = null
    var currentFocus:number = 0
    var idSelect = -1
    useEffect(() => {
        create()
    },[])

    const create = () => {

        inputElement = document.getElementById(props.elementId) as HTMLInputElement
        //clearElement = document.getElementById(props.elementId + '-clear') as HTMLDivElement

        if(props.initialId){
            inputElement.setAttribute('data-select-id',props.initialId)
        }
        if(props.initialValue){
            inputElement.value = props.initialValue
        }

        inputElement.addEventListener("input", async function(e) {
            
                if(searchTimeout){
                    clearTimeout(searchTimeout)
                }
                
                searchTimeout = setTimeout(async function(){
                    key = inputElement.value.toLocaleLowerCase()
                    
                    closeAllLists();
                    if (!key || key.length == 0) {
                        props.onSelect({id: -1, value: "",index: -1,index2: -1})
                        return false;
                    }
                    else{
                        currentFocus = -1;
                        //props.onSelect({id: -1, value: inputElement.value,index: -1})
    
                        let results = await props.onChange(key,props.index)
                        let element: any
                        if(!results || results.length == 0){
                            closeAllLists()
                            arrowClick()
                            return
                        }
                        
                        divElement = document.createElement("div");
                        divElement.setAttribute("id", props.elementId + "-autocomplete-list");
                        divElement.setAttribute("class", "autocomplete-items");
                        divElement.style.top = getHeight() + 'px'
                        inputElement?.parentNode?.appendChild(divElement);
                       
                        for (var i in results) {
                            let dataId = results[i][props.idKey]
                            let dataValue = results[i][props.valueKey]
    
                            element = document.createElement("div");
                            element.innerHTML =  dataValue
                            element.innerHTML += "<input type='hidden' value='" + dataId + "'>";
                            element.innerHTML += "<input type='hidden' value='" + dataValue + "'>";
                            element.innerHTML += "<input type='hidden' value='" + JSON.stringify(results[i]) + "'>";
    
                            element.addEventListener("click", function(e) {
                                let id = parseInt(this.getElementsByTagName("input")[0].value)
                                let value = this.getElementsByTagName("input")[1].value
                                let values = this.getElementsByTagName("input")[2].value
                                let index = (props.index) ? props.index : 0
                                let index2 = (props.index2) ? props.index2: 0
                                inputElement.value= value
                                idSelect = id
                                closeAllLists();
                                props.onSelect({id: id, value: value,values:values,index: index,index2:index2})
                            });
                            divElement.appendChild(element);           
                        }      
                    }
                  

                },500)
        });

        inputElement.addEventListener("click", async function(e) {
            if(inputElement.value.length == 0){
                arrowClick()
            }
             
        });

        inputElement.addEventListener("keydown", function(e) {
            var val = this.value
            if(val.length > 0){
                var divElement = document.getElementById(props.elementId + "-autocomplete-list") as HTMLDivElement
                var x
                if (divElement) x = divElement.getElementsByTagName("div")
                    if (e.keyCode == 40) {
                        currentFocus++;
                        addActive(x);
                    } else if (e.keyCode == 38) { 
                        currentFocus--;
                        addActive(x);
                    } else if (e.keyCode == 13) {
                        e.preventDefault();
                    if (currentFocus > -1) {
                        if (x) x[currentFocus].click();
                    }
                }
            }
        });
    
    }

    const arrowClick = async function(){
        if(!props.onClick) return
            
            currentFocus = -1;
            let arrowDownElement = document.getElementById('autocomplete-down') as HTMLDivElement
            let arrowUpElement = document.getElementById('autocomplete-up') as HTMLDivElement
            //if(arrowDownElement){
                arrowDownElement.style.display = 'none'
           // }
          // if(arrowUpElement){
                arrowUpElement.style.display = "inline"
          //  }

            let results = await props.onClick()
            let element: any
            if(results.length == 0){
                closeAllLists()
                return
            }

            divElement = document.createElement("div");
            divElement.setAttribute("id", props.elementId + "-autocomplete-list");
            divElement.setAttribute("class", "autocomplete-items");
            divElement.style.top = getHeight() + 'px'
            inputElement?.parentNode?.appendChild(divElement);
           
            for (var i in results) {
                let dataId = results[i][props.idKey]
                let dataValue = results[i][props.valueKey]

                element = document.createElement("div");
                element.innerHTML =  dataValue
                element.innerHTML += "<input type='hidden' value='" + dataId + "'>";
                element.innerHTML += "<input type='hidden' value='" + dataValue + "'>";
                                  
                element.addEventListener("click", function(e) {
                    let id = parseInt(this.getElementsByTagName("input")[0].value)
                    let value = this.getElementsByTagName("input")[1].value
                    let index = (props.index) ? props.index : 0
                    let index2 = (props.index2) ? props.index2 : 0
                    inputElement.value= value
                    idSelect = id
                    closeAllLists();
                    props.onSelect({id: id, value: value,index: index,index2: index2})
                });
                divElement.appendChild(element);           
            }      
    }

    document.addEventListener("click", function (e) {
        if(e){
            closeAllLists();
        }
    });
    
    const addActive = (x) => {
        if (!x) return false;
            removeActive(x);
        if (currentFocus >= x.length) currentFocus = 0;
        if (currentFocus < 0) currentFocus = (x.length - 1);
  
        x[currentFocus].classList.add("autocomplete-active");
    }
    const removeActive = (x) => {
        for (var i = 0; i < x.length; i++) {
            x[i].classList.remove("autocomplete-active");
        }
    }
    const closeAllLists = () => {
        var x = document.getElementsByClassName("autocomplete-items");
        if(x){
            for (var i = 0; i < x.length; i++) {
                x[i]?.parentNode?.removeChild(x[i]);
            }
        }
    }

    const closeAllListArrow = () => {
        var x = document.getElementsByClassName("autocomplete-items");
        if(x){
            for (var i = 0; i < x.length; i++) {
                x[i]?.parentNode?.removeChild(x[i]);
            }
        }
        let arrowDownElement = document.getElementById('autocomplete-down') as HTMLDivElement
        let arrowUpElement = document.getElementById('autocomplete-up') as HTMLDivElement
        if(arrowDownElement){
           arrowDownElement.style.display = 'inline'
       }
       if(arrowUpElement){
           arrowUpElement.style.display = "none"
       }
        
       
    }
    const getHeight = () => {
        let element = document.getElementById(props.elementId + '-autocomplete') as HTMLDivElement
        return element.offsetHeight
    }

    const clearInput = () => {
        
        inputElement.value = ""
        closeAllLists()
        idSelect = -1
        props.onSelect({id: -1, value: "",index: -1,index2: -1})
    }

    return(
        <div id={props.elementId + '-autocomplete'} className='autocomplete'>
            <div className='wrapper wrapper-box'>
                <div className='autocomplete-section' style={{backgroundColor : props.backgroundColor}}>
                    <AiOutlineSearch className='autocomplete-search-icon' size={20} color="grey"/>
                    <input ref={props.reference} id={props.elementId} className={props.extendClass ? 'autocomplete-search-box ' + props.extendClass : 'autocomplete-search-box'} autoComplete="off"  style={{backgroundColor : props.backgroundColor}}/>
                    
                    <div id='autocomplete-down' onClick={() => arrowClick()} style={{cursor: 'pointer',marginTop: '-3px',paddingRight:'10px',display: props.hiddenArrow ? 'none':'inline'}}>
                        <IoIosArrowDown size={16} color="#212529"/>
                    </div>
                    <div id='autocomplete-up' onClick={() => closeAllListArrow()} style={{cursor: 'pointer',display:'none',marginTop: '-3px',paddingRight:'10px'}}>
                        <IoIosArrowDown size={16} color="#212529" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Autocomplete
