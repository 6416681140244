import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { AiOutlineCheckCircle ,AiOutlineSave} from "react-icons/ai";
import { RoleService,HistoryLogService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { RoleProjectLevel,  RoleSystemLevel ,AuthenUser, HistoryLog} from '../../models';
import Loading from '../Loading'
import { DateHelper,StringHelper } from '../../helpers';
import uniqid from 'uniqid';
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    roleProjectLevels:RoleProjectLevel[]
    roleSystemLevels: RoleSystemLevel[]
    updateDate:string
    updateBy:string
    historyProjectLevelLog: string[]
    historySystemLevelLog:string[]
    isLoading:boolean
}

const AdminRole = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>(
        { 
            roleProjectLevels: [], 
            roleSystemLevels: [],
            updateDate: "",
            updateBy: "",
            historyProjectLevelLog: [],
            historySystemLevelLog: [],
            isLoading:true
    })
    const [isSuccess, setIsSuccess] = useState(false)
    
    useEffect(() => {
        if(accessToken){
            new RoleService(accessToken).getAll().then((result) => {
                //console.log(result)
                if(result && result.success){
                    const state = Object.assign({},appState)
                    //console.log(result.data)
                    state.roleProjectLevels = result.data.roleProjectLevels
                    state.roleSystemLevels = result.data.roleSystemLevels
                    state.updateBy = result.data.updateBy
                    state.updateDate = DateHelper.getDate(result.data.updateDate)
                    state.isLoading =false
                    setAppState(state)
                    let projectElement = document.getElementById('project-modify') as HTMLDivElement
                    projectElement.innerHTML = "Modified by " + StringHelper.htmlEncode(result.data.roleProjectLevels[0].updateBy) + " " + DateHelper.getDateTime(result.data.roleProjectLevels[0].updateDateTime)
                    let systemElement = document.getElementById('system-modify') as HTMLDivElement
                    systemElement.innerHTML = "Modified by " + StringHelper.htmlEncode(result.data.roleSystemLevels[0].updateBy) + " " + DateHelper.getDateTime(result.data.roleSystemLevels[0].updateDateTime)
                }
            })
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const onRoleProjectLevelChange = (item:RoleProjectLevel,permissionName:string,permissionText:string,checked:boolean) => {
        let state = Object.assign({},appState)
        let index = appState.roleProjectLevels.findIndex( x => x.roleProjectLevelId === item.roleProjectLevelId)
        if(index > -1){
            let checkStatus = checked ? 'Checked' : 'Unchecked'
            state.roleProjectLevels[index][permissionName] = checked ? 1 : 0
            state.roleProjectLevels[index].updateBy = name
            state.historyProjectLevelLog.push(item.roleName + " set " + permissionText + ' = ' + checkStatus)
        }
        setAppState(state)
    }

    const saveProjectLevel = async () => {
        let roleProjectLevels = appState.roleProjectLevels.map((item) => { item.updateBy = name; return item })

        let saveBtn =document.getElementById('save-project-btn') as HTMLButtonElement
        saveBtn.disabled = true

        let token = await getToken()
        let result = await new RoleService(token).UpdateRoleProject(roleProjectLevels)
       
        if(result && result.success){
            saveBtn.disabled = false
            new HistoryLogService(token).create(user.userId, null,"Admin Role","Upadte Project Role ("+ appState.historyProjectLevelLog.join(", ") + ")")
            setIsSuccess(true)
            setTimeout(() => {
                let state = Object.assign({},appState)
                state.historyProjectLevelLog = []
                setAppState(state)
                setIsSuccess(false)
                if(result.message){
                    let element = document.getElementById('project-modify') as HTMLDivElement
                    element.innerHTML =  result.message
                }
            }, 500);
        }
        else{
            saveBtn.disabled = false
        }
    }

    const onRoleSystemLevelChange = (item:RoleSystemLevel,permissionName:string,permissionText:string, checked:boolean) => {
        let state = Object.assign({},appState)
        let index = appState.roleSystemLevels.findIndex( x => x.roleSystemLevelId === item.roleSystemLevelId)
        if(index > -1){
            let checkStatus = checked ? 'Checked' : 'Unchecked'
            state.roleSystemLevels[index][permissionName] = checked ? 1 : 0
            state.roleSystemLevels[index].updateBy = name
            state.historySystemLevelLog.push(item.roleName + " set " + permissionText + ' = ' + checkStatus)
        }
        setAppState(state)
    }

    const saveSystemLevel = async () => {
        let roleSystemLevels = appState.roleSystemLevels.map((item) => { item.updateBy = name; return item })

        let saveBtn =document.getElementById('save-system-btn') as HTMLButtonElement
        saveBtn.disabled = true

        let token = await getToken()
        let result = await new RoleService(token).UpdateRoleSysytem(roleSystemLevels)
        if(result && result.success){
            saveBtn.disabled = false
            new HistoryLogService(token).create(user.userId, null,"Admin Role","Upadte System Role (" + appState.historySystemLevelLog.join(", ") + ")")
            setIsSuccess(true)
            setTimeout(() => {
                let state = Object.assign({},appState)
                state.historySystemLevelLog = []
                setAppState(state)
                setIsSuccess(false)
                if(result.message){
                    let element = document.getElementById('system-modify') as HTMLDivElement
                    element.innerHTML =  result.message
                }
            }, 500);
        }
        else{
            saveBtn.disabled = false
        }
    }
    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                
             <div className='project-block-none' style={{ paddingTop: '5px'}}>
             <div className="row">
                <div className="col-sm-6">
                    <span className='project-label'>Role And Permission</span>
                    
                </div>
            </div>
            {/*
            <div className='row'>
                <div className="col-sm-12">
                    <button type="button" className="btn btn-sm btn-outline-success">
                        <i className="material-icons header-icon">add</i>
                        <span style={{ "verticalAlign" : "top"}}>Add Role</span>
                        </button>
                </div>
            </div>
            */}

                        <div className="row">
                            <div className="col-sm-6">
                                <h6 style={{ fontWeight: "bold", marginBottom: "0px" }} >Project Level</h6> (The Project Role can be assigned in each project)
                            </div>
                            <div className='col-sm-6' style={{textAlign:'right'}} >
                                { user.administratorUpdate > 0 ?
                                    <button id="save-project-btn" type="button" className="btn btn-sm bg-dark-blue" style={{width:'150px'}} onClick={saveProjectLevel} disabled={appState.historyProjectLevelLog.length === 0}>
                                        <AiOutlineSave size={20} />
                                        <span style={{ marginLeft: '5px'}}>Save</span>
                                    </button>
                                    :<></>
                                }
                            </div>
                        </div>
                    { !appState.isLoading ?
                    <>
                        <div className="table-responsive table-responsive-sm">
                            <table className="table table-sm table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th rowSpan={2} style={{minWidth: '150px'}}>Project Role</th>
                                        <th colSpan={5}>Project</th>
                                        <th colSpan={4}>Deliverable</th>
                                    </tr>
                                    <tr>

                                        <th>View Sensitive Data</th>
                                        <th>Update Information</th>
                                        <th>Update Status</th>
                                        <th>Waive Phase</th>
                                        <th>Change Phase/Stage</th>
                                        <th>Update Assurance Activity</th>

                                        <th>View</th>
                                        <th>Approve</th>
                                        <th>Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                { appState.roleProjectLevels.map((item) => (
                                        <tr key={uniqid()} style={{ backgroundColor: '#f3f3f3', height: '45px' }}>
                                               <td>{item.roleName}</td>
       
                                               <td className='text-center-both'>
                                                   <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.sensitiveInformationView > 0} disabled={ item.sensitiveInformationView == 2 } onChange={(ev) => onRoleProjectLevelChange(item,'sensitiveInformationView','Sensitive Information View' ,ev.target.checked)}/>
                                               </td>
       
       
                                               <td className='text-center-both'>
                                                   <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.informationUpdate > 0} disabled={ item.informationUpdate == 2 }   onChange={(ev) => onRoleProjectLevelChange(item,'informationUpdate','information Update',ev.target.checked)}/>
                                               </td>
       
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.statusUpdate > 0} disabled={ item.statusUpdate == 2 }  onChange={(ev) => onRoleProjectLevelChange(item,'statusUpdate','Status Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.waiveUpdate > 0} disabled={ item.waiveUpdate == 2 }  onChange={(ev) => onRoleProjectLevelChange(item,'waiveUpdate','Waive Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.phaseChange > 0} disabled={ item.phaseChange == 2 }   onChange={(ev) => onRoleProjectLevelChange(item,'phaseChange','Phase Change',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.assuranceActivityUpdate > 0} disabled={ item.assuranceActivityUpdate == 2 }  onChange={(ev) => onRoleProjectLevelChange(item,'assuranceActivityUpdate','Assurance Activity Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.deliverableView > 0} disabled={ item.deliverableView == 2 }   onChange={(ev) => onRoleProjectLevelChange(item,'deliverableView','deliverable View',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.deliverableApprove > 0}  disabled={ item.deliverableApprove == 2 }  onChange={(ev) => onRoleProjectLevelChange(item,'deliverableApprove','Deliverable Approve',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.deliverableUpdate > 0} disabled={ item.deliverableUpdate == 2 }  onChange={(ev) => onRoleProjectLevelChange(item,'deliverableUpdate','Deliverable Update',ev.target.checked)}/>
                                               </td>
       
                                           </tr>

                                    ))

                                }
                          
                             
                                </tbody>
                            </table>
                        </div>
                        <div className='row'>
                            <div className='col-md-12' style={{ textAlign: 'right' }}>
                            <span id="project-modify" style={{fontStyle:'italic',color:'grey'}}></span>
                            </div>
                        </div>
                        
                        <div className="row" style={{marginTop:'30px'}}>
                            <div className="col-sm-6">
                                <h6 style={{ fontWeight: "bold", marginBottom:"0px" }} >System Level</h6> (The System Role can be assigned in "user" menu)
                            </div>
                            <div className='col-sm-6' style={{textAlign:'right'}}>
                            { user.administratorUpdate > 0 ?
                                <button id="save-system-btn" type="button" className="btn btn-sm bg-dark-blue" style={{width:'150px'}} onClick={saveSystemLevel} disabled={appState.historySystemLevelLog.length === 0}>
                                    <AiOutlineSave size={20} />
                                    <span style={{ marginLeft: '5px'}}>Save</span>
                                </button>
                                :<></>
                            }
                            </div>
                        </div>
                        <div className="table-responsive table-responsive-sm">
                                <table className="table table-sm table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th rowSpan={2} style={{minWidth: '100px'}}>System Role</th>
                                            <th colSpan={6}>All Project</th>
                                            <th colSpan={4}>All Deliverable</th>
                                            <th colSpan={2}>All Administrator Function</th>
                                            <th colSpan={2}>User Management</th>
                                        </tr>
                                        <tr>
                        
                                            <th>View</th>
                                            <th>View Sensitive Data</th>
                                            <th>Create</th>
                                            <th>Update Information</th>
                                            <th>Update Status</th>
                                            <th>Change Phase/Stage</th>
                                            <th>Update Assurance Activity</th>
                        
                        
                                            <th>View</th>
                                            <th>Approve</th>
                                            <th>Update</th>

                                            <th>View</th>
                                            <th>Manage</th>

                                            <th>View</th>
                                            <th>Update</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                { appState.roleSystemLevels.map((item) => (
                                        <tr key={uniqid()} style={{ backgroundColor: '#f3f3f3', height: '45px' }}>
                                               <td>{item.roleName}</td>
       
                                               <td className='text-center-both'>
                                                   <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.informationView > 0}  disabled={ item.informationView == 2 } onChange={(ev) => onRoleSystemLevelChange(item,'informationView','Information View',ev.target.checked)}/>
                                               </td>
       
       
                                               <td className='text-center-both'>
                                                   <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.sensitiveInformationView > 0}  disabled={ item.sensitiveInformationView === 2 || item.roleSystemLevelId === 1 } onChange={(ev) => onRoleSystemLevelChange(item,'sensitiveInformationView','Sensitive Information View',ev.target.checked)}/>
                                               </td>
       
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.projectCreate > 0}  disabled={ item.projectCreate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'projectCreate','Project Create',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.informationUpdate > 0}  disabled={ item.informationUpdate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'informationUpdate','Information Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.statusUpdate > 0}  disabled={ item.statusUpdate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'statusUpdate','Status Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.phaseChange > 0}  disabled={ item.phaseChange == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'phaseChange','Phase Change',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.assuranceActivityUpdate > 0}  disabled={ item.assuranceActivityUpdate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'assuranceActivityUpdate','Assurance Activity Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.deliverableView > 0}  disabled={ item.deliverableView == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'deliverableView','Deliverable View',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.deliverableApprove > 0}  disabled={ item.deliverableApprove == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'deliverableApprove','Deliverable Approve',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.deliverableUpdate > 0}  disabled={ item.deliverableUpdate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'deliverableUpdate','Deliverable Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.administratorView > 0}   disabled={ item.administratorView == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'administratorView','Administrator View',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.administratorUpdate > 0}  disabled={ item.administratorUpdate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'administratorUpdate','administrator Update',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.userManagementView > 0}  disabled={ item.userManagementView == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'userManagementView','User Management View',ev.target.checked)}/>
                                               </td>
                                               <td className='text-center-both'>
                                                    <input className="form-check-input" type="checkbox" value={1} defaultChecked={item.userManagementUpdate > 0}  disabled={ item.userManagementUpdate == 2 || item.roleSystemLevelId === 1} onChange={(ev) => onRoleSystemLevelChange(item,'userManagementUpdate','User Management Update',ev.target.checked)}/>
                                               </td>
                                           </tr>

                                    ))

                                }
                                </tbody>
                            </table>
            
           
                        </div>
                        <div className='row'>
                            <div className='col-md-12' style={{ textAlign: 'right' }}>
                            <span id="system-modify" style={{fontStyle:'italic',color:'grey'}}></span>
                            </div>
                        </div>
                    </>:<Loading text="loading..."/>
                    }

                        <div className='row' style={{ marginBottom: '100px'}}>
                            <div className='col-md-12' style={{ textAlign: 'center'}}>
                               
                            </div>
                         </div>
                    </div>
                </div>
            </div>

            <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}}>
                        <div className="alert alert-success" role="alert" style={{marginBottom: 0}}>
                            <AiOutlineCheckCircle color="green" size={25}/>
                            <span style={{marginLeft:'10px',color:'green'}}>Success</span>
                        </div>
                    </Modal.Body>
                </Modal>
        </div>
    );
  
}

export default AdminRole