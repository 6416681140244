import axios from 'axios'
import qs from 'qs'
import { PageDataResponse, DataArrayResponse,DataObjectResponse  ,PageResponse } from '../models/PageResponse'
import { UploadFile,ProjectDeliverableItemFile,UploadUrl,DeleteData } from '../models'
import { config } from '../config'
import { DateHelper } from '../helpers'
import { CookieHelper } from '../helpers'

const apiUrl = config.API_URL

interface FileResponse {
    success: boolean
    data: string,
    message: string | null
}

export default class FileManageService
{
    protected headers = {}
    protected token = ""
    protected headerFile = {}
    

    constructor(token:string | null){
        if(token){
            this.token = token
            this.headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }

            
        this.headerFile = {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
        }
        }

    }

    async getFiles(projectNo:string,phase:string,stage:string,deliverable:string){
        if(!this.token) return null

        let options = {
            headers:this.headers,
            params: {
                projectNo: projectNo,
                phase:phase,
                stage:stage,
                deliverable:deliverable
            }
        }
        const { data }  = await axios.get<PageResponse>(`${apiUrl}/v1/filemanagement/call`,options )
        return data;
    }

    async getManualFile(){
        if(!this.token) return null

        let options = {
            headers:this.headers,
        }
        const { data }  = await axios.get<DataObjectResponse<string>>(`${apiUrl}/v1/filemanagement/manual`,options )
        return data;
    }
    
    async downloadFile(directory:string, filename:string){
        var url = `${apiUrl}/v1/filemanagement/download?directory=` + encodeURI(directory) + '&filename=' + encodeURI(filename)
        var request = new XMLHttpRequest();
        request.onload = function(e) {
            if (this.status === 200) {
                if(this.response.type == "application/octet-stream"){
                    var blob = this.response;
                    
                        var downloadLink = window.document.createElement('a');
                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader? contentTypeHeader : "" }));
                        downloadLink.download = filename;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    
                    
                }
                
            }
            else{
                //console.log("File not found.")
              
            }
           
        };
        request.open('GET', url);
        request.setRequestHeader("Authorization", `Bearer ${this.token}`);
       // request.setRequestHeader('Access-Control-Allow-Origin', '*');
        request.responseType = 'blob';
        request.send();
    }

    async downloadZipFile(directory:string){
        var url = `${apiUrl}/v1/filemanagement/downloadzip?directory=` + directory
        var request = new XMLHttpRequest();
        request.onload = function(e) {
            if (this.status === 200) {
                //console.log()
                if(this.response.type == "application/octet-stream"){
                    var blob = this.response;
                    
                        var downloadLink = window.document.createElement('a');
                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader? contentTypeHeader : "" }));
                        downloadLink.download = "pttep_all_documents_" + DateHelper.getDateName(new Date) + ".zip";
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                } 
            }
            else{
                //console.log("File not found.")
              
            }
           
        };
        request.open('GET', url);
        request.setRequestHeader("Authorization", `Bearer ${this.token}`);
       // request.setRequestHeader('Access-Control-Allow-Origin', '*');
        request.responseType = 'blob';
        request.send();
    }

    async downloadManual(filename:string){
        var url = `${apiUrl}/v1/filemanagement/download/manual`
        var request = new XMLHttpRequest();
        request.onload = function(e) {
            if (this.status === 200) {
                if(this.response.type == "application/octet-stream"){
                    var blob = this.response;
                    
                        var downloadLink = window.document.createElement('a');
                        var contentTypeHeader = request.getResponseHeader("Content-Type");
                        downloadLink.href = window.URL.createObjectURL(new Blob([blob], { type: contentTypeHeader? contentTypeHeader : "" }));
                        downloadLink.download = filename;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    
                    
                }
                
            }
            else{
                //console.log("File not found.")
              
            }
           
        };
        request.open('GET', url);
        request.setRequestHeader("Authorization", `Bearer ${this.token}`);
       // request.setRequestHeader('Access-Control-Allow-Origin', '*');
        request.responseType = 'blob';
        request.send();
    }

    async uploadUrl(uploadUrl:UploadUrl){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        //console.log(uploadUrl)
        let options = {
            headers:this.headers
        }
        const { data }  = await axios.post<DataArrayResponse<ProjectDeliverableItemFile>>(`${apiUrl}/v1/filemanagement/url`,JSON.stringify(uploadUrl),options )
        return data;
    }

    async uploadFiles(formData){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<ProjectDeliverableItemFile>>(`${apiUrl}/v1/filemanagement/files`,formData,options )
        return data;
    }

    async uploadProgressFile(formData){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<ProjectDeliverableItemFile>>(`${apiUrl}/v1/filemanagement/progress/file`,formData,options )
        return data;
    }

    async uploadUserManualFile(formData){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headerFile
        }
        const { data }  = await axios.post<DataArrayResponse<ProjectDeliverableItemFile>>(`${apiUrl}/v1/filemanagement/manual/file`,formData,options )
        return data;
    }

    async deleteUrl(id: number,updateBy:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers,
        }
        let deleteData:DeleteData = {
            id: id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<DataArrayResponse<ProjectDeliverableItemFile>>(`${apiUrl}/v1/filemanagement/delete/url`,JSON.stringify(deleteData),options )
        return data;
    }

    async deleteFile(id: number,updateBy:string){
        if(!this.token) return null

        let xsrfToken = await CookieHelper.getCookie(this.token)
        this.headers['X-XSRF-TOKEN'] = xsrfToken
        let options = {
            headers:this.headers
        }
        let deleteData:DeleteData = {
            id:id,
            updateBy:updateBy
        }
        const { data }  = await axios.post<DataArrayResponse<ProjectDeliverableItemFile>>(`${apiUrl}/v1/filemanagement/delete/file`,JSON.stringify(deleteData) ,options )
        return data;
    }

}