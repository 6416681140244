import React, { useState,useEffect ,useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { AiOutlineDelete,AiOutlineEdit,AiOutlineSearch,AiOutlineCloseCircle } from "react-icons/ai";
import { RiRefreshLine,RiErrorWarningLine } from "react-icons/ri";
import ReactPaginate from 'react-paginate';
import { VscAdd } from "react-icons/vsc";
import uniqid from 'uniqid';
import { DeliverableItemService ,HistoryLogService} from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { DeliverableItem ,PageEvent,AuthenUser,SelectItem } from '../../models';
import { Color } from '../../enum';
import Loading from '../Loading'
import { DateHelper } from '../../helpers';
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    items:DeliverableItem[]
    isLoading:boolean
}

const AdminDeliverableItem = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const deliverableItemService = new DeliverableItemService(accessToken)
    const [deliverableItem,setDeliverableItem] = useState<DeliverableItem>({ deliverableItemId : 0, deliverableItemName: "", updateDateTime:new Date(),updateBy: ""})
    const [isLoading,setIsLoading] = useState(true)
    const [isSearch,setIsSearch] = useState(false)
    const [isAddEdit, setIsAddEdit] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        items:[],
        isLoading:true
    })
    const [selectItem,setSelectItem] = useState({deliverableItemName: ""})
    const [errors,setErrors] = useState<string[]>([])

    useEffect(() => {
        if(accessToken){
            getItems(1, appState.limit);
        }
     
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let currentPage = event.selected + 1
        await getItems(currentPage, appState.limit);
        
    }

    const getItems = async(currentPage: number,pageLimit:number,  key:string = "") => {
      
        let result =await deliverableItemService.getAll(currentPage,pageLimit,key);
        let state = Object.assign({},appState)
        //console.log(result)
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state.limit = limit
            state.total = total
            state.page = page
            state.totalPgae = Math.ceil(total / limit)
            state.items = items
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const onSearchItem = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new DeliverableItemService(token).Search(key);
        //console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectItem = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ deliverableItemName: item.value})
          }
         
      }


      const searchDeliverableItem = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({deliverableItemName: inputSearch.value})
                key = inputSearch.value
            }
       
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)

        setIsSearch(true)
        getItems(1,appState.limit,key)
    }

    const resetDeliverableItem= () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectItem({deliverableItemName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setIsSearch(false)
        getItems(1,appState.limit)
    }


    const addItem = () => {
        let item:DeliverableItem = {
            deliverableItemId: 0,
            deliverableItemName: "",
            updateDateTime: new Date(),
            updateBy: account && account.name ? account.name : ""
        }
        setDeliverableItem(item)
        setIsAddEdit(true)
    }

    const editItem = (item:DeliverableItem) => {
        setDeliverableItem(item)
        setIsAddEdit(true)
    }

    const confirmItem = (item:DeliverableItem) => {
        setDeliverableItem(item)
        setIsConfirm(true)
    }

    const saveItem = async () => {
        let result:any = null
        let _deliverableItem = Object.assign({},deliverableItem)
        let inputItem =document.getElementById('input-item') as HTMLInputElement

        if(!inputItem.value){
            return
        }

        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true

        try{
            _deliverableItem.deliverableItemName = inputItem.value
            //console.log(_deliverableItem)
            if(_deliverableItem.deliverableItemId == 0){
                result = await deliverableItemService.Create(_deliverableItem)
            }
            else{
                result = await deliverableItemService.Update(_deliverableItem)

            }
            if(result.success){
                saveBtn.disabled = false
                if(_deliverableItem.deliverableItemId == 0){
                    let token = await getToken()
                    new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Item",'Create item "' + _deliverableItem.deliverableItemName + '"')
                }
                else{
                    let token = await getToken()
                    new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Item",'Update item "' + _deliverableItem.deliverableItemName + '"')
                }
                setIsAddEdit(false)
                getItems(1,appState.limit)
            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            saveBtn.disabled = false
           setErrors([err.message])
            
        }
    }

    const deleteItem = async() => {
        try{
            let updateBy = account && account.name ? account.name : ""
            let result = await deliverableItemService.Delete(deliverableItem.deliverableItemId,updateBy)
            //console.log(result)
            
            if(result.success){
                let token = await getToken()
                new HistoryLogService(token).create(user.userId, null,"Admin Deliverable Item",'Delete item "' + deliverableItem.deliverableItemName + '"')
                setIsConfirm(false)
                getItems(1,appState.limit)
            }
            else{
                setIsConfirm(false)
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            setErrors([err.message])
            
        }
        
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('user-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        let limit = parseInt(ev.target.value)
        getItems(1,limit,key)
    }

    return (
        <div className="container-fluid">
           <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                   <div className="row">
                        <div className="col-sm-6">
                            <span className='project-label'>Deliverable Item</span>
                        </div>
                    </div>
           
                    <div className='row' style={{ marginTop: '10px'}}>
                    <div className="col-md-4">
                        <div className="input-group">
                        <TextboxAutocomplete key={uniqid()}  valueKey="deliverableItemName" onChange={onSearchItem} onSelect={onSelectItem} elementId='input-search' initialValue={selectItem.deliverableItemName} hiddenArrow={true}/>
                        </div>
                    </div>
                    <div className='col-md-1'>
                            <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}} onClick={() => searchDeliverableItem()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}}/>
                            </button>
                    </div>
                    <div className='col-md-1'>
                    { isSearch ?
                                 <span className="input-group-append" style={{cursor:"pointer"}} onClick={resetDeliverableItem}>
                                     <RiRefreshLine size={20} color={Color.Blue}/>
                                     <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}}>Reset</span>
                                 </span>:null
                             } 
                    </div>
                        <div className='col-md-6' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}}>
                            <div className="admin-action">
                            { user.administratorUpdate > 0 ?
                                <span className='app-active' style={{ cursor: 'pointer'}} onClick={addItem}>
                                    <VscAdd size={20} color={Color.Blue} />
                                    <span style={{ fontSize: '14px',marginLeft:'2px'}}>Add Deliverable Item</span>
                                </span>
                                :<></>
                            }
                            </div> 
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: '10px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            <select value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                                <option value={10}>10</option>
                                <option value={25}>25</option> 
                                <option value={50}>50</option>
                                <option value={100}>100</option>        
                            </select>
                        </div>
                    </div>
            { !appState.isLoading ?
                <>
                    <div className="table-responsive table-responsive-sm">
                        <table className="table table-sm table-bordered table-hover" >
                            <thead>
                                <tr>
                                    <th style={{width: '80px'}}>No.</th>
                                    <th>Deliverable Item</th>
                                    <th>Modified By</th>
                                    <th>Modified Date</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    appState.items.map((item,index) =>(
                                        <tr key={uniqid()}  style={{height: '45px',textAlign:'left',paddingLeft:'20px', backgroundColor: (index%2) ? '#f3f3f3' : ''}}>
                                            <td>
                                            {((appState.page -1) * appState.limit) + index + 1}
                                            </td>   
                                            <td style={{textAlign:'left',paddingLeft:'20px'}}>
                                                <span>{ item.deliverableItemName }</span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span>{ item.updateBy }</span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span>{item.updateDateTime ? DateHelper.getDate(item.updateDateTime) : "" }</span>
                                            </td>
                                            <td className='text-center-both'>
                                                { user.administratorUpdate > 0 ?
                                                    <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                                    :<></>
                                                }
                                            </td>
                                            <td>
                                                { user.administratorUpdate > 0 ?
                                                    <AiOutlineDelete size={20} color={Color.Red2} cursor="pointer" onClick={ () => confirmItem(item)}/>
                                                    :<></>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                 }
                            </tbody>
                        </table>
                    </div>

                    <div className='row'>
                        <div className='col-md-4' style={{ display: 'flex'}}>
                     
                        </div>
                        <div className='col-md-8'>
                            <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={appState.totalPgae}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                onPageChange={handlePageClick}
                                forcePage={appState.page - 1}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active-gradient"
                            />
                            </div>
                        </div>     
                    </div>
                </>: <Loading text="Loading..."/>
            }

            <div className='row' style={{ marginTop: '30px'}}>
                <div className='col-md-12'></div>
            </div>
                    <Modal show={isAddEdit} onHide={() => setIsAddEdit(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                            
                                { deliverableItem.deliverableItemId ? 'Update Deliverable Item':  'Add Deliverable Item' }
                                <span style={{marginRight:'5px',cursor:'pointer',float:'right'}} onClick={() => setIsAddEdit(false)}>X</span>
                         
                        </Modal.Header>
                        <Modal.Body style={{ textAlign: 'center'}}>
                            <div className='row'>
                                <div className="col-sm-1 col-label">
                            
                                </div>
                                <div className="col-sm-10">
                                    <label  className="form-label col-label" style={{ float:'left'}}>Deliverable Item:</label>
                                    <input id="input-item" defaultValue={deliverableItem.deliverableItemName} type="text"  className="form-control form-control-sm " />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-12 text-center-both'>
                                    <button id="save-btn" type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}} onClick={saveItem}>Save</button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal show={isConfirm} onHide={() => setIsConfirm(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                            Confirm Message
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setIsConfirm(false)}>X</span>
                        </Modal.Header>
                         <Modal.Body style={{ textAlign: 'center'}}>
                            <div>
                                <RiErrorWarningLine size={25} color={'red'} />
                                <span style={{ marginLeft: '2px', fontWeight: 'bold', color: 'red' }}> Would you like to  delete {deliverableItem.deliverableItemName}?</span>
                            </div>
                            <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => deleteItem()} >
                                <span style={{ marginLeft: '5px'}} >Yes</span>
                            </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setIsConfirm(false)} >
                                <span style={{ marginLeft: '5px' }} >No</span>
                            </button>
                        </Modal.Body>
                    </Modal>

                    <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}} >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                </div>
            </div> 
        </div>
    );
}

export default AdminDeliverableItem