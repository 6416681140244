import React, { useState,useEffect  } from 'react';
import DashboardTable  from './DashboardTable'
import DashboardChart from './DashboardChart' 
import { BiWorld } from "react-icons/bi";
import { GiOilPump,GiFactory,GiOilRig } from "react-icons/gi";
import { RiErrorWarningLine } from "react-icons/ri";
import { useApiAuth } from '../hooks/useApiAuth';
import { PropertyService } from '../services'
import { ProjectMode } from '../models/Property'
import '../assets/css/tab.css'
import uniqid from 'uniqid';
import {  Color } from '../enum'

interface AppState {
    projectModeId:number
    projectModes:ProjectMode[]
    categorySizeId: number
    phaseNumber: number
    projectStatusId:number
    registerDate:number
    activeDate:number
    spi:number
    bpi:number
    tab:number
    isLoading:boolean
}

const Dashboard = () => {
    const {accessToken } = useApiAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const [appState,setAppState] = useState<AppState>({
        projectModeId: 0,
        projectModes: [],
        categorySizeId: 0,
        phaseNumber: 0,
        projectStatusId:0,
        registerDate: 0,
        activeDate:0,
        spi:0,
        bpi:0,
        tab:0,
        isLoading: true
    })
    // const projectId:number =  (id) ? parseInt(id) : 0
    const [tab,setTab] = useState(1)
    
    useEffect(() => {
        if(accessToken)
        {
            let projectModeParam = urlParams.get('projectMode')
            let categorySizeParam = urlParams.get('categorySize')
            let phaseParam = urlParams.get('phase')
            let projectStatusParam = urlParams.get('projectStatus')
            let registerDateParam = urlParams.get('registerDate')
            let activeDateParam = urlParams.get('activeDate')
            let spiParam = urlParams.get('spi')
            let bpiParam = urlParams.get('bpi')

            new PropertyService(accessToken).getProperties(['projectMode']).then((result) => {
                
                let state = Object.assign({},appState)
                if(result && result.success){
                    state.projectModes = result.data.projectModes
                }
                
                state.tab = state.projectModeId = projectModeParam ? parseInt(projectModeParam) : 0
                state.categorySizeId = categorySizeParam ? parseInt(categorySizeParam) : 0
                state.phaseNumber = phaseParam ? parseInt(phaseParam) : 0
                state.projectStatusId = projectStatusParam ? parseInt(projectStatusParam) : 1
                state.registerDate = registerDateParam ? parseInt(registerDateParam) : 0
                state.activeDate = activeDateParam ? parseInt(activeDateParam) : 0
                state.spi = spiParam ? parseInt(spiParam) : 0
                state.bpi = bpiParam ? parseInt(bpiParam) : 0
                state.isLoading = false
                setAppState(state)
            })
        }
        
            //setTab(2)
            window.addEventListener("scroll", updatePosition);

            updatePosition()
        
        return () => window.removeEventListener("scroll", updatePosition);
    },[accessToken])

    const updatePosition = () => {
        let pos = window.pageYOffset;
       
        if(pos > 20){
            document.getElementById('dashboard-menu')?.classList.add('active')
        }
        else{
            document.getElementById('dashboard-menu')?.classList.remove('active')
        }

    }

    const onTabChange = (tab:number) => {
        let state = Object.assign({},appState)
       
        state.categorySizeId = 0
        state.phaseNumber = 0
        state.tab = tab
        setAppState(state)
        
    }


    return (
        <div className="container-fluid">
           <nav className="nav-menu" aria-label="breadcrumb">
                
                <ol className="breadcrumb breadcrumb-bottom" style={{ border:'1px solid #ccc' }}>
                 
                    <li className="breadcrumb-item" aria-current="page" style={{ color: 'white'}}>
                        Portfolio Dashboard
                    </li>
                    { !appState.isLoading ? 
                        <li className="breadcrumb-item" aria-current="page">
                            { appState.tab > 0 ? 
                                <span>{ appState.projectModes[appState.tab -1 ].projectModeName }</span> 
                                : 
                                appState.tab == 0 ? <span>Overall</span> : <span>E&P Modification</span> 
                            }
                        </li>:null
                    }
                </ol>
                
            </nav>
        { !appState.isLoading ? 
            <div id="dashboard-menu" className='tabs dashboard-menu' >
                
                        <input type="radio" id="tab1" name="tab-control" checked={appState.tab === 0 ? true: false} onChange={() => onTabChange(0)}/>
                        <input type="radio" id="tab2" name="tab-control" checked={appState.tab === 1 ? true: false} onChange={() => onTabChange(1)}/>
                        <input type="radio" id="tab3" name="tab-control" checked={appState.tab === -1 ? true: false} onChange={() => onTabChange(-1)}/>
                        <input type="radio" id="tab4" name="tab-control" checked={appState.tab === 2 ? true: false} onChange={() => onTabChange(2)}/>
                        <input type="radio" id="tab5" name="tab-control" checked={appState.tab === 3 ? true: false} onChange={() => onTabChange(3)}/>
                        <ul>
                            <li>
                                <label htmlFor="tab1" role="button">
                                    <BiWorld fontSize={30} style={{ color : tab === 1 ? Color.Blue : ''}}/>
                                    <br/>
                                    <span className='dashboard-topic'>Overall</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="tab2" role="button">
                                    <GiOilPump fontSize={30}/>
                                    <br/>
                                    <span className='dashboard-topic'>{ appState.projectModes[0].projectModeName }</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="tab3" role="button">
                                    <GiOilRig fontSize={30}/>
                                    <br/>
                                    <span className='dashboard-topic'>E&P Modification</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="tab4" role="button">
                                    <GiFactory fontSize={28}/>
                                    <br/>
                                    <span className='dashboard-topic'>{ appState.projectModes[1].projectModeName }</span>
                                </label>
                            </li>
                            <li>
                                <label htmlFor="tab5" role="button">
                                    <GiOilPump fontSize={30}/>
                                    <br/>
                                    <span className='dashboard-topic'>{ appState.projectModes[2].projectModeName }</span>
                                </label>
                            </li>
                        </ul>
                        <div className="slider" style={{ width: '20%'}}>
                            <div className="indicator"></div>
                        </div>
            </div>:null
        }
            
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                    <div className="tabs">
                        <div id="content" className="content" style={{ marginTop: '86px',minHeight: '400px'}}>
                            <section style={{ display : appState.tab === 0 ?  'inline': 'none'}}>
                                { appState.tab === 0 ? 
                                    <>
                                        <DashboardChart key={uniqid()} projectModeId={0}/> 
                                        <DashboardTable 
                                            key={uniqid()} 
                                            projectModeId={0} 
                                            categorySizeId={appState.categorySizeId}  
                                            phaseNumber={appState.phaseNumber} 
                                            projectStatusId={appState.projectStatusId}
                                            registerDate={appState.registerDate}
                                            activeDate={appState.activeDate}
                                            spi={appState.spi}
                                            bpi={appState.bpi}
                                        /> 
                                    </>: null 
                                }
                            </section>
                            <section style={{ display : appState.tab === 1 ?  'inline': 'none'}}>
                                { appState.tab === 1 ? 
                                    <>
                                        <DashboardChart key={uniqid()} projectModeId={1}/> 
                                        <DashboardTable 
                                            key={uniqid()} 
                                            projectModeId={1} 
                                            categorySizeId={appState.categorySizeId}  
                                            phaseNumber={appState.phaseNumber} 
                                            projectStatusId={appState.projectStatusId}
                                            registerDate={appState.registerDate}
                                            activeDate={appState.activeDate}
                                            spi={appState.spi}
                                            bpi={appState.bpi}
                                        /> 
                                    </>: null 
                                }
                            </section>
                            <section style={{ paddingTop:'40px',  paddingLeft: '80px',display : appState.tab === -1 ?  'inline': 'none'}}>
                                { appState.tab === -1 ? 
                                    <>
                                     <div >
                                        <RiErrorWarningLine size={32} color="grey"/>
                                        <span style={{ color: 'grey',fontWeight: 'bold'}}>To be linked to EPMS System...</span>
                                    </div>
                                    </>: null 
                                }
                            </section>
                            <section style={{ display : appState.tab === 2 ?  'inline': 'none'}}>
                                { appState.tab === 2 ? 
                                    <>
                                        <DashboardChart key={uniqid()} projectModeId={2}/> 
                                        <DashboardTable 
                                            key={uniqid()} 
                                            projectModeId={2} 
                                            categorySizeId={appState.categorySizeId}  
                                            phaseNumber={appState.phaseNumber} 
                                            projectStatusId={appState.projectStatusId}
                                            registerDate={appState.registerDate}
                                            activeDate={appState.activeDate}
                                            spi={appState.spi}
                                            bpi={appState.bpi}
                                        /> 
                                    </>: null 
                                }
                            </section>
                            <section style={{ display : appState.tab === 3 ?  'inline': 'none'}}>
                                { appState.tab === 3 ? 
                                    <>
                                        <DashboardChart key={uniqid()} projectModeId={3}/> 
                                        <DashboardTable 
                                            key={uniqid()} 
                                            projectModeId={3} 
                                            categorySizeId={appState.categorySizeId}  
                                            phaseNumber={appState.phaseNumber} 
                                            projectStatusId={appState.projectStatusId}
                                            registerDate={appState.registerDate}
                                            activeDate={appState.activeDate}
                                            spi={appState.spi}
                                            bpi={appState.bpi}
                                        /> 
                                    </>: null 
                                }
                            </section>
                           
                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
    );
  
}

export default Dashboard
