import React, { useEffect, useState } from 'react';
import { Project, Phase } from '../../models'
import { AiOutlineInfoCircle, AiOutlineSearch } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal'
import uniqid from 'uniqid';
import { Color } from '../../enum'
import { config } from '../../config';

const PhaseSelection = (props) => {
    const [phaseId,setPhaseId]= useState(props.phaseId)
    const [phases,setPhases]= useState<Phase[]>([])
    const [phaseTemp,setPhaseTemp]= useState<Phase>()
    const [isFlowShow, setIsFlowShow] = useState(false);
    const [isConfirmUpdate,setIsConfirmUpdate] = useState(false)
    const [flowNumber, setFlowNumber] = useState(0);

    useEffect(() => {
        setPhaseId(props.phaseId)
        setPhases(props.phases)

        var beforePrint = function() {
            props.phases.forEach((item) => {
                let element = document.getElementById('tab-phase-' + item.phaseId) as HTMLButtonElement
                if(element && !element.classList.contains('active')){
                    element.style.display = 'none'
                }
            })
        };
    
        var afterPrint = function() {
            props.phases.forEach((item) => {
                let element = document.getElementById('tab-phase-' + item.phaseId) as HTMLButtonElement
                if(element){
                    element.style.display = 'inline'
                }
            })
        };

        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');

            mediaQueryList.addListener(function (mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
            
         }
    },[])

    const imageFlowShow = (_flowNumber:number) => {
        console.log(_flowNumber)
        setFlowNumber(_flowNumber)
        setIsFlowShow(true)
    }

    const confirmPhaseChange = () => {
        if(phaseTemp){
            setIsConfirmUpdate(false)
            setPhaseId(phaseTemp.phaseId)
            if(props.onChange){
                props.onChange(phaseTemp)
            }
        }
           
    }

    const onPhaseChange = (_phase:Phase) => {
        if(phaseId === _phase.phaseId){
            return
        }
        //console.log(props.isUpdate)
        if(props.isUpdate){
            setPhaseTemp(_phase)
            setIsConfirmUpdate(true)
        }
        else{
            setPhaseId(_phase.phaseId)
            if(props.onChange){
                props.onChange(_phase)
            }
        }
    }

    return (
        <div className='row' style={{ marginBottom : '0'}}>
            <div style={{ marginTop : '10px',minHeight: '43px'}}>
            {   phases.map((_phase) => (
                    <button key={uniqid()} id={'tab-phase-' + _phase.phaseId} className={phaseId !== _phase.phaseId ? 'tab-btn btn-phase-tab' :  'tab-btn btn-phase-tab active'}  onClick={ () => onPhaseChange(_phase) } >
                        <span className='phase-name-full'>{_phase.phaseName}</span>
                        <span className='phase-name-mini'>Phase {_phase.phaseNumber}</span>
                        <AiOutlineInfoCircle className='phase-name-full' size={20} cursor="pointer" onClick={() => imageFlowShow(_phase.phaseNumber)} style={{ marginLeft: '5px' }} />
                    </button>
                ))
            }
            </div>
            <div id={ 'phase-'+ phaseId}>
               
            </div>

            <Modal show={isFlowShow} onHide={() => setIsFlowShow(false)} size="xl">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                    { 
                    <img src={config.API_URL + '/v1/images/phase/' + props.projectModeId.toString() + "/" + flowNumber.toString()} style={{ width: '100%'}}/>
                    }
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal show={isConfirmUpdate} onHide={() => setIsConfirmUpdate(false)} size="lg" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => setIsConfirmUpdate(false)}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}>Data has change without saving, Would you like to change phase?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => confirmPhaseChange()} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => setIsConfirmUpdate(false)} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PhaseSelection