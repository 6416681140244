import axios from 'axios'
import { config } from '../config'


const apiUrl = config.API_URL

export default class ApiAzureService
{
    async getClientId(){
       
        
        const { data }  = await axios.get(`${apiUrl}/v1/az/clientId` )
        
        return data;
    }
}