import React,{useState, useEffect, useRef } from 'react';
import PhaseSelection from '../ComponentTools/PhaseSelection';
import {  ProjectService,ProjectProgressService,GraphApiService,FileManageService,HistoryLogService,EmailService,ProjectPhaseService} from '../../services'
import {  Phase ,DeliverableTemplate,ProjectPhase,ProjectPhaseRole, 
        CurrentUpdate,PhaseUpdate,WaiveUpdate,Item,UserItem, ProjectProgress,AuthenUser,RoleProjectLevel,PageEvent
     } from '../../models'
import { AiOutlineInfoCircle,AiOutlineCloseCircle,AiOutlineCheckCircle,AiOutlinePlusCircle,AiOutlineEdit,AiOutlineSave } from "react-icons/ai";
import { useApiAuth } from '../../hooks/useApiAuth';
import { RiErrorWarningLine,RiDeleteBin6Line } from "react-icons/ri";
import { VscRefresh } from "react-icons/vsc";
import {BsFilter} from "react-icons/bs"
import Loading from '../Loading';
import { FaCircle } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal'
import DatePicker from "react-datepicker";
import uniqid from 'uniqid';
import { Color,PhaseName,ProjectLevel,SystemLevel } from '../../enum'
import { DateHelper, NumHelper ,StringHelper}  from '../../helpers'
import { Picker } from "../picker";
import { config } from '../../config'
import ReactTooltip from "react-tooltip";
import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    phaseId:number
    phaseNumber:number
    projectPhase:ProjectPhase
    projectProgressMaster:ProjectProgress[]
    projectProgresses:ProjectProgress[]
    projectProgress:ProjectProgress| null
    tableProgress:ProjectProgress[]
    tableIndex: number,
    tablePerpage: number,
    currentDeliverableTemplateId:number
    deliverableTemplates:DeliverableTemplate[]
    deliverableTemplateId:number
    projectManagers:Item[]
    secretaries:Item[]
    focalpoints:Item[]
    progressDate:Date,
    trackBudget:number,
    trackProgress:number
    lastPhaseId:number
    isReloadTemplate:boolean,
    isUpdate:boolean
    isCurrentPhaseChange:boolean,
    isDocumentTemaplateChange:boolean,
    isLoading:boolean
}

interface Props {
    projectId:number
    projectName:string
    projectModeId:number 
    phaseId:number 
    phases:Phase[] 
    user:AuthenUser
    projectOwners:UserItem[]
    projectRole:RoleProjectLevel | null
    projectPhases:ProjectPhase[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectProgresses:ProjectProgress[]
    deliverableTemplates:DeliverableTemplate[]
    currentdate: Date
    onChange:(projectPhase:ProjectPhase, projectPhases:ProjectPhase[],projectPhaseRoles:ProjectPhaseRole[],projectProgresses:ProjectProgress[]) => {}
}

const StatusUpdate = (props:Props) => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const projectId = props.projectId
    const [appState,setAppState] = useState<AppState>()
    const [startDate,setStartDate] = useState<Date>()
    const [endDate,setEndDate] = useState<Date>()
    const [isShow,setIsShow] = useState(false)
    const [isFilter,setIsFilter] = useState(false)
    const [isFilterLoad,setIsFilterLoad] = useState(false)
    const [isSuccess,setIsSuccess] = useState(false)
    const [isConfirm, setIsconfirm] = useState(false);
    const [error,setError] = useState("")
    const [errors,setErrors] = useState<string[]>([])
    const [documentTemplateBackgroundColor, setDocumentTemplateBackgroundColor] = useState("")
    const [focalPointsBackgroundColor, setFocalPointsBackgroundColor] = useState("")
    const spanFocalPointMessage = useRef<HTMLSpanElement>(null)
    const selectDocumentTemplate = useRef<HTMLSelectElement>(null)
    const spanDocumentTemplateMessage = useRef<HTMLSpanElement>(null)
    const isGatekeeper =  props.projectRole?.roleProjectLevelId == ProjectLevel.GateKeeper ? true : false
    const isSuperAdmin = props.user.roleSystemLevelId == SystemLevel.SuperAdmin ? true : false
    const isCanUpdateStatus = props.user.statusUpdate || props.projectRole?.statusUpdate ? true : false
    const isCanPhaseChange = props.user.phaseChange || props.projectRole?.phaseChange ? true : false
    const isCanWaivePhase = props.projectRole?.waiveUpdate ? true : false
    const errorColor = "#FFDDDD"
  
    useEffect(() => {
        if(accessToken){
            let state = Object.assign({},appState)
            state.isLoading = true
            state.isReloadTemplate = false
            setAppState(state)
            //console.log('Project Role')
            //console.log( props.projectRole)
            new ProjectProgressService(accessToken).getByProjectId(projectId).then((result) => {
               
                let phase:Phase|undefined = props.phases.find(x => x.phaseId == props.phaseId)
                if(phase){
                    onPhaseChange(phase,result.data)
                }
            })
            
        }
        
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const onPhaseChange = async (item:Phase,projectProgressMaster:ProjectProgress[] | null | undefined,retIsUpdate:boolean = false) => {
       
        let state = Object.assign({},appState)
        //console.log( state.projectProgressMaster)
        state.isUpdate = retIsUpdate
        state.isReloadTemplate = false
        if(projectProgressMaster){
            state.projectProgressMaster = projectProgressMaster
        }
        let tmpProjectPhase = props.projectPhases.find(x => x.phaseId === item.phaseId)
        if(tmpProjectPhase){
            let projectPhase = Object.assign({},tmpProjectPhase)
            state.projectPhase = projectPhase
            state.currentDeliverableTemplateId = projectPhase.deliverableTemplateId

            let projectProgresses:ProjectProgress[] = []
            let tableProgress:ProjectProgress[] = []
            if(state.projectProgressMaster){
                projectProgresses = state.projectProgressMaster.filter(x => x.projectPhaseId == projectPhase?.projectPhaseId)
                tableProgress = projectProgresses
            }
           
            if(projectProgresses && projectProgresses.length > 0){
                state.projectProgresses = projectProgresses.sort((a,b) => new Date(a.progressDate).getTime() - new Date(b.progressDate).getTime() )
            }

            state.deliverableTemplates = props.deliverableTemplates.filter(x => x.phaseId == item.phaseId)
            state.projectProgress = null
            state.tableProgress = tableProgress
            state.tableIndex = 0
            state.tablePerpage = 10;
            state.phaseId = item.phaseId
            state.phaseNumber =item.phaseNumber
            state.progressDate = props.currentdate
            state.trackBudget = 1
            state.trackProgress = 1
            state.projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => { let item:Item = { key:p.username,name:p.name}; return item})
            state.focalpoints = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.FocalPoint && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => { let item:Item = { key:p.username,name:p.name}; return item})
            state.secretaries = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.Secretary && x.projectPhaseId === projectPhase?.projectPhaseId).map((p) => { let item:Item = { key:p.username,name:p.name}; return item})
            state.trackBudget = projectPhase.trackBudget
            state.trackProgress = projectPhase.trackProgress

            if(item.phaseNumber === 1 || item.phaseNumber === 5){
                state.trackBudget = 0
                state.trackProgress = 0
            }
            state.isLoading = false
            setAppState(state)
        
        }
    }

    const updateCurrentPhase = async () => {
        if(appState){
            let currentUpdate:CurrentUpdate = {
                projectId: projectId,
                currentPhaseId:  appState.phaseId,
                currentStageId:0,
                updateBy: account && account.name ? account.name : ""
            }
            let token = await getToken()
            let result = await new ProjectService(token).updateCurrent(currentUpdate);
            if(result && result.success){

                let oldPhase:Phase|undefined = props.phases.find(x => x.phaseId == props.phaseId) 
                let oldPhaseName = oldPhase && oldPhase.phaseName ? oldPhase.phaseName : ""
                let phase:Phase|undefined = props.phases.find(x => x.phaseId == appState.phaseId)
                let phaseName = phase && phase.phaseName ? phase.phaseName : ""
                new HistoryLogService(token).create(user.userId, props.projectId,"Project Status Update","Change Current Phase To " + phaseName)
                new EmailService(token).sendPhaseChange(props.projectId,appState.phaseId,props.projectName,oldPhaseName,phaseName)

                
                    window.location.reload()
                

                    // setTimeout(() => { 
                       
                    //     let projectPhase = Object.assign({},appState?.projectPhase)
                    //     projectPhase.phaseId = currentUpdate.currentPhaseId
                    //     props.onChange(projectPhase,props.projectPhases,props.projectPhaseRoles,props.projectProgresses)
                    // }, 500);
                    
            }
            else{
                if(result)
                alert(`fail ,${result.message}`)
    
                return false
            }
        }
       
    }

    const updateProjectPhaseStatus = async () => {
        let saveBtn = document.getElementById('save-phase-btn') as HTMLButtonElement
        if(saveBtn){
            saveBtn.disabled = true
        }

        if(spanFocalPointMessage.current){
            spanFocalPointMessage.current.innerText = "*"
        }
        if(selectDocumentTemplate.current){
            selectDocumentTemplate.current.style.backgroundColor = ""
        }
        if(spanDocumentTemplateMessage.current){
            spanDocumentTemplateMessage.current.innerText = "*"
        }

        if(appState){
            let deliverableTemplateId = appState.projectPhase.deliverableTemplateId
            if(!appState.isReloadTemplate && appState.currentDeliverableTemplateId === appState.projectPhase.deliverableTemplateId){
                deliverableTemplateId = 0
            }

            let phaseUpdate:PhaseUpdate = {
                projectPhaseId: appState.projectPhase.projectPhaseId,
                projectId: appState.projectPhase.projectId,
                phaseId: appState.projectPhase.phaseId,
                deliverableTemplateId: deliverableTemplateId,
                trackBudget: appState.projectPhase.trackBudget,
                trackProgress: appState.projectPhase.trackProgress,
                projectManagers: appState.projectManagers.map((p) => { let userItem:UserItem = { username:p.key, name:p.name}; return userItem}),
                focalPoints : appState.focalpoints.map((p) => { let userItem:UserItem = { username:p.key, name:p.name}; return userItem}),
                secretaries: appState.secretaries.map((p) => { let userItem:UserItem = { username:p.key, name:p.name}; return userItem}),
                updateBy: account && account.name ? account.name : ""
            }

            if(phaseUpdate.focalPoints.length === 0){
                setFocalPointsBackgroundColor(errorColor)
                if(spanFocalPointMessage.current){
                    spanFocalPointMessage.current.innerText = " *Required Field."
                    spanFocalPointMessage.current.scrollIntoView({block: "center"})
                }
                if(saveBtn){
                    saveBtn.disabled = false
                }
                return
            }

            let token = await getToken()
            let result = await new ProjectService(token).updatePhase(phaseUpdate);
            if(result && result.success){
               
                let phase:Phase|undefined = props.phases.find(x => x.phaseId == phaseUpdate.phaseId)
                new HistoryLogService(token).create(user.userId, props.projectId,"Project Status Update","Update Data " + phase?.phaseName)
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    window.location.reload()
                }, 500);

            }
            else{
                if(result)
                alert(`fail ,${result.message}`)
                if(saveBtn){
                    saveBtn.disabled = false
                }
                return false
            }
        }
        
    }

    const updateWaiveStatus = async() => {
        
        ////console.log('update waive')
        if(appState){
            let inputWaivePhaseReason = document.getElementById('waive-reason') as HTMLInputElement
            let waivePhaseReasonMessage = document.getElementById('waive-reason-message') as HTMLSpanElement

            if(inputWaivePhaseReason){
                inputWaivePhaseReason.style.backgroundColor = ""
                waivePhaseReasonMessage.innerHTML = ""
            }
           
            if(appState.projectPhase.waivePhase && appState.projectPhase.waivePhaseReason.length == 0){
                if(inputWaivePhaseReason){
                    inputWaivePhaseReason.style.backgroundColor = "#FFDDDD"
                    waivePhaseReasonMessage.innerHTML = "Required Field."
                }
               
                return false
            }

            let waivePhaseReason = appState.projectPhase.waivePhase === 1 ? appState.projectPhase.waivePhaseReason : ""

            let waiveUpdate:WaiveUpdate = {
                projectPhaseId: appState.projectPhase.projectPhaseId,
                waivePhase: appState.projectPhase.waivePhase,
                waivePhaseReason: waivePhaseReason,
                focalPoints: appState.focalpoints.map((p) => { let userItem:UserItem = { username:p.key, name:p.name}; return userItem}),
                updateBy: account && account.name ? account.name : ""
            }
            //console.log(waiveUpdate)
            let token = await getToken()
            let result = await new ProjectService(token).updateWaive(waiveUpdate);
            if(result && result.success)
            {
                let projectPhase = Object.assign({},appState.projectPhase)
                let phase:Phase|undefined = props.phases.find(x => x.phaseId == projectPhase.phaseId)
                new HistoryLogService(token).create(user.userId,props.projectId,"Project Status Update","Waive " + phase?.phaseName)
                setIsSuccess(true)
                setTimeout(() => {
                    setIsSuccess(false)
                    window.location.reload()
                }, 500);
                // let projectPhases:ProjectPhase[] = Object.assign([],props.projectPhases)
                // let index = projectPhases.findIndex( x => x.projectPhaseId === projectPhase.projectPhaseId)
                // if(index > -1 ){
                //     projectPhases[index].waivePhase = projectPhase.waivePhase = waiveUpdate.waivePhase
                //     projectPhases[index].waivePhaseReason = projectPhase.waivePhaseReason = waiveUpdate.waivePhaseReason
                // }
               
                // setIsSuccess(true)
                // setTimeout(() => { 
                //     props.onChange(projectPhase,props.projectPhases,props.projectPhaseRoles,props.projectProgresses)
                //     setIsSuccess(false) 
                // }, 500);
                
            }
            else{
                if(result)
                alert(`fail ,${result.message}`)

                return false
            }
        }
    }

    const getProgresses = async () => {
        let state = Object.assign([],appState)
        let token = await getToken()
        let result = await new ProjectProgressService(token).getByProjectId(projectId)
        if(result.success && appState){
            state.projectProgressMaster = result.data
            let projectProgresses:ProjectProgress[] = []
            if(state.projectProgressMaster && state.projectProgressMaster.length > 0){
                projectProgresses = state.projectProgressMaster.filter(x => x.projectPhaseId == appState.projectPhase.projectPhaseId)
                if (projectProgresses && projectProgresses.length > 0) {
                    projectProgresses = projectProgresses.sort((a, b) => new Date(a.progressDate).getTime() - new Date(b.progressDate).getTime())
                }
            }
        
            state.projectProgresses = projectProgresses
            state.tableProgress = projectProgresses
            state.tableIndex = 0
            props.onChange(appState.projectPhase,props.projectPhases,props.projectPhaseRoles,projectProgresses)
            setAppState(state)
          
            setTimeout(() => {
                window.scrollTo(0, document.body.scrollHeight - 20);
                // let element = document.getElementById('table-progress') as HTMLTableElement
                // element.scrollIntoView({block: "end"})
            }, 500);
        }
    }
    
    const filterProgress = () => {
       setIsFilterLoad(true)
       setIsFilter(true)
        if(appState && startDate && endDate){
           
            let state = Object.assign([],appState)
            let data = appState.projectProgresses.filter(x => DateHelper.getYearMonth(x.progressDate) >= DateHelper.getYearMonth(startDate) && DateHelper.getYearMonth(x.progressDate) <= DateHelper.getYearMonth(endDate))

            let projectProgresses = appState.projectProgresses
            if (projectProgresses && projectProgresses.length > 0) {
                projectProgresses = projectProgresses.sort((a, b) => new Date(a.progressDate).getTime() - new Date(b.progressDate).getTime())
            }
            appState.projectProgresses = projectProgresses;
            ////console.log(data)
            state.tableProgress = Object.assign([],data)
            setAppState(state)
        }
        setTimeout(() => {
            setIsFilterLoad(false)
        }, 300);
  
       
    }

    const resetProgress = () => {
        setIsFilterLoad(true)
        setIsFilter(false)
        setStartDate(undefined)
        setEndDate(undefined)
         if(appState && startDate && endDate){
             let state = Object.assign([],appState)
             state.tableProgress = Object.assign([],appState.projectProgresses)
             setAppState(state)
         }
         setTimeout(() => {
             setIsFilterLoad(false)
         }, 300);
   
        
     }

    const createProgress = () => {
        if(appState){
            let state = Object.assign({},appState)
            let currentYear = props.currentdate.getFullYear()
            let currentMonth = props.currentdate.getMonth()
            //console.log(state.projectProgresses)
            state.progressDate = props.currentdate
            let projectProgress:ProjectProgress = {
                    projectProgressId:0,
                    projectPhaseId: appState.projectPhase.projectPhaseId,
                    progressDate: props.currentdate,
                    planCost: -1,
                    actualCost: -1,
                    planProgress:-1,
                    actualProgress:-1,
                    costProjection:-1,
                    costCumulative:-1, 
                    activity:"",
                    areaOfConcern:"",
                    attachedFile:"",
                    createBy: account && account.name ? account.name : "",
                    updateBy:""
                }
           
            if(state.projectProgresses && state.projectProgresses.length > 0)
            {
                let projectProgressTmp:ProjectProgress | undefined = state.projectProgresses.find(x => new Date(x.progressDate).getFullYear() == currentYear && new Date(x.progressDate).getMonth() == currentMonth && x.projectPhaseId == appState.projectPhase.projectPhaseId)
                //console.log(appState.projectPhase.projectPhaseId)
                //console.log(projectProgressTmp)
                if(projectProgressTmp){
                    projectProgress = Object.assign({},projectProgressTmp)
                    state.progressDate = new Date(projectProgress.progressDate)
                }
            }
            //console.log(projectProgress)
            state.projectProgress = projectProgress
            setAppState(state)
            
        }
        setIsShow(true)
    }

    const onProgressDateChange = (date) => {
       
        if(appState){
            let state = Object.assign({},appState)
            let year = date.getFullYear()
            let month = date.getMonth()
            let projectProgress:ProjectProgress|null|undefined =  null
            if(state.projectProgresses && state.projectProgresses.length > 0){
                projectProgress = state.projectProgresses.find(x => new Date(x.progressDate).getFullYear() == year && new Date(x.progressDate).getMonth() == month && x.projectPhaseId == appState.projectPhase.projectPhaseId)
            }
            state.progressDate = date

            let inputPlanCost = document.getElementById('plan-cost') as HTMLInputElement
            let inputActualCost = document.getElementById('actual-cost') as HTMLInputElement
            let inputPlanProgress = document.getElementById('plan-progress') as HTMLInputElement
            let inputActualProgress = document.getElementById('actual-progress') as HTMLInputElement
            let inputCostProjection = document.getElementById('cost-projection') as HTMLInputElement
            let inputCostCumulative = document.getElementById('cost-cumulative') as HTMLInputElement
            let inputAreaOfConcern = document.getElementById('area-of-concern') as HTMLTextAreaElement
            let inputActivity = document.getElementById('activity') as HTMLTextAreaElement
            let fileElement = document.getElementById("attach-file-name") as HTMLLabelElement

            if(projectProgress)
            {
                if(inputPlanCost){
                    inputPlanCost.value = projectProgress.planCost >= 0 ? NumHelper.WithComma(projectProgress.planCost) : ""
                }
                if(inputActualCost){
                    inputActualCost.value = projectProgress.actualCost >= 0 ? NumHelper.WithComma(projectProgress.actualCost) : ""
                }
                if(inputPlanProgress){
                    inputPlanProgress.value = projectProgress.planProgress >= 0 ? projectProgress.planProgress.toString() : ""
                }
                if(inputActualProgress){
                    inputActualProgress.value = projectProgress.actualProgress >= 0 ? projectProgress.actualProgress.toString() : ""
                }
                if(inputCostProjection){
                    inputCostProjection.value = projectProgress.costProjection >= 0 ? NumHelper.WithComma(projectProgress.costProjection) : ""
                }
                if(inputCostCumulative){
                    inputCostCumulative.value = projectProgress.costCumulative >= 0 ? NumHelper.WithComma(projectProgress.costCumulative) : ""
                }
                inputAreaOfConcern.value = projectProgress.areaOfConcern
                inputActivity.value = projectProgress.activity
                fileElement.innerHTML = projectProgress.attachedFile
            }
            else{
                projectProgress = {
                    projectProgressId:0,
                    projectPhaseId: appState.projectPhase.projectPhaseId,
                    progressDate: date,
                    planCost: -1,
                    actualCost: -1,
                    planProgress:-1,
                    actualProgress:-1,
                    costProjection:-1,
                    costCumulative:-1, 
                    activity:"",
                    areaOfConcern:"",
                    attachedFile:"",
                    createBy: "",
                    updateBy:""
                }

                if(inputPlanCost){
                    inputPlanCost.value = ""
                }
                if(inputActualCost){
                    inputActualCost.value = ""
                }
                if(inputPlanProgress){
                    inputPlanProgress.value = ""
                }
                if(inputActualProgress){
                    inputActualProgress.value = ""
                }
                if(inputCostProjection){
                    inputCostProjection.value = ""
                }
                if(inputCostCumulative){
                    inputCostCumulative.value = ""
                }
            
                inputAreaOfConcern.value = ""
                inputActivity.value = ""
            }
            
            state.projectProgress = projectProgress
            setAppState(state)
        }
        
    }

    const onInputFileChange = () => {
        let fileNameElement = document.getElementById("attach-file-name") as HTMLLabelElement
        let inputFile= document.getElementById('attach-file') as HTMLInputElement
        let attachFileError = document.getElementById('attach-file-error') as HTMLDivElement

        fileNameElement.innerHTML = ""  
        attachFileError.innerHTML = ""
        inputFile.style.backgroundColor = ""
    }

    const updateProgress = (projectProgress:ProjectProgress) => {
        let state = Object.assign({},appState)
        state.progressDate = new Date(projectProgress.progressDate)
        state.projectProgress =  projectProgress
        ////console.log(state)
        setAppState(state)
        setIsShow(true)
    }

    const validate = () => {
        let inputPlanProgress = document.getElementById('plan-progress') as HTMLInputElement
        let inputActualProgress = document.getElementById('actual-progress') as HTMLInputElement
        let inputFile= document.getElementById('attach-file') as HTMLInputElement
        
        let planProgressError = document.getElementById('plan-progress-error') as HTMLDivElement
        let actualProgressError = document.getElementById('actual-progress-error') as HTMLDivElement
        let attachFileError = document.getElementById('attach-file-error') as HTMLDivElement
        
        let planProgress = inputPlanProgress ? NumHelper.ToFloat(inputPlanProgress.value) : 0
        let actualProgress = inputActualProgress ? NumHelper.ToFloat(inputActualProgress.value) : 0
        
        let files:FileList|null = inputFile.files
        let isNotError = true
        let errorColor = "#FFDDDD"

        if(planProgressError){
            planProgressError.style.display = 'none'
            planProgressError.innerHTML = ""
        }
        if(actualProgressError){
            actualProgressError.style.display = 'none'
            actualProgressError.innerHTML = ""
        }
        
        if(attachFileError){
            attachFileError.style.display = 'none'
            attachFileError.innerHTML = ""
        }
        if(inputActualProgress){
            inputActualProgress.style.backgroundColor = ""
        }
        if(inputFile){
            inputFile.style.backgroundColor = ""
        }
        
       
        if(files && files.length > 0){
            let type = files[0].type.split('/').reverse()[0].toLowerCase()
            let typename = files[0].name.split('.').reverse()[0].toLowerCase()
            if(config.Upload_File_Extensions.indexOf(type) === -1 && config.Upload_File_Extensions.indexOf(typename) === -1){
                attachFileError.style.display = 'inline'
                inputFile.style.backgroundColor = errorColor
                attachFileError.innerHTML = StringHelper.htmlEncode(`File type ${type} not allowed.`)
                isNotError = false
            }
            if(files[0].size == 0){
                attachFileError.style.display = 'inline'
                inputFile.style.backgroundColor = errorColor
                attachFileError.innerHTML = `File has 0 byte size.`
                isNotError = false
            }
            if(files[0].size > config.Max_File_Size){
                attachFileError.style.display = 'inline'
                inputFile.style.backgroundColor = errorColor
                attachFileError.innerHTML = `File has over size limit 10MB.`
                isNotError = false
            }
        }
        
        if(planProgress > 100){
            planProgressError.style.display = 'inline'
            inputPlanProgress.style.backgroundColor = errorColor
            planProgressError.innerHTML = "Percentage not exceeding 100"
            actualProgressError.style.display = 'inline'
            actualProgressError.innerHTML = "&nbsp;"
            inputPlanProgress.scrollIntoView({block: "center"})
            isNotError = false
        }

        if(actualProgress > 100){
            actualProgressError.style.display = 'inline'
            inputActualProgress.style.backgroundColor = errorColor
            actualProgressError.innerHTML = "Percentage not exceeding 100"
            planProgressError.style.display = 'inline'
            if(planProgressError.innerHTML.length == 0){
                planProgressError.innerHTML = "&nbsp;"
            } 
            actualProgressError.scrollIntoView({block: "center"})
            isNotError = false
        }

        return isNotError

    }

    const saveProgress = async() => {
        let saveBtn = document.getElementById('save-progress-btn') as HTMLButtonElement
        if(saveBtn){
            saveBtn.disabled = true
        }
        if(!validate()){
            if(saveBtn){
                saveBtn.disabled = false
            }
            return false
        }

        if(appState){
            let inputPlanCost = document.getElementById('plan-cost') as HTMLInputElement
            let inputActualCost = document.getElementById('actual-cost') as HTMLInputElement
            let inputPlanProgress = document.getElementById('plan-progress') as HTMLInputElement
            let inputActualProgress = document.getElementById('actual-progress') as HTMLInputElement
            let inputCostProjection = document.getElementById('cost-projection') as HTMLInputElement
            let inputCostCumulative = document.getElementById('cost-cumulative') as HTMLInputElement
            let inputAreaOfConcern = document.getElementById('area-of-concern') as HTMLTextAreaElement
            let inputActivity = document.getElementById('activity') as HTMLTextAreaElement
            let inputFile= document.getElementById('attach-file') as HTMLInputElement
           
            let planCost = inputPlanCost ? NumHelper.ToFloat2(inputPlanCost.value) : -1
            let actualCost = inputActualCost ? NumHelper.ToFloat2(inputActualCost.value) : -1
            let planProgress = inputPlanProgress ? NumHelper.ToFloat2(inputPlanProgress.value) : -1
            let actualProgress = inputActualProgress ? NumHelper.ToFloat2(inputActualProgress.value) : -1
            let costProjection = inputCostProjection ? NumHelper.ToFloat2(inputCostProjection.value) : -1
            let costCumulative = inputCostCumulative ? NumHelper.ToFloat2(inputCostCumulative.value) : -1
            let files:FileList|null = inputFile.files
            let isFileUpload = false

            if(appState.phaseNumber == 1 || appState.phaseNumber == 5){
                if(inputActivity.value == "" && inputAreaOfConcern.value == "" && files?.length == 0){
                    setErrors(["All values are empty, please fill some value."])
                    if(saveBtn){
                        saveBtn.disabled = false
                    }
                    return 
                }
            }

            if(appState.phaseNumber == 2 || appState.phaseNumber == 3 || appState.phaseNumber == 4){
                if(inputActivity.value == "" && inputAreaOfConcern.value == "" && files?.length == 0
                    && inputPlanCost.value == "" && inputActualCost.value == "" && inputPlanProgress.value ==""
                    && inputActualProgress.value == "" && inputCostCumulative.value == "" && inputCostProjection.value == ""
                ){
                    setErrors(["All values are empty,plase fill some value."])
                    if(saveBtn){
                        saveBtn.disabled = false
                    }
                    return 
                }
            }

            if(files && files.length > 0){
                isFileUpload = true
            }

            let projectProgress:ProjectProgress = {
                projectProgressId: appState.projectProgress ? appState.projectProgress.projectProgressId : 0,
                projectPhaseId: appState.projectPhase.projectPhaseId,
                progressDate: appState.progressDate,
                planCost: planCost,
                actualCost: actualCost,
                planProgress: planProgress,
                actualProgress: actualProgress,
                costProjection: costProjection,
                costCumulative: costCumulative,
                activity:inputActivity.value,
                areaOfConcern:inputAreaOfConcern.value,
                attachedFile: "",
                createBy: "",
                updateBy: ""
            }
            //console.log(projectProgress)
           
                let result:any
                let id = 0
                if(projectProgress.projectProgressId == 0){
                    projectProgress.createBy = account && account.name ? account.name : ""
                    let token = await getToken()
                    result = await new ProjectProgressService(token).create(projectProgress);
                    id = result.id
                }
                else{
                    id = projectProgress.projectProgressId
                    projectProgress.updateBy = account && account.name ? account.name : ""
                    let token = await getToken()
                    result = await new ProjectProgressService(token).update(projectProgress);
                }
                //console.log(result)
                if(result.success){

                    if(isFileUpload){
                        handleFilesUpload(id)
                        if(saveBtn){
                            saveBtn.disabled = false
                        }
                    }
                    else{
                        getProgresses()
                        if(saveBtn){
                            saveBtn.disabled = false
                        }
                    }
                }
                else{
                    alert(`fail ,${result.message}`)
                    if(saveBtn){
                        saveBtn.disabled = false
                    }
                    return false
                }
                setIsShow(false)
        }
    }

    const handleFilesUpload = async (projectProgressId:number) => {
        let fileInput= document.getElementById('attach-file') as HTMLInputElement
        const files:FileList|null = fileInput.files
        //console.log(files)
        let formData = new FormData();
        formData.append('projectId', projectId.toString())
        formData.append('projectProgressId', projectProgressId.toString())
        for( var i in files ){
            formData.append('files[' + i + ']', files[i]);
        }
        
        let token = await getToken()
        let result = await new FileManageService(token).uploadProgressFile(formData);
        //console.log(result)
        if(result && result.success){
            getProgresses()
        }
    }
    
    const searchUser = async(key) => {
        let result = await new GraphApiService(accessToken).SearchUsername(key)
        ////console.log(result)
        return result.data
    }

    const searchUserOther = async(key) => {
        let token = await getToken()
        let result = await new GraphApiService(token).SearchOther(key)
        return result.data
    }

    const searchFocalpoint = async(key) => {
        let token = await getToken()
        let result = await new GraphApiService(token).SearchRole(key,SystemLevel.FocalPoint)
        let data = result.data.filter(x => appState?.focalpoints.findIndex(f => f.key == x.userPrincipalName) == -1)
       // //console.log(data)
        return data
    }

    const onProjectManagersSelect = (item) => {
        
        let state = Object.assign({},appState)
        if(state && state.projectManagers){
            state.projectManagers = item
            state.isUpdate = true
            setAppState(state)
        }
    }

    const onSecretariesSelect = (item) => {
        
        let state = Object.assign({},appState)
        if(state && state.secretaries){
            state.secretaries = item
            state.isUpdate = true
            setAppState(state)
        }
    }

    const onFocalPointSelect = (item) => {

        let state = Object.assign({},appState)
        if(state && state.focalpoints){
            state.focalpoints = item
            state.isUpdate = true
            setAppState(state)
        }
    }

    const onDeliverableTemplateChange = (deliverableTemplateId) => {
        if(deliverableTemplateId == 0){
            return
        }
        if(appState && appState.projectPhase.deliverableTemplateId > 0){
            let state = Object.assign({},appState)
            state.isDocumentTemaplateChange = true
            state.deliverableTemplateId = deliverableTemplateId
            setAppState(state)
        }
        else{
            changeDeliverableTemplate(deliverableTemplateId)
        }

    }

    const onDocumentTemplateClose = () =>{
        let state = Object.assign({},appState)
        state.isDocumentTemaplateChange = false
        setAppState(state)
    }

    const changeDeliverableTemplate = (deliverableTemplateId:number) => {
        let element = document.getElementById('reload-template') as HTMLInputElement
        let state = Object.assign({},appState)

        if(deliverableTemplateId > 0){
            state.deliverableTemplateId = deliverableTemplateId
            if(element){
                state.isReloadTemplate = element.checked
            }
        }

        if(state && state.projectPhase){
            let deliverableTemplate:DeliverableTemplate | undefined = state.deliverableTemplates.find( x => x.deliverableTemplateId == state.deliverableTemplateId)
            if(deliverableTemplate){
                state.projectPhase.deliverableTemplateId =  state.deliverableTemplateId
                state.projectPhase.deliverableTemplateName = deliverableTemplate.deliverableTemplateName
            }
        }
        state.isDocumentTemaplateChange = false
        state.isUpdate = true
        setAppState(state)
    }

    const onTrackBudgetChange = () => {
        let element = document.getElementById('track-budget') as HTMLInputElement
        let state = Object.assign({},appState)
        if(state && state.projectPhase){
            state.isUpdate = true
            state.projectPhase.trackBudget =  element.checked ? 1 : 0
            setAppState(state)
        }
    }
    const onTrackProgressChange = () => {
        let element = document.getElementById('track-progress') as HTMLInputElement
        let state = Object.assign({},appState)
        if(state && state.projectPhase){
            state.isUpdate = true
            state.projectPhase.trackProgress =  element.checked ? 1 : 0
            setAppState(state)
        }
    }

    const onWaiveChange = () => {
        let element = document.getElementById('waive-phase') as HTMLInputElement
        let state = Object.assign({},appState)
        if(state && state.projectPhase){
            state.isUpdate = true
            state.projectPhase.waivePhase =  element.checked ? 1 : 0
            setAppState(state)
        }
    }

    const onWaiveReasonChange = (ev) => {
        let state = Object.assign({},appState)
        if(state && state.projectPhase){
            state.isUpdate = true
            state.projectPhase.waivePhaseReason = ev.target.value
            setAppState(state)
        }
    }

    const getSpi = (actual,plan) => {
        let spi = 0
        if(plan > 0){
          spi = (actual/plan)
        }
        return (
            <>
            <FaCircle color={spi < 1 ? Color.DarkRed : Color.LightGreen}  size={10} />
            <span style={{marginLeft:'5px'}}>{ spi.toFixed(2) }</span>
            </>
        )
        
    }

    const getBpi = (actual,plan) => {
        let bpi = 0
        if(plan > 0){
            bpi = (actual/plan)
        }
        return (
            <>
            <FaCircle color={ bpi > 1 ? Color.DarkRed : Color.LightGreen}  size={10}/>
            <span style={{marginLeft:'5px'}}>{ bpi.toFixed(2) }</span>
            </>
        )
        
    }
    
    const onlyNumber = (ev) => {
        var currentInput = ev.target.value
        ev.target.value = currentInput.replace(/[^0-9\.]/g, '');
    }
    
    const numberWithComma = (ev) => {
        ev.target.value = NumHelper.WithComma(ev.target.value,2)
    }
    
    const onCurrentPhaseChange = (phaseId) => {
        let state = Object.assign({},appState)
        state.lastPhaseId = state.phaseId
        state.phaseId = phaseId
        state.isCurrentPhaseChange =true
        setAppState(state)
    }

    const onCurrentPhaseClose = () => {
        let state = Object.assign({},appState)
        state.phaseId = state.lastPhaseId
        state.isCurrentPhaseChange =false
        setAppState(state)
    }
    
    const download = async (projectId:number, projectProgressId:number, attachedFile:string) => {
        let path = "p" + projectId.toString() + "/prg" + projectProgressId.toString()
        let token = await getToken()
        new FileManageService(token).downloadFile(path,attachedFile);
    }
    
    const handlePageClick = async (event: PageEvent) => {
        let state = Object.assign({},appState)
        state.tableIndex = event.selected
        //console.log(state.tableIndex)
        setAppState(state)
    }

    const confirmItem = (projectProgress:ProjectProgress) => {
        let state = Object.assign({},appState)
        state.projectProgress  = projectProgress
        setAppState(state)
        setIsconfirm(true)
    }

    const deleteItem = async() => {
        try{
            let updateBy = (user.username) ? user.username : ""
            let token = await getToken()
            let projectProgressId = appState && appState.projectProgress ? appState.projectProgress.projectProgressId : 0
            let result = await new ProjectProgressService(token).delete(projectProgressId,updateBy)
            if(result && result.success){
                new HistoryLogService(token).create(user.userId, projectId,"Project Status Update","Delete Historical Phase " + appState?.phaseNumber + ", Date " + appState?.projectProgress?.progressDate)
                setIsconfirm(false)
                getProgresses()
            }
            else{
                setIsconfirm(false)
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            setIsconfirm(false)
            setErrors([err.message])
        }
    }
    return (
    <div className='inform-section'>
        <div className='project-label'>
            <span>Project Status Update</span>
        </div>
        { appState && !appState.isLoading ?
            <>
                { isGatekeeper && isCanPhaseChange ?
                    <>
                        <div className='row' style={{ marginTop: '5px'}}>
                            <div className="col-sm-6">
                                <div>
                                    <span className='topic-head'>Current Phase:</span>
                                        <select id="current-phase" value={appState.phaseId} className="form-select form-select-sm" style={{ display: 'inline', width:'200px' ,marginLeft: '10px'}} onChange={ev => onCurrentPhaseChange(parseInt(ev.target.value))}>
                                            { props.phases.map(p => <option key={uniqid()} value={p.phaseId}>{ p.phaseName }</option>) }          
                                        </select>
                                </div>
                                <div style={{ marginTop: '5px'}}></div>
                            </div>
                            <div className='col-sm-6'></div>
                        </div>
                    </>:null
                }

            {isCanUpdateStatus ?
                <PhaseSelection phaseId={appState.phaseId} projectModeId={props.projectModeId} isUpdate={appState.isUpdate} phases={props.phases} onChange={onPhaseChange}/>:null
            }

            
                { !isGatekeeper && isCanUpdateStatus ?
                    <>
                        <div className="status-info"> 
                            <div className="col-sm-12" style={{border: '1px solid #ddd',padding: '20px'}}>
                                <div id="document-user-setting">
                                    <div className='row' style={{ marginBottom: '15px' }}>
                                        <div className="col-sm-2 col-label">
                                            <span className='topic-head'>Document Template
                                                <a data-tip data-for='document-template-info' style={{cursor: 'pointer'}}>
                                                <AiOutlineInfoCircle size={20} cursor="pointer" style={{ marginLeft: '5px' }} />
                                                <span className='topic-head'>:</span>
                                                </a>
                                                {/* <span ref={spanDocumentTemplateMessage} className='require'>*</span> */}
                                                <ReactTooltip id='document-template-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                                    <div className=''>Assurance Scenario by Phase.</div>
                                                </ReactTooltip>  
                                            </span>
                                        </div>
                                        <div className="col-sm-4">
                                            <div >
                                                { appState.projectPhase.waivePhase === 0 ?
                                                    <select  
                                                        key={uniqid()} id="deliverable-template" 
                                                        ref={selectDocumentTemplate} 
                                                        value={appState.projectPhase.deliverableTemplateId} 
                                                        className="form-select form-select-sm" 
                                                        style={{ backgroundColor: documentTemplateBackgroundColor }}  
                                                        onChange={(ev) => onDeliverableTemplateChange( parseInt(ev.target.value))}
                                                        disabled={appState.isReloadTemplate}
                                                    >
                                                        <option value={0}>Please Select</option>
                                                        { appState.deliverableTemplates?.map(d => 
                                                            <option 
                                                                key={uniqid()} 
                                                                value={d.deliverableTemplateId} 
                                                                disabled={ d.deliverableTemplateId === appState.currentDeliverableTemplateId ? true : false }
                                                            >
                                                            { d.deliverableTemplateName } ({d.documents})
                                                            </option>)
                                                         } 
                                                    </select>
                                                    :  
                                                    <input  type="text" className="form-control form-control-sm" disabled/>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-sm-6" >
                                            { appState.currentDeliverableTemplateId > 0 ?
                                                <>
                                                    <input  key={uniqid()} id="reload-template" className="form-check-input" type="checkbox" name="reload-template" checked={appState.isReloadTemplate} onChange={() => changeDeliverableTemplate(appState.currentDeliverableTemplateId)}/>
                                                    <span style={{marginLeft:'10px',verticalAlign:'bottom'}}>Reload Current Template</span>
                                                </>
                                                :
                                                <></>
                                            }
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-2 col-label">
                                            <span className='topic-head'>Focal Point:<span ref={spanFocalPointMessage} className='require'>*</span></span>
                                        </div>
                                        <div className="col-sm-4">
                                            { 
                                                appState.projectPhase.waivePhase === 1 ?  <input  type="text" className="form-control form-control-sm" disabled/> :   
                                                <Picker
                                                    key={uniqid()}
                                                    placeholder='Search by ID , Name'
                                                    selectedItems={appState.focalpoints ? appState.focalpoints : []}
                                                    onChange={item => onFocalPointSelect(item)}
                                                    service={searchFocalpoint}
                                                    inputTextColor={focalPointsBackgroundColor}
                                                    minimumTextLength={1}
                                                />
                                            }
                                        </div>
                                    </div>
                    
                                    <div className='row'>
                                        <div className="col-sm-2 col-label">
                                                <span className='topic-head'>Project Manager:</span>
                                        </div>
                                        <div className="col-sm-4">
                                
                                            { appState.projectPhase.waivePhase === 1 ?  <input  type="text" className="form-control form-control-sm" disabled/> :   
                                                <Picker
                                                    key={uniqid()}
                                                    placeholder='Search by ID , Name'
                                                    selectedItems={appState.projectManagers ? appState.projectManagers : []}
                                                    onChange={item => onProjectManagersSelect(item)}
                                                    service={searchUserOther}
                                                    minimumTextLength={1}
                                                />
                                            }
                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className="col-sm-2 col-label">
                                                <span className='topic-head'>EDR Focal Point:</span>
                                        </div>
                                        <div className="col-sm-4">
                                
                                            { appState.projectPhase.waivePhase === 1 ?  <input  type="text" className="form-control form-control-sm" disabled/> :   
                                                <Picker
                                                    key={uniqid()}
                                                    placeholder='Search by ID , Name'
                                                    selectedItems={appState.secretaries ? appState.secretaries : []}
                                                    onChange={item => onSecretariesSelect(item)}
                                                    service={searchUserOther}
                                                    minimumTextLength={1}
                                                />
                                            }
                                        </div>
                                    </div>

                            { (appState.phaseNumber === 2 || appState.phaseNumber === 3 || appState.phaseNumber === 4) ?
                                <>
                                    <div className='row'>
                                        <div className="col-sm-2 col-label">
                                                        <span className='topic-head'>Track Budget</span>
                                        </div>
                                        <div className="col-sm-4">
                                        
                                            <input  key={uniqid()} id="track-budget" className="form-check-input" type="checkbox" name="status-check"  value={1} checked={appState.projectPhase.trackBudget == 1 ? true : false} onChange={onTrackBudgetChange} disabled={appState.projectPhase.waivePhase === 1}/>
                                       
                                            </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-sm-2 col-label">
                                                        <span className='topic-head'>Track Progress</span>
                                        </div>
                                        <div className="col-sm-4">
                                       
                                            <input  key={uniqid()} id="track-progress" className="form-check-input" type="checkbox" name="status-check"  value={1} checked={appState.projectPhase.trackProgress == 1 ? true : false} onChange={onTrackProgressChange} disabled={appState.projectPhase.waivePhase === 1}/>
                                        
                                        </div>
                                    </div>
                                </>: null
                            }
                                    <div className='row'>
                                        <div className="col-sm-12" style={{textAlign:'right'}}>
                                            { appState.projectPhase.waivePhase === 0 ?
                                                <button id="save-phase-btn" type="button" className="btn btn-sm bg-dark-blue" style={{width:'150px'}} disabled={!appState.isUpdate} onClick={updateProjectPhaseStatus}>
                                                    <AiOutlineSave size={20} />
                                                    <span style={{ marginLeft: '5px'}}>Save</span>
                                                </button>:null
                                            }
                                        </div>
                                    </div>
                            
                                </div>
                       
                            </div>
                        { appState.projectPhase.waivePhase === 0 ?
                            <div id="status-history">
                                <div id="update-status-table" style={{ marginTop: '30px'}}>
                                    <div className="row">
                                        <div className='col-md-5' style={{ paddingTop: '10px',paddingLeft: '20px',fontWeight: 'bold'}}>
                                            <span>Historical Information</span>
                                            <span style={{ marginLeft: '10px'}}>
                                            <AiOutlinePlusCircle size="25" color={Color.Blue} onClick={() => createProgress()} cursor="pointer"/>
                                            </span>
                                        </div>
                                    
                                        <div className='col-md-7 historical-tool'>
                                            <div style={{ padding:'5px'}}>Start</div>
                                            <div className='historical-tool-date'>
                                            <DatePicker
                                                key={uniqid()} 
                                                dateFormat="MMM yyyy"
                                                showMonthYearPicker
                                                selected={ startDate } 
                                                onChange={ (date) => setStartDate(date) } 
                                                isClearable 
                                                placeholderText="Please select" 
                                                className="form-control form-control-sm"
                                            />
                                            </div>
                                            <div style={{ padding:'5px'}}>To</div>
                                            <div className='historical-tool-date' style={{paddingRight: '10px'}}>
                                            <DatePicker
                                                key={uniqid()} 
                                                dateFormat="MMM yyyy"
                                                showMonthYearPicker
                                                selected={ endDate } 
                                                onChange={ (date) => setEndDate(date) } 
                                                isClearable 
                                                placeholderText="Please select" 
                                                className="form-control form-control-sm"
                                               
                                            />
                                            </div>
                                            <button type="button" className="btn btn-sm project-tool" style={{width : '150px',backgroundColor: 'rgb(0, 51, 141)'}} onClick={() => filterProgress()}>
                                                <BsFilter size="20" color="white" />
                                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}}>Filter</span>
                                            </button>
                                            <button type="button" className="btn bg-grey  btn-sm project-tool" style={{width : "150px",marginLeft:'5px'}} onClick={() => resetProgress()}>
                                                <VscRefresh size="20" color="white" />
                                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}}>Reset</span>
                                            </button>
                                        
                                        </div>
                                    </div>

                                    <div className='row project-tool-mini'>
                                        <div className='col-md-12'>
                                            <button type="button" className="btn btn-sm" style={{width : '100%',backgroundColor: 'rgb(0, 51, 141)'}} onClick={() => filterProgress()}>
                                                <BsFilter size="20" color="white" />
                                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}}>Filter</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className='row project-tool-mini'>
                                        <div className='col-md-12'>
                                            <button type="button" className="btn bg-grey  btn-sm" style={{width : "100%"}} onClick={() => resetProgress()}>
                                                <VscRefresh size="20" color="white" />
                                                <span style={{ "verticalAlign" : "middle",marginLeft: '5px',color: 'white'}}>Reset</span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="table-responsive table-responsive-sm" >
                                        <table id="table-progress" className="table portfolio-table table-hover">
                                            <thead>
                                                <tr>
                                                    <th className='td-header' style={{ width: '110px'}}>
                                                    <span>Month</span>
                                                    </th>
                                                    { appState.phaseNumber > 1 && appState.phaseNumber < 5 ?
                                                        <>
                                                            { appState.projectPhase.trackProgress ?
                                                                <>
                                                                    <th className="td-header" style={{ minWidth: '110px'}}>
                                                                        <div>Plan</div>
                                                                        <div>Progress(%)</div>
                                                                    </th>
                                                                    <th className='td-header' style={{ minWidth: '110px'}}>
                                                                        <div>Actual</div>
                                                                        <div>Progress(%)</div>
                                                                    </th>
                                                                </>:null
                                                            }
                                                            { appState.projectPhase.trackBudget ?
                                                                <>
                                                                    <th className='td-header' style={{ minWidth: '110px'}}>
                                                                        <div>Plan Cost</div>
                                                                        <div>(MMUSD)</div>
                                                                    </th>
                                                                    <th className='td-header' style={{ minWidth: '110px'}}>
                                                                        <span>Actual Cost(MMUSD)</span>
                                                                    </th>
                                                                    <th className='td-header' style={{ minWidth: '110px'}}>
                                                                        <div>Current Approved Budget</div>
                                                                        <div>(MMUSD)</div>
                                                                    </th>
                                                                    <th className='td-header' style={{ minWidth: '110px'}}>
                                                                        <div>Estimated Spending at</div>
                                                                        <div>Completion</div>
                                                                        <div>(MMUSD)</div>
                                                                    </th>
                                                                </>:null
                                                            }
                                                            { appState.projectPhase.trackProgress ?
                                                                <th className='td-header' style={{ width: '150px'}}>
                                                                    <span>SPI</span>
                                                                </th>: null
                                                            }
                                                            { appState.projectPhase.trackBudget ?
                                                                <th className='td-header' style={{ width: '150px'}}>
                                                                    <span>BPI</span>
                                                                </th>:null
                                                            }
                                
                                                        </>:null
                                                    }

                                                    <th className='td-header' >
                                                        <span>Attached File</span>
                                                    </th>
                                            
                                                    <th className='td-header' style={{ width: appState.phaseNumber !== 1 && appState.phaseNumber !== 5 && (appState.projectPhase.trackProgress === 1 || appState.projectPhase.trackBudget === 1 )?  '200px' : '30%' }}>
                                                        <span>Activity</span>
                                                    </th>
                                                    <th className='td-header' style={{ width: appState.phaseNumber !== 1 && appState.phaseNumber !== 5 && (appState.projectPhase.trackProgress === 1 || appState.projectPhase.trackBudget === 1) ?  '200px' : '30%' }}>
                                                        <span>Area Of Concern</span>
                                                    </th>
                                                    <th style={{width:'60px'}}>Edit</th>
                                                    <th style={{width:'60px'}}>Delete</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { !isFilterLoad ?
                                                    <>
                                                        { appState.tableProgress.sort((a, b) => new Date(b.progressDate).getTime() - new Date(a.progressDate).getTime()).slice(appState.tableIndex * appState.tablePerpage ,(appState.tableIndex + 1) *  appState.tablePerpage).map(progress => 
                                                            <tr key={uniqid()}>
                                                                <td>
                                                                    <span>{ DateHelper.getMonthYear(progress.progressDate) }</span>
                                                                </td>
                                                                    { appState.phaseNumber > 1 && appState.phaseNumber < 5 ?
                                                                        <>
                                                                            { appState.projectPhase.trackProgress ?
                                                                                <>
                                                                                    <td>
                                                                                        <span>{progress.planProgress >= 0 ? progress.planProgress : ""}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{progress.actualProgress >= 0 ? progress.actualProgress : ""}</span>
                                                                                    </td>
                                                                                </>:null
                                                                            }
                                                                            { appState.projectPhase.trackBudget ?
                                                                                <>
                                                                                    <td>
                                                                                        <span>{progress.planCost >=0 ? NumHelper.WithComma(progress.planCost,2) : ""}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{progress.actualCost >= 0 ? NumHelper.WithComma(progress.actualCost,2) : ""}</span>
                                                                                    </td>
                                                
                                                                                <>
                                                                                    <td>
                                                                                        <span>{progress.costProjection >= 0 ? NumHelper.WithComma(progress.costProjection,2) : ""}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <span>{progress.costCumulative >= 0 ? NumHelper.WithComma(progress.costCumulative,2): ""}</span>
                                                                                    </td>
                                                                                </>
                                                
                                                                        </>:null
                                                                    }

                                                                    { appState.projectPhase.trackProgress ?
                                                                        <td className='td-header' style={{ width: '150px'}}>
                                                                            {progress.actualProgress >= 0 && progress.planProgress > 0 ? getSpi(progress.actualProgress,progress.planProgress) : ""}
                                                                        </td>:null
                                                                    }
                                                                    { appState.projectPhase.trackBudget ?
                                                                        <td className='td-header' style={{ width: '150px'}}>
                                                                            {progress.costProjection >= 0 && progress.costCumulative > 0 ? getBpi(progress.costCumulative,progress.costProjection) : ""}
                                                                        </td>:null
                                                                    }
                                                                        </>:null
                                                                    }
                                                                <td>
                                                                    <span style={{ cursor:'pointer',color:Color.Blue}} onClick={() => download(props.projectId,progress.projectProgressId,progress.attachedFile)}>{progress.attachedFile}</span>
                                                                </td>
                                
                                                                <td style={{ wordBreak: 'break-all',textAlign:'left'}}>
                                                                    { progress.activity }
                                                                </td>
                                                                <td style={{ wordBreak: 'break-all',textAlign:'left'}}>
                                                                    { progress.areaOfConcern }
                                                                </td>
                                                                <td>
                                                                    <AiOutlineEdit cursor="pointer" color="orange"  size={20} onClick={() => updateProgress(progress)}/>  
                                                                </td>
                                                                <td>
                                                                    <RiDeleteBin6Line size={18} color={Color.Red} cursor="pointer" onClick={ () => confirmItem(progress)}/>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>: <Loading text="Loading..."/>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                
                { appState.tableProgress.length > 0 ?
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={ Math.ceil(appState.tableProgress.length / appState.tablePerpage)}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                onPageChange={handlePageClick}
                               // renderOnZeroPageCount={null}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                />
                            </div>
                        </div>
                    </div>:null 
                }
                   
                <Modal  show={isShow} onHide={() => setIsShow(false)} size="lg">
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px',fontWeight: 'bold'}}>Historical Information</Modal.Title>
                        <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '16px',fontWeight: 'bold'}} onClick={() => setIsShow(false)}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        { appState.projectProgress ?
                        <div id="update-status" style={{ padding: '15px 10px' , marginTop:'5px'}}>
                            <div className='row'>
                                <div className='col-md-1'></div>
                                <div className="col-sm-5 col-label" style={{ textAlign: 'left' }}>
                                        <span className='topic-head'>Month/Year :</span>
                                        <DatePicker
                                            dateFormat="MMM yyyy"
                                            showMonthYearPicker
                                            selected={ appState.progressDate } 
                                            onChange={ (date) => onProgressDateChange(date) }  
                                            placeholderText="Please select" 
                                            className="form-control form-control-sm"
                                        />
                                </div>
    
                                </div>
                                   
                                       { appState.phaseNumber > 1 && appState.phaseNumber < 5 && appState.projectPhase.trackProgress ?
                                        <div className='row' style={{ marginTop: '25px'}}>
                                            <div className="col-sm-1 col-label">
                                            </div>
                                            <div className="col-sm-5" style={{ textAlign: 'left'}}>
                                                <div>
                                                    <label className="form-label col-label topic-head">Plan Progress(%)</label>
                                                    <a data-tip data-for='plan-progress-info' style={{ cursor: 'pointer', marginLeft : '3px' }}>
                                                        <AiOutlineInfoCircle size={18} color={Color.Blue} style={{ verticalAlign: 'middle' }} />
                                                    </a>
                                                    <ReactTooltip id='plan-progress-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                        Cumulative
                                                    </ReactTooltip>
                                                </div>
                                                <div id="plan-progress-error" className='require' style={{ display : 'none'}}></div>
                                                <input id="plan-progress" type="text" autoComplete="off" defaultValue={appState.projectProgress.planProgress > 0 ? appState.projectProgress.planProgress : ""} className="form-control form-control-sm " onKeyUp={onlyNumber} />
        
                                            </div>
                                            <div className="col-sm-5" style={{ textAlign: 'left'}}>
                                                <div>
                                                    <label className="form-label col-label topic-head">Actual Progress(%)</label>
                                                    <a data-tip data-for='actual-progress-info' style={{ cursor: 'pointer', marginLeft : '3px' }}>
                                                        <AiOutlineInfoCircle size={18} color={Color.Blue} style={{ verticalAlign: 'middle' }} />
                                                    </a>
                                                    <ReactTooltip id='actual-progress-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                        Cumulative
                                                    </ReactTooltip>
                                                </div>
                                                   
                                                <div id="actual-progress-error" className='require' style={{ display : 'none'}}></div>
                                                <input id="actual-progress" type="text" autoComplete="off" defaultValue={appState.projectProgress.actualProgress >= 0 ? appState.projectProgress.actualProgress : ""}  className="form-control form-control-sm " onKeyUp={onlyNumber}/>
        
                                            </div>
    
                                        </div>: null
                                        }
                                   

                               
                                        {appState.phaseNumber > 1 && appState.phaseNumber < 5 && appState.projectPhase.trackBudget ?
                                        <>
                                            <div className='row'>
    
                                            <div className="col-sm-1 col-label">
        
                                            </div>
                                            <div className="col-sm-5" style={{ textAlign: 'left'}}>
                                                <label className="form-label col-label topic-head">Plan Cost(MMUSD):</label>
                                                    <a data-tip data-for='plan-cost-info' style={{ cursor: 'pointer', marginLeft : '3px' }}>
                                                        <AiOutlineInfoCircle size={18} color={Color.Blue} style={{ verticalAlign: 'middle' }} />
                                                    </a>
                                                    <ReactTooltip id='plan-cost-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                        Cumulative
                                                    </ReactTooltip>
                                                <input id="plan-cost" type="text" defaultValue={appState.projectProgress.planCost > 0 ? NumHelper.WithComma(appState.projectProgress.planCost,2) : ""}  className="form-control form-control-sm " onBlur={numberWithComma} onKeyUp={onlyNumber} />
        
                                            </div>
                                            <div className="col-sm-5" style={{ textAlign: 'left'}}>
                                                <label className="form-label col-label topic-head">Actual Cost(MMUSD):</label>
                                                    <a data-tip data-for='actual-cost-info' style={{ cursor: 'pointer', marginLeft : '3px' }}>
                                                        <AiOutlineInfoCircle size={18} color={Color.Blue} style={{ verticalAlign: 'middle' }} />
                                                    </a>
                                                    <ReactTooltip id='actual-cost-info' data-tooltip-html={true} backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8 }}>
                                                        Cumulative
                                                    </ReactTooltip>
                                                <input id="actual-cost" type="text" defaultValue={appState.projectProgress.actualCost >= 0 ? NumHelper.WithComma(appState.projectProgress.actualCost,2) : ""}  className="form-control form-control-sm " onBlur={numberWithComma} onKeyUp={onlyNumber} />
        
                                            </div>
    
                                            </div>
                                           
                                                <div className='row'>
    
                                                    <div className="col-sm-1 col-label">
        
                                                    </div>
                                                    <div className="col-sm-5" style={{ textAlign: 'left'}}>
                                                    <label className="form-label col-label topic-head" style={{ height:'52px'}}>Current Approved<br/> Budget(MMUSD):</label>
                                                    <input id="cost-projection" type="text" defaultValue={appState.projectProgress.costProjection >= 0 ? NumHelper.WithComma(appState.projectProgress.costProjection,2) : ""}  className="form-control form-control-sm " onKeyUp={onlyNumber} onBlur={numberWithComma} />
        
                                                </div>
                                                    <div className="col-sm-5" style={{ textAlign: 'left'}}>
                                                    <label className="form-label col-label topic-head" style={{ height:'52px'}}>Estimated Spending at<br/> Completion(MMUSD):</label>
                                                    <input id="cost-cumulative" type="text" defaultValue={appState.projectProgress.costCumulative >= 0 ? NumHelper.WithComma(appState.projectProgress.costCumulative,2) : "" }  className="form-control form-control-sm " onKeyUp={onlyNumber} onBlur={numberWithComma} />
        
                                                    </div>
    
                                                </div>  
                                                           

                                            </> : null
                                        }

                                    
                                

                            <div className='row'>
    
                                <div className="col-sm-1 col-label">
        
                                </div>
                                <div className="col-sm-10" style={{ textAlign: 'left'}}>
                                    <label className="form-label col-label topic-head">Activity:</label>
                                    <textarea id="activity" defaultValue={appState.projectProgress.activity}  className="form-control"    style={{ height: '100px' }}></textarea>
        
                                </div>
    
    
                            </div>

                        <div className='row'>
    
                            <div className="col-sm-1 col-label">
        
                            </div>
                            <div className="col-sm-10" style={{ textAlign: 'left'}}>
                                <label className="form-label col-label topic-head">Area Of Concern:</label>
                                <textarea id="area-of-concern" defaultValue={appState.projectProgress.areaOfConcern}  className="form-control"   style={{ height: '100px' }}></textarea>
        
                        </div>
                    </div>

            
                <div className='row'>
                    <div className='col-sm-1'></div>
                    <div className="col-sm-10" style={{ textAlign: 'left'}}>
                        <div>
                            <label className="form-label col-label topic-head">Attached File:</label>
                            <label id="attach-file-name" style={{marginLeft:'5px'}}>{ appState.projectProgress.attachedFile }</label>
                        </div>
                        <div id="attach-file-error" className='require' style={{ display : 'none'}}></div>
                        <input id="attach-file" type="file" className="form-control form-control-sm " onChange={() => onInputFileChange()}/>
                        <span style={{ color: 'red',fontSize:'12px'}}>Max: 10MB and Accepted: *.docx, *.doc, *.ppt, *.pptx, *.xls, *.xlsx, *.pdf, *.zip, *.rar, *.pdf, *.bmp, *. jpg, *.png</span>
                        
                    </div>
                </div>
            

                <div className='row' style={{ marginTop: '25px'}}>
                    <div className="col-md-12" style={{ textAlign: 'center' }}>

                    <button id="save-progress-btn" type="button" className="btn btn-sm" style={{"width": "160px", height: '35px',  backgroundColor: 'rgb(0, 51, 141)', color: 'white'}} onClick={saveProgress}>
                        <span style={{ marginLeft: '5px'}}>Save</span>
                    </button>


            </div>
        </div>

                        </div>:null
                        }
                    </Modal.Body>
                </Modal>
                                </div> 
                            </div>:null
                        }
                        </div>
        
        
                    </>:null
                } 
           

                { isGatekeeper || isCanWaivePhase ?
                    <div className="status-info"> 
                        <div className="col-sm-12" style={{border: '1px solid #ddd',padding: '20px'}}>
                            <div id="document-user-setting">
                                <div className='row'>
                                        <div className="col-sm-2 col-label">
                                            <span className='topic-head'>Waive Phase</span>
                                        </div>
                                        <div className="col-sm-4">
                                            <input  key={uniqid()} id="waive-phase" className="form-check-input" type="checkbox" name="status-check"  value={1} checked={appState.projectPhase.waivePhase == 1 ? true : false} onChange={onWaiveChange}/>
                                        </div>
                                        <div className='col-sm-6' style={{ textAlign:'right',paddingRight: '0'}}>
                                           
                                        </div>
                                </div>
                                {appState.projectPhase.waivePhase == 1 ?
                                    <div className='row'>
                                        <div className="col-sm-2 col-label">
                                            <div className='topic-head'>Waive Reason<span className='require'>*</span></div>
                                            <div id="waive-reason-message" className='require'></div>
                                        </div>
                                        <div className="col-sm-4">
                                            <textarea id="waive-reason" value={appState.projectPhase.waivePhaseReason} className="form-control" rows={3} onChange={ev => onWaiveReasonChange(ev)}></textarea>
                                         </div>
                                         <div className='col-sm-6'>
                                            
                                        </div>
                                    </div>:null
                                }
                                     <div className='row'>
                                        <div className="col-sm-12" style={{textAlign:'right'}}>
                                            <button type="button" className="btn btn-sm bg-dark-blue" style={{width:'150px'}} disabled={!appState.isUpdate} onClick={updateWaiveStatus}>
                                                <AiOutlineSave size={20} />
                                                <span style={{ marginLeft: '5px'}}>Save</span>
                                             </button>
                                        </div>
                                    </div>
                            

                            </div>
                           
                        </div>
                
                    </div>:null
                } 
            </>:<Loading text="Loading..."/>
        }

        <Modal show={appState?.isCurrentPhaseChange} onHide={() => onCurrentPhaseClose()} size="sm" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => onCurrentPhaseClose()}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}> Would you like to change phase?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateCurrentPhase()} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => onCurrentPhaseClose()} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>

            <Modal show={appState?.isDocumentTemaplateChange} onHide={() => onDocumentTemplateClose()} size="sm" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => onDocumentTemplateClose()}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}> Would you like to change document template?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => changeDeliverableTemplate(0)} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => onDocumentTemplateClose()} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>

        <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
            <Modal.Body style={{ textAlign: 'center',padding: 0}}>
                <div className="alert alert-success" role="alert" style={{marginBottom: 0}}>
                    <AiOutlineCheckCircle color="green" size={25}/>
                    <span style={{marginLeft:'10px',color:'green'}}>Success</span>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="sm" style={{ top: '20%'}}>
            <Modal.Header className="bg-dark-blue">
                <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => setErrors([])}>X</span>
            </Modal.Header>
            <Modal.Body style={{ textAlign: 'center'}}>
                <div><RiErrorWarningLine color="red" size={25}/></div>
                <div style={{ fontSize: '14px'}}>
                {   errors.map((msg) =>(
                            <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                        ))
                    }
                    
                </div>
                <div style={{marginTop:'15px'}}>
               
                <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                    <span style={{ marginLeft: '5px'}} >Close</span>
                </button>
               </div>
          </Modal.Body>
        </Modal>

        
        <Modal show={error != "" ? true : false} onHide={() => setError("")} size="sm">
            <Modal.Body style={{ textAlign: 'center',padding: 0}}>
                <div className="alert alert-danger" role="alert" style={{marginBottom: 0}}>
                    <AiOutlineCloseCircle color="red" size={25}/>
                    <span style={{marginLeft:'10px',color:'red'}}>{error}</span>
                    <button type="button" className="btn btn-light" style={{"width": "100px", height: '32px',marginTop:'20px'}} onClick={() => setError("")}>
                        <span style={{ fontSize:'14px',verticalAlign:'top'}}>OK</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={isConfirm} onHide={() => setIsconfirm(false)} size="lg">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setIsconfirm(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}}>
                         <div>
                             <RiDeleteBin6Line size={18} color={'red'} />
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to delete { DateHelper.getYearMonth2(appState?.projectProgress?.progressDate) }?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => deleteItem()} >
                             <span style={{ marginLeft: '5px'}} >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setIsconfirm(false)} >
                             <span style={{ marginLeft: '5px'}} >No</span>
                         </button>
                     </Modal.Body>
                 </Modal>
             
    </div>
 
    );

    

}

export default StatusUpdate
