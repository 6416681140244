import React, { useState,useEffect ,useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { AiOutlineDelete,AiOutlineEdit,AiOutlineSearch,AiOutlineCloseCircle } from "react-icons/ai";
import { RiRefreshLine,RiErrorWarningLine } from "react-icons/ri";
import ReactPaginate from 'react-paginate';
import { VscAdd } from "react-icons/vsc";
import uniqid from 'uniqid';
import { AssuranceActivityService ,HistoryLogService} from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { AssuranceActivity ,PageEvent ,AuthenUser,SelectItem} from '../../models';
import { Color } from '../../enum'
import Loading from '../Loading'
import { DateHelper } from '../../helpers';
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AppState {
    total: number,
    totalPgae: number,
    page: number,
    limit: number,
    assuranceActivities:AssuranceActivity[]
    isLoading:boolean
}

const AdminAssuranceActivity = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [assuranceActivity,setAssuranceActivity] = useState<AssuranceActivity>({assuranceActivityId: 0,assuranceActivityName: "", updateDateTime: null, updateBy : name})
    const [searchStatus,setSearchStatus] = useState(false)
    const searchKey = useRef<HTMLInputElement>(null)
    const [modalShow, setModalShow] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    const [errors,setErrors] = useState<string[]>([])
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        assuranceActivities:[],
        isLoading:true
    })
    const [selectItem,setSelectItem] = useState({assuranceActivityName: ""})
  
    useEffect(() => {
        if(accessToken){
            getItems(1,appState.limit)
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getItems(page,appState.limit);
        
    }

    const getItems = async(page: number, limitPage:number ,key:string = "") => {
        let token = await getToken()
        let result = await new AssuranceActivityService(token).getAll(page,limitPage,key);
        let state = Object.assign({},appState)
        //console.log(result)
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state.limit = limit
            state.total = total
            state.page = page
            state.totalPgae = Math.ceil(total / limit)
            state.assuranceActivities = items
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const onSearchAssuranceActivity = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new AssuranceActivityService(token).Search(key);
        //console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectAssuranceActivity = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ assuranceActivityName: item.value})
          }
         
      }

      

      const searchAssuranceActivity = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({assuranceActivityName: inputSearch.value})
                key = inputSearch.value
            }
       
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)

        setSearchStatus(true)
        getItems(1,appState.limit,key)
    }

    const resetAssuranceActivity= () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectItem({assuranceActivityName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setSearchStatus(false)
        getItems(1,appState.limit)
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        
        let limit = parseInt(ev.target.value)
        getItems(1,limit,key)
    }

    const addItem = () => {
        let item:AssuranceActivity = {
            assuranceActivityId: 0,
            assuranceActivityName: "",
            updateDateTime: null,
            updateBy: name
        }
        setAssuranceActivity(item)
        setModalShow(true)
    }

    const editItem = (item:AssuranceActivity) => {
        setAssuranceActivity(item)
        setModalShow(true)
    }

    const confirmItem = (item:AssuranceActivity) => {
        setAssuranceActivity(item)
        setConfirmShow(true)
    }

    const saveItem = async () => {
        let result:any = null
        let _assuranceActivity = Object.assign({},assuranceActivity)
        let inputItem =document.getElementById('input-item') as HTMLInputElement
        //let inputColor =document.getElementById('input-color') as HTMLSelectElement
        if(!inputItem.value){
            return
        }
        
        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true

        try{
            _assuranceActivity.updateBy = name
            _assuranceActivity.assuranceActivityName = inputItem.value
            if(_assuranceActivity.assuranceActivityId == 0){
                let token = await getToken()
                result = await new AssuranceActivityService(token).Create(_assuranceActivity)
                new HistoryLogService(token).create(user.userId, null,"Admin Assurance Activity",'Create assurance activity "' + _assuranceActivity.assuranceActivityName + '"')
            }
            else{
                let token = await getToken()
                result = await new AssuranceActivityService(token).Update(_assuranceActivity)
                new HistoryLogService(token).create(user.userId, null,"Admin Assurance Activity",'Update assurance activity "' + _assuranceActivity.assuranceActivityName + '"')
            }
            if(result.success){
                saveBtn.disabled = false
                setModalShow(false)
                getItems(1,appState.limit)
            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            saveBtn.disabled = false
                setErrors([err.message])
            
        }
    }

    const deleteItem = async() => {
        try{
            let updateBy = name
            //console.log(assuranceActivity)
            
            let token = await getToken()
            let result = await new AssuranceActivityService(token).Delete(assuranceActivity.assuranceActivityId,updateBy)
            if(result && result.success){
                let token = await getToken()
                new HistoryLogService(token).create(user.userId, null,"Admin Assurance Activity",'Delete assurance activity "' + assuranceActivity.assuranceActivityName + '"')
                setConfirmShow(false)
                getItems(1,appState.limit)
            }
            else{
                setConfirmShow(false)
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            setConfirmShow(false)
            setErrors([err.message])
        }
        
    }

    return (
        <div className="container-fluid">
        <div style={{ "padding":"5px 15px"}}>
             <div className="body">
                <div className="row">
                     <div className="col-sm-6">
                         <span className='project-label'>Assurance Activity</span>
                     </div>
                 </div>
        
                 <div className='row' style={{ marginTop: '10px'}}>
                    <div className="col-md-4">
                        <div className="input-group">
                        <TextboxAutocomplete key={uniqid()}  valueKey="assuranceActivityName" onChange={onSearchAssuranceActivity} onSelect={onSelectAssuranceActivity} elementId='input-search' initialValue={selectItem.assuranceActivityName} hiddenArrow={true}/>
                        </div>
                    </div>
                    <div className='col-md-1'>
                            <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}} onClick={() => searchAssuranceActivity()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}}/>
                            </button>
                    </div>
                    <div className='col-md-1'>
                    { searchStatus ?
                                 <span className="input-group-append" style={{cursor:"pointer"}} onClick={resetAssuranceActivity}>
                                     <RiRefreshLine size={20} color={Color.Blue}/>
                                     <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}}>Reset</span>
                                 </span>:null
                             } 
                    </div>
                     <div className='col-md-6' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}}>
                         <div className="admin-action">
                            { user.administratorUpdate > 0 ?
                             <span className='app-active' style={{ cursor: 'pointer'}} onClick={addItem}>
                                 <VscAdd size={20} color={Color.Blue} />
                                 <span style={{ fontSize: '14px',marginLeft:'2px'}}>Add Assurance Activity</span>
                             </span>
                             :<></>
                            }
                         </div> 
                     </div>
                 </div>
                 <div className='row' style={{ marginTop: '10px'}}>
                     <div className='col-md-10' style={{ paddingTop:'7px'}}>
                         <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                     </div>
                     <div className='col-md-2' style={{textAlign: 'right'}}>
                         <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                         <select value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                             <option value={10}>10</option>
                             <option value={25}>25</option> 
                             <option value={50}>50</option>
                             <option value={100}>100</option>        
                         </select>
                     </div>
                 </div>
         { !appState.isLoading ?
             <>
                 <div className="table-responsive table-responsive-sm">
                     <table className="table table-sm table-bordered table-hover" >
                         <thead>
                             <tr>
                                <th style={{width: '80px'}}>No.</th>
                                 <th>Assurance Activity</th>
                              
                                 <th>Modified By</th>
                                 <th>Modified Date</th>
                                 <th>Edit</th>
                                 <th>Delete</th>
                             </tr>
                         </thead>
                         <tbody>
                             {
                                 appState.assuranceActivities.map((item,index) =>(
                                     <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '#f3f3f3' : ''}}>
                                         
                                         <td>
                                            {((appState.page -1) * appState.limit) + index + 1}
                                        </td>
                                            
                                         <td style={{textAlign:'left',paddingLeft:'20px'}}>
                                             <span>{ item.assuranceActivityName }</span>
                                         </td>
                                      
                                         <td style={{ textAlign: 'center' }}>
                                             <span>{ item.updateBy }</span>
                                         </td>
                                         <td style={{ textAlign: 'center' }}>
                                             <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                         </td>
                                         <td className='text-center-both'>
                                            { user.administratorUpdate > 0 ?
                                                <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                                :<></>
                                            }
                                        </td>
                                        <td>
                                            { user.administratorUpdate > 0 ?
                                                <AiOutlineDelete size={20} color={Color.Red2} cursor="pointer" onClick={ () => confirmItem(item)}/>
                                                :<></>
                                            }
                                        </td>
                                     </tr>
                                 ))
                              }
                         </tbody>
                     </table>
                 </div>

                 <div className='row'>
                     <div className='col-md-4' style={{ display: 'flex'}}>
                  
                     </div>
                     <div className='col-md-8'>
                         <div id='pagination' style={{ float: 'right'}}>
                         <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                             forcePage={appState.page - 1}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                         </div>
                     </div>     
                 </div>
             </>: <Loading text="Loading..."/>
         }

         <div className='row' style={{ marginTop: '30px'}}>
            <div className='col-md-12'></div>
         </div>
                 <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                        <Modal.Title style={{ fontSize: '15px'}}>
                            { assuranceActivity.assuranceActivityId > 0 ? <span>Update Assurance Activity</span> : <span> Add Assurance Activity</span> }
                        </Modal.Title>
                        <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setModalShow(false)}>X</span>
                         
                     </Modal.Header>
                     <Modal.Body style={{ textAlign: 'center'}}>
                         <div className='row'>
                             <div className="col-sm-1 col-label">
                         
                             </div>
                             <div className="col-sm-10">
                                 <label  className="form-label col-label" style={{ float:'left'}}>Assurance Activity</label>
                                 <input id="input-item" defaultValue={assuranceActivity.assuranceActivityName} type="text" maxLength={8}  className="form-control form-control-sm " />
                             </div>
                         </div>
                        
                         <div className='row'>
                             <div className='col-sm-12 text-center-both'>
                                 <button id="save-btn" type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}} onClick={saveItem}>Save</button>
                             </div>
                         </div>
                     </Modal.Body>
                 </Modal>

                    <Modal show={confirmShow} onHide={() => setConfirmShow(false)} size="lg">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer',fontWeight:'bold'}} onClick={() => setConfirmShow(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}}>
                         <div>
                             <RiErrorWarningLine size={25} color={'red'} />
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to delete {assuranceActivity.assuranceActivityName}?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => deleteItem()} >
                             <span style={{ marginLeft: '5px'}} >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setConfirmShow(false)} >
                             <span style={{ marginLeft: '5px'}} >No</span>
                         </button>
                     </Modal.Body>
                 </Modal>

                 <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}} >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
             </div>
         </div> 
     </div>
    );
}

export default AdminAssuranceActivity