import React, { useState } from 'react';
import { VscStopCircle,VscCheck,VscCircleLargeFilled,VscClose,
    VscCircleSlash,VscIssueReopened,VscCircleLargeOutline,VscSync } from "react-icons/vsc";

import ReactPaginate from 'react-paginate';
import  secureLocalStorage  from  "react-secure-storage";

const PrepStageGateStatus = () => {
    const [status,setStatus] = useState(1)
    const role = localStorage.getItem('role')
    
    return (
        <div style={{ borderStyle: 'solid', borderColor: '#cccccc', padding: '15px', borderRadius: '10px', marginTop:'10px' }}>
        <div style={{ marginTop: '20px'}}>
            <div className='row'>
                <div className='col-md-3'>
                    <span className='project-label'>PREP#Gate Status</span>

                </div>
                <div className='col-md-9' style={{ textAlign: 'right'}}>

                    <span style={{ marginRight: '5px'}}>
                        <span style={{ fontWeight : 'bold'}}>Deliverable Status:</span>
                    </span>
                    <span>
                        <VscCheck size={18} color='green' style={{ marginRight: '5px'}}/>
                        <span>Accept</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscClose size={20} color="red" style={{ marginRight: '5px'}}/>
                        <span>Reject</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/>
                        <span>Waiting for Verification</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>
                        <span>Wait for Deliverable</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleSlash size={20} color='black' style={{ marginRight: '5px'}}/>
                        <span>Waive</span>
                    </span>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-3'>
                    
                </div>
                <div className='col-md-9' style={{ textAlign: 'right'}}>
                    <span style={{ marginRight: '5px'}}>
                        <span style={{ fontWeight : 'bold'}}>PREP Status: </span>
                    </span>
                    <span>
                        <VscCheck size={18} color='green' style={{ marginRight: '5px'}}/>
                        <span>Pass</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscSync size={20} color='black' style={{ marginRight: '5px'}}/>
                        <span>Recycle</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscStopCircle size={20} color='black' style={{ marginRight: '5px'}}/>
                        <span>Stop</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleLargeFilled size={20} color='black' style={{ marginRight: '5px'}}/>
                        <span>Hold</span>
                    </span>
                </div>
            </div>

            <div className='row' style={{ marginTop: '20px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found 4 record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            
                            <select className="" defaultValue={10} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                                <option value={10}>10</option>
                                <option value={20}>20</option> 
                                <option value={50}>50</option>
                                <option value={100}>100</option>        
                            </select>
                        </div>
            </div>

            <div className="table-responsive table-responsive-sm" >
                <table className="table deliverable-table">
                    <thead>
                        <tr style={{ borderTop: '1px solid #ddd'}}>
                            <th style={{ height: '35px'}}>Stage</th>
                            <th>Assurance Activity</th>
                            <th>Deliverable Status</th>
                            <th>PREP Status</th>
                            <th style={{ width: '100px'}}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ borderBottom: '1px solid #ddd'}}>
                            <td>
                                <span>Decision Gate</span>
                            </td>
                            <td>
                                <span>EDR (Stage 2)</span>
                            </td>
                            <td>
                                <span>
                                    <VscCheck size={18} color='green' style={{ marginRight: '5px'}}/>
                                </span>
                            </td>
                            <td>
                               
                            </td>
                            <td style={{ textAlign: 'left'}}>
                                <div className='deliverable-action' style={{ display: 'inline'}}>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Pass</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscSync size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Recycle</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscStopCircle size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Stop</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCircleLargeFilled size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Hold</span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid #ddd'}}>
                            <td>
                                <span>Value Assurance Check</span>
                            </td>
                            <td>
                                <span>TRB (Stage 2)</span>
                            </td>
                            <td>
                                <span>
                                    <VscCheck size={18} color='green' style={{ marginRight: '5px'}}/>
                                </span>
                            </td>
                            <td></td>
                            <td style={{ textAlign: 'left'}}>
                                <div className='deliverable-action' style={{ display: 'inline'}}>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Pass</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscSync size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Recycle</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscStopCircle size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Stop</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCircleLargeFilled size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Hold</span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid #ddd'}}>
                            <td>
                                <span>Decision Gate</span>
                            </td>
                            <td>
                                <span>FG (Stage 2)</span>
                            </td>
                            <td>
                                <span>
                                    <VscClose size={20} color="red" />
                                </span>
                            </td>
                            <td>
                                <VscCircleLargeFilled size={20}/>
                            </td>
                            <td style={{ textAlign: 'left'}}>
                                <div className='deliverable-action' style={{ display: 'inline'}}>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Pass</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscSync size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Recycle</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscStopCircle size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Stop</span>
                                    </span>
                                  
                                </div>
                            </td>
                        </tr>
                        <tr style={{ borderBottom: '1px solid #ddd'}}>
                            <td>
                                <span>Appropriate Committee</span>
                            </td>
                            <td>
                                <span>EDR (Stage 3)</span>
                            </td>
                            <td>
                                <span>
                                    <VscIssueReopened size={20} color="#FFC107"/>
                                </span>
                            </td>
                            <td>
                                <VscStopCircle size={20}/>
                            </td>
                            <td style={{ textAlign: 'left'}}>
                                <div className='deliverable-action' style={{ display: 'inline'}}>
                                        <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCheck size={18} color={'green'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Pass</span>
                                    </span>
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscSync size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Recycle</span>
                                    </span>
                                   
                                    <span style={{ cursor: 'pointer',marginLeft: '10px'}} >
                                        <VscCircleLargeFilled size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '13px',marginLeft:'2px'}}>Hold</span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='row'>
                    <div className='col-md-4' style={{ display: 'flex'}}>
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={10}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                //onPageChange={handlePageClick}
                               // renderOnZeroPageCount={null}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active-gradient"
                            />
                        </div>
                    </div>
               </div>
        </div>
    </div>   
    );
  
}

export default PrepStageGateStatus