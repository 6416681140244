import React from 'react';
import { Link } from 'react-router-dom';
import { iconEditOrange } from '../../images';
import ReactPaginate from 'react-paginate';
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

const AdminProjectDevelopment = () => {
 
    return (
        <div className="container-fluid">
          
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                <div className="table-responsive table-responsive-sm">
             <div className='project-block-none' style={{ paddingTop: '5px'}}>
             <div className="row" style={{ width: '80%'}}>
                <div className="col-sm-12">
                    <span className='inform-create-header'>Project Development Scenario</span>
                </div>
            </div>
            <div className="row" style={{ width: '80%'}}>
                <div className="col-sm-6">
                    <button type="button" className="btn btn-sm btn-outline-success">
                        <i className="material-icons header-icon">add</i>
                        <span style={{ "verticalAlign" : "top"}}>Add Project Development Scenario</span>
                        </button>
                </div>
            </div>
           
            <table className="table table-sm table-bordered table-hover" style={{ width: '50%' }}>
                <thead>
                    <tr>
                        <th>Project Development Scenario</th>
                        <th>Status</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Brown field with no reserve change: GSA</td>
                        <td style={{ textAlign: 'center'}}>
                                <span>On</span>
                            </td>
                        <td className='text-center-both'>
                            <img src={iconEditOrange} style={{ width: '25px'}}/>
                        </td>
                        
                       
                    </tr>

                    <tr>
                        <td>Green field</td>
                        <td style={{ textAlign: 'center'}}>
                                <span>On</span>
                            </td>
                        <td className='text-center-both'>
                        <img src={iconEditOrange} style={{ width: '25px'}}/>
                        </td>
                       
                      
                    </tr>
                    <tr>
                        <td>Brown field with new reserve change</td>
                        <td style={{ textAlign: 'center'}}>
                                <span>On</span>
                            </td>
                        <td className='text-center-both'>
                        <img src={iconEditOrange} style={{ width: '25px'}}/>
                        </td>
                        
                      
                    </tr>
                   
                   
                </tbody>
            </table>
           
         </div>
         </div>
                </div>
            </div>
        </div>
    );
  
}

export default AdminProjectDevelopment