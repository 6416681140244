import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Partner,AuthenUser,PageEvent,SelectItem} from '../../models';
import { PartnerService,HistoryLogService } from '../../services';
import { AiOutlineSearch,AiOutlineCloseCircle,AiOutlineEdit,AiOutlineDelete} from "react-icons/ai";
import { useApiAuth } from '../../hooks/useApiAuth';
import  secureLocalStorage  from  "react-secure-storage";
import { DateHelper } from '../../helpers';
import { VscAdd } from "react-icons/vsc";
import { Color } from '../../enum'
import uniqid from 'uniqid';
import Loading from '../Loading'
import ReactPaginate from 'react-paginate';
import { RiRefreshLine } from "react-icons/ri";
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";
import { RiErrorWarningLine} from "react-icons/ri";

interface AppState {
    total: number
    totalPgae: number
    page: number
    limit: number
    partners:Partner[]
    partnerIndex:number
    partnerActive:number
    isConfirm: boolean
    isLoading:boolean
}

const AdminPartner = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        total: 0,
        totalPgae: 0,
        page:1,
        limit: 10,
        partners:[],
        partnerIndex: 0,
        partnerActive: 0,
        isConfirm: false,
        isLoading:true
    })
    const [partner, setPartner] = useState<Partner>(
    {
        partnerId: 0,
        partnerName: "",
        sharePercentage: 0,
        updateDateTime: new Date(),
        updateBy: name,
        active:1
    })
    const [modalShow, setModalShow] = useState(false);
    const [isConfirmDelete, setIsConfirmDelete] = useState(false);
    const [selectItem,setSelectItem] = useState({partnerName: ""})
    const [searchStatus,setSearchStatus] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [errors,setErrors] = useState<string[]>([])

    useEffect(() => {
        if(accessToken){
            getPartner(1,appState.limit);
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = async (event: PageEvent) => {
        let page = event.selected + 1
        await getPartner(page,appState.limit);
        
    }

    const getPartner =async (page: number, limitPage:number ,key:string = "") => {
        let token = await getToken()
        let result = await new PartnerService(token).getAll(page,limitPage,key);
        let state = Object.assign({},appState)
        //console.log(result)
        if(result && result.success){
            let {page,limit,total,items} = result.data
            state.limit = limit
            state.total = total
            state.page = page
            state.totalPgae = Math.ceil(total / limit)
            state.partners = items
        }
      
        state.isLoading = false
        setAppState(state)
    }

    const onSearchPartner = async(key) => {
        if(!key) return

        let token = await getToken()
        let result = await new PartnerService(token).search(key);
        //console.log(result)
        if(result && result.data){
            return result.data;
        }
       
    }

    const onSelectPartner = (item:SelectItem) => {
          //console.log(item)
            if(item.value){
              setSelectItem({ partnerName: item.value})
          }
         
      }

    const searchAsset = () =>{
        let key = ""
        
            let inputSearch = document.getElementById('input-search') as HTMLInputElement
            if(inputSearch){
                setSelectItem({partnerName: inputSearch.value})
                key = inputSearch.value
            }
       
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)

        setSearchStatus(true)
        getPartner(1,appState.limit,key)
    }

    const resetAsset = () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectItem({partnerName: ""})
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        setSearchStatus(false)
        getPartner(1,appState.limit)
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('input-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        
        let limit = parseInt(ev.target.value)
        getPartner(1,limit,key)
    }

    const addItem = () => {
        let item:Partner = {
            partnerId: 0,
            partnerName: "",
            sharePercentage: 0,
            updateDateTime: new Date(),
            updateBy: name,
            active:1
        }
        setPartner(item)
        setModalShow(true)
    }

    const editItem = (item:Partner) => {
        setPartner(item)
        setModalShow(true)
    }

    const confirmItem = (item:Partner) => {
        setPartner(item)
        setIsConfirmDelete(true)
    }

    const savePartner = async () => {
        let result:any = null
        let _partner = Object.assign({},partner)
        let inputPartner =document.getElementById('input-asset-name') as HTMLInputElement
    
        if(!inputPartner.value){
            return
        }

        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true
       
        try{
            _partner.updateBy = name
            _partner.partnerName = inputPartner.value
           
            if(_partner.partnerId == 0){
                let token = await getToken()
                result = await new PartnerService(token).create(_partner)
                new HistoryLogService(token).create(user.userId, null,"Admin Partner",'Create Partner "' + _partner.partnerName + '"')
            }
            else{
                let token = await getToken()
                result = await new PartnerService(token).update(_partner)
                new HistoryLogService(token).create(user.userId, null,"Admin Partner",'Update Partner"' + _partner.partnerName + '"')
            }
            if(result.success){
                saveBtn.disabled = false
                setModalShow(false)
                getPartner(1,appState.limit)
            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
            }
           
        }
        catch(err){
            saveBtn.disabled = false
            setErrors([err.message])
        }
    }

    const onPartnerChange = (index: number ,checked:boolean) => {
        let state = Object.assign({},appState)
        state.partnerIndex = index
        state.partnerActive = checked ? 1 : 0 
        setAppState(state)
        setIsConfirm(true)
    }

    const savePartnerStatus = async () => {
        let checkStatus =  appState.partnerActive ? 'Active' : 'Inactive'
        let index = appState.partnerIndex
        let _partner = appState.partners[index]
        _partner.active = appState.partnerActive
        _partner.updateBy = name
        let token = await getToken()
        let result = await new PartnerService(token).updateStatus(_partner)
       
        if(result && result.success){
            new HistoryLogService(token).create(user.userId, null,"Admin Partner","Update " + _partner.partnerName +", Set Active Status To "+ checkStatus)
           
            setTimeout(() => {
                let state = Object.assign({},appState)
                state.partners[index].active = appState.partnerActive
                setAppState(state)
                setIsConfirm(false)
            }, 500);
        }
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"5px 15px"}}>
                <div className="body">
                
            <div className='project-block-none' style={{ paddingTop: '5px'}}>
            <div className="row">
                     <div className="col-sm-6">
                         <span className='project-label'>Partner</span>
                     </div>
            </div>
            <div className='row' style={{ marginTop: '10px'}}>
                    <div className="col-md-4">
                        <div className="input-group">
                        <TextboxAutocomplete key={uniqid()}  valueKey="partnerName" onChange={onSearchPartner} onSelect={onSelectPartner} elementId='input-search' initialValue={selectItem.partnerName} hiddenArrow={true}/>
                        </div>
                    </div>
                    <div className='col-md-1'>
                            <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}} onClick={() => searchAsset()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}}/>
                            </button>
                    </div>
                    <div className='col-md-1'>
                    { searchStatus ?
                                 <span className="input-group-append" style={{cursor:"pointer"}} onClick={resetAsset}>
                                     <RiRefreshLine size={20} color={Color.Blue}/>
                                     <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}}>Reset</span>
                                 </span>:null
                             } 
                    </div>
                     <div className='col-md-6' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}}>
                         <div className="admin-action">
                            { user.administratorUpdate > 0 ?
                             <span className='app-active' style={{ cursor: 'pointer'}} onClick={addItem}>
                                 <VscAdd size={20} color={Color.Blue} />
                                 <span style={{ fontSize: '14px',marginLeft:'2px'}}>Add Partner</span>
                             </span>:<></>
                            }
                         </div> 
                     </div>
            </div>
            <div className='row' style={{ marginTop: '10px'}}>
                     <div className='col-md-10' style={{ paddingTop:'7px'}}>
                         <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                     </div>
                     <div className='col-md-2' style={{textAlign: 'right'}}>
                         <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                         <select value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                             <option value={10}>10</option>
                             <option value={25}>25</option> 
                             <option value={50}>50</option>
                             <option value={100}>100</option>        
                         </select>
                     </div>
            </div>
        
        { !appState.isLoading ?
        <>
            <div className="table-responsive table-responsive-sm">
                <table className="table table-sm table-bordered table-hover" >
                    <thead>
                        <th style={{width: '80px'}}>No.</th>
                        <th>Partner Name</th>
                        <th>Modified By</th>
                        <th>Modified Date</th>
                        <th>Active Status</th>
                        <th>Edit</th>
                        
                    </thead>
                    <tbody>
                    { appState.partners.map((item,index) =>(
                            <tr key={uniqid()}  style={{height: '45px',backgroundColor: (index%2) ? '#f3f3f3' : ''}}>
                                <td>
                                    {((appState.page -1) * appState.limit) + index + 1}
                                </td>
                                <td style={{textAlign:'left',paddingLeft:'20px'}}>
                                    <span>{ item.partnerName }</span>
                                </td>
                               
                                <td style={{ textAlign: 'center' }}>
                                    <span>{ item.updateBy? item.updateBy : 'Admin' }</span>
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                    <span>{ DateHelper.getDate(item.updateDateTime) }</span>
                                </td>
                                <td style={{ textAlign: 'center'}}>
                                    { user.administratorUpdate > 0 ?
                                        <div className="form-check form-switch" style={{margin: 'auto',width: '50px'}}>
                                            <input className="form-check-input" type="checkbox" role="switch" id={'partner-' + item.partnerId }  value={1} defaultChecked={item.active == 1} onChange={(ev) => onPartnerChange(index,ev.target.checked)}/>
                                        </div>:<></>
                                    }
                                </td>
                             
                                <td className='text-center-both'>
                                    { user.administratorUpdate > 0 ?
                                        <AiOutlineEdit  size={20} color={Color.Orange} cursor="pointer" onClick={ () => editItem(item)}/>
                                        :<></>
                                    }
                                </td>
                              
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
            <div className='row' style={{ marginTop:'10px',marginBottom:'40px'}}>
                    <div className='col-md-4' style={{ display: 'flex'}}>
                     
                    </div>
                    <div className='col-md-8'>
                        <div id='pagination' style={{ float: 'right'}}>
                        <ReactPaginate
                             pageRangeDisplayed={5}
                             pageCount={appState.totalPgae}
                             breakLabel="..."
                             previousLabel="<<"
                             nextLabel=">>"
                             onPageChange={handlePageClick}
                             forcePage={appState.page - 1}
                             marginPagesDisplayed={2}
                             pageClassName="page-item"
                             pageLinkClassName="page-link"
                             previousClassName="page-item"
                             previousLinkClassName="page-link"
                             nextClassName="page-item"
                             nextLinkClassName="page-link"
                             breakClassName="page-item"
                             breakLinkClassName="page-link"
                             containerClassName="pagination"
                             activeClassName="active-gradient"
                         />
                        </div>
                    </div>
                   
            </div>
        </>:<Loading text="Loading..."/>
        }
         </div>
         
        </div>
    </div>

            <Modal show={modalShow} onHide={() => setModalShow(false)} size="lg">
                    <Modal.Header  className='bg-dark-blue' style={{fontSize:'16px'}}>
                        <Modal.Title style={{ fontSize: '15px'}}>
                            { partner.partnerId > 0 ? <span>Update Partner</span> : <span> Add Partner</span> }
                        </Modal.Title>
                        <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setModalShow(false)}>X</span>
                         
                     </Modal.Header>
                     <Modal.Body style={{ textAlign: 'center'}}>
                         <div className='row'>
                             <div className="col-sm-1 col-label">
                         
                             </div>
                             <div className="col-sm-10">
                                 <label  className="form-label col-label" style={{ float:'left'}}>Partner Name</label>
                                 <input id="input-asset-name" defaultValue={partner.partnerName} type="text"  className="form-control form-control-sm " />
                             </div>
                         </div>
                         
                         <div className='row'>
                             <div className='col-sm-12 text-center-both'>
                                 <button id="save-btn" type="button" className="btn bg-dark-blue" style={{"width": "200px",'marginTop': "15px"}} onClick={savePartner}>Save</button>
                             </div>
                         </div>
                     </Modal.Body>
                 </Modal>

                 <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="lg" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color="red" size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                                <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                            <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                                <span style={{ marginLeft: '5px'}} >Close</span>
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={isConfirm} onHide={() => setIsConfirm(false)} size="sm">
                     <Modal.Header className='bg-dark-blue'>
                         <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                         <span style={{marginRight:'5px',cursor:'pointer',fontWeight:'bold'}} onClick={() => setIsConfirm(false)}>X</span>
                     </Modal.Header>
                      <Modal.Body style={{ textAlign: 'center'}}>
                         <div>
                             <span style={{ marginLeft: '2px',fontWeight: 'bold',color: 'red'}}> Would you like to change active status?</span>
                         </div>
                         <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => savePartnerStatus()} >
                             <span style={{ marginLeft: '5px'}} >Yes</span>
                         </button>
                            <button type="button" className="btn bg-grey" style={{ "width": "100px", height: '35px', fontSize: '14px', marginTop: '15px', marginLeft: '10px' }} onClick={() => setIsConfirm(false)} >
                             <span style={{ marginLeft: '5px'}} >No</span>
                         </button>
                     </Modal.Body>
                </Modal>
                
        </div>
    );
  
}

export default AdminPartner