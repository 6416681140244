import React,{ useState } from "react";
import DeliverableDocument from './DeliverableDocument'
import Information  from './Information';
import { Link } from 'react-router-dom';

const Deliverable = () => {
    const [tab,setTab] = useState(0)

    return (
        <div className="container-fluid">
            <nav className="nav-menu" aria-label="breadcrumb">
                <ol className="breadcrumb breadcrumb-bottom">
                    <li className="breadcrumb-item active" aria-current="page">
                        <Link to="/dashboard">
                        <span>Portfolio Dashboard</span>
                        </Link>
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                       <span>Deliverable Document</span>
                    </li>
                </ol>
            </nav>
            <div style={{ "padding":"5px 15px"}}>
            <div className="body">
                <div className="tabs">
                    <input type="radio" id="tab1" name="tab-control" defaultChecked onClick={() => setTab(0)}/>
                    
                    <ul></ul>
                   
                    <div className="content">
                        <section>
                            <Information/>
                            <DeliverableDocument/>            
                        </section>
                        <section>
                        
                        </section>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Deliverable