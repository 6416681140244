import React, { useState ,useEffect} from "react";
import { AiFillCloseCircle,AiOutlineInfoCircle,AiOutlineDelete,AiOutlineFile, AiOutlineFolder, AiOutlineEdit,
    AiOutlineFilePdf,AiOutlineFileExcel,AiOutlineFileImage,AiOutlineFileWord,AiOutlineClockCircle,
     AiOutlinePauseCircle, AiFillCheckCircle, AiOutlineTrademarkCircle,AiFillCaretUp,AiFillCaretDown
} from "react-icons/ai";
import { RiArrowGoBackFill } from "react-icons/ri";
import { VscCircleSlash,VscCheck,VscChromeClose,VscAdd,VscLink,
    VscCloudDownload,VscClose,VscChevronRight,VscChevronDown,VscIssueReopened,VscCircleLargeOutline } from "react-icons/vsc";
  
import ReactTooltip from "react-tooltip";
import '../../assets/css/deliverable.css'
import Modal from 'react-bootstrap/Modal'
import {ProjectDeliverableService, ProjectDeliverableDocumentService ,FileManageService,HistoryLogService,EmailService} from '../../services'
import { Color ,StatusDeliverable, StatusDeliverableDocument} from '../../enum'
import { ProjectDeliverable, ProjectDeliverableDocument,ProjectDeliverableItemFile,UserItem,ProjectPhaseRole,AuthenUser,EmailReverse} from '../../models'
import uniqid from 'uniqid';
import { useApiAuth } from '../../hooks/useApiAuth';
import { DateHelper } from "../../helpers";
import { ProjectLevel } from "../../enum";
import Loading from "../Loading";
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface Props{
    projectId:number
    projectName:string
    projectDeliverable:ProjectDeliverable 
    projectPhaseRoles:ProjectPhaseRole[]
    projectOwners:UserItem[]
    onChange:any
 }
 
 interface AppState{
     arrowDown:boolean[]
     projectDeliverableDocument:ProjectDeliverableDocument | null
     projectDeliverableDocuments:ProjectDeliverableDocument[]
     deliverableStatus:string
     action:string
     currentAssuranceActionId:number
     currentAssuranceStatusName:string
     assuranceAction:string
     remark:string
     isFeedbackShow:boolean
     isStatusShow:boolean
     isDownloading:boolean[]
     isAssuranceShow:boolean
     isLoading:boolean
 }

const GatekeeperDocument = (props:Props) => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const user:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [appState,setAppState] = useState<AppState>({
        arrowDown: [],
        projectDeliverableDocument:null,
        projectDeliverableDocuments: [],
        deliverableStatus:"",
        action:"",
        currentAssuranceActionId:props.projectDeliverable.deliverableStatusId,
        currentAssuranceStatusName: props.projectDeliverable.deliverableStatusName,
        assuranceAction:"",
        remark:props.projectDeliverable.remark,
        isFeedbackShow:false,
        isDownloading:[],
        isStatusShow:false,
        isAssuranceShow:false,
        isLoading: true
    })

  const [dropdownStatus, setDropdownStatus] = useState(false);
  
  useEffect(() => {
    if(accessToken){
        //console.log(props.projectDeliverable)
            getProjectDeliverableDocuments()
    }
  },[accessToken])
    
const getToken = async() => {
    const acc = accounts && accounts.length ? accounts[0] : null;
    apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
    if(acc){
        const { accessToken: token } = await instance.acquireTokenSilent({
            account: acc,
            scopes: apiRequest.scopes
        });
        return token;
    }
    return ""
}
    const getProjectDeliverableDocuments = async () => {
        let { projectPhaseId,stageId,assuranceActivityId} = props.projectDeliverable
        let token = await getToken()
        let result = await new ProjectDeliverableDocumentService(token).getDocuments(projectPhaseId,stageId,assuranceActivityId)
        //console.log(result)
        if(result && result.success){
            let state = Object.assign({},appState)
            state.projectDeliverableDocuments = result.data
            let length = result.data.length
            state.arrowDown = state.arrowDown.fill(false,0,length)
            state.isDownloading = state.isDownloading.fill(false,0,length)
            state.deliverableStatus = "Waiting"
            if(state.projectDeliverableDocuments.findIndex(x => x.deliverableDocumentStatusId === StatusDeliverableDocument.Reject) > -1){
                state.deliverableStatus = 'Reject'
            }
            else{
                if(state.projectDeliverableDocuments.filter(x => x.deliverableDocumentStatusId === StatusDeliverableDocument.Reject).length === length){
                    state.deliverableStatus = 'Accept'
                }
            }

            state.assuranceAction = ""
            if(appState.currentAssuranceActionId === StatusDeliverable.WaitingForStart){
                state.assuranceAction = "waiting"
            }
            if(appState.currentAssuranceActionId === StatusDeliverable.InProgress){
                state.assuranceAction = "inprogress"
            }
            if(appState.currentAssuranceActionId === StatusDeliverable.Pass){
                state.assuranceAction = "pass"
            }
            if(appState.currentAssuranceActionId === StatusDeliverable.Recycle){
                state.assuranceAction = "recycle"
            }

            if(appState.currentAssuranceActionId === StatusDeliverable.Hold){
                state.assuranceAction = "hold"
            }
        
            if(appState.currentAssuranceActionId === StatusDeliverable.Waive){
                state.assuranceAction = "waive"
            }

            if(appState.currentAssuranceActionId === StatusDeliverable.Stop){
                state.assuranceAction = "stop"
            }
            state.isLoading = false
            setAppState(state)
      
        }

    }

    const setArrowDown = (index,status) => {
        let state = Object.assign({},appState)
        state.arrowDown[index] = status
        setAppState(state)
    }

    const feedbackShow = (item:ProjectDeliverableDocument) => {
        let state = Object.assign({},appState)
        state.projectDeliverableDocument = item
        state.isFeedbackShow = true
        setAppState(state)
    }

    const feedbackClose = () => {
        let state = Object.assign({},appState)
        state.isFeedbackShow = false
        setAppState(state)
    }

    const statusActionShow = (item:ProjectDeliverableDocument,action) => {
        let state = Object.assign({},appState)
        state.projectDeliverableDocument = item
        state.action = action
        state.isStatusShow = true
        setAppState(state)
    }

    const statusActionClose = () => {
        let state = Object.assign({},appState)
        state.isStatusShow = false
        setAppState(state)
    }

    const assuranceActionShow = (action) => {
        let state = Object.assign({},appState)
        state.assuranceAction = action
        state.isAssuranceShow = true
        setAppState(state)
        setDropdownStatus(false)
    }

    const assuranceActionClose = () => {
        let state = Object.assign({},appState)
        state.isAssuranceShow = false
        setAppState(state)
    }

    const updateFeedback = async() => {
        let projectDeliverableDocument = Object.assign({},appState.projectDeliverableDocument)
        let inputFeedback = document.getElementById('gatekeeper-feedback') as HTMLTextAreaElement
        let feedback = ""
        if(inputFeedback){
            feedback = inputFeedback.value
        }

        let token = await getToken()
        let result = await new ProjectDeliverableDocumentService(token).updateFeedback(projectDeliverableDocument.projectDeliverableDocumentId,feedback,name)
        if(result && result.success){
            new HistoryLogService(token).create(user.userId, props.projectId,"PREP Stage Gate Status","Update Gatekeeper Feedback")
            let state = Object.assign({},appState)
            state.isFeedbackShow = false
            let index = state.projectDeliverableDocuments.findIndex(x => x.projectDeliverableDocumentId === projectDeliverableDocument.projectDeliverableDocumentId)
            state.projectDeliverableDocuments[index].gatekeeperFeedback = feedback
            setAppState(state)
        }
    }

    const updateStatus = async() => {
        if(appState.projectDeliverableDocument){
            let {projectDeliverableDocumentId,deliverableDocumentStatusId} =  appState.projectDeliverableDocument
            let oldStatusId = deliverableDocumentStatusId
            let oldStatus = ""
            let action = ""
            
            if(appState.action === 'accept'){
                action = 'Accept'
                deliverableDocumentStatusId = StatusDeliverableDocument.Accept
            }
            if(appState.action === 'reject'){
                action = 'Reject'
                deliverableDocumentStatusId = StatusDeliverableDocument.Reject
            }
            if(appState.action === 'waive'){
                action = 'Waive'
                deliverableDocumentStatusId = StatusDeliverableDocument.Waive
            }
            if(appState.action === 'reverse'){
                action = 'Reverse'
                deliverableDocumentStatusId = StatusDeliverableDocument.WaitingForVerification
            }

            if(oldStatusId === StatusDeliverableDocument.WaitingForAttach){
                oldStatus = 'Waiting For Attach'
            }
            if(oldStatusId === StatusDeliverableDocument.WaitingForVerification){
                oldStatus = 'Waiting For Verification'
            }
            if(oldStatusId === StatusDeliverableDocument.Accept){
                oldStatus = 'Accept'
            }
            if(oldStatusId === StatusDeliverableDocument.Reject){
                oldStatus = 'Reject'
            }
            if(oldStatusId === StatusDeliverableDocument.Waive){
                oldStatus = 'Waive'
            }

            let token = await getToken()
            let result = await new ProjectDeliverableDocumentService(token).updateStatus(projectDeliverableDocumentId,deliverableDocumentStatusId,name)
            if(result && result.success){

                let historyAction = "Update Deliverable Document "+  props.projectDeliverable.stageCode + "/" + props.projectDeliverable.assuranceActivityName + "/" + appState.projectDeliverableDocument.deliverableItemName + ', ' + action
                new HistoryLogService(token).create(user.userId, props.projectId,"PREP Stage Gate Status",historyAction)
                if(appState.action === 'reverse'){
                    let emailReverse:EmailReverse = {
                        projectDeliverableDocumentId:projectDeliverableDocumentId,
                        projectId : props.projectId,
                        projectName : props.projectName,
                        projectPhaseId: props.projectDeliverable.projectPhaseId,
                        stageCode : props.projectDeliverable.stageCode,
                        assuranceActivityName : props.projectDeliverable.assuranceActivityName,
                        deliverableItemName : appState.projectDeliverableDocument.deliverableItemName,
                        oldStatus: oldStatus
                    }
                    let token = await getToken()
                    new EmailService(token).sendStatusReverse(emailReverse)
                }
                //let focalpoints = [...new Set(props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.FocalPoint && x.projectPhaseId === props.projectDeliverable.projectPhaseId).map((p) => { let userItem:UserItem = { username:p.username, name:p.name}; return userItem}))]
                //let projectUsers:UserItem[] = props.projectOwners.concat(focalpoints)
               

                let state = Object.assign({},appState)
                state.isStatusShow = false
                let index = state.projectDeliverableDocuments.findIndex(x => x.projectDeliverableDocumentId === projectDeliverableDocumentId)
                state.projectDeliverableDocuments[index].deliverableDocumentStatusId = deliverableDocumentStatusId
                state.projectDeliverableDocuments[index].verifyDateTime = result.date
                state.projectDeliverableDocuments[index].verifyBy = name
                setAppState(state)
            }
    
        }
    }

    const updateAssuranceStatus = async() => {
        let projectDeliverable = Object.assign({},props.projectDeliverable)
        let inputRemark = document.getElementById('input-remark') as HTMLTextAreaElement
        let oldStatusId = projectDeliverable.deliverableStatusId
        let oldStatus = ""

        if(inputRemark){
            projectDeliverable.remark = inputRemark.value
        }

        let action = ""
        if(appState.assuranceAction === 'waiting'){
            action = 'Waiting for Start'
            projectDeliverable.deliverableStatusId = StatusDeliverable.WaitingForStart
        }
        if(appState.assuranceAction === 'inprogress'){
            action = 'In Progress'
            projectDeliverable.deliverableStatusId = StatusDeliverable.InProgress
        }
        if(appState.assuranceAction === 'pass'){
            action = 'Pass'
            projectDeliverable.deliverableStatusId = StatusDeliverable.Pass
        }
        if(appState.assuranceAction === 'recycle'){
            action = 'Recycle'
            projectDeliverable.deliverableStatusId = StatusDeliverable.Recycle
        }
        if(appState.assuranceAction === 'hold'){
            action = 'Hold'
            projectDeliverable.deliverableStatusId = StatusDeliverable.Hold
        }
        if(appState.assuranceAction === 'waive'){
            action = 'Waive'
            projectDeliverable.deliverableStatusId = StatusDeliverable.Waive
        }
        if(appState.assuranceAction === 'stop'){
            action = 'Stop'
            projectDeliverable.deliverableStatusId = StatusDeliverable.Stop
        }

        if(oldStatusId === StatusDeliverable.InProgress){
            oldStatus = 'InProgress'
        }
        if(oldStatusId === StatusDeliverable.WaitingForStart){
            oldStatus = 'Waiting For Start'
        }
        if(oldStatusId === StatusDeliverable.Pass){
            oldStatus = 'Pass'
        }
        if(oldStatusId === StatusDeliverable.Recycle){
            oldStatus = 'Recycle'
        }
        if(oldStatusId === StatusDeliverable.Hold){
            oldStatus = 'Hold'
        }
        if(oldStatusId === StatusDeliverable.Waive){
            oldStatus = 'Waive'
        }
        if(oldStatusId === StatusDeliverable.WaivePhase){
            oldStatus = 'Waive Phase'
        }
        if(oldStatusId === StatusDeliverable.Stop){
            oldStatus = 'Stop'
        }
       
        projectDeliverable.updateBy = name
        let token = await getToken()
        let result = await new ProjectDeliverableService(token).updateStatus(projectDeliverable)
        if(result && result.success){

            let historyAction = "Update Assurance Status "+  props.projectDeliverable.stageCode + "/" + props.projectDeliverable.assuranceActivityName + ", " + action
            new HistoryLogService(token).create(user.userId,props.projectId,"PREP Stage Gate Status",historyAction)
            let projectManagers = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.ProjectManager && x.projectPhaseId === props.projectDeliverable.projectPhaseId).map((p) => { let userItem:UserItem = { username:p.username, name:p.name}; return userItem})
            let secretaries = props.projectPhaseRoles.filter(x => x.roleProjectLevelId === ProjectLevel.Secretary && x.projectPhaseId === props.projectDeliverable.projectPhaseId).map((p) => { let userItem:UserItem = { username:p.username, name:p.name}; return userItem})
            let projectUsers:UserItem[] = props.projectOwners.concat(projectManagers).concat(secretaries)
           // new EmailService(accessToken).sendAssuranceActivity(props.projectId, props.projectName,props.projectDeliverable.stageName + "/" + props.projectDeliverable.assuranceActivityName ,oldStatus,action,projectUsers)

            projectDeliverable.completeDateTime = result.date
            let state = Object.assign({},appState)
            state.currentAssuranceActionId =  projectDeliverable.deliverableStatusId
            state.currentAssuranceStatusName = action
            state.remark = projectDeliverable.remark
            state.isAssuranceShow = false
            props.onChange(projectDeliverable)
            setAppState(state)
        }
    
    }

    const cancelUpdateAssuranceStatus = () => {
        let state = Object.assign({},appState)
        state.assuranceAction = ""

        if(appState.currentAssuranceActionId === StatusDeliverable.WaitingForStart){
            state.assuranceAction = "waiting"
        }
        if(appState.currentAssuranceActionId === StatusDeliverable.InProgress){
            state.assuranceAction = "inprogress"
        }
        if(appState.currentAssuranceActionId === StatusDeliverable.Pass){
            state.assuranceAction = "pass"
        }
        if(appState.currentAssuranceActionId === StatusDeliverable.Recycle){
            state.assuranceAction = "recycle"
        }

        if(appState.currentAssuranceActionId === StatusDeliverable.Hold){
            state.assuranceAction = "hold"
        }
        
        if(appState.currentAssuranceActionId === StatusDeliverable.Waive){
            state.assuranceAction = "waive"
        }

        if(appState.currentAssuranceActionId === StatusDeliverable.Stop){
            state.assuranceAction = "stop"
        }
        state.isAssuranceShow = false
        //console.log(state.assuranceAction)
        setAppState(state)
    }

    const download = async (item:ProjectDeliverableItemFile) => {
        let token = await getToken()
        new FileManageService(token).downloadFile(item.path,item.name);
    }

    const downloadZip = async (item:ProjectDeliverableDocument,index:number) => {
        if(item.projectDeliverableItemFiles.length == 0){
            return
        }
        //console.log('downloadZip')
        let state = Object.assign({},appState)
        state.isDownloading[index] = true
        setAppState(state)
        let path = "p" + props.projectId.toString() + "/ph" +  props.projectDeliverable.projectPhaseId.toString() + "/s" + props.projectDeliverable.stageId.toString() + "/a" + props.projectDeliverable.assuranceActivityId +  "/d" + item.projectDeliverableDocumentId.toString()
        //console.log(path)
        let token = await getToken()
        await new FileManageService(token).downloadZipFile(path);
        let state2 = Object.assign({},appState)
        state2.isDownloading[index] = false
        setAppState(state2)
    }

    const redirectUrl = (url:string) => {
        window.open(url, '_blank');
    }

    return (

       
        <div style={{marginTop:'10px' }}>
           
            <div className="row">
                <div className="col-md-6">
                    <span className="col-label font-blue">Stage:</span>
                    <span style={{ marginLeft: '5px'}}>{ props.projectDeliverable.stageName }</span>
                </div>
                <div className="col-md-6">
                    <span className="col-label font-blue">Assurance Activity:</span>
                    <span style={{ marginLeft: '5px'}}>{ props.projectDeliverable.assuranceActivityName}</span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <span className="col-label font-blue">Current Assurance Status:</span>
                    <span style={{ marginLeft: '5px' }}>{ appState.currentAssuranceStatusName }</span>

                </div>
                <div className="col-md-6" style={{ wordBreak:'break-all'  }}>
                    <span className="col-label font-blue" style={{ marginRight: '5px' }}>Remark Assurance Status:</span>
                    <span style={{ marginLeft: '5px'}}>{appState.remark}</span>
                {/*
                <span className="col-label font-blue">Deliverable Status:</span>
                    { appState.deliverableStatus === 'Accept' ? <VscCheck size={18} color='green' style={{ marginLeft: '5px'}}/> : null }
                    { appState.deliverableStatus === 'Reject' ? <VscClose size={18} color="red" style={{ marginRight: '5px'}}/>: null }
                    <span style={{ marginLeft: '5px'}}>{appState.deliverableStatus}</span>
                */}
                </div>
            </div>
       
            <div className="row" style={{ marginTop: '15px'}}>
                <div className="col-md-6" style={{ display: 'flex'}}>
                    <span className="col-label font-blue" style={{ marginRight: '5px'}}>Assurance Status Action:</span>
                    <div className="dropdown-action">
                        <div className="dropdown-action-list" style={{ borderBottom : 'none'}} onClick={() => setDropdownStatus(!dropdownStatus)}>
                            { appState.assuranceAction === '' ? <span>Select Action</span> : null }
                            { appState.assuranceAction === 'waiting' ?
                            <span>
                                <AiOutlineClockCircle size={18} color={Color.Grey} style={{ marginRight: '5px'}}/>
                                <span>Waiting for Start</span>
                            </span>: null
                            }
                            { appState.assuranceAction === 'inprogress' ?
                            <span>
                                <AiOutlineClockCircle size={18} color={Color.Yellow} style={{ marginRight: '5px'}}/>
                                <span>In Progress</span>
                            </span>: null
                            }
                            { appState.assuranceAction === 'pass' ?
                            <span>
                                <AiFillCheckCircle size={18} color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span>Pass</span>
                            </span>: null
                            }
                            { appState.assuranceAction === 'recycle' ?
                            <span>
                                <AiOutlineTrademarkCircle size={20} color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span>Recycle</span>
                            </span>: null
                            }
                             { appState.assuranceAction === 'hold' ?
                            <span>
                             <AiOutlinePauseCircle size={20} color='orange' style={{ marginRight: '5px' }} />
                                <span>Hold</span>
                            </span>: null
                            }
                             { appState.assuranceAction === 'stop' ?   
                            <span>
                                <AiFillCloseCircle size={20} color='red' style={{ marginRight: '5px'}}/>
                                <span>Stop</span>
                            </span>: null
                            }
                             { appState.assuranceAction === 'waive' ?   
                            <span>
                                <VscCircleSlash size={20} color='black' style={{ marginRight: '5px'}}/>
                                <span>Waive</span>
                            </span>: null
                            }
                            { dropdownStatus ?
                                <AiFillCaretUp fontSize={20} style={{ float: 'right',marginTop: '1px'}}/>
                                :
                                <AiFillCaretDown fontSize={20} style={{ float: 'right',marginTop: '1px'}}/>
                            }
                        </div>
                        { dropdownStatus ? 
                        <>
                           { appState.assuranceAction !== 'waiting' ?
                            <div className="dropdown-action-list" onClick={() => assuranceActionShow('waiting')}>
                                <AiOutlineClockCircle size={18} color={Color.Grey} style={{ marginRight: '5px'}}/>
                                <span>Waiting for Start</span>
                            </div>:null
                            }
                            { appState.assuranceAction !== 'inprogress' ?
                            <div className="dropdown-action-list" onClick={() => assuranceActionShow('inprogress')}>
                                <AiOutlineClockCircle size={18} color={Color.Yellow} style={{ marginRight: '5px'}}/>
                                <span>In Progress</span>
                            </div>:null
                            }
                            { appState.assuranceAction !== 'pass' ?
                            <div className="dropdown-action-list" onClick={() => assuranceActionShow('pass')}>
                                <AiFillCheckCircle size={18} color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span>Pass</span>
                            </div>:null
                            }
                            { appState.assuranceAction !== 'recycle' ?
                            <div className="dropdown-action-list" onClick={() => assuranceActionShow('recycle')}>
                                <AiOutlineTrademarkCircle size={20} color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span>Recycle</span>
                            </div> : null
                            }
                            { appState.assuranceAction !== 'hold' ?
                            <div className="dropdown-action-list" onClick={() => assuranceActionShow('hold')}>
                                <AiOutlinePauseCircle size={20} color='orange' style={{ marginRight: '5px' }} />
                                <span>Hold</span>
                            </div>: null
                            }
                            { appState.assuranceAction !== 'waive' ?
                              <div className="dropdown-action-list" onClick={() => assuranceActionShow('waive')}>
                               
                                <VscCircleSlash size={20} color='black' style={{ marginRight: '5px'}}/>
                                <span>Waive</span>
                            
                            </div>:null
                            }
                            { appState.assuranceAction !== 'stop' ?
                            <div className="dropdown-action-list" onClick={() => assuranceActionShow('stop')}>
                                <AiFillCloseCircle size={20} color='red' style={{ marginRight: '5px'}}/>
                                <span>Stop</span>
                            </div> : null
                            }
                        </>:null
                        }
                        
                        
                    </div>
                </div>
                <div className="col-md-6" style={{ display: 'flex' }}>
            
                </div>
            </div>
        
            <div className='row' style={{ marginTop: '30px'}}>
                <div className='col-md-4'>
                   

                </div>
                <div className='col-md-8' style={{ textAlign: 'right'}}>
                    <span style={{ marginRight: '5px'}}>
                        <span style={{ fontWeight: 'bold', fontSize: '12px' }}>Verification Status:</span>
                    </span>
                    <span>
                        <VscCheck size={18} color={Color.Green} style={{ marginRight: '5px'}}/>
                        <span style={{ fontSize: '13px'}}>Accept</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscClose size={20} color="red" style={{ marginRight: '5px'}}/>
                        <span style={{ fontSize: '13px'}}>Reject</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <RiArrowGoBackFill size={18} color={Color.Blue} style={{ marginRight: '5px'}}/>
                        <span style={{ fontSize: '13px'}}>Reverse</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/>
                        <span style={{ fontSize: '13px'}}>Waiting for Verification</span>
                    </span>
                    <span style={{ marginLeft: '10px'}}>
                        <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>
                        <span style={{ fontSize: '13px'}}>Wait for Deliverable</span>
                    </span>
                </div>
            </div>
            
        <div className="table-responsive table-responsive-sm" >
            <table className="table deliverable-table">
                <thead>
                    <tr style={{ borderTop: '1px solid'}}>
                        <th>Verification Status</th>
                            <th style={{ textAlign: 'center', minWidth: '600px' }}>Deliverable Name</th>
                            <th style={{ width: '150px' }}>Recommendation</th>
                            <th style={{ width: '150px' }}>Focal Point Comment</th>
                            <th style={{ width: '150px' }}>Gatekeeper Feedback</th>
                        <th style={{ width: '100px'}}>
                            <span>Deliverable Type</span>
                            <a data-tip data-for='deliverable-type-info' style={{cursor: 'pointer'}}>
                                <AiOutlineInfoCircle color='#00338D' size={20} style={{ verticalAlign: 'middle',marginLeft: '2px'}}/>
                            </a>
                            <ReactTooltip id='deliverable-type-info' data-tooltip-html={true}  backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true} offset={{ top: 8}}>
                                <div>M = Mandatory</div>
                                <div>C = Conditional</div>
                            </ReactTooltip>
                        </th>
                        
                        <th style={{ textAlign: 'center',minWidth: '100px'}}>Verified by</th>
                    </tr>
                </thead>
                { appState && !appState.isLoading ?
                     <tbody>
                    { appState.projectDeliverableDocuments.map((item,index) =>(
                        <>
                            <tr key={uniqid()} className="deliverable-tr" style={{ borderTop: '2px solid #ddd'}}>
                            <td  >
                            <div className="deliverable-user">
                            { !appState?.arrowDown[index] ?
                                    <VscChevronRight size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setArrowDown(index,true)}/>
                                    :
                                    <VscChevronDown size={20} cursor="pointer" color={Color.Blue} style={{ float: 'left'}} onClick={ () => setArrowDown(index,false)}/>
                                }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Accept ? <VscCheck size={18} color={Color.Green} style={{ marginRight: '5px'}}/> : null }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Reject ?   <VscClose size={20} color="red" style={{ marginRight: '5px'}}/> : null}
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.WaitingForVerification ?   <VscIssueReopened size={20} color="#FFC107" style={{ marginRight: '5px'}}/> : null }
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.WaitingForAttach ?    <VscCircleLargeOutline size={20} color='grey' style={{ marginRight: '5px'}}/>: null}
                                { item.deliverableDocumentStatusId == StatusDeliverableDocument.Waive ?    <VscCircleSlash size={20} color='grey' style={{ marginRight: '5px'}}/>: null}
                            </div>
                            </td>
                        
                            <td className="deliverable-doc">
                            <div className="deliverable-action">
                                { item.projectDeliverableItemFiles.length > 0 && item.deliverableDocumentStatusId !== StatusDeliverableDocument.Accept ?
                                    <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'accept')}>
                                        <VscCheck size={18} color={Color.Green}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Accept</span>
                                    </span>:null
                                }
                                { item.projectDeliverableItemFiles.length > 0 && item.deliverableDocumentStatusId !== StatusDeliverableDocument.Reject ?
                                <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'reject')}>
                                        <VscChromeClose size={18} color={'red'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reject</span>
                                    </span>:null
                                }
                                { item.deliverableDocumentStatusId !== StatusDeliverableDocument.Waive ?
                                    <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'waive')}>
                                        <VscCircleSlash size={18} color={'black'}  cursor="pointer"/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Waive</span>
                                    </span>:null
                                }

                                { item.deliverableDocumentStatusId === StatusDeliverableDocument.Accept ||  item.deliverableDocumentStatusId === StatusDeliverableDocument.Reject || item.deliverableDocumentStatusId === StatusDeliverableDocument.Waive?
                                    <span style={{ cursor: 'pointer'}}   onClick={() => statusActionShow(item,'reverse')}>
                                        <RiArrowGoBackFill size={19} color={Color.Blue} cursor="pointer" style={{ verticalAlign: 'top'}}/>
                                        <span style={{ fontSize: '12px',marginLeft:'2px'}}>Reverse</span>
                                    </span>:null
                                }


                                
                               
                                { item.projectDeliverableItemFiles.length > 0 ?
                                        <span style={{ cursor: 'pointer'}}  onClick={() => downloadZip(item,index)}>
                                            <VscCloudDownload size={18} color={Color.Blue} cursor="pointer"/>
                                            <span style={{ fontSize: '12px',marginLeft:'2px'}}>Download All</span>
                                        </span>:null
                                }
                                        
                            </div>
                            <AiOutlineFolder size={25} style={{ paddingRight:'5px'}}/><span style={{ fontWeight:'bold'}}>{item.deliverableItemName} ({item.projectDeliverableItemFiles.length})</span>
                            { appState?.arrowDown[index] ?
                                    <>
                                   
                                        <table key={uniqid()} className="file-table" style={{ width: '95%'}}>
                                            <thead  key={uniqid()}>
                                            <tr>
                                                <td colSpan={3} style={{textAlign: 'left',paddingLeft: '40px'}}>
                                                    <div  style={{ fontWeight: 'bold',padding: '5px',textDecoration:'underline'}}>Files/Urls</div>
                                                </td>
                                            </tr>
                                            </thead>
                                            {item.projectDeliverableItemFiles && item.projectDeliverableItemFiles.map((file) => (
                                               <tbody  key={uniqid()}>
                                               <tr>
                                                    <td style={{ paddingLeft: '55px',width: '50px'}}>
                                                        { file.itemType === 'file' ? 
                                                            <span>
                                                                { file.name.indexOf('.pdf') > -1 ? 
                                                                    <AiOutlineFilePdf size={18} color={'red'}/> : 
                                                                        file.name.indexOf('.xls') > -1 ||  file.name.indexOf('.xlsx') > -1 ? 
                                                                            <AiOutlineFileExcel size={18} color={Color.Green}/> :
                                                                                file.name.indexOf('.jpg') > -1 ||  file.name.indexOf('.jpeg') > -1 ||  file.name.indexOf('.png') > -1 ? 
                                                                                    <AiOutlineFileImage size={18} color={Color.DarkBlue}/> :
                                                                                        file.name.indexOf('.doc') > -1 ||  file.name.indexOf('.docx') > -1 ? 
                                                                                            <AiOutlineFileWord size={18} color={Color.DarkBlue} /> :
                                                                                                <AiOutlineFile size={18}/> 
                                                                }
                                                            </span> 
                                                            : 
                                                            <span style={{ cursor:'pointer'}}  onClick={() => redirectUrl(file.path)}><VscLink color={Color.Blue} size={18}/></span>  
                                                        }
                                                    </td>
                                                    <td style={{textAlign: 'left'}}>
                                                        { file.itemType === 'file' ? 
                                                            <span style={{marginRight: '10px'}}>{file.name}</span>
                                                            :
                                                            <span className="font-blue" style={{ cursor:'pointer'}}  onClick={() => redirectUrl(file.path)}>{file.name}</span>
                                                        }
                                                    </td>
                                                    <td style={{ minWidth:'80px',textAlign:'right'}}>
                                                        { file.itemType === 'file' ?
                                                        
                                                            <VscCloudDownload cursor="pointer" size={20} className="deliverable-download" onClick={() => download(file)}/>:null
                                                           
                                                        
                                                        
                                                        }
                                                       
                                                    </td>
                                                </tr>
                                                </tbody>
                                                 ))
                                            }
                                            
                                            
                                        </table>
                                       
                                    
                                    </>:null
                            }
                          
                        </td>
                        <td style={{textAlign: 'left'}}>
                                    <span className="deliverable-desc" style={{ paddingTop: '32px' }}>{item.recomendation}</span>
                        </td>
                        <td className="deliverable-doc">
                                    <div className="deliverable-action" style={{ float: 'right' }}>
                                       
                                    </div>
                                    <span className='deliverable-desc' style={{ paddingTop: '32px',fontSize:'13px' }}>{item.focalPointComment}</span>
                        </td>
                        <td className="deliverable-doc">
                                    <div className="deliverable-action" style={{ float: 'right' }}>
                                        { !item.gatekeeperFeedback ?     
                                            <span style={{ cursor: 'pointer' }} onClick={() => feedbackShow(item)}>
                                                <VscAdd size={18} color={'#009FDA'} cursor="pointer" onClick={() => feedbackShow(item)} />
                                                <span style={{ fontSize: '12px', marginLeft: '2px' }}>Feedback</span>
                                            </span>
                                            :
                                            <span style={{ cursor: 'pointer' }} onClick={() => feedbackShow(item)}>
                                                <AiOutlineEdit size={18} color={'#009FDA'} cursor="pointer" onClick={() => feedbackShow(item)} />
                                                <span style={{ fontSize: '12px', marginLeft: '2px' }}>Feedback</span>
                                            </span>
                                        }
                                    </div>
                                    <span className='deliverable-desc' style={{ paddingTop: '0',fontSize:'13px' }}>{item.gatekeeperFeedback}</span>

                        </td>
                        <td style={{ paddingTop: '32px' }}>
                            <span className="deliverable-desc" style={{ fontSize:'13px',fontWeight: 'bold'}}>{ item.deliverableTypeName}</span>
                        </td>
                     
                        <td>
                            <div className='deliverable-user' style={{ paddingTop: '0' }}>
                                <div style={{fontSize:'13px'}}>{item.verifyBy }</div>
                                <div style={{fontSize:'13px'}}>{ DateHelper.getDate(item.verifyDateTime)}</div>
                            </div>
                        </td>
                            </tr>
                           
                        </>
                    ))
                    }
                    </tbody>:<Loading text="Loading..."/>
                }
                    
            </table>
        </div>

            <Modal show={appState.isStatusShow} onHide={() => statusActionClose()} size="sm" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue" >
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => statusActionClose()}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                        { appState.action === 'accept' ? <VscCheck size={20} color={Color.Green}/> : null }
                        { appState.action === 'reject' ? <VscClose size={25} color={'red'} />: null }
                        { appState.action === 'waive' ? <VscCircleSlash size={22} color={'black'} />: null }
                        { appState.action === 'reverse' ? <RiArrowGoBackFill size={22} color={Color.Blue} />: null }
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}> Would you like to {appState.action}?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateStatus()} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => statusActionClose()} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>

        <Modal show={appState.isAssuranceShow} onHide={() => assuranceActionClose()} size="lg"  style={{top:'20%'}}>
         <Modal.Header className="bg-dark-blue">
            <Modal.Title style={{ fontSize: '15px'}}>Assurance Status Update</Modal.Title>
            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => assuranceActionClose()}>X</span>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
                <div>
                    <span style={{fontWeight:'bold',fontSize:'14px'}}>Please fill remark for update assurance status </span>
                            { appState.assuranceAction === 'waiting' ?
                            <span>
                                <AiOutlineClockCircle size={30} color={Color.Grey} style={{ marginRight: '5px'}}/>
                                <span style={{ display:'none'}}>Waiting for Start</span>
                            </span>: null
                            }
                            { appState.assuranceAction === 'inprogress' ?
                            <span>
                                <AiOutlineClockCircle size={30} color={Color.Yellow} style={{ marginRight: '5px'}}/>
                                <span style={{ display:'none'}}>In Progress</span>
                            </span>: null
                            }
                            { appState.assuranceAction === 'pass' ?
                            <span>
                                <AiFillCheckCircle size={30} color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span style={{ display:'none'}}>Pass</span>
                            </span>: null
                            }
                            { appState.assuranceAction === 'recycle' ?
                            <span>
                                <AiOutlineTrademarkCircle size={30} color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span style={{display:'none'}}>Recycle</span>
                            </span>: null
                            }
                             { appState.assuranceAction === 'hold' ?
                            <span>
                             <AiOutlinePauseCircle size={30} color='orange' style={{ marginRight: '5px' }} />
                                <span style={{display:'none'}}>Hold</span>
                            </span>: null
                            }
                             { appState.assuranceAction === 'stop' ?   
                            <span>
                                <AiFillCloseCircle size={30} color='red' style={{ marginRight: '5px'}}/>
                                <span style={{display:'none'}}>Stop</span>
                            </span>: null
                            }
                             { appState.assuranceAction === 'waive' ?   
                            <span>
                                <VscCircleSlash size={30} color='black' style={{ marginRight: '5px'}}/>
                                <span style={{display:'none'}}>Waive</span>
                            </span>: null
                            }
                </div>
              <div>
                    <span className="col-label font-blue" style={{ marginRight: '5px',float: 'left' }}>Remark Assurance Status:</span>
                    <textarea id="input-remark" className="form-control" style={{height:'100px'}}></textarea>
              </div>
              <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateAssuranceStatus()} >
                  <span style={{ marginLeft: '5px'}} >Save</span>
               </button>
               <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => cancelUpdateAssuranceStatus()} >
                        <span style={{ marginLeft: '5px'}} >Cancel</span>
                    </button>
          </Modal.Body>
        </Modal>

        <Modal show={appState.isFeedbackShow} onHide={() => feedbackClose()} size="lg"  style={{top:'20%'}}>
         <Modal.Header className="bg-dark-blue">
            <Modal.Title style={{ fontSize: '15px'}}>Gatekeeper Feedback</Modal.Title>
            <span style={{marginRight:'5px',cursor:'pointer'}} onClick={() => feedbackClose()}>X</span>
         </Modal.Header>
          <Modal.Body style={{ textAlign: 'center'}}>
              <div>
              <textarea id="gatekeeper-feedback" defaultValue={appState.projectDeliverableDocument?.gatekeeperFeedback} className="form-control"></textarea>
              </div>
              <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateFeedback()} >
                  <span style={{ marginLeft: '5px'}} >Save</span>
               </button>
               <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => feedbackClose()} >
                        <span style={{ marginLeft: '5px'}} >Cancel</span>
             </button>
          </Modal.Body>
        </Modal>

        </div>
    
    );
  
}

export default GatekeeperDocument