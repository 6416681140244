import React, { useEffect } from 'react';
import Chart from 'chart.js/auto';
import { ProjectProgress,AuthenUser,RoleProjectLevel } from '../../models'
import { DateHelper,StringHelper } from '../../helpers';
import { Color } from '../../enum';
import uniqid from 'uniqid';

interface Props{
  planChartId:string
  costChartId:string
  phaseId: number
  phaseNumber:number
  progresses: ProjectProgress[]
  trackBudget: number
  trackProgress: number
  spi:number
  bpi:number
  isTeamMember:boolean
  isSuperAdmin:boolean
  user:AuthenUser
  projectRole:RoleProjectLevel | null,
}

const StatusChartPdf = (props:Props) => {
  const progresseData:ProjectProgress[] = props.progresses
  var costChart:Chart
  var planActualChart:Chart
  
  useEffect(() => {
    createChart()
  });

  const createChart = () => {
     
      let progressPlan:any[] = []
      let progressActual:any[] = []
      let spending:any[] = []
      let budget:any[] = []
      let planCost:any[] =[]
      let actualCost:any[] = []
      let labels: string[] = []
      
      if(progresseData.length > 0 && (props.trackBudget || props.trackProgress)){
          var summaryDate = DateHelper.getDate(progresseData[progresseData.length -1 ].progressDate)
          var summaryElement = document.getElementById('summary-label-' + props.phaseNumber) as HTMLDivElement
          if(summaryElement){
            summaryElement.innerHTML = StringHelper.htmlEncode("Summary as of  ") + summaryDate
          }
          
  
      }
   
      progresseData.forEach(item => {
          let planProgressValue = item.planProgress >= 0 ? item.planProgress : null
          let progressActualValue = item.actualProgress >=0 ? item.actualProgress : null
          let budgetValue = item.costProjection >=0 ? item.costProjection : null
          let spendingValue = item.costCumulative >=0 ? item.costCumulative : null
          let planCostValue = item.planCost >=0 ? item.planCost : null
          let actualCostValue = item.actualCost >=0 ? item.actualCost : null

          progressPlan.push(planProgressValue)
          progressActual.push(progressActualValue)
          spending.push(spendingValue)
          budget.push(budgetValue)
          planCost.push(planCostValue)
          actualCost.push(actualCostValue)
          labels.push(DateHelper.getMonthYear(item.progressDate))
      
      })

      if(props.trackProgress)
      {
        let element = document.getElementById(props.planChartId) as HTMLCanvasElement
        element.style.display = 'inline'
          createPlanActualChart(progressPlan,progressActual,labels)
      }
      if(props.trackBudget)
      {
        if(props.isSuperAdmin || (props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1)){
          let element = document.getElementById(props.costChartId) as HTMLCanvasElement
          element.style.display = 'inline'
          createCostChart(spending,budget,actualCost,planCost,labels)
        }
      }
        
    
}

  const createPlanActualChart = (progressPlan:number[],progressActual:number[],labels:string[]) => {
    if(planActualChart){
      planActualChart.destroy()
    }

    let canvasElement = document.getElementById(props.planChartId) as HTMLCanvasElement
   
    const data = {
      labels: labels,
      datasets: [
        {
          label: 'Progress Plan',
          backgroundColor: Color.Blue,
          borderColor: Color.Blue,
          data:progressPlan,
          tension: 0.8
        },
        {
          label: 'Progress Actual',
          backgroundColor: Color.Red,
          borderColor: Color.Red,
          data: progressActual,
          tension: 0.8
        }
      ]
    };

    planActualChart = new Chart(
      canvasElement,
      {
        type: 'line',
        data: data,
        options: {
          scales: {
            y: {
              title:{
                display: true,
                text: '%',
                color: 'grey',
                font: {
                  size: 12,
                  family: 'tahoma'
                },
              },
              min: 0
            }
          },
          plugins:{
            title: {
              display: true,
              text: "Project Progress",
              font:{
                size: 14
              }
            },
            subtitle: {
              display: false,
              text: 'Summary as of Mar 03,2022',
              color: '#00338D',
              font: {
                size: 12,
                family: 'tahoma',
                weight: 'bold',
                style: 'italic'
              },
              padding: {
                bottom: 10
              }
            }
          }
        }
      }
    );
    //console.log(myChart)
    //myChart.canvas.parentNode.style.height = '150px';
    //myChart.canvas.parentNode.style.width = '100%';
  }

  const createCostChart = (spending:any[],budget:any[],actualCost:any[],planCost:any[],labels:any[]) => {
    if(costChart){
      costChart.destroy()
    }

    let canvasElement = document.getElementById(props.costChartId) as HTMLCanvasElement
    let budgetSet = {
      label: 'Current Approved Budget',
      backgroundColor: Color.Red,
      borderColor: Color.Red,
      data: budget,
      tension: 0.8
    }
    let spendingSet = {
      label: 'Estimated Spending at Completion',
      backgroundColor: Color.DarkBlue,
      borderColor: Color.DarkBlue,
      data: spending,
      tension: 0.8
    }
    let actualCostSet = {
      label: 'Actual Cost',
      backgroundColor: Color.Blue,
      borderColor: Color.Blue,
      data: actualCost,
      tension: 0.8
    }
    let planCostSet =  {
      label: 'Plan Cost',
      backgroundColor: Color.Green,
      borderColor: Color.Green,
      data: planCost,
      tension: 0.8
    }

    let dataSets = [spendingSet,budgetSet,actualCostSet,planCostSet]
    // if(props.phaseId === 4) {
    //     dataSets.push(projectionSet)
    //     dataSets.push(cumulativeSet)
    // }

    
    const data = {
      labels: labels,
      datasets: dataSets
    };

  
    costChart = new Chart(
      canvasElement,
      {
        type: 'line',
        data: data,
        options: {
          scales: {
            y: {
              title:{
                display: true,
                text: 'MMUSD',
                color: 'grey',
                font: {
                  size: 12,
                  family: 'tahoma'
                },
              },
              min: 0
            }
          },
          plugins:{
            title: {
              display: true,
              text: "Project Cost",
              font:{
                size: 14
              }
            },
            subtitle: {
              display: false,
              text: 'Summary as of Mar 03,2022',
              color: '#00338D',
              font: {
                size: 12,
                family: 'tahoma',
                weight: 'bold',
                style: 'italic'
              },
              padding: {
                bottom: 10
              }
            }
          }
        }
      }
    );
    //console.log(myChart)
    //myChart.canvas.parentNode.style.height = '150px';
    //myChart.canvas.parentNode.style.width = '100%';
  }

    return (
     
        <>
            <div className='row' style={{ marginTop: '30px'}}>
              <div className='col-md-12'>
                  
                  <div  className="chart-container" style={{ width: '584px',height: '250px',margin:'auto'}}> 
                      <canvas key={uniqid()} id={props.planChartId}></canvas> 
                  </div> 
              </div>
            </div>

            { props.trackBudget === 1 ?
              <>
                {props.isSuperAdmin || (props.user.sensitiveInformationView > 0 || props.projectRole?.sensitiveInformationView === 1) ?
                    <>
                        <div className='row' style={{ marginTop: '50px'}}>
                            <div className='col-md-12'>
                                  <div  className="chart-container"style={{ width: '584px',height: '250px',margin:'auto'}}> 
                                    <canvas key={uniqid()}  id={props.costChartId}></canvas> 
                                </div> 
                            </div>
                        </div>
                    </>: null
                }
              </>: null 
            }

      </>
    );
  
}

export default StatusChartPdf