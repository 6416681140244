import React, { useState, useEffect } from 'react';
import { rigGreenIcon,rigGreyIcon,rigBlueIcon } from '../images';
import uniqid from 'uniqid';
import '../assets/css/overall.css'
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AiOutlinePrinter,AiOutlineCloseSquare} from "react-icons/ai";
import { PhaseName,CategorySize,ProjectModeNumber, Color } from '../enum'
import { ProjectService,PropertyService } from '../services'
import { useApiAuth } from '../hooks/useApiAuth';
import { ProjectLocation,ProjectSummary} from '../models'
import { ProjectMode } from '../models/Property'
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Loading from './Loading';
import OverallTablePdf from './OverallTablePdf'
import { BsFillStopFill } from "react-icons/bs";
import { DateHelper} from '../helpers';

var map:google.maps.Map;
var markers:google.maps.Marker[] =[]
var groupMode = false
const center = { lat: 0.361475, lng: 23.113635 }

const OverAllPdf = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    
    useEffect(() => {
      if(accessToken){
        let urlParamMode = urlParams.get('groupmode') ? urlParams.get('groupmode') : "0"
        let urlParamZoom =  urlParams.get('zoom') ? urlParams.get('zoom') : "2"
        let urlParamLat = urlParams.get('lat') ? urlParams.get('lat') : center.lat.toString()
        let urlParamLng = urlParams.get('lng') ? urlParams.get('lng') : center.lng.toString()
        let urlParamType = urlParams.get('type') ? urlParams.get('type') : "roadmap"
  
        let groupMode:number = urlParamMode ? parseInt(urlParamMode) : 0
        let zoom:number = urlParamZoom ? parseInt(urlParamZoom) : 2
        let lat:number = urlParamLat ? parseFloat(urlParamLat) : center.lat
        let lng:number = urlParamLng ? parseFloat(urlParamLng) : center.lng
        let type:string | null | undefined = urlParamType ? urlParamType : 'roadmap '

          Promise.all([
            new ProjectService(accessToken).getLocation(),
            new ProjectService(accessToken).getSummaryProject(),
            new ProjectService(accessToken).getSummaryPhase(),
            new PropertyService(accessToken).getProperties(['projectMode'])
          ]).then((result) => {
            let loadElement = document.getElementById("loading") as HTMLDivElement
            loadElement.style.display = 'none'
            let tableTop = document.getElementById("table-top") as HTMLTableElement
            tableTop.style.display = 'block'
            let tableBottom = document.getElementById("table-bottom") as HTMLTableElement
            tableBottom.style.display = 'block'

            let projectLocations:ProjectLocation[] = []
            let projectSummaries:ProjectSummary[] = []
            let date = new Date()
            let projectModes:ProjectMode[] = []

            if(result[0] && result[0].success){
                projectLocations =  result[0].data
            }

            if(result[1] && result[1].success){
              projectSummaries = result[1].data
              date = new Date(result[1].date)

              let totalProject1 = document.getElementById("total-project-1") as HTMLSpanElement
              totalProject1.innerHTML = projectSummaries[0].totalProject.toString()
              let totalProject2 = document.getElementById("total-project-2") as HTMLSpanElement
              totalProject2.innerHTML = projectSummaries[1].totalProject.toString()
              let totalProject3 = document.getElementById("total-project-3") as HTMLSpanElement
              totalProject3.innerHTML = projectSummaries[2].totalProject.toString()

              let newRegister1 = document.getElementById("new-register-1") as HTMLSpanElement
              newRegister1.innerHTML = projectSummaries[0].totalNewRegister.toString()
              let newRegister2 = document.getElementById("new-register-2") as HTMLSpanElement
              newRegister2.innerHTML = projectSummaries[1].totalNewRegister.toString()
              let newRegister3 = document.getElementById("new-register-3") as HTMLSpanElement
              newRegister3.innerHTML = projectSummaries[2].totalNewRegister.toString()

              let totalComplete1 = document.getElementById("total-complete-1") as HTMLSpanElement
              totalComplete1.innerHTML = projectSummaries[0].totalCompleted.toString()
              let totalComplete2 = document.getElementById("total-complete-2") as HTMLSpanElement
              totalComplete2.innerHTML = projectSummaries[1].totalCompleted.toString()
              let totalComplete3 = document.getElementById("total-complete-3") as HTMLSpanElement
              totalComplete3.innerHTML = projectSummaries[2].totalCompleted.toString()

              let totalActive1 = document.getElementById("total-active-1") as HTMLSpanElement
              totalActive1.innerHTML = projectSummaries[0].totalActive.toString()
              let totalActive2 = document.getElementById("total-active-2") as HTMLSpanElement
              totalActive2.innerHTML = projectSummaries[1].totalActive.toString()
              let totalActive3 = document.getElementById("total-active-3") as HTMLSpanElement
              totalActive3.innerHTML = projectSummaries[2].totalActive.toString()

              let totalSpi1 = document.getElementById("total-spi-1") as HTMLSpanElement
              totalSpi1.innerHTML = projectSummaries[0].totalSpi.toString()
              let totalSpi2 = document.getElementById("total-spi-2") as HTMLSpanElement
              totalSpi2.innerHTML = projectSummaries[1].totalSpi.toString()
              let totalSpi3 = document.getElementById("total-spi-3") as HTMLSpanElement
              totalSpi3.innerHTML = projectSummaries[2].totalSpi.toString()

              let totalBpi1 = document.getElementById("total-bpi-1") as HTMLSpanElement
              totalBpi1.innerHTML = projectSummaries[0].totalBpi.toString()
              let totalBpi2 = document.getElementById("total-bpi-2") as HTMLSpanElement
              totalBpi2.innerHTML = projectSummaries[1].totalBpi.toString()
              let totalBpi3 = document.getElementById("total-bpi-3") as HTMLSpanElement
              totalBpi3.innerHTML = projectSummaries[2].totalBpi.toString()

              let fullYear = document.getElementById("full-year") as HTMLSpanElement
              fullYear.innerHTML = date.getFullYear().toString()
              
            }

            if(result[3] && result[3].success){
                projectModes = result[3].data.projectModes
                let projectMode1 = document.getElementById("project-mode-1") as HTMLSpanElement
                projectMode1.innerHTML = projectModes[0].projectModeName
                let mapProjectMode1 = document.getElementById("map-project-mode-1") as HTMLSpanElement
                mapProjectMode1.innerHTML = projectModes[0].projectModeName
                let projectMode2 = document.getElementById("project-mode-2") as HTMLSpanElement
                projectMode2.innerHTML = projectModes[1].projectModeName
                let mapProjectMode2 = document.getElementById("map-project-mode-2") as HTMLSpanElement
                mapProjectMode2.innerHTML = projectModes[1].projectModeName
                let projectMode3 = document.getElementById("project-mode-3") as HTMLSpanElement
                projectMode3.innerHTML = projectModes[2].projectModeName
                let mapProjectMode3 = document.getElementById("map-project-mode-3") as HTMLSpanElement
                mapProjectMode3.innerHTML = projectModes[2].projectModeName
                
            }

            if(projectLocations.length > 0){
                initMap(projectLocations,zoom,lat,lng,type,groupMode)
            }

            if(result[2] && result[2].success){
              let [epLargeData,epMediumData,beLargeData,beMediumData,decommData] = result[2].data

              if( epLargeData.reduce((a, b) => a + b, 0) > 0){
                  createDonutChart('ep-large-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Large, epLargeData, projectModes)
              }
              else{
                  createEmptyDonutChart('ep-large-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Large,projectModes)
              }

              if( epMediumData.reduce((a, b) => a + b, 0) > 0){
                  createDonutChart('ep-medium-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Medium, epMediumData, projectModes)
              }
              else{
                  createEmptyDonutChart('ep-medium-donut-chart',ProjectModeNumber.EpCapital,CategorySize.Medium,projectModes)
              }

              if( beLargeData.reduce((a, b) => a + b, 0) > 0){
                createDonutChart('be-large-donut-chart',ProjectModeNumber.Beyond,CategorySize.Large, beLargeData ,projectModes)
              }
              else{
                createEmptyDonutChart('be-large-donut-chart',ProjectModeNumber.Beyond,CategorySize.Large,projectModes)
              }

              if( beMediumData.reduce((a, b) => a + b, 0) > 0){
                createDonutChart('be-medium-donut-chart',ProjectModeNumber.Beyond,CategorySize.Medium, beMediumData ,projectModes)
              }
              else{
                createEmptyDonutChart('be-medium-donut-chart',ProjectModeNumber.Beyond,CategorySize.Medium,projectModes)
              }

              if( decommData.reduce((a, b) => a + b, 0) > 0){
                createDonutChart('decomm-donut-chart',ProjectModeNumber.Decomm,0, decommData ,projectModes)
              }
              else{
                createEmptyDonutChart('decomm-donut-chart',ProjectModeNumber.Decomm,0,projectModes)
              }
            }

          })

          var beforePrint = function() {
             //document.body.classList.add("zoomtofit")
              let overallPrinter = document.getElementById('overall-printer') as HTMLDivElement
                  overallPrinter.style.display = 'none'
              // let overallTable = document.getElementById('col-overall-table') as HTMLDivElement
              //     overallTable.style.width = '200mm'
             
          }

          var afterPrint = function() {
              //document.body.classList.remove("zoomtofit")
              let overallPrinter = document.getElementById('overall-printer') as HTMLDivElement
              overallPrinter.style.display = 'inline'
             
          }

          if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');
    
            mediaQueryList.addListener(function (mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
          }

          const wrapper = document.getElementById("wrapper") as HTMLDivElement
                wrapper.style.paddingTop = '0'
                wrapper.style.paddingBottom = '0'
          const navbar = document.getElementById("navbar") as HTMLElement
                navbar.style.display = 'none'
          const footerPage = document.getElementById("footer-page") as HTMLElement
                footerPage.style.display = 'none'
             
          window.resizeTo( window.screen.availWidth /1.5, window.screen.availHeight);
         
      }
    },[accessToken])

    
    /* Google Map Function */
    const initMap = (projectLocations: ProjectLocation[], zoom: number, lat: number, lng: number, type: string | null | undefined , groupMode: number) => {
  
      map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
        center: {lat: lat ,lng: lng },
        zoom: zoom,
        mapTypeId: type,
        //disableDefaultUI: true,
      });

      //map.setOptions({draggable: false});
      
      markers = []
      //var bounds = new google.maps.LatLngBounds();
      projectLocations.forEach((item) => {
          let rigIcon ="";
          if(item.projectModeId === 1){
              rigIcon = rigBlueIcon;
          }
          else if(item.projectModeId === 2){
              rigIcon = rigGreenIcon;
          }
          else{
              rigIcon = rigGreyIcon;
          }

          let projectName = (item.projectName.length <= 15 ) ? item.projectName : item.projectName.substring(0,15) + '...'
          let marker = new google.maps.Marker({
            position: { lat:  item.lat, lng: item.lng },
            map: map,
            icon: rigIcon,
            label: {
              text:  projectName,
              color:'white',
              className:"marker-label",
              fontSize:'11px',
              fontFamily:'tahoma'
            }
          });

          markers.push(marker)
          //bounds.extend( { lat:  item.lat, lng: item.lng });
      })
        
     // map.fitBounds(bounds);

      if(groupMode){
        new MarkerClusterer({ markers, map });
      }
      
    }

  
  /*
  const selectedRequestBy: IPickerItem<IEmployee> | null = searchValues.requestBy
      ? { key: searchValues.requestBy.id!, name: searchValues.requestBy.name!, data: searchValues.requestBy }
      : null;

*/
  /* End Google Map Function */
  
    
    const createDonutChart = (element:string,projectModeId:number,categorySizeId:number,data:number[],projectModes:ProjectMode[]) => {
    
      var title = ""
      var subTitle = ""
      if(categorySizeId === 0){
        if(projectModeId == 3){
          title = projectModes[2].projectModeName
        }
      }

      if(categorySizeId === 1){
          subTitle = "Large"
          if(projectModeId === 1){
            title = projectModes[0].projectModeName
          }
          if(projectModeId === 2){
            title = projectModes[1].projectModeName
          }
         
      }

      if(categorySizeId === 2){
        subTitle = "Medium"
      }

      if(categorySizeId === 3){
        subTitle = "Decomm"
      }

      var backgroundColor = [
        Color.Red,
        Color.DarkBlue,
        Color.Green,
        Color.Blue,
        Color.Grey
      ]

      if(title === 'Decommissioning'){
        backgroundColor[4] = Color.LightGrey
      }
      
      const centerText = {
        id:'centerText',
        afterDatasetsDraw(chart,args,pluginOption){
            const { ctx } = chart;
            ctx.save()
            const x = chart.getDatasetMeta(0).data[0].x
            const y = chart.getDatasetMeta(0).data[0].y
            let total = data.reduce((a, b) => a + b, 0)
          let totalX = x
          let totalY = y
          let length = total.toString().length
          
            if(length === 1){
              totalX = x - 5
            }
            if(length === 2){
              totalX = x - 8
            }
            if(length === 3){
              totalX = x - 15
            }
            if(length === 4){
              totalX = x - 20
            }

            ctx.font = 'bold 15px Tahoma'
            ctx.fillText(total,totalX, totalY)

            if(subTitle == 'Large'){
              ctx.font = 'bold 9px Tahoma'
              ctx.fillText('Large Project',x - 30  ,y + 14)
            }
            if(subTitle == 'Medium'){
              ctx.font = 'bold 9px Tahoma'
              ctx.fillText('Medium Project',x - 35  ,y + 14)
            }
          
            
        }
      }

      const canvasElement = document.getElementById(element)  as HTMLCanvasElement
      
      var myChart = new Chart(
        canvasElement,
        {
          type: 'doughnut',
          data:{
            labels: [],
            datasets: [{
              //label: 'Asset Type vs Phase vs Active Project',
             // title: "",
              data:  data ,
              backgroundColor: backgroundColor ,
              hoverOffset: 4
            }]
        },
        plugins: [ChartDataLabels,centerText],
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
              font:{
                size: 14
              }
            },
            
            datalabels: {
              color: 'white',
              font: {
                weight: 'bold'
              },
              padding: 6,
              formatter: function(value) {
                if(value >0 ){
                    return value;
                }else{
                    value = "";
                    return value;
                }
              }
            },
            legend: {
              position: 'chartArea',
              labels: {
                  font: {
                      size: 14
                  }
              },
              onClick: newLegendClickHandler
            },
            
          },
          responsive: true,
          maintainAspectRatio: false,
          
        }
      })
      
      function clickHandler(evt) {
        const points = myChart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

        if (points.length) {
            const firstPoint = points[0];
            window.location.href = '/dashboard?projectMode=' + projectModeId + '&categorySize=' + categorySizeId + '&phase=' + (firstPoint.index + 1) + '&projectStatus=1'
           
        }
      }
      canvasElement.onclick = clickHandler

    }

    const createEmptyDonutChart = (element:string, projectModeId:number, categorySizeId:number, projectModes:ProjectMode[]) => {
      var title = ""
      var subTitle = ""
      if(categorySizeId === 0){
        if(projectModeId == 3){
          title = projectModes[2].projectModeName
        }
      }

      if(categorySizeId === 1){
          subTitle = "Large"
          if(projectModeId === 1){
            title = projectModes[0].projectModeName
          }
          if(projectModeId === 2){
            title = projectModes[1].projectModeName
          }
         
      }

      if(categorySizeId === 2){
        subTitle = "Medium"
      }

      
      const canvasElement = document.getElementById(element)  as HTMLCanvasElement
      
      new Chart(
        canvasElement,
        {
          type: 'doughnut',
          data:{
            labels:  [],
            datasets: [{
              data:  [1,0,0,0,,0] ,
              backgroundColor: "rgb(187, 187, 187)" ,
              hoverOffset: 4
            }]
        },
        //plugins: [centerText],
        options: {
          plugins: {
            title: {
              display: true,
              text: title,
              font:{
                size: 14
              }
            },
            tooltip: {enabled: false},
          },
          responsive: true,
          maintainAspectRatio: false,
          
        }
    })
     
    }

    // const onGroupModeChange = () =>{
    //   groupMode = !groupMode
    //   let mapModeSpan = document.getElementById('map-mode') as HTMLSpanElement
    //   mapModeSpan.innerHTML = groupMode ? 'Group View' : 'Single View'
      
    //   let length = markers.length
    //   for(let i = 0; i < length ; i++){
    //     markers[i].setMap(null)
    //   }
    //   let zoom = map.getZoom()
    //   let pos = map.getCenter()
    //   let lat = pos ? pos.lat() : center.lat
    //   let lng = pos ? pos.lng() : center.lng
    //   let type =  map.getMapTypeId() ? map.getMapTypeId() : 'roadmap '
    //   initMap(appState.projectLocations,zoom,lat,lng,type,false)
    // }
    const newLegendClickHandler = () => {
      return false
    }

     return (
      <div>
        
        <div style={{ "padding" :"8px 15px"}}>
            
                  <div id="overall-printer" className='row' style={{ minHeight:'35px',marginTop:'20px', marginBottom:'20px'}}>
                                            <div className='col-md-12' style={{ textAlign: 'center'}}>
                                                <button   type="button" className="btn btn-sm btn-print"  onClick={() => window.print()}>
                                                    <AiOutlinePrinter size={30} />
                                                    <span style={{ marginLeft:'5px'}}>Print</span>
                                                </button>
                                                <AiOutlineCloseSquare id="printer-close"  size={35} color='red' style={{ float:'right',cursor:'pointer'}} onClick={() => { window.close() }}/>
                                            </div>
                                        </div>
              <div id="overall-map" className='overall-map'>
                          <div className='overall-legend'>
                            <img src={rigBlueIcon}/>
                            <span id="map-project-mode-1"></span>
                            <img src={rigGreenIcon}/>
                            <span id="map-project-mode-2"></span>
                            <img src={rigGreyIcon}/>
                            <span id="map-project-mode-3"></span>
                          </div>
              </div>
              <div id="map" style={{ width: '100%', height: '400px', margin: 0, padding: 0 ,display: 'inline-flex'}}></div>
              <table id="table-top" style={{ width: '200mm',margin:'auto',display:'none'}}>
                  <tr>   
                    <td>
                          
                         
                    </td>
                  </tr>
                  <tr>
                      <td style={{ paddingTop: '20px',paddingBottom: '10px'}}>
                          <div className='overall-topic-chart' >Project Summary</div>
                      </td>
                  </tr>
                  <tr>
                      <td  colSpan={3} >
                          <OverallTablePdf/>
                      </td>                 
                  </tr>
              </table>

              <div className="pagebreak"> </div>

              <table id="table-bottom" style={{ width: '210mm',margin:'auto',display:'none'}}>
                  <tr  style={{padding: '5px',margin:'auto'}}>
                    <td colSpan={3} style={{ paddingTop: '20px',paddingBottom: '10px'}}>
                              <div className='overall-topic-chart'>
                                <div>Number of Active Project by Phase</div>
                              </div>
                    </td>
                  </tr>
                  <tr>
                      <td style={{  borderRight: '1px solid #ddd'}}>
                        <canvas key={uniqid()} id="ep-large-donut-chart" style={{ width:'66.66mm', height: '200px'}}></canvas>
                                   
                      </td>
                      <td  style={{ borderRight: '1px solid #ddd'}}>
                          <canvas key={uniqid()} id="be-large-donut-chart" style={{ width:'66.66mm',height: '200px'}}></canvas>                                   
                      </td>
                      <td>
                          <canvas key={uniqid()} id="decomm-donut-chart" style={{ width:'66.66mm',height: '200px'}}></canvas>
                      </td>
                  </tr>
                  <tr>
                          <td style={{ borderRight: '1px solid #ddd'}}>
                              <canvas key={uniqid()} id="ep-medium-donut-chart" style={{ width:'66.66mm',height: '200px'}}></canvas>  
                          </td>
                          <td style={{ borderRight: '1px solid #ddd'}}>
                              <canvas key={uniqid()} id="be-medium-donut-chart" style={{ width:'66.66mm',height: '200px'}}></canvas>  
                          </td>
                          <td style={{ paddingTop: '60px',textAlign: 'left',fontSize:'13px'}}>
                              <div style={{ paddingLeft: '50px'}}>
                                      <div>
                                        <BsFillStopFill color={Color.Red} size={20} />
                                        <span style={{ color:'grey'}}>Phase1-Assess </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.DarkBlue} size={20} />
                                        <span style={{ color:'grey'}}>Phase2-Select </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.Green} size={20} />
                                        <span style={{ color:'grey'}}>Phase3-Define </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.Blue} size={20} />
                                        <span style={{ color:'grey'}}>Phase4-Execute </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.Grey} size={20} />
                                        <span style={{ color:'grey'}}>Phase5-Operate </span>
                                      </div>
                                      <div>
                                        <BsFillStopFill color={Color.LightGrey} size={20} />
                                        <span style={{ color:'grey'}}>Phase5-Post Decomm. </span>
                                      </div>
                              </div> 
                          </td>   
                  </tr>
              </table>
                                                                 
                    <div className="divFooter">
                      <div>{account?.name}</div>
                        <div style={{float: 'right'}}>{DateHelper.getDateTime(new Date())}</div>
                    </div>
                  
              <div id="loading" style={{textAlign: 'center',display:'inline'}}>
                <button className="btn btn-primary" type="button" style={{margin:'auto',backgroundColor: 'white',border: 'none',color: Color.Blue}} disabled>
                  <span className="spinner-border" role="status" aria-hidden="true" style={{ width : '5rem',height: '5rem',borderWidth:'0.8rem'}}></span>
                  <span style={{ fontSize: '33px',fontWeight: 'bold'}}>Loading...</span>
                </button>
              </div>
                
               
        </div>
        
      </div>
 
    );

}

export default OverAllPdf