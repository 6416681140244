import React, { useState,useEffect } from 'react';
import '../../assets/css/portfolio.css'
import { AiOutlineClockCircle, AiOutlineInfoCircle, AiFillCloseCircle, AiOutlinePauseCircle, AiFillCheckCircle, AiOutlineTrademarkCircle } from "react-icons/ai";
import {  VscCircleSlash, VscDebugStepOver } from "react-icons/vsc";
import { Color, SystemLevel,StatusDeliverable ,ProjectLevel} from '../../enum'
import { DateHelper} from '../../helpers';
import Modal from 'react-bootstrap/Modal'
import ReactTooltip from "react-tooltip";
import FocalPointDocument from './FocalPointDocument'
import GatekeeperDocument from './GatekeeperDocument'
import { Phase,AuthenUser,RoleProjectLevel,ProjectDeliverable, ProjectPhase,UserItem,ProjectPhaseRole} from '../../models'
import uniqid from 'uniqid';
import Loading from '../Loading';
import { config } from '../../config';

interface Props {
    isUpdate: boolean 
    projectId:number
    projectName:string
    projectModeId:number 
    phaseId:number 
    phases:Phase[] 
    user:AuthenUser
    projectOwners:UserItem[]
    gatekeepers:UserItem[]
    projectPhaseRoles:ProjectPhaseRole[]
    projectRole:RoleProjectLevel | null
    projectDeliverables:{ [key:string]:ProjectDeliverable[] }
    projectPhases:ProjectPhase[]
}

const PrepStatus = (props:Props) => {
    const isCanDeliverableUpdate = (props.user.deliverableUpdate || props.projectRole?.deliverableUpdate ) ? true : false
    const isCanAssuranceActivityUpdate = (props.projectRole?.deliverableApprove  || props.projectRole?.assuranceActivityUpdate  ) ? true : false
    const isGatekeeper =  props.user.roleSystemLevelId == SystemLevel.GateKeeper || props.projectRole?.roleProjectLevelId == ProjectLevel.GateKeeper ? true : false
    // const thBgColor = '#36a2eb'
    const iconSize = 28
    const blockHeight = 90
    const [isFlowShow, setIsFlowShow] = useState(false);
    const [flowNumber, setFlowNumber] = useState(0);
    const [isLoading,setIsloading] = useState(true)
    const [maxHeight,setMaxHeight] = useState(200)
    const [isFocalPointShow,setIsFocalPointShow] = useState(false)
    const [isGatekeeperShow,setIsGatekeeperShow] = useState(false)
    const [projectPhases,setProjectPhases] = useState<ProjectPhase[]>(props.projectPhases)
    const [projectDeliverables,setProjectdeliverables] = useState<{[key:string]:ProjectDeliverable[]}>(props.projectDeliverables)
    const [projectDeliverable,setProjectdeliverable] = useState<ProjectDeliverable>(
        {
            projectDeliverableId:0,
            projectId:0,
            projectPhaseId:0,
            phaseId:0,
            stageId:0,
            stageName:"",
            stageCode:"",
            assuranceActivityId:0,
            assuranceActivityName:"",
            remark:"",
            color:"",
            deliverableStatusId:0,
            deliverableStatusName:"",
            completeDateTime:null,
            updateDateTime:null,
            updateBy: ""

        }
    )
    const [phaseStatus,setPhaseStatus] = useState<{[key:number]:{waivePhase:boolean,waivePhaseReason:string} }>({})

    useEffect(() => {
        let status = {}
        props.projectPhases.forEach((item) => {
            status[item.phaseId] = { 
                waivePhase: item.waivePhase == 1 ? true : false,
                waivePhaseReason: item.waivePhaseReason
            }
        })
        let max = 200;
        let h = blockHeight + 5
        for (const [key, value] of Object.entries(projectDeliverables)) {
            if(value.length > 0 && !status[value[0].phaseId].waivePhase){
                let maxHeight = ((value.length + 1) * h)
                if(max < maxHeight){
                    max = maxHeight
                }
            }
        }
          
        //console.log(status)
        setMaxHeight(max)
        setPhaseStatus(status)
        setIsloading(false)
        setTimeout(() => {
            onWindowResize()
        }, 1000);
        
        
        window.addEventListener("resize", () => {
            onWindowResize()
        });

        var beforePrint = function() {
            setStageNameShow(0)
            setStageCodeShow(1)
            setPrepStatusLabelDiv('15px')
            setPrepStatusIconSpan('11px')
        };
    
        var afterPrint = function() {
            onWindowResize()
            setPrepStatusLabelDiv('5px')
            setPrepStatusIconSpan('13px')
        };

        if (window.matchMedia) {
            var mediaQueryList = window.matchMedia('print');

            mediaQueryList.addListener(function (mql) {
                if (mql.matches) {
                    beforePrint();
                } else {
                    afterPrint();
                }
            });
            
         }

    },[])

    const onWindowResize = () => {
        let element = document.getElementById('prep-status') as HTMLDivElement
        if(element && element.offsetWidth < 1100){
            setStageNameShow(0)
            setStageCodeShow(1)
        }
        else{
            setStageNameShow(1)
            setStageCodeShow(0)
        }
    }

    const setPrepStatusLabelDiv = (height:string) => {
        let element = document.getElementById('prep-status-label') as HTMLDivElement
        element.style.marginBottom = height
    }

    const setPrepStatusIconSpan = (fontSize:string) =>{
        let stageNameClasses = document.querySelectorAll(".prep-status-icon-span") as NodeListOf<Element>
        stageNameClasses.forEach(item => {
            let element = document.getElementById(item.id) as HTMLSpanElement
            element.style.fontSize = fontSize
        });
    }

    const setStageNameShow = (show:number) => {
        let stageNameClasses = document.querySelectorAll(".prep-stage-name") as NodeListOf<Element>
        stageNameClasses.forEach(item => {
            let element = document.getElementById(item.id) as HTMLSpanElement
            element.style.display = show === 1 ? 'inline' : 'none'
        });
    }

    const setStageCodeShow = (show:number) => {
        let stageCodeClasses = document.querySelectorAll(".prep-stage-code") as NodeListOf<Element>
        stageCodeClasses.forEach(item => {
            let element = document.getElementById(item.id) as HTMLSpanElement
            element.style.display = show === 1 ? 'inline' : 'none'
        });
    }

    const showDocument = (item:ProjectDeliverable) => {
    
        if(!props.isUpdate){
            return
        }

        if(isCanAssuranceActivityUpdate){
            setProjectdeliverable(item)
            setIsGatekeeperShow(true)
            return
        }

        if(isCanDeliverableUpdate && !isGatekeeper){
            setProjectdeliverable(item)
            setIsFocalPointShow(true)
        }

    }

    const imageFlowShow = (_flowNumber:number) => {
        setFlowNumber(_flowNumber)
        setIsFlowShow(true)
    }

    const updateProjectDeliverable = (item:ProjectDeliverable) =>{
        //console.log(item)
        let _projectDeliverables = Object.assign({},projectDeliverables)
        let _projectPhases:ProjectPhase[] = Object.assign([],projectPhases)
        let index = _projectDeliverables[item.phaseId].findIndex(x => x.projectDeliverableId === item.projectDeliverableId)
        _projectDeliverables[item.phaseId][index].deliverableStatusId = item.deliverableStatusId
        _projectDeliverables[item.phaseId][index].completeDateTime = item.completeDateTime
        _projectDeliverables[item.phaseId][index].remark = item.remark

        let phaseIndex = _projectPhases.findIndex( x => x.phaseId == item.phaseId)
        if(phaseIndex > -1){
            _projectPhases[phaseIndex].completeDateTime = item.completeDateTime
            setProjectPhases(_projectPhases)
        }
        setProjectdeliverables(_projectDeliverables)

    }

    return (
     <>
        <div id="prep-status-section" className='inform-section' style={{ paddingBottom: '50px'}}>
                <div id="prep-status-label" className='row' style={{ marginBottom: '5px' }}>
                    <div  className='col-md-12'>
                        <div className='project-label' style={{ padding: '0',marginLeft: '0'}}>
                            <span>
                                PREP Stage Gate Status
                            </span>
                        </div>
                    </div>
                </div>

            <div className="prep-status-resposive" >   
                <div className='row' style={{ marginBottom: "10px" }}>
                    <div id="prep-status-icon" className='prep-status-description' >
                        <div>
                            <AiFillCheckCircle className='prep-status-icon-img' color={Color.Green} style={{ marginRight: '5px'}}/>
                            <span id="prep-status-icon-pass" className='prep-status-icon-span'>Pass</span>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <AiOutlineTrademarkCircle className='prep-status-icon-img' color={Color.Green} style={{ marginRight: '5px' }} />
                            <span id="prep-status-icon-recycle" className='prep-status-icon-span'>Recycle</span>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <AiOutlinePauseCircle className='prep-status-icon-img' color={Color.Orange} style={{ marginRight: '5px' }} />
                            <span id="prep-status-icon-hold" className='prep-status-icon-span'>Hold</span>
                        </div>
                        <div style={{ marginLeft: '10px' }}>
                            <AiFillCloseCircle className='prep-status-icon-img' color={Color.Red} style={{ marginRight: '5px' }} />
                            <span id="prep-status-icon-stop" className='prep-status-icon-span'>Stop</span>
                        </div>
                        <div style={{ marginLeft: '10px'}}>
                            <AiOutlineClockCircle className='prep-status-icon-img' color={Color.Yellow} style={{ marginRight: '5px'}}/>
                            <span id="prep-status-icon-in-progress" className='prep-status-icon-span'>In Progress</span>
                        </div>
                        <div style={{ marginLeft: '10px',wordBreak:'break-word'}}>
                            <AiOutlineClockCircle className='prep-status-icon-img' color={Color.Grey} style={{ marginRight: '5px'}}/>
                            <span id="prep-status-icon-wait-for-start" className='prep-status-icon-span'>Waiting for Start</span>
                        </div>
                        <div style={{ marginLeft: '10px'}}>
                            <VscCircleSlash className='prep-status-icon-img' color='black' style={{ marginRight: '5px'}}/>
                            <span id="prep-status-icon-waive" className='prep-status-icon-span'>Waive</span>
                        </div>
                        <div style={{ marginLeft: '10px'}}>
                            <VscDebugStepOver className='prep-status-icon-img' size={20} color='Grey' style={{ marginRight: '5px'}}/>
                            <span id="prep-status-icon-waive-phase" className='prep-status-icon-span'>Waive Phase</span>
                        </div>

                    </div>
                    <div  className='prep-status-description-mini' >
                       <div style={{display : 'flex'}}>
                            <div>
                                <AiFillCheckCircle className='prep-status-icon-img' color={Color.Green} style={{ marginRight: '5px'}}/>
                                <span id="prep-status-icon-pass" className='prep-status-icon-span'>Pass</span>
                            </div>
                            <div>
                                <AiOutlineTrademarkCircle className='prep-status-icon-img' color={Color.Green} style={{ marginRight: '5px' }} />
                                <span id="prep-status-icon-recycle" className='prep-status-icon-span'>Recycle</span>
                            </div>
                            <div>
                                <AiOutlinePauseCircle className='prep-status-icon-img' color={Color.Orange} style={{ marginRight: '5px' }} />
                                <span id="prep-status-icon-hold" className='prep-status-icon-span'>Hold</span>
                            </div>
                        
                            <div>
                                 <AiFillCloseCircle className='prep-status-icon-img' color={Color.Red} style={{ marginRight: '5px' }} />
                                <span id="prep-status-icon-stop" className='prep-status-icon-span'>Stop</span>
                            </div>
                        </div>
                        <div style={{display : 'flex'}}>
                            <div>
                                <AiOutlineClockCircle className='prep-status-icon-img' color={Color.Yellow} style={{ marginRight: '5px'}}/>
                                <span id="prep-status-icon-in-progress" className='prep-status-icon-span'>In Progress</span>
                            </div>
                            <div style={{wordBreak:'break-word'}}>
                                <AiOutlineClockCircle className='prep-status-icon-img' color={Color.Grey} style={{ marginRight: '5px'}}/>
                                <span id="prep-status-icon-wait-for-start" className='prep-status-icon-span'>Waiting for Start</span>
                            </div>
                            <div>
                                <VscCircleSlash className='prep-status-icon-img' color='black' style={{ marginRight: '5px'}}/>
                                <span id="prep-status-icon-waive" className='prep-status-icon-span'>Waive</span>
                            </div>
                        </div>
                        <div style={{display : 'flex'}}>
                            <div>
                                <VscDebugStepOver className='prep-status-icon-img' size={20} color='Grey' style={{ marginRight: '5px'}}/>
                                <span id="prep-status-icon-waive-phase" className='prep-status-icon-span'>Waive Phase</span>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div style={{ height: maxHeight.toString() + 'px'}}>
                { !isLoading ?
                    <table key={uniqid()} className="table portfolio-table2">
                        <thead>
                            <tr style={{ height: '40px' }}>
                            { props.phases.map((phase) => (
                                <>
                                { projectPhases.filter(x=>x.phaseId === phase.phaseId && x.completeDateTime).length > 0 || phaseStatus[phase.phaseId].waivePhase ?
                                    <th key={uniqid()} style={{ backgroundColor : Color.Green }}>
                                        <span key={uniqid()} style={{color:'black'}}>{phase.phaseName}</span>
                                        <AiOutlineInfoCircle key={uniqid()} size={20} cursor="pointer" color='black' onClick={() => imageFlowShow(phase.phaseNumber)} style={{ marginLeft: '5px' }} />
                                            <div key={uniqid()} style={{position:'relative' ,zIndex: '3',color:'black',marginBottom:'10px'}}>Completed</div>
                                        
                                        <div key={uniqid()} className="block-down" >
                                            <div key={uniqid()}  style={{ backgroundColor : Color.Green }}>&nbsp;</div>
                                        </div>
                                    </th>
                                    :
                                    <th key={uniqid()} style={{ backgroundColor : projectDeliverables[phase.phaseId].length === 0 ? '#bbb' :'' }}>
                                        <span key={uniqid()} >{phase.phaseName}</span>
                                        <AiOutlineInfoCircle key={uniqid()} size={20} cursor="pointer" onClick={() => imageFlowShow(phase.phaseNumber)} style={{ marginLeft: '5px' }} />
                                        <br />&nbsp;
                                        <div key={uniqid()} className="block-down" >
                                            <div key={uniqid()} style={{ backgroundColor : projectDeliverables[phase.phaseId].length === 0 ? '#bbb' :'' }}>&nbsp;</div>
                                        </div>
                                    </th>
                                }
                                </>
                                ))
                            }
                            </tr>
                        </thead>
                        <tbody key={uniqid()} >
                            <tr key={uniqid()}  style={{ borderBottom: "0px none" }} >
                                <td style={{ borderBottom: "0px none" }}></td>
                                <td style={{ borderBottom: "0px none" }}></td>
                                <td style={{ borderBottom: "0px none" }}></td>
                                <td style={{ borderBottom: "0px none" }}></td>
                                <td style={{ borderBottom: "0px none" }}></td>
                            </tr>
                        { props.phases.length > 0 ?
                            <tr key={uniqid()}>
                            { props.phases.map((phase,phaseIndex) => (
                                <td key={uniqid()} style={{ textAlign: 'center' }}>
                                    <div key={uniqid()} style={{ maxWidth: "200px",margin:'auto' }}>
                                    { phaseStatus[phase.phaseId].waivePhase ?
                                    <>
                                        <div key={uniqid()}> 
                                        <VscDebugStepOver key={uniqid()} size={iconSize} color='Grey' style={{ marginRight: '5px'}}/>
                                            <span className="portfolio-stage bg-light-grey-gd" style={{backgroundColor:'rgb(187, 187, 187)',height: blockHeight + 'px'}}>
                                                <span style={{padding: 0,height:'18px',width: '150px'}}>
                                                   {  phaseStatus[phase.phaseId].waivePhaseReason.length < 25 ?
                                                        phaseStatus[phase.phaseId].waivePhaseReason 
                                                    :
                                                    <>
                                                        <span data-tip data-for={'waive-info-' + phase.phaseNumber } style={{ cursor: 'pointer' }}>
                                                            { phaseStatus[phase.phaseId].waivePhaseReason.substring(0,25) + '...' }
                                            
                                                        </span>
                                                        <ReactTooltip id={'waive-info-' + phase.phaseNumber } data-tooltip-html={true} className='message-tooltip2' place="bottom" backgroundColor='white' borderColor='#ccc' textColor='black' multiline={true} border={true}  offset={{ top: 8 }}>
                                                            <div style={{minWidth:'200px',maxWidth:'300px'}}>
                                                            { phaseStatus[phase.phaseId].waivePhaseReason }
                                                            </div>
                                                        </ReactTooltip>
                                                    </>
                                                    }
                                                </span>
                                            </span>
                                        </div>
                                    </>
                                    :
                                    <>
                                    { projectDeliverables[phase.phaseId].map((item,index) => (
                                            <div key={uniqid()} style={{ cursor: (isCanDeliverableUpdate || isCanAssuranceActivityUpdate) && props.isUpdate ? 'pointer' : 'default' }}  onClick={() => showDocument(item)}>
                                            { item.deliverableStatusId === StatusDeliverable.Pass ? <AiFillCheckCircle  key={uniqid()}  size={iconSize} color={Color.Green} style={{ marginRight: '5px'}}/> : null }
                                            { item.deliverableStatusId === StatusDeliverable.Recycle ? <AiOutlineTrademarkCircle key={uniqid()}  size={iconSize} color={Color.Green} style={{ marginRight: '5px' }} />: null }
                                            { item.deliverableStatusId === StatusDeliverable.Hold ? <AiOutlinePauseCircle key={uniqid()}  size={iconSize} color={Color.Orange} style={{ marginRight: '5px' }} />: null }
                                            { item.deliverableStatusId === StatusDeliverable.Stop ? <AiFillCloseCircle key={uniqid()} size={iconSize} color={Color.Red} style={{ marginRight: '5px' }} />: null }
                                            { item.deliverableStatusId === StatusDeliverable.InProgress ? <AiOutlineClockCircle key={uniqid()}  size={iconSize} color={Color.Yellow} style={{ marginRight: '5px'}}/>: null }
                                            { item.deliverableStatusId === StatusDeliverable.WaitingForStart ? <AiOutlineClockCircle key={uniqid()}  size={iconSize} color={Color.Grey} style={{ marginRight: '5px'}}/>: null }
                                            { item.deliverableStatusId === StatusDeliverable.Waive ? <VscCircleSlash key={uniqid()}  size={iconSize} color='black' style={{ marginRight: '5px'}}/>: null }
                                            { item.deliverableStatusId === StatusDeliverable.WaivePhase ? <VscDebugStepOver key={uniqid()}  size={iconSize} color='Grey' style={{ marginRight: '5px'}}/>: null }
                                            
                                                <span key={uniqid()} className="portfolio-stage" style={{ border: '6px solid ' + item.color ,height: blockHeight + 'px'}}>
                                                    <span id={'stage-name-' + phaseIndex + '-' + index} className='prep-status-box prep-stage-name'>{ item.stageName }</span>
                                                    <span id={'stage-code-' + phaseIndex + '-' + index} className='prep-status-box prep-stage-code' style={{ display: 'none'}}>{ item.stageCode }</span>
                                                    <span className='prep-status-box'>{ item.assuranceActivityName ? '(' + item.assuranceActivityName + ')' : ""} </span>
                                                {  item.deliverableStatusId !== StatusDeliverable.InProgress && item.deliverableStatusId !== StatusDeliverable.WaitingForStart ?
                                                    <span className='prep-status-box'>{ item.completeDateTime ? DateHelper.getDate(item.completeDateTime) : DateHelper.getDate(item.updateDateTime)}</span>
                                                    :null
                                                }   
                                                </span>
                                            
                                            </div>
                                        ))
                                    }
                                    </>
                                    }
                                    </div>
                                </td>
                            ))
                            }
                            </tr>
                            :
                            <tr key={uniqid()}>
                                <td key={uniqid()} style={{ textAlign: 'center' }}>
                                    <div key={uniqid()} style={{ maxWidth: "200px",margin:'auto' }}></div>
                                </td>
                            </tr>
                        }
                        </tbody>
                    </table>
                    :<Loading text="Loading..."/>
                }
                </div>
            </div>

                <Modal show={isFlowShow} onHide={() => setIsFlowShow(false)} size="xl">
                    <Modal.Header className='bg-dark-blue'  style={{ fontSize: '20px', fontWeight:"bold"}}>
                        &nbsp;
                        <span style={{float:'right' ,marginRight:'10px',cursor:'pointer'}} onClick={() => setIsFlowShow(false)}>X</span>
                    </Modal.Header>
                        <Modal.Body>
                            { 
                            <img src={config.API_URL + '/v1/images/phase/' + props.projectModeId.toString() + "/" + flowNumber.toString()} style={{ width: '100%'}}/>
                            }
                        </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
                
                <Modal show={isFocalPointShow} onHide={() => setIsFocalPointShow(false)} size="xl">
                    <Modal.Header className='bg-dark-blue'  style={{ fontSize: '20px', fontWeight:"bold"}}>
                        { props.user.roleSystemLevelId === SystemLevel.FocalPoint ? 'Deliverable Update' : 'Deliverable and Assurance Update' }
                        <span style={{marginRight:'10px',cursor:'pointer'}} onClick={() => setIsFocalPointShow(false)}>X</span>
                    </Modal.Header>
                    <Modal.Body>
                        <FocalPointDocument 
                            projectId={props.projectId} 
                            projectName={props.projectName}
                            projectDeliverable={projectDeliverable} 
                            projectOwners={props.projectOwners}
                            gatekeepers={props.gatekeepers}
                            projectPhaseRoles={props.projectPhaseRoles}
                            onChange={updateProjectDeliverable}
                        />
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>

                <Modal show={isGatekeeperShow} onHide={() => setIsGatekeeperShow(false)} size="xl">
                    <Modal.Header className='bg-dark-blue'  style={{ fontSize: '20px', fontWeight:"bold"}} >
                        { props.user.roleSystemLevelId === SystemLevel.FocalPoint ? 'Deliverable Update' : 'Deliverable and Assurance Update' }
                        <span style={{marginRight:'10px',cursor:'pointer'}} onClick={() => setIsGatekeeperShow(false)}>X</span>
                    </Modal.Header>
                    <Modal.Body>
                        <GatekeeperDocument 
                            projectId={props.projectId} 
                            projectName={props.projectName}
                            projectDeliverable={projectDeliverable} 
                            projectOwners={props.projectOwners}
                            projectPhaseRoles={props.projectPhaseRoles}
                            onChange={updateProjectDeliverable}
                        />
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </div>
        </>
    );
}

export default PrepStatus;