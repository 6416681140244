import React, { useState,useEffect } from 'react';
import { AiOutlineSearch,AiOutlineEdit,AiOutlineCloseCircle,AiOutlineSave,AiOutlineCheckCircle } from "react-icons/ai";
import Modal from 'react-bootstrap/Modal'
import ReactPaginate from 'react-paginate';
import { VscAdd } from "react-icons/vsc";
import { RiRefreshLine,RiErrorWarningLine } from "react-icons/ri";
import { UserService,RoleService, GraphApiService,HistoryLogService } from '../../services';
import { useApiAuth } from '../../hooks/useApiAuth';
import { User,PageEvent,SelectItem,AuthenUser, RoleName} from '../../models';
import TextboxAutocomplete from '../ComponentTools/TextboxAutocomplete'
import { TiArrowSortedDown,TiArrowSortedUp } from "react-icons/ti";
import '../../assets/css/admin.css'
import { Color,SystemLevel } from '../../enum'
import uniqid from 'uniqid';
import Loading from '../Loading';
import { DateHelper } from '../../helpers'
import  secureLocalStorage  from  "react-secure-storage";
import { apiRequest } from "../../configurations/authConfig";
import { useMsal } from "@azure/msal-react";

interface AdminUser{
    userId: number
    username: string
    mail:string
    name:string
    officeLocation:string
    department:string
    jobTitle:string
    roleSystemLevelId: number
    roleSystemLevelName:string
    updateBy: string
    updateDateTime: string
}

interface AppState {
    page:number
    total:number
    totalPage:number
    limit:number
    key:string
    users:User[]
    user:AdminUser
    sortType: number
    sortBy: string
    isEdit:boolean
    isLoading:boolean
}

const AdminUser = () => {
    const { instance, accounts } = useMsal();
    const {account, accessToken } = useApiAuth();
    const name = account && account.name ? account.name : ""
    const userStore = secureLocalStorage.getItem('user')
    const userLogin:AuthenUser = userStore ? JSON.parse(userStore.toString()): null
    const [isSuccess, setIsSuccess] = useState(false)
    const [appState,setAppState] = useState<AppState>({
        page:1,
        total:0,
        totalPage:0,
        limit:10,
        key:"",
        users:[],
        user:{  
            userId: 0,
            username: "",
            name:"",
            mail:"",
            officeLocation:"",
            department:"",
            jobTitle:"",
            roleSystemLevelId: 5,
            roleSystemLevelName:"",
            updateBy: "",
            updateDateTime: ""
        },
        sortType: 1,
        sortBy:"username",
        isEdit: false,
        isLoading: true
    })
    const [isAddEditOpen,setIsAddEditOpen] = useState(false)
    const [errors,setErrors] = useState<string[]>([])
    const [isUserStatusChange,setIsUserStatusChange] = useState(false)
    const [userStatus,setUserStatus] = useState({userId : 0, active: 0})
    const [selectUser,setSelectUser] = useState({username : "", name: ""})
    const [searchStatus,setSearchStatus] = useState(false)
    const [rolenames,setRoleNames] = useState<RoleName[]>([])

    useEffect(() => {
        if(accessToken){
            new RoleService(accessToken).getRoleSystemName().then((result) => {
                
                if(result && result.success){
                    setRoleNames(result.data)
                }
                getUsers(1,appState.limit,"",appState.sortBy,appState.sortType);
            })
           
        }
      
    },[accessToken])

    const getToken = async() => {
        const acc = accounts && accounts.length ? accounts[0] : null;
        apiRequest.scopes[0]=  "api://" + secureLocalStorage.getItem('clientId') + "/read_user_profile"
        if(acc){
            const { accessToken: token } = await instance.acquireTokenSilent({
                account: acc,
                scopes: apiRequest.scopes
            });
            return token;
        }
        return ""
    }

    const handlePageClick = (event: PageEvent) => {
        let currentPage = event.selected + 1
        getUsers(currentPage,appState.limit,appState.key,appState.sortBy,appState.sortType);
        
    }

    const getUsers = async(page: number,limitPage:number,key:string ="",sortBy:string ="username",sortType:number = 1) => {
        let state = Object.assign({},appState)
        let selectActive = document.getElementById('select-active') as HTMLSelectElement
        let active = 2
        if(selectActive){
            active = parseInt(selectActive.value)
        }

        let selectRole = document.getElementById('select-role') as HTMLSelectElement
        let roleId = 0
        if(selectRole){
            roleId = parseInt(selectRole.value)
        }
        
        let token = await getToken()
        let result = await new UserService(token).getAll(page,limitPage,key,sortBy,sortType,active,roleId);
        
        if(result && result.success){
            
            let { page ,limit, total, items} = result.data
            state.page = page
            state.limit = limit
            state.total = total
            state.totalPage =  Math.ceil(total / limit)
            state.key = key
            state.sortBy = sortBy
            state.sortType = sortType
            state.users = items
        }

        state.isLoading = false
        //setTimeout(() => {
            setAppState(state)
       // }, 500);
       
    }

    const onAddUserClick = () => {
        let state = Object.assign({},appState)
        state.user = {
            userId: 0,
            username: "",
            mail:"",
            name:"",
            officeLocation:"",
            department:"",
            jobTitle:"",
            roleSystemLevelId: 5,
            roleSystemLevelName: "",
            updateBy: "",
            updateDateTime: ""
        }
        state.isEdit = false
        setAppState(state)
        setIsAddEditOpen(true)
    }

    const onEditUserClick = async (item:User) => {
        let token = await getToken()
        let result =  await new GraphApiService(token).getByUername(item.username)
        if(result && result.success && result.data.username != ""){
            let state = Object.assign({},appState)
            let u = result.data
            state.user =  {
                    userId: item.userId,
                    username: item.username,
                    mail : u.mail,
                    name: u.givenName + ' ' + u.surname,
                    officeLocation:u.officeLocation,
                    department: u.department,
                    jobTitle: u.jobTitle,
                    roleSystemLevelId: item.roleSystemLevelId,
                    roleSystemLevelName:"",
                    updateBy:item.updateBy,
                    updateDateTime: DateHelper.getDate(item.updateDateTime)
                }
                
            state.isEdit = true
            setAppState(state)
            setIsAddEditOpen(true)
        }
        else{
            if(result && result.message){
                setErrors([result.message])
            }
        }
       
    }

    const searchGraphApiUser = async (key) => {
        if(!key) return

        let token = await getToken()
        let result =  await new GraphApiService(token).Search(key)
        //console.log(result)
        let data = result.data.map((u) => {
            return {
                userId: u.userId,
                username: u.userPrincipalName,
                name: u.givenName + ' ' + u.surname,
                officeLocation:u.officeLocation,
                department: u.department,
                jobTitle: u.jobTitle,
                roleSystemLevelId: 5,
                roleSystemLevelName:"Viewer"
            }
            
        })
        return data
    }

    const searchUserDb = async (key) => {
        if(!key) return

        let token = await getToken()
        let result =  await new GraphApiService(token).SearchDb(key)
        //console.log(result)
        if(result && result.data){
            let data = result.data.map((u) => {
                return {
                    userId: u.userId,
                    username: u.userPrincipalName,
                    name: u.givenName + ' ' + u.surname,
                    officeLocation:u.officeLocation,
                    department: u.department,
                    jobTitle: u.jobTitle,
                    roleSystemLevelId: 5,
                    roleSystemLevelName:"Viewer"
                }
                
            })
            return data;
        }
        
    
    }

    const onUserSelect = (item:SelectItem) => {
        
        if(item.values){
            let state = Object.assign({},appState)
            let userData:AdminUser = JSON.parse(item.values)
            //console.log(userData)
            state.user = {
                userId: 0,
                username: userData.username,
                mail: userData.mail,
                name: userData.name,
                officeLocation: userData.officeLocation,
                department: userData.department,
                jobTitle: userData.jobTitle,
                roleSystemLevelId: 5, 
                roleSystemLevelName:"",
                updateBy: "",
                updateDateTime: ""
            }
   
            setAppState(state)
        }
    }

    const onUserSelectDb = (item:SelectItem) => {
      //  //console.log(item)
        if(item.values){
            let userData:AdminUser = JSON.parse(item.values)
            setSelectUser({ username: userData.username, name: userData.name})

        }
       
    }

    const onRoleChange = (ev) =>{
        let state = Object.assign({},appState)
        state.user.roleSystemLevelId = parseInt(ev.target.value)
        setAppState(state)
    }

    const saveUser = async () =>{
        let appUser = Object.assign({},appState.user)

        let saveBtn =document.getElementById('save-btn') as HTMLButtonElement
        saveBtn.disabled = true

        if(!appUser.username){
            setErrors(["User not found."])
            saveBtn.disabled = false
            return
        }

        let user:User = {
            userId:appUser.userId,
            username: appUser.username,
            userPrincipalName: "",
            mail:"",
            givenName:"",
            surname:"",
            officeLocation:"",
            extensionAttribute4:"",
            department:"",
            jobTitle:"",
            roleSystemLevelId: appUser.roleSystemLevelId,
            roleSystemLevelName:"",
            updateBy: name,
            active:1,
            updateDateTime:new Date()
        }

        //console.log(user)
        if(user.userId == 0){
            let token = await getToken()
            let result = await new UserService(token).Create(user)
           
            //console.log(result)
            if(result && result.success){
                saveBtn.disabled = false
                new HistoryLogService(token).create(userLogin.userId, null,"Admin User","Create user " + user.username)
                getUsers(1,appState.limit,"","username",1)
                setIsAddEditOpen(false)

            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
                   
            }
        }
        else{
            let token = await getToken()
            let result = await new UserService(token).Update(user)
         
            if(result && result.success){
                saveBtn.disabled = false
                let rolename = ""
                if(user.roleSystemLevelId == SystemLevel.SuperAdmin){
                    rolename = "Super Admin"
                }
                if(user.roleSystemLevelId == SystemLevel.Management){
                    rolename = "Management"
                }
                if(user.roleSystemLevelId == SystemLevel.FocalPoint){
                    rolename = "Focalpoint"
                }
                if(user.roleSystemLevelId == SystemLevel.GateKeeper){
                    rolename = "Gatekeeper"
                }
                if(user.roleSystemLevelId == SystemLevel.Viewer){
                    rolename = "Viewer"
                }
                new HistoryLogService(token).create(userLogin.userId, null,"Admin User","Update user " + user.username + " set role = " + rolename.toLocaleLowerCase())
                getUsers(appState.page,appState.limit,appState.key,appState.sortBy,appState.sortType)
                setIsAddEditOpen(false)
            }
            else{
                saveBtn.disabled = false
                if(result && result.message){
                    setErrors([result.message])
                }
            }
        }
        
    }

    const onPageLimitChange = (ev) => {
        let key = ""
        let inputSearch = document.getElementById('user-search') as HTMLInputElement
        if(inputSearch){
            key = inputSearch.value
        }
        let limit = parseInt(ev.target.value)
        let state = Object.assign({},appState)
        state.limit = limit
        setAppState(state)
        getUsers(appState.page,limit,appState.key,appState.sortBy,appState.sortType)
    }

    const onUserStatusChange = (userId:number,status:boolean) => {
        let user = Object.assign({},userStatus)
        user.userId = userId
        user.active = status ? 1 : 0
        setUserStatus(user)
        setIsUserStatusChange(true)
    }

    const updateUserStatus =async () => {
        let user = appState.users.find(u => u.userId == userStatus.userId)
        let token = await getToken()
        let result = await new UserService(token).UpdateStatus(userStatus.userId,userStatus.active,name)
        let status = userStatus.active == 1 ? 'on' : 'off'
        new HistoryLogService(token).create(userLogin.userId, null,"Admin User","Update status " + user?.username + " = " + status )
        if(result && result.success){
            getUsers(appState.page,appState.limit,appState.key,appState.sortBy,appState.sortType)
            setIsUserStatusChange(false)
        }
        else{
            if(result && result.message){
                setErrors([result.message])
            }
        }
    }

    const searchUser = () =>{
        let key = ""
        let inputSearch = document.getElementById('user-search') as HTMLInputElement
        if(inputSearch){
            setSelectUser({username: "",name: inputSearch.value})
            key = inputSearch.value

         }
        
        getUsers(1,appState.limit,key,appState.sortBy,appState.sortType)
        // let state = Object.assign({},appState)
        // state.isLoading = true
        // setAppState(state)

        setSearchStatus(true)
        
    }

    const resetUser= () => {
        let state = Object.assign({},appState)
        state.isLoading = true
        setAppState(state)
        setSelectUser({username: "",name: ""})
        let inputSearch = document.getElementById('user-search') as HTMLInputElement
        if(inputSearch){
            inputSearch.value = ""
        }
        let selectActive = document.getElementById('select-active') as HTMLSelectElement
        if(selectActive){
            selectActive.value = "2"
        }
        let selectRole = document.getElementById('select-role') as HTMLSelectElement
        if(selectRole){
            selectRole.value = "0"
        }
       
        setSearchStatus(false)
        getUsers(1,appState.limit,"","username",1)
    }

    const sorting = (sortBy:string,sortType:number) =>{
        getUsers(1,appState.limit,appState.key,sortBy,sortType)
    
       
    }

    return (
        <div className="container-fluid">
           
            <div style={{ "padding":"15px 15px"}}>
                <div className="body">
                <div className='project-block-none'>
                    <div className="row">
                        <div className="col-sm-6">
                            <span className='project-label'>User Management</span>
                    
                        </div>
                    </div>
                    <div className="row ">
                        <div className="col-md-4">
                            <div className="input-group">
                            <TextboxAutocomplete key={uniqid()} idKey="userId" valueKey="name" onChange={searchUserDb} onSelect={onUserSelectDb} elementId={'user-search'} initialValue={selectUser.name} hiddenArrow={true}/>
                            </div>
                        </div>
                        <div className='col-md-2'>
                            <select id="select-role" className='form-select form-select-sm'>
                            <option value={0}>All Role</option>
                            { rolenames.map(p => <option key={uniqid()} value={p.roleId}>{ p.roleName }</option>) } 
                            </select>
                        </div>
                        <div className='col-md-2'>
                         <select id="select-active" className='form-select form-select-sm'>
                                <option value={2}>All Active Status</option>
                                <option value={1}>Active</option> 
                                <option value={0}>Inactive</option>
                               
                            </select>
                    </div>
                    <div className='col-md-1'>
                            <button className="btn bg-dark-blue border-start-0 border ms-n5" type="button" style={{ height: '35px'}} onClick={() => searchUser()}>
                                     <AiOutlineSearch size={20} style={{ verticalAlign: 'top'}}/>
                            </button>
                    </div>
                    <div className='col-md-1'>
                    { searchStatus ?
                                 <span className="input-group-append" style={{cursor:"pointer"}} onClick={resetUser}>
                                     <RiRefreshLine size={20} color={Color.Blue}/>
                                     <span className='app-active' style={{ fontSize: '14px',marginLeft:'2px'}}>Reset</span>
                                 </span>:null
                             } 
                    </div>
                    <div className='col-md-2' style={{ textAlign: 'right',paddingTop: '5px',paddingRight: '30px'}}>
                        <div className="admin-action">
                            { userLogin.administratorUpdate > 0 ?
                                <span className='app-active' style={{ cursor: 'pointer'}} onClick={onAddUserClick}>
                                    <VscAdd size={20} color={Color.Blue} />
                                    <span style={{ fontSize: '14px',marginLeft:'2px'}}>Add User</span>
                                </span>
                                :<></>
                            }
                        </div> 
                    </div>
                </div>

                <div className='row' style={{ marginTop: '10px'}}>
                        <div className='col-md-10' style={{ paddingTop:'7px'}}>
                            <span style={{ marginLeft: '5px'}}>Search found {appState.total} record(s).</span>
                        </div>
                        <div className='col-md-2' style={{textAlign: 'right'}}>
                            
                            <span style={{ marginRight: '5px',marginTop: '8px'}}>Show</span>
                            
                            <select value={appState.limit}  onChange={ ev => onPageLimitChange(ev)} style={{ width: '80px',height: '30px',border: '1px solid #ccc',borderRadius: '5px'}}>
                                <option>10</option>
                                <option>25</option> 
                                <option>50</option>
                                <option>100</option>        
                            </select>
                        </div>
                </div>

            <div className="table-responsive table-responsive-sm" style={{ marginTop: '15px'}}>

                            <table className="table table-sm table-bordered table-hover" >
                    <thead>
                        <tr style={{ height: '35px'}}>
                            <th style={{minWidth: '50px'}}>No.</th>
                            <th style={{ minWidth: '150px'}}>
                                <span>Employee Name</span>
                                { appState.sortBy === 'username'?
                                    <>
                                        { appState.sortType == -1 ?
                                            <TiArrowSortedUp size={16} color={Color.DarkBlue}  cursor="pointer"  onClick={() => sorting('username',1)}/>
                                            :
                                            <TiArrowSortedDown size={16} color={Color.DarkBlue}  cursor="pointer"  onClick={() => sorting('username',-1)}/>
                                        }
                                    </>:
                                    <TiArrowSortedDown size={16} color="white" cursor="pointer" onClick={() => sorting('username',1)}/>
                                }
                            </th>
                            <th>Position</th>
                            <th>Office Location</th>
                            <th>Department</th>
                            <th>Role</th>
                            <th style={{minWidth: '100px'}}>
                                <span>Modified By</span>
                                { appState.sortBy === 'modifyBy'?
                                    <>
                                        { appState.sortType == -1 ?
                                            <TiArrowSortedUp size={16} color={Color.DarkBlue}  cursor="pointer"  onClick={() => sorting('modifyBy',1)}/>
                                            :
                                            <TiArrowSortedDown size={16} color={Color.DarkBlue}  cursor="pointer"  onClick={() => sorting('modifyBy',-1)}/>
                                        }
                                    </>:
                                    <TiArrowSortedDown size={16} color="white" cursor="pointer" onClick={() => sorting('modifyBy',1)}/>
                                }
                            </th>
                            <th>Modified Date</th>
                            <th>
                                <span>Active Status</span>
                               
                            </th>
                            <th>Setting</th>
                        </tr>
                    </thead>
                    <tbody>
                        { appState && !appState.isLoading ?
                            <>
                                {
                                    appState.users.map((user,index) => (
                                        <tr key={uniqid()}  style={{height: '45px',textAlign:'left',paddingLeft:'20px', backgroundColor: (index%2) ? '#f3f3f3' : ''}}>
                                             <td>{((appState.page -1) * appState.limit) + index + 1}</td>
                                            <td style={{ textAlign: 'left',paddingLeft:'10px'}}>
                                                { user.givenName ?
                                                    <span>{user.givenName + ' ' + user.surname}</span>
                                                    :
                                                    <span>{user.username}</span>
                                                }
                                                
                                            </td>
                                            <td style={{ textAlign: 'left',paddingLeft:'10px'}}>
                                                <span>{user.jobTitle}</span>
                                            </td>
                                            <td style={{ textAlign: 'center',paddingLeft:'10px'}}>
                                                <span>{user.officeLocation}</span>
                                            </td>
                                            <td style={{ textAlign: 'left',paddingLeft:'10px'}}>
                                                <span>{user.department}</span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span>{user.roleSystemLevelName}</span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span>{user.updateBy}</span>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <span>{DateHelper.getDate(user.updateDateTime)}</span>
                                            </td>
                                            <td style={{ textAlign: 'center'}}>
                                                { userLogin.administratorUpdate > 0 ?
                                                <div className="form-check form-switch" style={{margin: 'auto',width: '50px'}}>
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" defaultChecked={user.active == 1 ? true : false} onChange={(ev) => onUserStatusChange(user.userId,ev.target.checked)} />
                                                </div>
                                                :<></>
                                                }
                                            </td>
                                            <td style={{ textAlign:'center'}}>
                                                { userLogin.administratorUpdate > 0 ?
                                                    <AiOutlineEdit size={22} cursor="pointer" color="rgb(255, 159, 64)" onClick={() => onEditUserClick(user)}/>
                                                    :<></>
                                                }
                                                </td>
                                        </tr>
                                    ))
                                }
                            </>:<Loading text="Loading..."/>
                        }
                    </tbody>
                </table>
            </div>

            <div className='row' style={{ marginTop: '15px'}}>
                    <div className='col-sm-12'>
                        <div id='pagination' style={{ float: 'right'}}>
                            <ReactPaginate
                                pageRangeDisplayed={5}
                                pageCount={appState.totalPage}
                                breakLabel="..."
                                previousLabel="<<"
                                nextLabel=">>"
                                onPageChange={handlePageClick}
                               // renderOnZeroPageCount={null}
                                forcePage={appState.page - 1}
                                marginPagesDisplayed={2}
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                            />
                        </div>
                    </div>
                   
                </div>
        </div>
                </div>
            </div>

            <div className='row' style={{ marginTop: '30px'}}>
            <div className='col-md-12'></div>
         </div>
            <Modal show={ isAddEditOpen } onHide={() => setIsAddEditOpen(false)} size="lg">
                    <Modal.Header  className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Update Employee</Modal.Title>
                        <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => setIsAddEditOpen(false)}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                    <div className='row'>
                    
                    
                           
                            <div className="col-sm-1 col-label">
                               
                            </div>
                            <div className="col-sm-10" style={{ textAlign: 'left'}}>
                                <label  className="form-label col-label" style={{ float:'left'}}>Employee Name:</label>
                                { appState.isEdit ?
                                
                                <input type="text"  className="form-control form-control-sm " value={appState.user.username} disabled/>
                                :
                                <div className="input-group">
                                    <TextboxAutocomplete key={uniqid()} valueKey="name" onChange={searchGraphApiUser} onSelect={onUserSelect} elementId={'employee'} initialValue={appState.user.name} hiddenArrow={true}/>
                                </div>
                                }
                            </div>
                            
                        
                    
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                           
                        </div>
                        <div className="col-sm-10">
                        <label  className="form-label col-label" style={{ float:'left'}}>Position:</label>
                            <input id="user-position" type="text"  className="form-control form-control-sm " defaultValue={appState.user.jobTitle } disabled/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            
                        </div>
                        <div className="col-sm-10">
                        <label  className="form-label col-label" style={{ float:'left'}}>Office Location:</label>
                            <input id="user-office-location" type="text"  className="form-control form-control-sm " defaultValue={appState.user.officeLocation } disabled/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            
                        </div>
                        <div className="col-sm-10">
                        <label  className="form-label col-label" style={{ float:'left'}}>Department:</label>
                            <input id="user-department" type="text"  className="form-control form-control-sm " defaultValue={appState.user.department } disabled/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-sm-1 col-label">
                            
                        </div>
                        <div className="col-sm-10">
                        <label  className="form-label col-label" style={{ float:'left'}}>Role:</label>
                            <select className="form-select form-select-sm" value={appState.user.roleSystemLevelId} onChange={(ev) => onRoleChange(ev)}>
                                <option value={1}>Super Admin</option>
                                <option value={2}>Management Level</option>
                                <option value={3}>Focal Point</option>
                                <option value={4}>Gatekeeper</option>
                                <option value={5}>Viewer</option>
                              
                               
                            </select>
                        </div>
                    </div>

                   

                    <div className='row'>
                        <div className='col-md-12'>
                        <button id="save-btn" type="button" className="btn btn-sm bg-dark-blue" style={{width:'200px'}} onClick={saveUser}>
                            <AiOutlineSave size={20} />
                            <span style={{ marginLeft: '5px'}}>Save</span>
                        </button>
                        </div>
                    </div>
                
                    </Modal.Body>
                </Modal>    
                
            <Modal show={isUserStatusChange } onHide={() => setIsUserStatusChange(false)} size="sm" style={{ top : '20%'}}>
                <Modal.Header  className="bg-dark-blue">
                    <Modal.Title style={{ fontSize: '15px'}}>Confirm Message</Modal.Title>
                    <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontSize: '15px',fontWeight: 'bold'}} onClick={() => setIsUserStatusChange(false)}>X</span>
                </Modal.Header>
                <Modal.Body style={{ textAlign: 'center'}}>
                     <div>
                        <span style={{ marginLeft: '2px',fontWeight: 'bold'}}> Would you like to turn user { userStatus.active == 1 ? 'on' : 'off'} ?</span>
                    </div>
                    <button type="button" className="btn bg-dark-blue" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px' }} onClick={() => updateUserStatus()} >
                        <span style={{ marginLeft: '5px'}} >Yes</span>
                    </button>
                    <button type="button" className="btn bg-grey" style={{"width": "100px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }} onClick={() => setIsUserStatusChange(false)} >
                        <span style={{ marginLeft: '5px'}} >No</span>
                    </button>
                </Modal.Body>
            </Modal>

                <Modal show={errors.length > 0 ? true : false} onHide={() => setErrors([])} size="sm" style={{ top: '20%'}}>
                    <Modal.Header className="bg-dark-blue">
                        <Modal.Title style={{ fontSize: '15px'}}>Error Message</Modal.Title>
                            <span style={{padding: '5px', marginRight:'5px',cursor:'pointer',fontWeight:'bold'}} onClick={() => setErrors([])}>X</span>
                    </Modal.Header>
                    <Modal.Body style={{ textAlign: 'center'}}>
                        <div><RiErrorWarningLine color={'red'} size={25}/></div>
                        <div style={{ fontSize: '14px'}}>
                        {   errors.map((msg) =>(
                            <div style={{marginLeft:'10px',color:'red'}}>{msg}</div>
                            ))
                        }
                    
                        </div>
                        <div style={{marginTop:'15px'}}>
               
                        <button type="button" className="btn bg-dark-blue" style={{"width": "200px",height: '35px',fontSize: '14px',marginTop: '15px',marginLeft:'10px' }}  onClick={() => setErrors([])} >
                            <span style={{ marginLeft: '5px'}} >Close</span>
                        </button>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal show={isSuccess} onHide={() => setIsSuccess(false)} size="sm">
                    <Modal.Body style={{ textAlign: 'center',padding: 0}}>
                        <div className="alert alert-success" role="alert" style={{marginBottom: 0}}>
                            <AiOutlineCheckCircle color="green" size={25}/>
                            <span style={{marginLeft:'10px',color:'green'}}>Success</span>
                        </div>
                    </Modal.Body>
                </Modal>
        </div>
    );
}

export default AdminUser