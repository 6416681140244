import React, { useState, useEffect} from 'react';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { AiOutlineFilePdf } from "react-icons/ai";
import { useApiAuth } from '../hooks/useApiAuth';
import ReactTooltip from "react-tooltip";
import { ProjectService} from '../services'
import '../assets/css/capital.css'
import { Color, ProjectModeNumber } from '../enum'
import { rigImage, nogoingImage, completedImage,pendingImage,cancelImage } from '../images'
import { ProjectDashboardSummary,AssetTypeCategory,AssetTypePhase } from '../models';
import Loading from './Loading';

interface AppState {
  data: ProjectDashboardSummary | null
  chartSizeType:number
  isLoading:boolean
}

interface ChartData {
  label:string
  data:number[]
  backgroundColor: string
}

interface Props{
  projectModeId: number
}

const DashboardChart = (props:Props) => {
  const winSize = 1333
  const { accessToken } = useApiAuth()
  const [appState,setAppState] = useState<AppState>({
      data: null,
      chartSizeType:1,
      isLoading: true
  })
  var donutChart2:Chart
  var stackBarChart:Chart
  var stackBarChart2:Chart
  var stackBarChart3:Chart

    useEffect(()=>{
        if(accessToken){
            new ProjectService(accessToken).getSummaryProjectCategory(props.projectModeId).then((result) => {
              
                if(result && result.success)
                {
                    let state = Object.assign({},appState)
                    state.data = result.data
                    state.chartSizeType = window.innerWidth >= winSize ? 1 : 2
                    state.isLoading = false
                    setAppState(state)
                    let {assetTypeCategories,assetTypePhases,categorySizes, assetTypes,phases} = result.data
                    if(props.projectModeId > 0){
                      if(props.projectModeId !== 3)
                      {
                        let categorySize = categorySizes.find(x => x.projectModeId === props.projectModeId)
                        let categorySizeThreshold = categorySize ? categorySize.thresholdValue : 200
                      
                        createAssetCategoryChart(assetTypeCategories,categorySizeThreshold)
                        createAssetPhaseChart(assetTypePhases,assetTypes,phases)
                      }
                      else{
                        createDecomPhaseChart(assetTypePhases,phases)
                        //createDecomAssetChart()
                      }
                  }
                }
            })

            window.addEventListener('resize', function() {
              windowResize()
              
          });
          
          windowResize()
        }
    },[accessToken])

    const windowResize = () => {
      // let state = Object.assign({},appState)
      // state.chartSizeType = window.innerWidth >= winSize ? 1 : 2
      // setAppState(state)
      if(window.innerWidth < winSize){
          let summaryBox = document.getElementById('summary-box') as HTMLDivElement
          if(summaryBox){
              summaryBox.style.display = 'none'
          }
          let summaryBoxMini = document.getElementById('summary-box-mini') as HTMLDivElement
          if(summaryBoxMini){
            summaryBoxMini.style.display = 'inline'
          }
      }
      else{
        let summaryBox = document.getElementById('summary-box') as HTMLDivElement
        if(summaryBox){
            summaryBox.style.display = 'inline'
        }
        let summaryBoxMini = document.getElementById('summary-box-mini') as HTMLDivElement
        if(summaryBoxMini){
            summaryBoxMini.style.display = 'none'
        }
      }
  }
    const trStyles = {
        odd: {
            background: '#ddd',
            height: '50px'
        },
        event: {
            background: '#f3f3f3',
            height: '50px'
        }
    }

    const createAssetCategoryChart = (assetTypeCategories: AssetTypeCategory[] ,categorySizeThreshold:number) => {

        var labelSet = assetTypeCategories.sort((a,b) => {
            if(a.assetTypeId > b.assetTypeId)
              return 1
            else if(a.assetTypeId < b.assetTypeId)
              return -1
            else
              return 0
        })
        let labels = [...new Set(labelSet.map(item => item.assetTypeName))];
        let data1:number[] =[]
        let data2:number[] =[]
        labels.forEach((label,index) => {
            let assetType = assetTypeCategories.find(x => x.assetTypeName == label && x.categorySizeId == 1)
            if(assetType){
                data1[index] = assetType.count
            }
        });
        labels.forEach((label,index) => {
            let assetType = assetTypeCategories.find(x => x.assetTypeName == label && x.categorySizeId == 2)
            if(assetType){
                data2[index] = assetType.count
            }
        });

        if(stackBarChart){
            stackBarChart.destroy()
        }
      
        const data = {
            labels: labels,
            datasets: [{
                label: '0-' + categorySizeThreshold + " MMUSD",
                data:data2,
                backgroundColor: Color.LightBlue,
            },
            {
                label: '> ' + categorySizeThreshold + " MMUSD",
                data: data1,
                backgroundColor: Color.DarkBlue,
            }]
        };
  
        const canvasElement = document.getElementById('asset-category-chart') as HTMLCanvasElement
        stackBarChart = new Chart(canvasElement,
        {
            type: 'bar',
            data: data,
            plugins: [ChartDataLabels],
            options: {
              plugins: {
                  title: {
                    display: true,
                    text: 'Project Summary by Size & Asset Type',
                    color: '#929daf',
                    font:{
                      size: 14,
                      family: 'tahoma',
                      weight: 'bold',
                    },
                    position: 'top'
                  },
                  tooltip: {
                    callbacks: {
                      label: function(context) {
                        let label = context.dataset.label || '';
                        return label;
                      }
                    }
                  },
                  datalabels: {
                    color: 'white',
                    font:function(context) {
                      var width = context.chart.width;
                      var size = Math.round(width / 32);
                      if(size > 16){
                        size = 16
                      }
                      if(size < 10){
                        size = 10
                      }
                      return {
                          weight: 'bold',
                          size: size
                      };
                    },
                    padding: 6,
                    //formatter: Math.round
                  },
                  legend: {
                    onClick: () => null,
                    labels: {
                      font:function(context) {
                        var width = context.chart.width;
                        var size = 12
                        if(width > 200 && width <= 250){
                          size = 9
                        }
                        if(width <= 200){
                          size = 6
                        }
                        return {
                            size: size
                        };
                      },
                    } 
                  },
                  subtitle: {
                    display: false,
                    text: 'Summary as of Mar 03,2022',
                    color: '#00338D',
                    font: {
                      size: 12,
                      family: 'tahoma',
                      weight: 'bold',
                      style: 'italic'
                  },
                  padding: {
                    bottom: 10
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                    stacked: true,
                    grid: {
                      display: false
                    },
                    ticks:{
                      font:function(context) {
                        var width = context.chart.width;
                        var size = 12
                        if(width > 200 && width <= 250){
                          size = 9
                        }
                        if(width <= 200){
                          size = 6
                        }
                        return {
                            size: size
                        };
                      },
                    }
                },
                y: {
                    stacked: true,
                    ticks: {
                      precision: 0
                    },
                    grid: {
                      display: false
                    }
                },
                
              }
            }
        }
      )
     
    }
    
    const createAssetPhaseChart = (assetTypePhases:AssetTypePhase[],assetTypes:string[],labels:string[]) => {
        let chartDatas:ChartData[] = []
        let backgroundColors:string[] = [Color.Blue,Color.Red,Color.Green,Color.DarkBlue]
       
        assetTypes.forEach((label,index) => {
            let datasets:ChartData = {
              label: label,
              data: new Array(5).fill(null),
              backgroundColor: backgroundColors[index]
            }
            let val1 = assetTypePhases.find(x => x.assetTypeName == label && x.phaseNumber == 1)
            let val2 = assetTypePhases.find(x => x.assetTypeName == label && x.phaseNumber == 2)
            let val3 = assetTypePhases.find(x => x.assetTypeName == label && x.phaseNumber == 3)
            let val4 = assetTypePhases.find(x => x.assetTypeName == label && x.phaseNumber == 4)
            let val5 = assetTypePhases.find(x => x.assetTypeName == label && x.phaseNumber == 5)
            if(val1){
              datasets.data[0] = val1.count
            }
            if(val2){
              datasets.data[1] = val2.count
            }
            if(val3){
              datasets.data[2] = val3.count
            }
            if(val4){
              datasets.data[3] = val4.count
            }
            if(val5){
              datasets.data[4] = val5.count
            }

           chartDatas.push(datasets)
        })
        
        if(stackBarChart2){
          stackBarChart2.destroy()
        }
        
        labels = labels.map((label) => {
          if(label.indexOf('-') > -1){
            let la =  label.split('-')
            return [la[0].trim()+" -",la[1].trim()]
          }
          if(label.indexOf('–') > -1){
            let la = label.split('–')
            return [la[0].trim()+" -",la[1].trim()]
          }
          return label
          
        })

        const data = {
            labels: labels,
            datasets:chartDatas
        };
    
        const canvasElement = document.getElementById('asset-phase-chart') as HTMLCanvasElement
        stackBarChart2 = new Chart(
          canvasElement,
          {
            type: 'bar',
            data: data,
            plugins: [ChartDataLabels],
            options: {
              plugins: {
                title: {
                  display: true,
                  text: 'Project Summary by Phase & Asset Type',
                  color: '#929daf',
                  font:{
                    size: 14,
                    family: 'tahoma',
                    weight: 'bold',
                  },
                  position: 'top'
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      let label = context.dataset.label || '';
                      return label;
                    }
                  }
                },
                datalabels: {
                  color: 'white',
                  font:function(context) {
                    var width = context.chart.width;
                    var size = Math.round(width / 32);
                    if(size > 16){
                      size = 16
                    }
                    if(size < 10){
                      size = 10
                    }
                    return {
                        weight: 'bold',
                        size: size
                    };
                  },
                  padding: 6
                },
                legend: {
                  onClick: () => null,
                  labels: {
                    font:function(context) {
                      var width = context.chart.width;
                      var size = 12
                      if(width > 200 && width <= 250){
                        size = 9
                      }
                      if(width <= 200){
                        size = 6
                      }
                      return {
                          size: size
                      };
                    },
                  }
                },
                subtitle: {
                  display: false,
                  text: 'Summary as of Mar 03,2022',
                  color: '#00338D',
                  font: {
                    size: 12,
                    family: 'tahoma',
                    weight: 'bold',
                    style: 'italic'
                  },
                  padding: {
                    bottom: 10
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false
                  },
                  ticks:{
                    font:function(context) {
                      var width = context.chart.width;
                      var size = 12
                      if(width > 200 && width <= 250){
                        size = 9
                      }
                      if(width <= 200){
                        size = 6
                      }
                      return {
                          size: size
                      };
                    },
                  }
                },
                y: {
                  stacked: true,
                  ticks: {
                    precision: 0
                  },
                  grid: {
                    display: false
                  }
                }
              }
            }
          }
        )
       
    }

    const createDecomPhaseChart = (assetTypePhases:AssetTypePhase[],labels:string[]) => {
        if(stackBarChart3){
          stackBarChart3.destroy()
        }
        
        let chartData = new Array(5).fill(null)
        let val1 = assetTypePhases.find(x => x.phaseNumber == 1)
        let val2 = assetTypePhases.find(x => x.phaseNumber == 2)
        let val3 = assetTypePhases.find(x => x.phaseNumber == 3)
        let val4 = assetTypePhases.find(x => x.phaseNumber == 4)
        let val5 = assetTypePhases.find(x => x.phaseNumber == 5)
        if(val1){
          chartData[0] = val1.count
        }
        if(val2){
          chartData[1] = val2.count
        }
        if(val3){
          chartData[2] = val3.count
        }
        if(val4){
          chartData[3] = val4.count
        }
        if(val5){
          chartData[4] = val5.count
        }

        labels = labels.map((label) => {
          if(label.indexOf('-') > -1){
            let la =  label.split('-')
            return [la[0].trim()+" -",la[1].trim()]
          }
          if(label.indexOf('–') > -1){
            let la = label.split('–')
            return [la[0].trim()+" -",la[1].trim()]
          }
          return label
          
        })

        const data = {
          labels: labels,
          datasets: [ {
            label: 'Project',
            data: chartData,
            backgroundColor: Color.Blue,
          }
          ]
        };
    
        const canvasElement = document.getElementById('decom-phase-chart') as HTMLCanvasElement
        stackBarChart2 = new Chart(
          canvasElement,
          {
            type: 'bar',
            data: data,
            plugins: [ChartDataLabels],
            options: {
              plugins: {
                title: {
                  display: true,
                  text: 'Project Summary by Phase',
                  color: '#929daf',
                  font:{
                    size: 14,
                    family: 'tahoma',
                    weight: 'bold',
                  },
                  position: 'top'
                },
                tooltip: {
                  callbacks: {
                    label: function(context) {
                      return "";
                    }
                  }
                },
                datalabels: {
                  color: 'white',
                  font:function(context) {
                    var width = context.chart.width;
                    var size = Math.round(width / 32);
                    if(size > 16){
                      size = 16
                    }
                    if(size < 10){
                      size = 10
                    }
                    return {
                        weight: 'bold',
                        size: size
                    };
                  },
                  padding: 6,
                  //formatter: Math.round
                },
                legend: {
                  display: false,
                  onClick: () => null,
                  labels: {
                    font:function(context) {
                      var width = context.chart.width;
                      var size = 12
                      if(width > 200 && width <= 250){
                        size = 9
                      }
                      if(width <= 200){
                        size = 6
                      }
                      return {
                          size: size
                      };
                    },
                  }
                },
                subtitle: {
                  display: false,
                  text: 'Summary as of Mar 03,2022',
                  color: '#00338D',
                  font: {
                    size: 12,
                    family: 'tahoma',
                    weight: 'bold',
                    style: 'italic'
                  },
                  padding: {
                    bottom: 10
                  }
                }
              },
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                x: {
                  stacked: true,
                  grid: {
                    display: false
                  },
                  ticks:{
                    font:function(context) {
                      var width = context.chart.width;
                      var size = 12
                      if(width > 200 && width <= 250){
                        size = 9
                      }
                      if(width <= 200){
                        size = 6
                      }
                      return {
                          size: size
                      };
                    },
                  }
                },
                y: {
                  stacked: true,
                  ticks: {
                    precision: 0
                  },
                  grid: {
                    display: false
                  }
                }
              }
            }
          }
        )
       
    }

    return (
         <div style={{ "padding" :"5px"}}>
          { !appState.isLoading ?
            <div className='row'>
                { props.projectModeId  == 0 ? <div className='col-md-4'></div> : null }
                { props.projectModeId  == ProjectModeNumber.Decomm ? <div className='col-md-2'></div> : null }
                
              
                <div id="summary-box" className='col-md-4' style={{ paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px', textAlign:"center" ,display: appState.chartSizeType === 1 ? 'inline' : 'none'}}>

                    <div style={{margin:'auto', marginBottom: '10px', height: "100%", minWidth: '270px', maxWidth: '470px' }}>
                        <div style={{ height: "100%", textAlign:"center" }}>
                            <span style={{fontWeight: "bold", color:"#929daf" }} >All Project Summary</span><br />
                            <table style={{ height: "75%", marginTop:"30px",overflowWrap:"anywhere",minWidth:'440px' }}>
                                <tr>
                                    <td rowSpan={4}>
                                        <a className="hvr-icon-bounce" style={{ height: "100%", cursor: 'default' }}>
                                            <table style={{ height: "100%", textAlign: "center" }}>
                                                <tr>
                                                <td className="info-box-3 bg-dark-blue">
                                        
                                                        <img src={rigImage} className="hvr-icon" style={{ width: '70px', filter: 'invert(1)', margin: "15px"}} /> 
                                                        
                                                </td>
                                                    <td style={{ width:"110px"}} >
                                                        <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }}>TOTAL</span><br />
                                                        <span className="" style={{ color: '#555555', padding: "5px", fontWeight: 'bold', marginLeft: '10px', marginRight: '2px'}}>
                                                          { appState.data && appState.data.projectSummary.totalProject < 100 ? 
                                                              <span style={{ fontSize: '48px' }}>{ appState.data.projectSummary.totalProject}</span> : null 
                                                          }
                                                          { appState.data && appState.data.projectSummary.totalProject >= 100 &&  appState.data.projectSummary.totalProject < 1000 ? 
                                                              <span style={{ fontSize: '42px' }}>{ appState.data.projectSummary.totalProject}</span> : null 
                                                          }
                                                          { appState.data && appState.data.projectSummary.totalProject >= 1000 ? 
                                                              <span style={{ fontSize: '33px' }}>{ appState.data.projectSummary.totalProject}</span> : null 
                                                          }
                                                        </span>
                                                </td>
                                                </tr>
                                            </table>
                                        </a>
                                    </td>
                                    <td>
                                        <a className="hvr-icon-spin"style={{ width: "100%", height: "100%", cursor: 'default' }}>
                                            <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                                <tr>
                                                    <td className="info-box-3 bg-orange" style={{width: "100px"}}>
                                                        <img src={nogoingImage} className="hvr-icon" style={{width: '35px',height: '35px', filter: 'invert(1)' }} />
                                                    </td>
                                                    <td style={{ width:"130px"}} >
                                                        <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>ONGOING</span><br />
                                                        <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                                          { appState.data?.projectSummary.totalActive}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </a>
                                    </td>
                                </tr>


                                <tr>
                                    <td>
                                        <a className="hvr-icon-pulse-grow" style={{ width: "100%", height: "100%", cursor: 'default' }}>
                                            <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                                <tr>
                                                    <td className="info-box-3 bg-green" style={{width: "100px"}}>
                                                        <img src={completedImage} className="hvr-icon" style={{width: '35px',height: '35px',filter: 'invert(1)'}} />
                                                    </td>
                                                    <td style={{ width: "130px" }} >
                                                        <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>COMPLETED</span><br />
                                                        <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                                          { appState.data?.projectSummary.totalCompleted}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <a className="hvr-icon-pulse-grow" style={{ width: "100%", height: "100%", cursor: 'default' }}>
                                            <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                                <tr>
                                                    <td className="info-box-3 bg-yellow" style={{width: "100px"}}>
                                                        <img src={pendingImage} className="hvr-icon" style={{width: '38px',height: '38px', filter: 'invert(1)'}} />
                                                    </td>
                                                    <td style={{ width: "130px" }} >
                                                        <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>PENDING</span><br />
                                                        <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                                          { appState.data?.projectSummary.totalPending}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </a>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        <a className="hvr-icon-pulse-grow" style={{ width: "100%", height: "100%", cursor: 'default' }}>
                                            <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                                <tr>
                                                    <td className="info-box-3 bg-red" style={{width: "100px"}}>
                                                        <img src={cancelImage} className="hvr-icon" style={{width: '33px',height: '33px', filter: 'invert(1)'}} />
                                                    </td>
                                                    <td style={{ width: "130px" }} >
                                                        <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>CANCEL</span><br />
                                                        <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                                          { appState.data?.projectSummary.totalCancel}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </table>
                                        </a>
                                    </td>
                                </tr>                     
                            </table>


                        </div>

                    </div>

             
                
                </div>
                
                <div id="summary-box-mini" className='col-md-4' style={{padding:'8px', textAlign:"center",display: appState.chartSizeType === 1 ? 'none' : 'inline'}}>

                  <div style={{margin:'auto', marginBottom: '10px', height: "100%", minWidth: '270px' }}>
                    <div style={{ height: "100%", textAlign:"center" }}>
                      <span style={{fontWeight: "bold", color:"#929daf" }} >All Project Summary</span><br />
                      <table className='summary-mini-table' style={{ height: "75%",overflowWrap:"anywhere" }}>
                        <tr>
                          <td rowSpan={4}>
                            <a className="hvr-icon-bounce" style={{ height: "100%", cursor: 'default' }}>
                              <table style={{ height: "100%", textAlign: "center" }}>
                                <tr>
                                  <td className="info-box-3 bg-dark-blue" style={{ width: '5px'}}>
                                  </td>
                                  <td  >
                                    <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '10px', fontSize: '20px' }}>TOTAL</span><br />
                                    <span className="" style={{ color: '#555555', padding: "5px", fontWeight: 'bold', marginLeft: '10px', marginRight: '2px', fontSize: '48px' }}>
                                            { appState.data && appState.data.projectSummary.totalProject < 100 ? 
                                                <span style={{ fontSize: '48px' }}>{ appState.data.projectSummary.totalProject}</span> : null 
                                            }
                                            { appState.data && appState.data.projectSummary.totalProject >= 100 &&  appState.data.projectSummary.totalProject < 1000 ? 
                                                <span style={{ fontSize: '42px' }}>{ appState.data.projectSummary.totalProject}</span> : null 
                                            }
                                            { appState.data && appState.data.projectSummary.totalProject >= 1000 ? 
                                                <span style={{ fontSize: '33px' }}>{ appState.data.projectSummary.totalProject}</span> : null 
                                            }
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                          <td>
                            <a className="hvr-icon-spin"style={{ width: "100%", height: "100%", cursor: 'default' }}>
                              <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <tr>
                                  <td className="info-box-3 bg-orange" style={{ width: '5px'}}>
                                    
                                  </td>
                                  <td>
                                    <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>ONGOING</span><br />
                                    <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                      { appState.data?.projectSummary.totalActive}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <a className="hvr-icon-pulse-grow" style={{ width: "100%", height: "100%", cursor: 'default' }}>
                              <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <tr>
                                  <td className="info-box-3 bg-green" style={{ width: '5px'}}>
                                   
                                  </td>
                                  <td >
                                    <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>COMPLETED</span><br />
                                    <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                      { appState.data?.projectSummary.totalCompleted}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <a className="hvr-icon-pulse-grow" style={{ width: "100%", height: "100%", cursor: 'default' }}>
                              <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <tr>
                                  <td className="info-box-3 bg-yellow" style={{ width: '5px'}}>
                                    
                                  </td>
                                  <td >
                                    <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>PENDING</span><br />
                                    <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                      { appState.data?.projectSummary.totalPending}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                        </tr>

                        <tr>
                          <td>
                            <a className="hvr-icon-pulse-grow" style={{ width: "100%", height: "100%", cursor: 'default' }}>
                              <table style={{ width: "100%", height: "100%", textAlign: "center" }}>
                                <tr>
                                  <td className="info-box-3 bg-red" style={{ width: '5px'}}>
                                   
                                  </td>
                                  <td>
                                    <span style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', fontSize: '16px' }}>CANCEL</span><br />
                                    <span className="" style={{ color: '#555555', fontWeight: 'bold', marginLeft: '0px', marginRight: '2px', fontSize: '20px' }}>
                                      { appState.data?.projectSummary.totalCancel}
                                    </span>
                                  </td>
                                </tr>
                              </table>
                            </a>
                          </td>
                        </tr>                     
                      </table>


                    </div>

                  </div>
                </div>
              
           
              { props.projectModeId > 0 ?
              <>
                { props.projectModeId != ProjectModeNumber.Decomm ? 
                  <>
                    <div id="asset-category-box" className='col-md-4' style={{ paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }}>
                      <div id="asset-category-container" className="chart-container" style={{ minHeight: '340px',margin: 'auto' }}>
                           <canvas id="asset-category-chart"></canvas>
                          
                      </div>
                    </div>
                
                    <div id="asset-phase-box" className='col-md-4 phase-box'>
                      <div id="asset-phase-container" className="chart-container" style={{minHeight: '360px',margin:'auto'}}> 
                        <canvas id="asset-phase-chart"></canvas>
                      </div> 
                    </div>
                  </>
                  :
                  <>
                    <div id="decom-phase-box" className='col-md-4 phase-box'>
                      <div id="decom-phase-container" className="chart-container" style={{ minHeight: '340px',margin:'auto' }}>
                        <canvas id="decom-phase-chart"></canvas>
                  
                      </div>
                    </div>
                    <div className='col-md-2' style={{ paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }}>
                  
                    </div>
              </>
              }
              </>:null
              }
            </div>:null
          }     
        </div>
  );
}

export default DashboardChart